import { ChartOptions } from 'chart.js'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { useInstance, useStore, useUser } from '../../contexts'
import QvtTimeSpentInMeeting from '../../models/QvtTimeSpentInMeeting'
import payload from '../../services/storage/payload'
import { toDayMonthString } from '../dateUtils'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import InfoBulle from '../share/Infobulle'
import { convertToRem } from '../utils'

const useStyles = () =>
    createUseStyles({
        qVTMonthlyEvolution: {
            width: '100%',
            maxHeight: convertToRem(500),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            gap: '2px',
            backgroundColor: 'var(--gd-main-app-background-color)',
            borderRadius: 'var(--gd-border-normal-radius)',
            padding: '20px',
        },
        graphContainer: {
            position: 'relative',
            height: '33vh',
        },
        titleContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            alignContent: 'center',
            width: '100%',
            height: '45px',
            gap: '8px',
        },
        title: {
            fontWeight: 'bold',
            fontSize: 'var(--gd-size-highlighted-small-title)',
            lineHeight: '20px',
        },
        legend: {
            borderRadius: 'var(--gd-border-normal-radius)',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '1rem',
            fontSize: 'var(--gd-size-legend-text)',
        },
        lineWithDot: {
            position: 'relative',
            height: '2px',
            backgroundColor: 'black',
            margin: '10px',
        },
        dot: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '10px',
            height: '10px',
            backgroundColor: 'black',
            borderRadius: '50%',
        },
        inMeeting: {
            backgroundColor: 'var(--gd-secondary-color)',
        },
        mobilized: {
            backgroundColor: 'var(--gd-primary-color)',
        },
        descriptionContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            backgroundColor: 'var(--gd-background-clear-color)',
            borderRadius: '10px',
            padding: '10px 20px',
            fontSize: '14px',
        },
        infobullIcon: {
            width: '20px',
            padding: '3px 0',
        },
        [MediaQueryBreakpoints.DESKTOP]: {
            container: {
                maxWidth: convertToRem(750),
            },
        },
        [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
            container: {
                maxWidth: 'unset',
            },
        },
        [MediaQueryBreakpoints.MOBILE]: {
            graphContainer: {
                width: '95%',
            },

            legend: {
                marginTop: convertToRem(15),
                paddingInline: '1rem',
                paddingBlock: '0.5rem',
                flexDirection: 'row',
                gap: '2rem',
                borderRadius: 'var(--gd-border-small-radius)',
            },
        },
    })()

interface DataType {
    x: string
    y: number
    labels: string[]
}

export default function QVTDailyMobilizeTeamMeetingEvolution() {
    const { lang } = useUser()
    const { customer } = useInstance()
    const {
        state: {
            userPayload: { qvtTimeSpentInMeetings },
        },
    } = useStore()
    const styles = useStyles()
    const customerTheme = customer.theme

    const convertAPIDataToChartData = (apiData: QvtTimeSpentInMeeting[]) => {
        if (!apiData) {
            return { datasets: [] }
        }
        const formatLabel = (totalTime: number, startDate: Date, endDate: Date, inMeeting = false) => {
            const hour = Math.floor(totalTime / 60)
            const minute = totalTime % 60
            return lang.goal.qvt.meetingEvolution.tooltipLabel(hour, minute, toDayMonthString(startDate.toString()), toDayMonthString(endDate.toString()), inMeeting)
        }

        const mobilized: DataType[] = apiData.map((dataPoint) => {
            return {
                labels: formatLabel(dataPoint.mobilized, dataPoint.dateRange[0], dataPoint.dateRange[1]),
                x: new Date(dataPoint.dateRange[1]).toString(),
                y: dataPoint.mobilized,
                min: 0,
            }
        })

        const style = {
            tension: 0,
            pointRadius: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: customerTheme.backgroundClearColor,
            pointHoverBorderWidth: 4,
        }

        return {
            datasets: [
                {
                    label: 'mobilized',
                    data: mobilized,
                    borderColor: customerTheme.secondaryColor,
                    backgroundColor: customerTheme.secondaryColor,

                    ...style,
                },
            ],
        }
    }

    const options: ChartOptions<'line'> = useMemo(() => {
        const chartOptions = {
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: customerTheme.textDarkColor,
                        callback: function (_, index) {
                            return toDayMonthString(this.getLabelForValue(index))
                        },
                    },
                },
                y: {
                    min: 0,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: customerTheme.textDarkColor,
                        stepSize: 60,
                        callback: (value) => `${Math.floor((value as number) / 60)}h`,
                    },
                },
            },
            spanGaps: false,
            maintainAspectRatio: false,
            plugins: {
                filler: {
                    drawTime: 'beforeDatasetsDraw',
                    propagate: true,
                },
                tooltip: {
                    displayColors: false,
                    backgroundColor: customerTheme.backgroundDarkColor,
                    bodyColor: customerTheme.textDarkColor,
                    bodyFont: {
                        size: 14,
                    },
                    bodyAlign: 'center',
                    callbacks: {
                        title: () => '',
                        label: (context) => (context.raw as DataType).labels || '',
                    },
                },
            },
        } as ChartOptions<'line'>
        return chartOptions
    }, [payload, lang])

    const chartData = convertAPIDataToChartData(qvtTimeSpentInMeetings)

    return (
        <div className={styles.qVTMonthlyEvolution}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{lang.goal.qvt.dailyMobilizeTeamMeetingEvolution.title}</div>
                <InfoBulle translateX='-50%' component={null}>
                    <div className={styles.infobullIcon}>
                        <IconAssets.Information />
                    </div>
                </InfoBulle>
            </div>

            <div className={styles.graphContainer}>
                <Line data={chartData} options={options} redraw updateMode='resize' />
            </div>
            <div className={styles.descriptionContainer}>
                <h3>{lang.goal.qvt.dailyMobilizeTeamMeetingEvolution.description.title} !</h3>
                <span>{lang.goal.qvt.dailyMobilizeTeamMeetingEvolution.description.text}</span>
            </div>
        </div>
    )
}
