import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import AwardIcon from '../assets/icon/welcomeback/AwardIcon'
import EmissionsIcon from '../assets/icon/welcomeback/EmissionsIcon'
import EngagementIcon from '../assets/icon/welcomeback/EngagementIcon'
import EvolutionIcon from '../assets/icon/welcomeback/EvolutionIcon'
import { useInstance, useStore, useUser } from '../contexts'
import CarbonImpactIndicatorsDailyDto from '../models/CarbonImpactIndicatorsDaily'
import UserPayload from '../models/UserPayload'
import Popup from './Popup'
import { toLiteralMonthYearString } from './dateUtils'
import { GreetButton } from './share/GreetButton'
import { capitalizeFirstLetter, convertToGCO2, numberFormatter, positionDecorator } from './utils'
import services from '../services'

const WelcomeBack = () => {
    const { user, switchPopUp } = useUser()
    const { customer } = useInstance()
    const { state } = useStore()
    const [needWelcome, setNeedWelcome] = useState<boolean>(!!user?.isFirstMonthlyLogin || !!user?.showPopup || !customer.canViewOnlyQVTGoal)
    const [removePopUp, setRemovePopup] = useState(!services.storage.appPreferences.get()?.showWelcomePopup)

    if (needWelcome === false || customer.canViewOnlyQVTGoal || !!user?.rulesAgreements === false || user?.rulesAgreementsDateTime == null || !services.storage.appPreferences.get()?.showWelcomePopup) {
        return null
    }

    const handleChange = () => {
        setRemovePopup(!removePopUp)
    }

    const onClose = () => {
        setNeedWelcome(false)
        services.storage.appPreferences.store({
            ...services.storage.appPreferences.get(),
            showWelcomePopup: !removePopUp,
        })
        if (removePopUp) switchPopUp(false)
    }

    return (
        <Popup onCloseRequest={onClose} closeOnOutside={false}>
            <WelcomeBackContent onClose={onClose} userPayload={state.userPayload} removePopUp={removePopUp} handleChange={handleChange} />
        </Popup>
    )
}

interface WelcomeBackContentProps {
    userPayload: UserPayload
    removePopUp: boolean
    onClose: () => void
    handleChange: () => void
}

const useWelcomeBackContentStyles = createUseStyles({
    welcomeBack: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '50vw',
        minHeight: '50vh',
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--gd-background-clear-color)',
    },
    welcomeBackContent: {
        backgroundColor: 'var(--gd-main-popup-background-color)',
    },
    welcomeBackContentHeader: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    welcomeBackContentHeaderDate: {
        lineHeight: '20px',
        padding: '20px 40px',
        backgroundColor: 'var(--gd-primary-color)',
        fontSize: '20px',
        fontWeight: '700',
        color: 'white',
        borderBottomRightRadius: 'var(--gd-border-normal-radius)',
    },
    welcomeBackContentBody: {
        color: 'var(--gd-text-dark-color)',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        padding: '50px',
        gap: '25px',
    },
    welcomeBackContentBodyColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '25px',
    },
    welcomeBackContentBodyColumnTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
    welcomeBackContentFooter: {
        padding: '20px 50px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    welcomeBackContentFooterMessage: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: '700',
    },

    welcomeBackDisplayPopUp: {
        padding: '20px 50px',
        backgroundColor: 'var(--gd-main-popup-background-color)',
    },

    welcomeBackCheckBoxDisplayPopup: {
        marginRight: '10px',
    },
})

const WelcomeBackContent = ({ userPayload, onClose, removePopUp, handleChange }: WelcomeBackContentProps) => {
    const { lang } = useUser()
    const styles = useWelcomeBackContentStyles()
    const userRanking = selectRanking(userPayload)
    const { welcomeBack } = lang
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth() == 0 ? 'Bilan 2023' : capitalizeFirstLetter(toLiteralMonthYearString(currentDate, lang.locale))
    const currentRank = userRanking?.rank ?? userRanking?.maxRank ?? 0
    const currentRankDecoration = positionDecorator(currentRank, lang.locale)
    const decil = userRanking?.decile ?? 0
    const currentPoints = numberFormatter(userRanking?.score ?? 0)
    const emissionCumuleeActuelles = calculerEmissionCumulees(userPayload?.consolidatedCarbonImpact?.daysOfCurrentYear)
    const emissionCumuleeLastYear = calculerEmissionCumulees(userPayload?.consolidatedCarbonImpact?.daysOfLastYear)
    const emissionCumuleeActuellesMesure = convertToGCO2(emissionCumuleeActuelles ?? 0)
    const emissionCumuleeLastYearMesure = convertToGCO2(emissionCumuleeLastYear ?? 0)
    const evolutionEmissionCumulees = calculerTauxEvolutionEmissionCumuleesEn1An(
        userPayload?.consolidatedCarbonImpact?.daysOfCurrentYear,
        userPayload?.consolidatedCarbonImpact?.daysOfLastYear,
    )
    const evolutionEmissionCumuleesPrefix = welcomeBack.evolutionCoparaison(evolutionEmissionCumulees)
    const evolutionEmissionCumuleesCalculees = `${evolutionEmissionCumuleesPrefix} ${numberFormatter(Math.abs(evolutionEmissionCumulees))}`

    const getPositioningAccordingToDecile = (decil: number) => {
        switch (decil) {
            case 1:
                return `${lang.welcomeBack.lessThan} 90`
            case 2:
                return `${lang.welcomeBack.lessThan} 80`
            case 3:
                return `${lang.welcomeBack.lessThan} 70`
            case 4:
                return `${lang.welcomeBack.lessThan} 60`
            case 5:
                return `${lang.welcomeBack.lessThan} 50`
            case 6:
                return `${lang.welcomeBack.moreThan} 50`
            case 7:
                return `${lang.welcomeBack.moreThan} 60`
            case 8:
                return `${lang.welcomeBack.moreThan} 70`
            case 9:
                return `${lang.welcomeBack.moreThan} 80`
            case 10:
                return `${lang.welcomeBack.moreThan} 90`
        }
    }

    return (
        <div className={styles.welcomeBack}>
            <div className={styles.welcomeBackContent}>
                <div className={styles.welcomeBackContentHeader}>
                    <div className={styles.welcomeBackContentHeaderDate}>{currentMonth}</div>
                </div>
                <div className={styles.welcomeBackContentBody}>
                    <div className={styles.welcomeBackContentBodyColumn}>
                        <EmissionsIcon />
                        <div className={styles.welcomeBackContentBodyColumnTitle}>{welcomeBack?.emissionTitle}</div>
                        <WelcomeBackCard
                            indicatorTitle={welcomeBack?.emissionCard1.title}
                            indicator={
                                currentDate.getMonth() === 0
                                    ? `${emissionCumuleeLastYearMesure.value} ${emissionCumuleeLastYearMesure.measured}`
                                    : `${emissionCumuleeActuellesMesure.value} ${emissionCumuleeActuellesMesure.measured}`
                            }
                            description={welcomeBack?.emissionCard1.description}
                        />
                        <WelcomeBackCard
                            indicatorTitle={welcomeBack?.emissionCard2.title}
                            indicator={getPositioningAccordingToDecile(decil)}
                            indicatorUnit={'%'}
                            description={welcomeBack?.emissionCard2.description}
                        />
                        <WelcomeBackCard indicatorTitle={welcomeBack?.emissionCard3.title} description={welcomeBack?.emissionCard3.description} />
                    </div>
                    <div className={styles.welcomeBackContentBodyColumn}>
                        <EvolutionIcon />
                        <div className={styles.welcomeBackContentBodyColumnTitle}>{welcomeBack?.evolutionTitle}</div>
                        <WelcomeBackCard
                            indicatorTitle={welcomeBack?.evolutionCard1.title}
                            indicator={`${evolutionEmissionCumuleesCalculees}`}
                            indicatorUnit='%'
                            description={welcomeBack?.evolutionCard1.description}
                        />
                        <WelcomeBackCard indicatorTitle={welcomeBack?.evolutionCard2.title} description={welcomeBack?.evolutionCard2.description} />
                        <WelcomeBackCard indicatorTitle={welcomeBack?.evolutionCard3.title} description={welcomeBack?.evolutionCard3?.descriptionParams?.('')} />
                    </div>
                    <div className={styles.welcomeBackContentBodyColumn}>
                        <EngagementIcon />
                        <div className={styles.welcomeBackContentBodyColumnTitle}>{welcomeBack?.engagementTitle}</div>
                        <WelcomeBackCard indicator={`${currentPoints}`} indicatorTitle={welcomeBack?.engagementCard1.descriptionParams?.()} indicatorUnit='pts' />
                        <WelcomeBackCard
                            indicator={`${currentRank}`}
                            indicatorDecorator={<sup>{currentRankDecoration}</sup>}
                            indicatorUnit='place'
                            indicatorTitle={welcomeBack?.engagementCard2.title}
                            description={welcomeBack?.engagementCard2.descriptionParams?.(userRanking?.maxRank)}
                        />
                        <WelcomeBackCard description={welcomeBack?.engagementCard3.description} />
                    </div>
                </div>
            </div>
            <div className={styles.welcomeBackDisplayPopUp}>
                <label>
                    <input className={styles.welcomeBackCheckBoxDisplayPopup} type='checkbox' checked={removePopUp} onChange={handleChange} />
                    {welcomeBack.checkboxPopUp}
                </label>
            </div>
            <div className={styles.welcomeBackContentFooter}>
                <div className={styles.welcomeBackContentFooterMessage}>
                    <AwardIcon />
                    <div>{welcomeBack?.footerText}</div>
                </div>
                <GreetButton label={welcomeBack?.buttonLabel} onClick={onClose} />
            </div>
        </div>
    )
}

const selectRanking = (userPayload: UserPayload) => {
    if (new Date().getMonth() === 0) return userPayload?.rankingSummary ?? {}
    return userPayload?.ranking ?? {}
}
const calculerEmissionCumulees = (donnees: CarbonImpactIndicatorsDailyDto[]) => {
    return donnees?.map((element) => element?.ci ?? 0)?.reduce((acc, curr) => acc + curr, 0)
}

const calculerTauxEvolutionEmissionCumuleesEn1An = (donneesActuelles: CarbonImpactIndicatorsDailyDto[], donneesAciennes: CarbonImpactIndicatorsDailyDto[]) => {
    const donneesClonees: CarbonImpactIndicatorsDailyDto[] = structuredClone(donneesActuelles ?? [])
    const anciennesDonneesClonnees: CarbonImpactIndicatorsDailyDto[] = structuredClone(donneesAciennes ?? [])
    anciennesDonneesClonnees.length = donneesClonees.length
    const cumulActuel = calculerEmissionCumulees(donneesClonees)
    const cumulAncien = calculerEmissionCumulees(anciennesDonneesClonnees)
    if (cumulAncien == 0) {
        // Soit une augmentation de 100% vu qu'il n'y a aucune données pour l'année dernière
        return -100
    }

    return ((cumulAncien - cumulActuel) / (cumulAncien ?? 1)) * 100
}

interface WelcomeBackCardProps {
    indicatorTitle?: string
    indicator?: string
    indicatorDecorator?: any
    indicatorUnit?: string
    description?: string
}

const useWelcomeBackCardStyle = createUseStyles({
    welcomeBackCard: {
        width: '100%',
        maxWidth: '300px',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: 'var(--gd-border-small-radius)',
        padding: '10px',
        fontSize: '14px',
        whiteSpace: 'pre-line',
        gap: '10px',
    },
    welcomeBackCardIndicator: {
        fontSize: '18px',
        fontWeight: '700',
        '& span': {
            display: 'inline-block',
            lineHeight: '15px',
            padding: '8px 10px',
            color: 'var(--gd-primary-color)',
            backgroundColor: 'var(--gd-main-app-background-color)',
            borderRadius: 'var(--gd-border-normal-radius)',
            '& sup': {
                fontSize: '12px',
            },
        },
    },
})

const WelcomeBackCard = ({ indicatorTitle, indicator, indicatorDecorator, indicatorUnit, description }: WelcomeBackCardProps) => {
    const styles = useWelcomeBackCardStyle()
    return (
        <div className={styles.welcomeBackCard}>
            {indicatorTitle != null ? <div>{indicatorTitle}</div> : null}
            {indicator != null ? (
                <div className={styles.welcomeBackCardIndicator}>
                    <span>
                        {indicator}
                        {indicatorDecorator} {indicatorUnit}
                    </span>
                </div>
            ) : null}
            {description != null ? <div>{description}</div> : null}
        </div>
    )
}

export default WelcomeBack
