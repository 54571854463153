import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { useStore, useUser } from '../../contexts'
import { Badge as BadgeModel } from '../../models/Badge'
import Badge, { BadgeTitle } from '../Badge'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import Title from '../Title'
import { GreetButton } from '../share/GreetButton'
import GreetFeaturingComponent, { GreetFeaturingTarget } from '../share/GreetFeaturingComponent'
import MatesVictories from './MatesVictories'

const useStyles = createUseStyles({
    victories: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '10px 0',
        gap: '10px',
        marginTop: '25px',
    },
    badgesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
    },
    badges: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
    },
    mobileWrapper: {
        display: 'none',
    },
    mobileBadgesWrapper: {
        display: 'none',
    },
    mobileBadges: {
        display: 'none',
    },
    mobileTitledBadge: {
        display: 'none',
    },
    showAllBadgesArea: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    showAllBadges: {
        width: 'unset',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        victories: {
            width: '90%',
            padding: '30px 0',
            gap: 'unset',
        },
        badgesWrapper: {
            display: 'none',
        },
        mobileWrapper: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
        },
        mobileBadgesWrapper: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '25px',
        },
        mobileBadges: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            maxWidth: '90vw',
            overflowX: 'scroll',
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
        },
        mobileTitledBadge: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '25px',
        },
        showAllBadgesArea: {
            justifyContent: 'center',
        },
        showAllBadges: {
            width: '100%',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        victories: {
            width: '100%',
            padding: '30px 20px',
        },
        showAllBadgesArea: {
            justifyContent: 'flex-end',
        },
    },
})

const NB_BADGES_IN_LINE = 4
const NB_ROWS_IN_COLUMN = 1

export default function Victory() {
    return <GreetFeaturingComponent target={GreetFeaturingTarget.RANKING_VICTORIES} onlyTarget legacyDef={VictoryLegacy} nextDef={MatesVictories} />
}

const VictoryLegacy = () => {
    const { lang } = useUser()
    const { state } = useStore()
    const { userPayload } = state
    const [allBadgesDisplayed, setAllBadgesDisplayed] = useState<boolean>(false)
    const styles = useStyles()
    const badges = userPayload?.userBadges != null ? [...userPayload.userBadges] : []

    if (!badges.length) {
        return <></>
    }

    badges.sort((a, b) => (a.type === b.type ? -1 : 1))
    const groupedBadges = badges.reduce<{ [key: string]: BadgeModel[] }>((group, badge) => {
        ;(group[badge.type] = group[badge.type] || []).push(badge)
        return group
    }, {})

    return (
        <div className={styles.victories}>
            <Title title={lang.home.victory.title} />
            <div className={styles.badgesWrapper}>
                <div className={styles.badges}>
                    {(!allBadgesDisplayed ? badges.slice(0, NB_BADGES_IN_LINE) : badges).map((item, index) => (
                        <Badge
                            key={item.id}
                            badge={item}
                            showBadgeTitle={index % NB_BADGES_IN_LINE === 0 || (index > 0 && badges[index - 1].type !== item.type)}
                            separateBadge={index < badges.length - 1 && badges[index + 1].type !== item.type}
                        />
                    ))}
                </div>
                {badges.length > NB_BADGES_IN_LINE ? (
                    <div className={styles.showAllBadgesArea}>
                        <GreetButton
                            type='secondary-inv'
                            label={allBadgesDisplayed ? lang.home.victory.badges.reduceDisplayedBadges : lang.home.victory.badges.displayAllBadges}
                            icon={allBadgesDisplayed ? <IconAssets.Minus /> : <IconAssets.PlusLightIcon />}
                            onClick={() => setAllBadgesDisplayed((prev) => !prev)}
                        />
                    </div>
                ) : null}
            </div>

            {/* mobile */}
            <div className={styles.mobileWrapper}>
                <div className={styles.mobileBadgesWrapper}>
                    <div>
                        {(!allBadgesDisplayed ? Object.keys(groupedBadges).slice(0, NB_ROWS_IN_COLUMN) : Object.keys(groupedBadges)).map((key) => {
                            return (
                                <div key={key} className={styles.mobileTitledBadge}>
                                    <BadgeTitle badge={groupedBadges[key][0]} showCardTitle={true} />
                                    <div className={styles.mobileBadges}>
                                        {groupedBadges[key].map((item) => (
                                            <Badge key={item.id} badge={item} showBadgeTitle={false} />
                                        ))}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {Object.keys(groupedBadges).length > NB_ROWS_IN_COLUMN ? (
                        <div className={styles.showAllBadgesArea}>
                            <GreetButton
                                type='secondary-inv'
                                label={allBadgesDisplayed ? lang.home.victory.badges.reduceDisplayedBadges : lang.home.victory.badges.displayAllBadges}
                                icon={allBadgesDisplayed ? <IconAssets.Minus /> : <IconAssets.PlusLightIcon />}
                                onClick={() => setAllBadgesDisplayed((prev) => !prev)}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
