import { ArcElement, Chart, Tooltip } from 'chart.js'
import { useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { createUseStyles } from 'react-jss'
// import IconAssets from '../../../assets/icon/IconAssets'
import CaretLeft from '../../../assets/icon/CaretLeft'
import CaretRight from '../../../assets/icon/CaretRight'
import IconAssets from '../../../assets/icon/IconAssets'
import IllustrationAssets from '../../../assets/illustration/IllustrationAssets'
import { useInstance, useUser } from '../../../contexts'
import { DigitalCleanupDayChallenge, Lang } from '../../../models'
import { CleanupDayI } from '../../../models/challenges/DigitalCleanupDayChallenge'
import { MediaQueryBreakpoints } from '../../MediaQueryBreakpoints'
import { toDayLiteralMonthString } from '../../dateUtils'
import { octetsConverter } from '../../utils'

Chart.register(ArcElement, Tooltip)

const useStyles = () =>
    createUseStyles({
        digitalCleanupDay: {
            height: '275px',
            width: '100%',
            borderRadius: 'var(--gd-border-normal-radius)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            textAlign: 'center',
            gap: '10px',
        },
        periodContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: 'var(--gd-primary-color)',
            fontSize: 'var(--gd-size-highlighted-small-title)',
            fontWeight: 'bold',
            height: '2rem',
            borderRadius: 'var(--gd-border-big-radius)',
            paddingInline: '1.5rem',
            backgroundColor: '#FFFFFF',
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: 'var(--gd-size-small-body-text)',
            height: '10px',
            margin: '5px 0',
        },
        arrowIcon: {
            width: '15px',
            height: '100%',
            alignSelf: 'center',
            margin: '0 10px 40px 10px',
            cursor: 'pointer',
        },
        doughnutWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center',
            width: '100%',
            height: '180px',
        },
        titleBold: {
            fontWeight: '700',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            periodContainer: {
                height: '35px',
            },
        },
    })()

interface DigitalCleanupDayProps {
    challenge: DigitalCleanupDayChallenge
}

export default function DigitalCleanupDay({ challenge }: DigitalCleanupDayProps) {
    const { lang } = useUser()
    const entities = buildDetailEntities(lang, challenge)
    const [index, setIndex] = useState(0)
    const styles = useStyles()

    if (challenge.startDate == null || challenge.endDate == null) {
        return null
    }

    const period = {
        startDate: new Date(challenge.startDate),
        endDate: new Date(challenge.endDate),
    }

    const nextDoughnut = () => {
        setIndex((index + 1) % entities.length)
    }

    const prevDoughnut = () => {
        setIndex(Math.abs(index - 1 + entities.length) % entities.length)
    }

    return (
        <div className={styles.digitalCleanupDay}>
            <div className={styles.periodContainer}>
                {lang.date.range(toDayLiteralMonthString(period.startDate, lang.locale), toDayLiteralMonthString(period.endDate, lang.locale))}
            </div>
            <div className={styles.details}>
                {lang.code === 'fr' ? (
                    <span>
                        {entities[index].title}
                        <span className={styles.titleBold}>{entities[index].titleBold}</span>
                    </span>
                ) : (
                    <span>
                        <span className={styles.titleBold}>{entities[index].titleBold}</span>
                        {entities[index].title}
                    </span>
                )}
            </div>
            <div className={styles.doughnutWrapper}>
                <div className={styles.arrowIcon} onClick={prevDoughnut}>
                    <CaretLeft fillColor='var(--gd-primary-dark-color)' />
                </div>
                <ChallengeDoughnut driveValue={entities[index].entity.driveValue} mailValue={entities[index].entity.mailValue} />

                <div className={styles.arrowIcon} onClick={nextDoughnut}>
                    <CaretRight fillColor='var(--gd-primary-dark-color)' />
                </div>
            </div>
        </div>
    )
}

const useChallengeDougnutsStyle = createUseStyles({
    challengeDoughnut: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: '10px',
    },
    totalDoughnetContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    total: {
        fontSize: 'var(--gd-size-highlighted-small-number)',
        alignSelf: 'center',
        position: 'relative',
        top: '42px',
        fontWeight: 'bold',
        height: 0,
    },
    doughnut: {
        width: '105px',
        height: '100px',
    },
    challengeDoughnutLegend: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '40px',
    },
    noChallengeData: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 'var(--gd-size-small-body-text)',
        width: '98%',
    },
    noChallengeDataMainTitle: {
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'bold',
    },
})

const ChallengeDoughnut = ({ driveValue, mailValue }: CleanupDayI): JSX.Element => {
    const styles = useChallengeDougnutsStyle()
    const { customer } = useInstance()
    const { lang } = useUser()
    const theme = customer.theme
    const driveValueOctets = driveValue * 1024 * 1024
    const mailValueOctets = mailValue * 1024 * 1024
    const totalOctets = driveValueOctets + mailValueOctets
    const total = Math.floor(driveValue) + Math.floor(mailValue)
    const totalConverted = octetsConverter(totalOctets, lang.code)
    if (total === 0) {
        return (
            <div className={styles.challengeDoughnut}>
                <IllustrationAssets.NoDeletionChallengeData />
                <div className={styles.noChallengeData}>
                    <span className={styles.noChallengeDataMainTitle}>{lang.goal.challenge.digitalCleanupDay.noDataTitle}</span>
                    <span>{lang.goal.challenge.digitalCleanupDay.noDataText}</span>
                </div>
            </div>
        )
    }
    return (
        <div className={styles.challengeDoughnut}>
            <div className={styles.totalDoughnetContainer}>
                <span className={styles.total}>
                    {totalConverted.value} <span>{totalConverted.measured}</span>
                </span>
                <div className={styles.doughnut}>
                    <Doughnut
                        data={{
                            datasets: [
                                {
                                    data: [mailValueOctets, driveValueOctets],
                                    backgroundColor: [theme.brickEmailColor, theme.brickOneDriveColor],
                                    borderWidth: 0,
                                },
                            ],
                        }}
                        options={{
                            cutout: '65%',
                            hover: { mode: undefined },
                            plugins: {
                                tooltip: {
                                    enabled: false,
                                },
                                legend: {
                                    display: false,
                                },
                            },
                        }}
                    />
                </div>
            </div>
            <ChallengeLegend driveValue={driveValueOctets} mailValue={mailValueOctets} />
        </div>
    )
}

const useChallengeLegendStyle = createUseStyles({
    challengeDoughnutLegend: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '40px',
    },
})

const ChallengeLegend = (entity: CleanupDayI) => {
    const styles = useChallengeLegendStyle()
    const { lang } = useUser()
    return (
        <div className={styles.challengeDoughnutLegend}>
            <ChallengeLegendElement
                lang={lang}
                icon={<IconAssets.OneDriveIcon />}
                value={entity.driveValue}
                description={lang.goal.challenge.digitalCleanupDay.files}
                color='var(--gd-brick-onedrive-color)'
            />
            <ChallengeLegendElement
                lang={lang}
                icon={<IconAssets.EmailIcon />}
                value={entity.mailValue}
                description={lang.goal.challenge.digitalCleanupDay.mails}
                color='var(--gd-brick-email-color)'
            />
        </div>
    )
}

const useChallengeLegendElementStyle = (color: string) =>
    createUseStyles({
        challengeLegendElement: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        challengeLegendElementValue: {
            fontWeight: 'bold',
            fontSize: 'var(--gd-size-highlighted-small-number)',
            marginTop: '2px',
        },
        challengeLegendElementDecription: {
            fontWeight: 'normal',
            fontSize: 'var(--gd-size-legend-text)',
        },
        challengeLegendElementLine: {
            height: '8px',
            width: '70%',
            marginTop: '5px',
            borderRadius: 'var(--gd-border-normal-radius)',
            transition: 'width 0.5s ease',
            backgroundColor: color,
        },
    })()

const ChallengeLegendElement = ({ lang, icon, value, description, color }: any) => {
    const styles = useChallengeLegendElementStyle(color)
    const convertedValue = octetsConverter(value, lang.code)
    return (
        <div className={styles.challengeLegendElement}>
            {icon}
            <div className={styles.challengeLegendElementValue}>
                {convertedValue.value} {convertedValue.measured}
            </div>
            <div className={styles.challengeLegendElementDecription}>{description}</div>
            <div className={styles.challengeLegendElementLine}></div>
        </div>
    )
}

const buildDetailEntities = (lang: Lang, challenge: DigitalCleanupDayChallenge) => {
    return [
        {
            title: lang.goal.challenge.digitalCleanupDay.after.title,
            titleBold: '',
            entity: challenge.user ? challenge.user : { mailValue: 0, driveValue: 0 },
        },
        {
            title: lang.goal.challenge.digitalCleanupDay.after.titleGroup,
            titleBold: lang.goal.challenge.digitalCleanupDay.after.groupName,
            entity: challenge.group ? challenge.group : { mailValue: 0, driveValue: 0 },
        },
        {
            title: lang.goal.challenge.digitalCleanupDay.after.titleCompany,
            titleBold: lang.goal.challenge.digitalCleanupDay.after.companyName,
            entity: challenge.company ? challenge.company : { mailValue: 0, driveValue: 0 },
        },
    ] as Array<{
        title: string
        titleBold: string
        entity: CleanupDayI
    }>
}
