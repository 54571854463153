import { Lang } from '../../../../models'
import defaultLang from '../../../lang/fr'


const tenFr: Lang = {
    ...defaultLang,
    goal: {
        ...defaultLang.goal,
        individual: {
            ...defaultLang.goal.individual,
            button: {
                title: 'Moi',
                addIndicators: 'Ajoutez vos indicateurs',
                qvt: 'Mes indicateurs QCVT',
            },
        }
    },
}

export default tenFr
