import { createUseStyles } from 'react-jss'

import classnames from 'classnames'
import IconAssets from '../assets/icon/IconAssets'
import { useStore, useUser } from '../contexts'
import { Lang } from '../models'
import { Badge as BadgeModel, BadgeType } from '../models/Badge'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import { substractYears, toLiteralIntervalParts, toYearString } from './dateUtils'
import { getCohortName, getPeriod } from './utils'

interface BadgeProps {
    badge: BadgeModel
    showBadgeTitle?: boolean
    separateBadge?: boolean
}

interface BadgeDetails {
    title: string
    textContent: string
    carbonEmissionDecreaseText: string
    unit: string
    color: string
    textColor: string
    lineColor: string
    icon: React.ReactNode
    reducedBy: number
}

const ICON_SIZE = {
    width: 88,
    height: 81,
}

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        maxWidth: '285px',
        minWidth: '285px',
        minHeight: '322px',
        gap: '25px',
    },
    badgeTitle: {
        display: 'block',
    },
    badgeWrapper: {
        display: 'flex',
        flex: '1 1 250px',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    badge: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'var(--gd-background-clear-color)',
        width: '95%',
        height: '100%',
        borderRadius: 'var(--gd-border-normal-radius)',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '25px',
        width: '90%',
        height: '90%',
        marginTop: '25px',
        color: 'var(--gd-text-dark-color)',
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'bold',
    },
    period: {
        width: '80%',
        backgroundColor: '#FFFFFF',
        color: 'var(--gd-secondary-dark-color)',
        borderRadius: 'var(--gd-border-big-radius)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: 'var(--gd-size-small-body-text)',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        rowGap: '5px',
        width: '80%',
        height: '50px',
        fontSize: 'var(--gd-size-small-body-text)',
    },
    iconWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        width: `${ICON_SIZE.width}px`,
        height: `${ICON_SIZE.height}px`,
    },
    valueContainer: {
        position: 'relative',
        bottom: `${ICON_SIZE.height / 2 + 16}px`,
        right: '3px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        columnGap: '3px',
        color: '#FFFFFF',
        fontSize: 'var(--gd-size-small-body-text)',
    },
    value: {
        fontSize: 'var(--gd-size-highlighted-number)',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        container: {
            minHeight: 'unset',
        },
        badgeTitle: {
            display: 'none',
        },
    },
})

export function getBadgeDetails(badge: BadgeModel, lang: Lang): BadgeDetails | undefined {
    const units = {
        fr: 'Mo',
        en: 'Mb',
    }

    const daysUnits = {
        fr: 'jours',
        en: 'days',
    }

    const badgePeriodeComparaison = toYearString(substractYears(badge.period, 1))

    switch (badge.type) {
        case BadgeType.DRIVE:
            return {
                title: lang.home.victory.badges.drive.title,
                textContent: lang.home.victory.badges.drive.reducedBy,
                carbonEmissionDecreaseText: lang.home.victory.badges.drive.comparedToLastYear(Math.round(badge.reducedBy), badgePeriodeComparaison),
                unit: units[lang.code as 'fr' | 'en'],
                icon: <IconAssets.Garbage />,
                color: 'var(--gd-badge-cleanup-color)',
                lineColor: 'var(--gd-badge-cleanup-line-color)',
                textColor: 'var(--gd-badge-text-color)',
                reducedBy: badge.reducedBy,
            }
        case BadgeType.MAIL:
            return {
                title: lang.home.victory.badges.mail.title,
                textContent: lang.home.victory.badges.mail.reducedBy,
                carbonEmissionDecreaseText: lang.home.victory.badges.mail.comparedToLastYear(Math.round(badge.reducedBy), badgePeriodeComparaison),
                unit: units[lang.code as 'fr' | 'en'],
                icon: <IconAssets.Attachement />,
                color: 'var(--gd-badge-mails-on-diet-color)',
                lineColor: 'var(--gd-badge-mails-on-diet-line-color)',
                textColor: 'var(--gd-badge-text-color)',
                reducedBy: badge.reducedBy,
            }
        case BadgeType.PLANET_FRIENDLY:
            return {
                title: lang.home.victory.badges.planetFriendly.title,
                textContent: lang.home.victory.badges.planetFriendly.reducedBy,
                carbonEmissionDecreaseText: lang.home.victory.badges.planetFriendly.comparedToLastYear(Math.round(badge.reducedBy), badgePeriodeComparaison),
                unit: '%',
                icon: <IconAssets.PlanetFriendlyIcon />,
                color: 'var(--gd-badge-planet-friendly-color)',
                lineColor: 'var(--gd-badge-planet-friendly-line-color)',
                textColor: 'var(--gd-badge-text-color)',
                reducedBy: badge.reducedBy,
            }
        case BadgeType.INTERN_ATTACHMENT:
            return {
                title: lang.home.victory.badges.internAttachment.title,
                textContent: lang.home.victory.badges.internAttachment.reducedBy,
                carbonEmissionDecreaseText: lang.home.victory.badges.internAttachment.comparedToLastYear(),
                unit: daysUnits[lang.code as 'fr' | 'en'],
                icon: <IconAssets.InternAttachment />,
                color: 'var(--gd-badge-intern-attachment-color)',
                lineColor: 'var(--gd-badge-intern-attachment-line-color)',
                textColor: 'var(--gd-badge-text-color)',
                reducedBy: 0,
            }
        case BadgeType.MAILSHOT:
            return {
                title: lang.home.victory.badges.mailShot.title,
                textContent: lang.home.victory.badges.mailShot.reducedBy,
                carbonEmissionDecreaseText: lang.home.victory.badges.mailShot.comparedToLastYear(Math.round(badge.reducedBy)),
                unit: daysUnits[lang.code as 'fr' | 'en'],
                icon: <IconAssets.MailShot />,
                color: 'var(--gd-badge-mailshot-color)',
                lineColor: 'var(--gd-badge-mailshot-line-color)',
                textColor: 'var(--gd-badge-text-color)',
                reducedBy: 0,
            }
        case BadgeType.IMAGE_HUNT:
            return {
                title: lang.home.victory.badges.imageHunt.title,
                textContent: lang.home.victory.badges.imageHunt.reducedBy,
                carbonEmissionDecreaseText: lang.home.victory.badges.imageHunt.comparedToLastYear(Math.round(badge.reducedBy)),
                unit: units[lang.code as 'fr' | 'en'],
                icon: <IconAssets.ImageHunt />,
                color: 'var(--gd-badge-imagehunt-color)',
                lineColor: 'var(--gd-badge-imagehunt-line-color)',
                textColor: 'var(--gd-badge-text-color)',
                reducedBy: badge.reducedBy,
            }
        case BadgeType.VIDEO_HUNT:
            return {
                title: lang.home.victory.badges.videoHunt.title,
                textContent: lang.home.victory.badges.videoHunt.reducedBy,
                carbonEmissionDecreaseText: lang.home.victory.badges.videoHunt.comparedToLastYear(Math.round(badge.reducedBy)),
                unit: units[lang.code as 'fr' | 'en'],
                icon: <IconAssets.VideoHunt />,
                color: 'var(--gd-badge-video-hunt-color)',
                lineColor: 'var(--gd-badge-video-hunt-line-color)',
                textColor: 'var(--gd-badge-text-color)',
                reducedBy: badge.reducedBy,
            }
        default:
            return {
                title: lang.home.victory.badges.planetFriendly.title,
                textContent: lang.home.victory.badges.planetFriendly.reducedBy,
                carbonEmissionDecreaseText: lang.home.victory.badges.planetFriendly.comparedToLastYear(Math.round(badge.reducedBy), badgePeriodeComparaison),
                unit: '%',
                icon: <IconAssets.PlanetFriendlyIcon />,
                color: 'var(--gd-badge-planet-friendly-color)',
                lineColor: 'var(--gd-badge-planet-friendly-color)',
                textColor: 'var(--gd-badge-text-color)',
                reducedBy: badge.reducedBy,
            }
    }
}



export default function Badge({ badge, separateBadge, showBadgeTitle }: BadgeProps) {
    const { lang } = useUser()
    const { state } = useStore()
    const styles = useStyles()

    const badgeDetails = getBadgeDetails(badge, lang)
    if (badgeDetails == null) {
        return null
    }

    const { from: fromPeriod, to: toPeriod } = getPeriod(badge)
    const cohortName = getCohortName(badge, state.cohortsPayload, state.userCohorts)

    return (
        <div className={styles.container}>
            <div className={styles.badgeTitle}>
                <BadgeTitle badge={badge} shortBorder={separateBadge} showCardTitle={showBadgeTitle} />
            </div>
            <div className={styles.badgeWrapper}>
                <div className={styles.badge}>
                    <div className={styles.content}>
                        <div className={styles.period}>{lang.home.victory.badges.awardPeriod(...toLiteralIntervalParts(fromPeriod, toPeriod, lang.locale))}</div>
                        <div className={styles.textContainer}>
                            {cohortName ? <strong>{cohortName}</strong> : null}
                            {badgeDetails.textContent}
                            <span>{badgeDetails.carbonEmissionDecreaseText}</span>
                        </div>
                        <div className={styles.iconWrapper}>
                            <div className={styles.icon}>
                                <IconAssets.AwardedBadge color={badgeDetails.color} />
                            </div>
                            <div className={styles.valueContainer}>
                                <span className={styles.value}>{Math.round(badgeDetails.reducedBy)}</span>
                                <span>{badgeDetails.unit}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface BadgeTitleProps {
    showCardTitle?: boolean
    shortBorder?: boolean
    badge: BadgeModel
}

const useBadgeTitleStyles = (textcolor: string, lineColor: string) =>
    createUseStyles({
        badgeTitle: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '15px',
            width: '100%',
            height: '80px',
            padding: '17px 0',
            color: textcolor,
            fontSize: 'var(--gd-size-highlighted-small-title)',
        },
        badgeTitleBorder: {
            borderBottom: '2px solid',
            borderBottomColor: lineColor,
        },
        badgeTitleShortBorder: {
            width: '97.5%',
        },
        badgeTitleHeight: {
            height: '80px',
            padding: '17px 0',
        },
        badgeTile: {
            fontSize: 'var(--gd-size-highlighted-small-title)',
            lineHeight: '20px',
            fontWeight: 'bold',
        },
        badgeIcon: {
            height: '38px',
            width: '38px',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            badgeTitle: {
                padding: 'unset',
            },
            badgeTitleHeight: {
                padding: 'unset',
            },
        },
    })()

export function BadgeTitle({ showCardTitle, shortBorder, badge }: BadgeTitleProps) {
    const { lang } = useUser()
    const badgeDetails = getBadgeDetails(badge, lang)
    const styles = useBadgeTitleStyles(badgeDetails?.textColor ?? '', badgeDetails?.lineColor ?? '')

    if (badgeDetails == null) {
        return null
    }

    return (
        <div>
            <div className={classnames([styles.badgeTitle, styles.badgeTitleHeight])}>
                {showCardTitle ? (
                    <>
                        <div className={styles.badgeIcon}>{badgeDetails.icon}</div>
                        <span className={styles.badgeTile}>{badgeDetails.title}</span>
                    </>
                ) : null}
            </div>
            <div className={classnames([styles.badgeTitleBorder, shortBorder ? styles.badgeTitleShortBorder : ''])}></div>
        </div>
    )
}
