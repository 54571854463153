import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { useStore, useUser } from '../../contexts'
import { isAfter, substractDays } from '../dateUtils'
import { convertToRem, numberFormatter } from './../utils'
import QVTBulbInformation from './QVTBulbInformation'

const useStyles = () =>
    createUseStyles({
        qvTMobilizeTeamMeeting: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            gap: '8px',
            borderRadius: 'var(--gd-border-normal-radius)',
            maxHeight: convertToRem(500),
            maxWidth: convertToRem(330),
            minWidth: convertToRem(330),
        },
        content: {
            height: '100%',
            backgroundColor: 'var(--gd-secondary-color)',
            borderTopRightRadius: 'var(--gd-border-normal-radius)',
            borderTopLeftRadius: 'var(--gd-border-normal-radius)',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',

            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            flexDirection: 'column',
            justifyContent: 'center',
            color: 'var(--gd-text-clear-color)',
            fontSize: 'var(--gd-size-highlighted-number)',
            fontWeight: 'bold',
            gap: '8px',
            '& > svg': {
                width: '32px',
                height: '40px',
            },
        },
        text: {
            width: '80%',
            textAlign: 'center',
        },
        descriptionContainer: {
            borderTopRightRadius: '5px!important',
            borderTopLeftRadius: '5px!important',
            borderBottomLeftRadius: 'var(--gd-border-normal-radius)!important',
            borderBottomRightRadius: 'var(--gd-border-normal-radius)!important',
            backgroundColor: 'var(--gd-main-app-background-color) !important',
        },
        descriptionContainerIcon: {
            '& > *:first-child': {
                fill: 'var(--gd-background-clear-color)',
            },
        },
    })()

export default function QVTMobilizeTeamMeeting() {
    const {
        state: {
            userPayload: { qvtTimeSpentInMeetings },
        },
    } = useStore()

    const { lang } = useUser()
    const styles = useStyles()
    const dateJ30 = substractDays(new Date(), 30)
    const mobilizeHour: string = numberFormatter(
        (qvtTimeSpentInMeetings ?? [])
            .filter((value) => isAfter(new Date(value.dateRange[0]), dateJ30) || isAfter(new Date(value.dateRange[1]), dateJ30))
            .map((q) => q.mobilized)
            .reduce((sum, num) => sum + num, 0) / 60,
    )

    return (
        <div className={styles.qvTMobilizeTeamMeeting}>
            <div className={styles.content}>
                <IconAssets.HourglassIcon />
                <div className={styles.text}>
                    <span>
                        {mobilizeHour} {lang.goal.qvt.mobilizeTeamMeeting.text}
                    </span>
                </div>
            </div>
            <QVTBulbInformation
                textContent={lang.goal.qvt.mobilizeTeamMeeting.information}
                classNames={{
                    container: styles.descriptionContainer,
                    icon: styles.descriptionContainerIcon,
                }}
            />
        </div>
    )
}
