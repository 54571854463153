const CarbonCumulGraphSubTabIcon = ({ active = false }: any) => {
    const fillColor = active ? '#FFFFFF' : 'var(--gd-secondary-dark-color)'
    return (
        <svg width='100%' height='24px' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M3.33317 15.8346V16.668H17.4998C17.9601 16.668 18.3332 17.0411 18.3332 17.5013C18.3332 17.9615 17.9601 18.3346 17.4998 18.3346H2.6665C2.11422 18.3346 1.6665 17.8869 1.6665 17.3346V2.5013C1.6665 2.04106 2.0396 1.66797 2.49984 1.66797C2.96007 1.66797 3.33317 2.04106 3.33317 2.5013V14.168C5.83317 14.168 8.33317 12.5013 10.0832 9.5013C12.3505 5.72247 14.8234 3.72572 17.502 3.38675C17.9586 3.32897 18.3332 3.70773 18.3332 4.16797C18.3332 4.62821 17.9572 4.99388 17.5043 5.07582C15.4659 5.4446 13.4922 7.18731 11.5832 10.418C9.4165 13.8346 6.4165 15.8346 3.33317 15.8346Z'
                fill={fillColor}
            />
        </svg>
    )
}

export default CarbonCumulGraphSubTabIcon