import { useCallback } from 'react'
import { useUser } from '../contexts'
import useQueryParams from './useQueryParams'

export default function useImpersonation() {
    const { impersonate } = useUser()
    const queryParams = useQueryParams()

    const userId = queryParams.get('userId')
    const upn = queryParams.get('upn')

    const doImpersonation = useCallback(async () => {
        if (!userId || !upn) {
            return
        }

        try {
            await impersonate(upn, userId)
        } catch (error) {
            /* empty */
        }
    }, [userId, upn])

    return { doImpersonation }
}
