import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { Customer } from '../../models'
import digigreen from './digigreen'

const digigreenBeta: Customer = {
    ...digigreen,
    name: 'digigreen_beta',
    disablePwa: true,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES, GreetFeaturingTarget.DRIVE_FOLDER_DELETION],
}

export default digigreenBeta
