import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { Customer } from '../../models'
import lecko from './lecko'

const leckoBeta: Customer = {
    ...lecko,
    name: 'lecko_beta',
    disablePwa: true,
    featuring: [...(lecko.featuring ?? [] ), GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES, GreetFeaturingTarget.DRIVE_FOLDER_DELETION, GreetFeaturingTarget.QVT, GreetFeaturingTarget.DRIVE_VERSION]
}

export default leckoBeta
