import { useState, useEffect } from 'react'
import settings from '../../settings'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { User } from '../../models'
import CohortPayload from '../../models/CohortPayload'
export enum SseEvent {
    UPDATED_COHORT_PAYLOAD,
}

const EventSource = EventSourcePolyfill
export const useSse = (sseEvent: SseEvent, user: User | null) => {
    const [data, updateData] = useState(null)
    const url = settings.global.API_URL + `/greet-desktop/subscribe?userId=${user?.userId}&sseEvent=${SseEvent[sseEvent]}`
    useEffect(() => {
        const a = new EventSource(url, {
            headers: {
                'Authorization': `${user?.leckoApiToken} ${user?.state}`,
            },
            heartbeatTimeout: 600000,
            withCredentials: true,
        })
        a.onmessage = (e) => {
            if (e.data != 'heartbeat') {
                updateData(JSON.parse(e.data))
            }
        }
        return () => a.close()
    }, [])

    return {
        sseCohortPayload: data ? (data as CohortPayload) : null,
    }
}
