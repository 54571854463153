import { Lang } from '../../../../models'
import defaultLang from '../../../lang/en'

const tenEn: Lang = {
    ...defaultLang,
    goal: {
        ...defaultLang.goal,
        individual: {
            ...defaultLang.goal.individual,
            button: {
                title: 'Me',
                addIndicators: 'Add your indicators',
                qvt: 'My QWLC indicators',
            },
        },
    },
}

export default tenEn
