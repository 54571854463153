import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import CoachContent from '../../models/coaching/CoachContent'

const useCoachingItemStyles = createUseStyles({
    coachingItem: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        height: '580px',
        backgroundColor: 'var(--gd-blogs-primary-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        color: 'var(--gd-text-clear-color)',
        textAlign: 'center',
    },
    coachingItemTitleContainer: {
        backgroundColor: 'var(--gd-blogs-primary-dark-color)',
        borderRadius: 'var(--gd-border-normal-radius) var(--gd-border-normal-radius) 0 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    coachingItemTitle: {
        color: 'var(--gd-text-clear-color)',
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
        padding: '10px 0',
        marginRight: '10px'
    },
    coachingItemTitleAction: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        cursor: 'pointer',
    },
    coachingItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    coachingItemSubContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        height: '100%',
    },
    coachImage: {
        width: '100%',
        height: '225px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    calendarMeetingIconContainer: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        justifyContent: 'center',
        top: '-30px',
        zIndex: '1',
    },
    calendarMeetingIcon: {
        width: '60px',
        height: '60px',
        cursor: 'pointer',
    },
    takeAppointmentContainer: {
        display: 'flex',
        position: 'relative',
        top: '-60px',
        width: '100%',
        height: '130px',
        justifyContent: 'center',
    },
    takeAppointmentSubContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        alignItems: 'center',
        backgroundColor: 'var(--gd-secondary-dark-color)',
        borderRadius: '0px 0px 10px 10px',
        cursor: 'pointer',
    },
    takeAppointmentSubSubContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        top: '40px',
        width: '80%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    takeAppointmentTitle: {
        fontSize: 'var(--gd-size-small-title)',
        fontWeight: 'bold',
    },
    takeAppointmentButton: {
        width: '50px',
        height: '50px',
        padding: '10px 0px',
    },
    coachingItemTextContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 45px',
        gap: '10px',
        height: '250px',
        maxHeight: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        top: '-45px',
    },
    coachingItemContentTitle: {
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
    },
})

interface CoachingItemMeetingProps {
    title: string
    onEditClick: (() => void) | null
    coaches: CoachContent[]
    appointmentTitle: string
    contentTitle1: string
    contentTitle2: string
}

const CoachingItemMeeting = ({ title, onEditClick, coaches, appointmentTitle, contentTitle1, contentTitle2 }: CoachingItemMeetingProps) => {
    const styles = useCoachingItemStyles()

    const getCoachingItems = (coaches: CoachContent[]) => {
        return coaches?.filter(item => item != null)?.map((coach: CoachContent) => <CoachingItemMeetingContent key={coach.firstname} coach={coach} appointmentTitle={appointmentTitle} />) ?? []
    }

    return (
        <div className={styles.coachingItem}>
            <div  className={styles.coachingItemTitleContainer}>
                <div className={styles.coachingItemTitle}>{title}</div>
                {onEditClick != null ? (
                    <div className={styles.coachingItemTitleAction} onClick={onEditClick}>
                        <IconAssets.Pen fillColor='var(--gd-text-clear-color)' />
                    </div>
                ) : null}
            </div>
            <div className={styles.coachingItemContainer}>{getCoachingItems(coaches)}</div>
            <div className={styles.coachingItemTextContentContainer}>
                <div className={styles.coachingItemContentTitle}>
                    <div>{contentTitle1}</div>
                    <div>{contentTitle2}</div>
                </div>
            </div>
        </div>
    )
}

interface CoachingItemMeetingContentProps {
    coach: CoachContent
    appointmentTitle: string
}

const CoachingItemMeetingContent = ({ coach, appointmentTitle }: CoachingItemMeetingContentProps) => {
    const styles = useCoachingItemStyles()

    return (
        <div className={styles.coachingItemSubContainer}>
            <div className={styles.coachImage} style={{ backgroundImage: `url(${coach.base64Picture})` }}></div>
            <div className={styles.calendarMeetingIconContainer}>
                <div
                    className={styles.calendarMeetingIcon}
                    onClick={() => window.open(coach.calendarURL, '_blank', 'noopener,noreferrer')}
                >
                    <IconAssets.MeetingCalendar />
                </div>
            </div>
            <div
                className={styles.takeAppointmentContainer}
                onClick={() => window.open(coach.calendarURL, '_blank', 'noopener,noreferrer')}
            >
                <div className={styles.takeAppointmentSubContainer}>
                    <div className={styles.takeAppointmentSubSubContainer}>
                        <div className={styles.takeAppointmentTitle}>{`${appointmentTitle} ${coach.firstname}`}</div>
                        <div className={styles.takeAppointmentButton}>
                            <IconAssets.ArrowButton />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoachingItemMeeting
