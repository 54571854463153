import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import CarbonCumulGraph from '../components/CarbonCumulGraph'
import CarbonFootprint from '../components/CarbonFootprint'
import CarbonFootprintRate from '../components/CarbonFootprintRate'
import { useInstance, useStore, useUser } from '../contexts'

import classNames from 'classnames'
import IconAssets from '../assets/icon/IconAssets'
import BadgeMonitor from '../components/BadgeMonitor'
import DeleteFolderProposals from '../components/DeleteFolderProposals'
import { MediaQueryBreakpoints } from '../components/MediaQueryBreakpoints'
import { WeeklyCleaning } from '../components/WeeklyCleaning'
import BenchMark from '../components/carbonfootprint/benchMark/BenchMark'
import Coaching from '../components/coaching/Coaching'
import CoachingItem from '../components/coaching/CoachingItem'
import PersonalScoring from '../components/individualUserWidget/PersonalScoring'
import withRedirectToQvt from '../components/share/withRedirectToQvt'
import { EGoal, EGoalType } from '../models'

const useStyles = () =>
    createUseStyles({
        container: {
            width: '100%',
            maxWidth: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            gap: '30px',
        },
        presentationHeaderContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: '30px',
        },
        benchmarkCarbonCumulGraphContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            rowGap: '30px',
            columnGap: '30px',
        },
        carbonImpactTipsContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        mobileContainer: {
            display: 'none',
        },
        mobileSubContainer: {
            display: 'none',
        },
        benchmarkCarbonCumulGraphContentContainer: {
            display: 'none',
        },
        benchmarkCarbonCumulGraphContentContainerMenu: {
            display: 'none',
        },
        benchmarkCarbonCumulGraphContentContainerMenuItem: {
            display: 'none',
        },
        benchmarkCarbonCumulGraphContentContainerMenuActiveItem: {
            display: 'none',
        },
        carbonFootprintContainerCoaches: {
            display: 'none',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            container: {
                display: 'none',
            },
            presentationHeaderContainer: {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px',
                padding: '0 20px',
                width: '100%',
            },
            benchmarkCarbonCumulGraphContainer: {
                backgroundColor: 'var(--gd-background-clear-color)',
                borderRadius: 'var(--gd-border-normal-radius) var(--gd-border-normal-radius) 0px 0px',
            },
            mobileContainer: {
                maxWidth: '100vw',
                width: '100%',
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '30px',
            },
            mobileSubContainer: {
                display: 'flex',
                gap: '20px',
                width: '100%',
                borderRadius: 'var(--gd-border-normal-radius)',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: 'var(--gd-background-clear-color)',
            },
            benchmarkCarbonCumulGraphContentContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                width: '100%',
                padding: '20px',
                rowGap: '30px',
            },
            benchmarkCarbonCumulGraphContentContainerMenu: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '36px',
                margin: 'auto',
                backgroundColor: '#FFFFFF',
                width: '75%',
                borderRadius: 'var(--gd-border-big-radius)',
            },
            benchmarkCarbonCumulGraphContentContainerMenuItem: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '30%',
                borderRadius: 'var(--gd-border-big-radius)',
            },
            benchmarkCarbonCumulGraphContentContainerMenuActiveItem: {
                backgroundColor: 'var(--gd-secondary-dark-color)',
            },
            carbonFootprintContainerCoaches: {
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#FFFFFF',
                borderRadius: 'var(--gd-border-normal-radius)',
                padding: '20px',
                width: '100%',
                rowGap: '50px',
                columnGap: '30px',
            },
        },
        [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
            presentationHeaderContainer: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '20px',
                padding: '0 20px',
            },
            benchmarkCarbonCumulGraphContainer: {
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'unset',
                width: '100%',
                padding: '0 20px',
            },
            carbonImpactTipsContainer: {
                padding: '0 20px',
            },
        },
    })()

function CarbonFootprintIndividualGoal() {
    const { customer } = useInstance()
    const { lang } = useUser()
    const { state } = useStore()
    const [activeTab, setActiveTab] = useState<GoalTab>(GoalTab.CARBON_CUMUL_GRAPH)
    const { userPayload } = state
    const styles = useStyles()
    const tips = lang.tipsContent.tips[Math.floor(Math.random() * lang.tipsContent.tips.length)]
    const showCoaching = customer.goalPagesLayout?.coachingOnIndividual === true

    return (
        <>
            <div className={styles.container}>
                <div className={styles.presentationHeaderContainer}>
                    <PersonalScoring userPayload={userPayload} eGoal={EGoal.CARBON_FOOTPRINT} />
                    <CarbonFootprintRate payload={userPayload} />
                    <WeeklyCleaning payload={userPayload} />
                </div>

                <div className={styles.benchmarkCarbonCumulGraphContainer}>
                    <CarbonCumulGraph
                        title={lang.goal.carbonCumul.titleIndividuel}
                        payload={userPayload}
                        carbonEmissionsLeftText={lang.goal.carbonCumul.leftTextIndividuel}
                        carbonEmissionsText={lang.goal.carbonCumul.carbonEmissions(EGoalType.INDIVIDUAL)}
                    />
                    <BenchMark payload={userPayload} />
                </div>

                <div className={styles.carbonImpactTipsContainer}>{userPayload && <CarbonFootprint payload={userPayload} title={lang.goal.carbonFootprint.title} />}</div>

                <DeleteFolderProposals />

                <BadgeMonitor />

                {showCoaching ? <Coaching /> : null}
            </div>
            {/* mobile container */}
            <div className={styles.mobileContainer}>
                <div className={styles.presentationHeaderContainer}>
                    <CarbonFootprintRate payload={userPayload} />
                    <WeeklyCleaning payload={userPayload} />
                </div>
                <div className={styles.mobileSubContainer}>
                    <div className={styles.benchmarkCarbonCumulGraphContainer}>
                        <div className={styles.benchmarkCarbonCumulGraphContentContainer}>
                            <div className={styles.benchmarkCarbonCumulGraphContentContainerMenu}>
                                <div
                                    className={classNames(
                                        styles.benchmarkCarbonCumulGraphContentContainerMenuItem,
                                        activeTab === GoalTab.CARBON_CUMUL_GRAPH ? styles.benchmarkCarbonCumulGraphContentContainerMenuActiveItem : '',
                                    )}
                                    onClick={() => setActiveTab(GoalTab.CARBON_CUMUL_GRAPH)}
                                >
                                    <IconAssets.CarbonCumulGraphSubTabIcon active={activeTab === GoalTab.CARBON_CUMUL_GRAPH} />
                                </div>
                                <div
                                    className={classNames(
                                        styles.benchmarkCarbonCumulGraphContentContainerMenuItem,
                                        activeTab === GoalTab.BENCH_MARK ? styles.benchmarkCarbonCumulGraphContentContainerMenuActiveItem : '',
                                    )}
                                    onClick={() => setActiveTab(GoalTab.BENCH_MARK)}
                                >
                                    <IconAssets.BenchMarkSubTabIcon active={activeTab === GoalTab.BENCH_MARK} />
                                </div>
                                <div
                                    className={classNames(
                                        styles.benchmarkCarbonCumulGraphContentContainerMenuItem,
                                        activeTab === GoalTab.DOUGHNUT ? styles.benchmarkCarbonCumulGraphContentContainerMenuActiveItem : '',
                                    )}
                                    onClick={() => setActiveTab(GoalTab.DOUGHNUT)}
                                >
                                    <IconAssets.DoughnutSubTabIcon active={activeTab === GoalTab.DOUGHNUT} />
                                </div>
                            </div>
                            <BenchMarkCarbonCumulGraphMobileSelector activeTab={activeTab} payload={userPayload} lang={lang} />
                        </div>
                    </div>

                    <BadgeMonitor />

                    <div className={styles.carbonFootprintContainerCoaches}>
                        {showCoaching ? (
                            <Coaching />
                        ) : (
                            <CoachingItem
                                title='Tips'
                                illustrationImg={<IconAssets.Bulb />}
                                contentTitle={tips.title}
                                subContent={tips.content}
                                buttonLabel={lang.goal.tips.readMore}
                                buttonLink={lang.tipsContent.link}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

enum GoalTab {
    CARBON_CUMUL_GRAPH,
    BENCH_MARK,
    DOUGHNUT,
}

const BenchMarkCarbonCumulGraphMobileSelector = ({ activeTab, payload, lang }: any) => {
    switch (activeTab) {
        case GoalTab.CARBON_CUMUL_GRAPH:
            return (
                <CarbonCumulGraph
                    title={lang.goal.carbonCumul.titleGroup}
                    payload={payload}
                    carbonEmissionsLeftText={lang.goal.carbonCumul.leftTextIndividuel}
                    carbonEmissionsText={lang.goal.carbonCumul.carbonEmissions(EGoalType.INDIVIDUAL)}
                />
            )
        case GoalTab.BENCH_MARK:
            return <BenchMark payload={payload} />
        case GoalTab.DOUGHNUT:
            return <CarbonFootprint payload={payload} title={lang.goal.carbonFootprint.title} />
        default:
            return null
    }
}

export default withRedirectToQvt(CarbonFootprintIndividualGoal)
