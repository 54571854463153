interface Props {
    color: string
    size: number
}

export default function Circle({ color, size }: Readonly<Props>) {
    return (
        <svg width={size} height={size}>
            <circle cx={`${size / 2}`} cy={`${size / 2}`} r={`${size / 2}`} fill={color} />
        </svg>
    )
}
