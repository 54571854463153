import { Customer, EGoal } from '../../models'

import WebinarImg from '../../assets/illustration/Webinar.png'

import DigigreenLogo from '../../components/headers/DigigreenLogo'
import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import ECoachingEvent from '../../models/coaching/ECoachingEvent'
import QVTIndividualGoal from '../../pages/QVTIndividualGoal'
import digigreenEn from './cutomerLangs/digigreen/digigreenEn'
import digigreenFr from './cutomerLangs/digigreen/digigreenFr'
import { defaultBlogs, defaultBricks, defaultBugReport, defaultCoachingMeeting } from './defaultCustomerValues'

const digigreen: Customer = {
    name: 'digigreen',
    platformName: 'accor',
    logo: DigigreenLogo,
    QVTIndividualGoal: QVTIndividualGoal,
    bricks: defaultBricks,
    theme: {
        primaryColor: '#B88D5B', //                                            --gd-primary-color
        primaryDarkColor: '#5F5C7D', //                                        --gd-primary-dark-color
        secondaryColor: '#050033', //                                          --gd-secondary-color
        secondaryDarkColor: '#5F5C7D', //                                      --gd-secondary-dark-color
        tertiaryColor: '#050033', //                                           --gd-tertiary-color

        borderSmallRadius: '10px', //                                          --gd-border-small-radius
        borderNormalRadius: '20px', //                                         --gd-border-normal-radius
        borderBigRadius: '50px', //                                            --gd-border-big-radius

        positiveColor: '#117846', //                                           --gd-positive-color
        intermediaryColor: '#F4991D', //                                       --gd-intermediary-color
        negativeColor: '#BA1F1A', //                                           --gd-negative-color

        primaryButtonColor: 'var(--gd-primary-color)', //                      --gd-primary-button-color
        secondaryButtonColor: 'var(--gd-secondary-color)', //                  --gd-secondary-button-color
        dangerButtonColor: 'var(--gd-negative-color)', //                      --gd-danger-button-color

        mainAppBackgroundColor: '#FFFFFF', //                                  --gd-main-app-background-color
        mainAppShadowedContent: 'rgb(0 0 0 / 8%) 0px -15px 15px 0px', //       --gd-main-app-shadowed-content
        mainActiveTabBackgroundColor: 'var(--gd-background-clear-color)', //   --gd-main-active-tab-background-color
        mainActiveTabColor: 'var(--gd-secondary-color)', //                    --gd-main-active-tab-color
        mainPopupBackgroundColor: '#FFFFFF', //                                --gd-main-popup-background-color

        blogsPrimaryColor: 'var(--gd-secondary-color)', //                     --gd-blogs-primary-color
        blogsPrimaryDarkColor: 'var(--gd-secondary-dark-color)', //            --gd-blogs-primary-color
        blogsSecondaryColor: 'var(--gd-primary-color)', //                     --gd-blogs-secondary-color

        backgroundClearColor: '#F0EFF3', //                                    --gd-background-clear-color
        backgroundDarkColor: '#D5D4D9', //                                     --gd-background-dark-color

        textClearColor: '#FFFFFF', //                                          --gd-text-clear-color
        textDarkColor: '#1C1B1F', //                                           --gd-text-dark-color
        textMainSubTitleColor: '#3E9097', //                                   --gd-text-main-subtitle-color
        bigTextDarkColor: '#050033', //                                        --gd-big-text-dark-color

        brickEmailColor: '#050033', //                                         --gd-brick-email-color
        brickEmailActiveColor: '#F0EFF3', //                                   --gd-brick-email-active-color
        brickOneDriveColor: '#37335C', //                                      --gd-brick-onedrive-color
        brickOneDriveActiveColor: '#F0EFF3', //                                --gd-brick-onedrive-active-color
        brickTeamsColor: '#5F5C7D', //                                         --gd-brick-teams-color
        brickTeamsActiveColor: '#F0EFF3', //                                   --gd-brick-teams-active-color
        brickSharepointColor: '#7F7D97', //                                    --gd-brick-sharepoint-color
        brickSharepointActiveColor: '#F0EFF3', //                              --gd-brick-sharepoint-active-color
        brickVisionColor: '#9997AC', //                                        --gd-brick-vision-color
        brickVisionActiveColor: '#F0EFF3', //                                  --gd-brick-vision-active-color
        brickChatColor: '#B88D5B', //                                          --gd-brick-chat-color
        brickGmailColor: '#002F7D', //                                         --gd-brick-gmail-color
        brickGmailActiveColor: '#F0F6FF', //                                   --gd-brick-gmail-active-color
        brickGDriveColor: '#14675A', //                                        --gd-brick-gdrive-color
        brickGDriveActiveColor: '#F0F6FF', //                                  --gd-brick-gdrive-active-color
        brickSharedGDriveColor: '#302E9D', //                                  --gd-brick-shared-gdrive-color
        brickSharedGDriveActiveColor: '#F0F6FF', //                            --gd-brick-shared-gdrive-active-color
        brickCopiloteColor: '#A86EDD', //                                      --gd-brick-copilote-color
        brickCopiloteActiveColor: '#1C1B1F', //                                --gd-brick-copilote-active-color

        goalTextColor: 'var(--gd-text-dark-color)', //                        --gd-goal-text-color
        goalFillColor: '#FFF', //                                              --gd-goal-fill-color
        goalCarbonFootprintColor: '#050033', //                                --gd-goal-carbon-footprint-color
        goalInfobisityColor: '#050033', //                                     --gd-goal-infobesity-color
        goalDeconnectionTimeColor: '#050033', //                               --gd-goal-deconnection-time-color

        badgeTextColor: 'var(--gd-text-dark-color)', //                        --gd-badge-text-color
        badgeFillColor: '#FFF', //                                             --gd-badge-fill-color
        badgeMailsOnDietColor: '#B88D5B', //                                   --gd-badge-mails-on-diet-color
        badgeMailsOnDietLineColor: '#B88D5B', //                               --gd-badge-mails-on-diet-line-color
        badgeMailsOnDietFillColor: '#B88D5B', //                               --gd-badge-mails-on-diet-fill-color
        badgeCleanUpColor: '#B88D5B', //                                       --gd-badge-cleanup-color
        badgeCleanUpLineColor: '#B88D5B', //                                   --gd-badge-cleanup-line-color
        badgeCleanUpFillColor: '#B88D5B', //                                   --gd-badge-cleanup-fill-color
        badgePlanetFriendlyColor: '#B88D5B', //                                --gd-badge-planet-friendly
        badgePlanetFriendlyLineColor: '#B88D5B', //                            --gd-badge-planet-line-friendly
        badgePlanetFriendlyFillColor: '#B88D5B', //                            --gd-badge-planet-fill-friendly
        badgeInternAttachmentColor: '#B88D5B', //                              --gd-badge-intern-attachment-color
        badgeInternAttachmentLineColor: '#B88D5B', //                          --gd-badge-intern-attachment-line-color
        badgeInternAttachmentFillColor: '#B88D5B', //                          --gd-badge-intern-attachment-fill-color
        badgeMailShotColor: '#B88D5B', //                                      --gd-badge-mailshot-color
        badgeMailShotLineColor: '#B88D5B', //                                  --gd-badge-mailshot-line-color
        badgeMailShotFillColor: '#B88D5B', //                                  --gd-badge-mailshot-fill-color
        badgeImageHuntColor: '#B88D5B', //                                     --gd-badge-imagehunt-color
        badgeImageHuntLineColor: '#B88D5B', //                                 --gd-badge-imagehunt-line-color
        badgeImageHuntFillColor: '#B88D5B', //                                 --gd-badge-imagehunt-fill-color
        badgeVideoHuntColor: '#B88D5B', //                                     --gd-badge-video-hunt-color
        badgeVideoHuntLineColor: '#B88D5B', //                                 --gd-badge-video-hunt-line-color
        badgeVideoHuntFillColor: '#B88D5B', //                                 --gd-badge-video-hunt-fill-color
        loaderColors: ['#050033', '#B88D5B', '#B88D5B', '#FFFFFF', '#B88D5B'],

        emailSendingRangeLessThanTen: '#9BCDD0', //                            --gd-email-sending-range-stick-less-than-ten-fill-color
        emailSendingRangeBetweenTenAndThirty: '#59ABB1', //                    --gd-email-sending-range-stick-beetwen-ten-and-thirty-fill-color
        emailSendingRangeMoreThanThirty: '#2D5659', //                          --gd-email-sending-range-stick-more-than-thirty-fill-color

        emailSendingRangeSmallRange: '#9BCDD0', //                             --gd-email-sending-range-small-range-color
        emailSendingRangeMediumRange: '#59ABB1', //                            --gd-email-sending-range-medium-range-color
        emailSendingRangeLargeRange: '#4d7679', //                             --gd-email-sending-range-large-range-color
        emailSendingRangeHeightRange: '#2D5659', //                            --gd-email-sending-range-height-range-color
    },
    goals: [EGoal.CARBON_FOOTPRINT, EGoal.QVT],
    contact: {
        hasLinkSupport: true,
        support: {
            name: 'Lucile Hossepied',
            mail: 'lucile.hossepied@accor.com',
        },
        link: 'https://teams.microsoft.com/l/channel/19%3A2916b6ce94ed4d718aa97b74e857d0c9%40thread.tacv2/%F0%9F%93%A9%20DigiGreen%20Registration?groupId=7fe850a1-d9b3-4410-9e39-cff3ca43a7e8&tenantId=3ee81190-954b-4064-8e7d-f12fd761fd39',
        linkName: 'DigiGreen Registration',
    },
    legalInformation: {
        cgu: 'https://leckogreet.blob.core.windows.net/accor/DigiGreen_CGU_Oct_2023.pdf',
        charter: 'https://leckogreet.blob.core.windows.net/lecko/Greet_Charte_de_protection_des_donnees_personnelles_Oct_2023.pdf',
    },
    customerLang: {
        fr: digigreenFr,
        en: digigreenEn,
    },
    webinarImg: WebinarImg,
    goalPagesLayout: {
        coachingOnIndividual: true,
        coachingOnCollective: {
            tips: true,
            training: true,
            coaching: true,
        },
    },
    bugReport: defaultBugReport,
    defaultCoachingMeeting: defaultCoachingMeeting,
    coachingEvent: ECoachingEvent.WEBINAR,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES],
    blogs: defaultBlogs
}

export default digigreen
