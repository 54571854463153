import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import Circle from '../../assets/icon/Circle'
import { MediaQueryBreakpoints } from '../../components/MediaQueryBreakpoints'
import ChartDescription from '../../components/qvt/ChartDescription'
import Chart from '../../components/qvt/CommunicationModesDistribution/Chart'
import QVTBulbInformation from '../../components/qvt/QVTBulbInformation'
import Divider from '../../components/share/Divider'
import ChartLegend from '../../components/share/qvt/ChartLegend'
import { convertToRem } from '../../components/utils'
import { useInstance, useUser } from '../../contexts'
import UserPayload from '../../models/UserPayload'

interface Props {
    payload: UserPayload
    className?: string
}

const useStyles = createUseStyles({
    container: {
        width: '100%',
        maxWidth: convertToRem(528),
    },
    doughnutsLegend: {
        padding: convertToRem(20),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: `${convertToRem(2)} solid var(--gd-background-dark-color)`,
        borderTopLeftRadius: 'var(--gd-border-normal-radius)',
        borderTopRightRadius: 'var(--gd-border-normal-radius)',
    },
    titles: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        columnGap: convertToRem(130),

        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        lineHeight: convertToRem(16),
        textAlign: 'center',
        letterSpacing: convertToRem(0.15),
        color: 'var(--gd-text-dark-color)',
    },
    chartTitle: {
        width: convertToRem(160),
    },
    doughnunts: {
        marginTop: convertToRem(8),
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        columnGap: convertToRem(65),
    },
    doughnuntsSeperator: {
        alignSelf: 'end',
        height: convertToRem(148),
    },
    legend: {
        marginTop: convertToRem(18),
        flexDirection: 'row',
        columnGap: convertToRem(14),
    },
    legendLabel: {
        marginLeft: convertToRem(8),
    },
    chartDescription: {
        marginTop: convertToRem(10),
    },
    tipContainer: {
        marginTop: convertToRem(2),
        border: `${convertToRem(2)} solid var(--gd-background-dark-color)`,
        borderTopLeftRadius: 'unset !important',
        borderTopRightRadius: 'unset !important',
        borderBottomLeftRadius: 'var(--gd-border-normal-radius)',
        borderBottomRightRadius: 'var(--gd-border-normal-radius)',
    },
    tipContent: {
        paddingLeft: convertToRem(16),
        paddingRight: convertToRem(20),
        paddingBlock: convertToRem(20),
        display: 'flex',
        flexDirection: 'column',
        rowGap: convertToRem(8),
        lineHeight: convertToRem(18),
    },
    tipTitle: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        container: {
            maxWidth: 'unset',
        },
    },
    [MediaQueryBreakpoints.MOBILE]: {
        titles: {
            columnGap: convertToRem(30),
        },
        doughnunts: {
            width: '95%',
            columnGap: convertToRem(30),
        },
        doughnuntsSeperator: {
            height: convertToRem(120),
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        titles: {
            columnGap: convertToRem(250),
        },
        tipContainer: {
            marginTop: convertToRem(2.5),
        },
        doughnuntsSeperator: {
            height: convertToRem(300),
        },
    },
})

export default function CommunicationModesDistribution({ payload, className }: Readonly<Props>) {
    const { lang } = useUser()
    const { customer } = useInstance()
    const styles = useStyles()
    const customerTheme = customer.theme

    const chartDataset = {
        colors: [customerTheme.brickTeamsColor, customerTheme.brickChatColor, customerTheme.brickEmailColor],
        data: {
            closestPeople: [
                payload?.qvtCommunicationModeInteractionsWithClosestPeople?.teamsChannelsInteractions ?? 0,
                payload?.qvtCommunicationModeInteractionsWithClosestPeople?.chatMessageInteractions ?? 0,
                payload?.qvtCommunicationModeInteractionsWithClosestPeople?.emailInteractions ?? 0,
            ],
            allInterlocutors: [
                payload?.qvtCommunicationModeInteractionsWithAllInterlocutors?.teamsChannelsInteractions ?? 0,
                payload?.qvtCommunicationModeInteractionsWithAllInterlocutors?.chatMessageInteractions ?? 0,
                payload?.qvtCommunicationModeInteractionsWithAllInterlocutors?.emailInteractions ?? 0,
            ],
        },
    }

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.doughnutsLegend}>
                <div className={styles.titles}>
                    <div className={styles.chartTitle}>{lang.goal.qvt.communicationModesDistribution.charts.titles.closestPeople}</div>
                    <div className={styles.chartTitle}>{lang.goal.qvt.communicationModesDistribution.charts.titles.allContacts}</div>
                </div>
                <div className={styles.doughnunts}>
                    <Chart dataset={{ data: chartDataset.data.closestPeople, colors: chartDataset.colors }} />
                    <Divider className={styles.doughnuntsSeperator} orientation='vertical' strokeWidth={2} color='var(--gd-background-dark-color)' />
                    <Chart dataset={{ data: chartDataset.data.allInterlocutors, colors: chartDataset.colors }} />
                </div>
                <ChartLegend
                    classNames={{ container: styles.legend }}
                    items={[
                        {
                            classNames: { label: styles.legendLabel },
                            label: lang.goal.qvt.communicationModesDistribution.charts.legend.teamsChannels,
                            icon: <Circle color={customerTheme.brickTeamsColor} size={9} />,
                        },
                        {
                            classNames: { label: styles.legendLabel },
                            label: lang.goal.qvt.communicationModesDistribution.charts.legend.chat,
                            icon: <Circle color={customerTheme.brickChatColor} size={9} />,
                        },
                        {
                            classNames: { label: styles.legendLabel },
                            label: lang.goal.qvt.communicationModesDistribution.charts.legend.email,
                            icon: <Circle color={customerTheme.brickEmailColor} size={9} />,
                        },
                    ]}
                />
                <ChartDescription
                    classNames={{ container: styles.chartDescription }}
                    title={lang.goal.qvt.communicationModesDistribution.charts.description.title}
                    description={lang.goal.qvt.communicationModesDistribution.charts.description.description}
                />
            </div>
            <QVTBulbInformation
                classNames={{ container: styles.tipContainer, info: styles.tipContent }}
                textContent={
                    <>
                        <span className={styles.tipTitle}>{lang.goal.qvt.communicationModesDistribution.charts.tip.title}</span>
                        <span>{lang.goal.qvt.communicationModesDistribution.charts.tip.modes.chat}</span>
                        <span>{lang.goal.qvt.communicationModesDistribution.charts.tip.modes.teamsChannels}</span>
                        <span>{lang.goal.qvt.communicationModesDistribution.charts.tip.modes.email}</span>
                        <span>{lang.goal.qvt.communicationModesDistribution.charts.tip.message}</span>
                    </>
                }
            />
        </div>
    )
}
