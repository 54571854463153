import { Lang } from '../../models'

const STORAGE_NAME = 'lang'

const lang = {
    store: (lang: Lang): void => {
        localStorage.setItem(STORAGE_NAME, JSON.stringify(lang))
    },
    get: (): Lang | null => {
        const langStringified = localStorage.getItem(STORAGE_NAME)
        if (langStringified !== null) {
            return JSON.parse(langStringified)
        } else {
            return null
        }
    },
    clear: () => {
        localStorage.removeItem(STORAGE_NAME)
    },
}

export default lang
