import { createUseStyles } from 'react-jss'
import { convertToRem } from '../utils'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'

interface Props {
    period: string
    show: boolean
}

const useStyles = createUseStyles({
    period: {
        marginLeft: convertToRem(15),
        fontSize: 'var(--gd-size-small-body-text)',
    },
    bar: {
        marginLeft: convertToRem(15),
        width: convertToRem(2),
        height: '100%',
        backgroundColor: 'var(--gd-background-dark-color)',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        period: {
            marginLeft: 'unset',
        },
        bar: {
            marginLeft: 'unset',
        },
    },
})

export default function GoalPeriod({ period, show }: Readonly<Props>) {
    const styles = useStyles()

    if (!show) {
        return null
    }

    return (
        <>
            <div className={styles.bar}></div>
            <span className={styles.period}>{period}</span>
        </>
    )
}
