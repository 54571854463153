import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { convertToRem } from '../utils'

type Orientation = 'horizental' | 'vertical'

interface Props {
    className?: string
    orientation?: Orientation
    strokeWidth?: number
    color?: string
}

const useStyles = createUseStyles({
    divider: ({ orientation, strokeWidth, color }: { orientation: Orientation; strokeWidth: number; color: string }) => ({
        ...{ ['horizental']: { width: '100%' }, ['vertical']: { height: '100%' } }[orientation],
        borderWidth: convertToRem(strokeWidth),
        borderStyle: 'solid',
        borderColor: color,
    }),
})

export default function Divider({ className, orientation = 'horizental', strokeWidth = 2, color = '#000' }: Readonly<Props>) {
    const styles = useStyles({ orientation, strokeWidth, color })
    return <div className={classNames(styles.divider, className)}></div>
}
