import { GlobalSettings } from './models/GlobalSettings'

const getGlobalSettings = (): GlobalSettings => {
    return {
        API_URL: 'https://analytics.api.preprod.lecko.fr',
        O365_CONF: (origin: string, path: string) => {
            const redirectUri = `${origin}${path}`
            return {
                auth: {
                    clientId: '985db64d-f4b7-4eea-a563-0cd072eb8c3b',
                    authority: 'https://login.microsoftonline.com/common',
                    navigateToRequestUrl: true,
                    redirectUri: redirectUri,
                    postLogoutRedirectUri: redirectUri,
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false,
                },
            }
        },
        GOOGLE_CONF: () => {
            return {
                clientId: '827521234570-teq4fa8u3sgnul3bdjd6kajieaic3his.apps.googleusercontent.com',
                authority: 'https://accounts.google.com/gsi/client',
            }
        },
    }
}

export default getGlobalSettings()
