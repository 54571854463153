import classnames from 'classnames'
import { Doughnut } from 'react-chartjs-2'
import { createUseStyles } from 'react-jss'
import IconAssets from '../assets/icon/IconAssets'
import IllustrationAssets from '../assets/illustration/IllustrationAssets'
import { useInstance, useUser } from '../contexts'
import UserPayload, { UserIndicators } from '../models/UserPayload'
import { substractDays, toDayLiteralMonthString } from './dateUtils'
import { octetsConverter } from './utils'

const useStyles = createUseStyles({
    weeklyCleaning: {
        width: '100%',
        height: '280px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 'var(--gd-border-normal-radius)',
        color: 'var(--gd-text-clear-color)',
        backgroundColor: 'var(--gd-primary-color)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    weeklyCleaningTitle: {
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '35px',
    },
    weeklyCleaningContent: {
        height: '100%',
        width: '100%',
        borderRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: 'var(--gd-background-clear-color)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center',
        gap: '10px',
    },
    weeklyCleaningPeriod: {
        color: 'var(--gd-primary-color)',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 'bold',
        width: '70%',
        borderRadius: 'var(--gd-border-normal-radius)',
        padding: '7px',
        backgroundColor: '#FFFFFF',
    },
    weeklyCleaningDescription: {
        color: 'var(--gd-text-dark-color)',
        fontSize: 'var(--gd-size-small-body-text)',
        width: '90%',
    },
})

interface IWeeklyCleaning {
    payload: UserPayload
}

export const WeeklyCleaning = ({ payload }: IWeeklyCleaning) => {
    const { lang } = useUser()
    const styles = useStyles()

    const indicators = payload?.indicators
    const cleaningFrom = toDayLiteralMonthString(indicators?.dateStart ?? substractDays(new Date(), 6), lang.locale)
    const cleaningTo = isNaN(new Date(indicators?.dateEnd).getTime())
        ? toDayLiteralMonthString(substractDays(new Date(), 1), lang.locale)
        : // substract one day
          // api convention [dateFrom, dateTo[
          toDayLiteralMonthString(substractDays(new Date(indicators.dateEnd), 1), lang.locale)
    const hasIndicators = indicators?.driveDeletedSize || indicators?.emailDeletedSize

    return (
        <div className={styles.weeklyCleaning}>
            <div className={styles.weeklyCleaningTitle}>{lang.goal.weeklyCleaning.title}</div>
            <div className={styles.weeklyCleaningContent}>
                <div className={styles.weeklyCleaningPeriod}>{lang.goal.weeklyCleaning.cleanPeriod(cleaningFrom, cleaningTo)}</div>
                <div className={styles.weeklyCleaningDescription}>{lang.goal.weeklyCleaning.description}</div>
                {hasIndicators ? <WeeklyCleaningChart {...indicators} /> : <NoWeeklyCleaning />}
            </div>
        </div>
    )
}

const useWeeklyCleaningChartStyles = createUseStyles({
    weeklyCleaningChartContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    weeklyCleaningChart: {
        width: '100px',
        height: '100px',
    },
    weeklyCleaningChartResult: {
        position: 'relative',
        fontWeight: 'bold',
        color: 'var(--gd-text-dark-color)',
        lineHeight: '15px',
        fontSize: 'var(--gd-size-highlighted-small-number)',
        top: '-63px',
    },
    weeklyCleaningChartResultUnit: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
    },
    weeklyCleaningLegend: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    weeklyCleaningLegendItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--gd-text-dark-color)',
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        lineHeight: '12px',
        gap: '4px',
    },
    weeklyCleaningLegendItemMeasures: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 'bold',
    },
    weeklyCleaningLegendItemInfo: {
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'normal',
    },
    weeklyCleaningLegendItemProgress: {
        height: '5px',
        width: '50%',
        borderRadius: 'var(--gd-border-small-radius)',
        marginTop: '2px',
    },
    weeklyCleaningLegendEmailItem: {
        backgroundColor: 'var(--gd-brick-email-color)',
    },
    weeklyCleaningLegendDriveItem: {
        backgroundColor: 'var(--gd-brick-onedrive-color)',
    },
})

const WeeklyCleaningChart = ({ emailDeletedSize, driveDeletedSize }: UserIndicators) => {
    const { customer } = useInstance()
    const { lang } = useUser()
    const customerTheme = customer.theme
    const styles = useWeeklyCleaningChartStyles()
    const emailConvertedSize = octetsConverter(emailDeletedSize, lang.code)
    const driveConvertedSize = octetsConverter(driveDeletedSize, lang.code)
    const totalConvertedSier = octetsConverter(emailDeletedSize + driveDeletedSize, lang.code)

    return (
        <div className={styles.weeklyCleaningChartContent}>
            <div className={styles.weeklyCleaningLegendItem}>
                <IconAssets.OneDriveIcon />
                <div className={styles.weeklyCleaningLegendItemMeasures}>
                    {driveConvertedSize.value} {driveConvertedSize.measured}
                </div>
                <div className={styles.weeklyCleaningLegendItemInfo}>{lang.goal.weeklyCleaning.driveDeleted}</div>
                <div className={classnames([styles.weeklyCleaningLegendItemProgress, styles.weeklyCleaningLegendDriveItem])}></div>
            </div>
            <div className={styles.weeklyCleaningChart}>
                <Doughnut
                    data={{
                        datasets: [
                            {
                                data: [emailDeletedSize, driveDeletedSize],
                                backgroundColor: [customerTheme.brickEmailColor, customerTheme.brickOneDriveColor],
                                borderWidth: 1,
                            },
                        ],
                    }}
                    options={{
                        cutout: '60%',
                        hover: { mode: undefined },
                        plugins: {
                            tooltip: {
                                enabled: false,
                            },
                            legend: {
                                display: false,
                            },
                        },
                    }}
                />
                <div className={styles.weeklyCleaningChartResult}>
                    {totalConvertedSier.value}
                    <br />
                    <span className={styles.weeklyCleaningChartResultUnit}>{totalConvertedSier.measured}</span>
                </div>
            </div>
            <div className={styles.weeklyCleaningLegendItem}>
                <IconAssets.EmailIcon />
                <div className={styles.weeklyCleaningLegendItemMeasures}>
                    {emailConvertedSize.value} {emailConvertedSize.measured}
                </div>
                <div className={styles.weeklyCleaningLegendItemInfo}>{lang.goal.weeklyCleaning.emailDeleted}</div>
                <div className={classnames([styles.weeklyCleaningLegendItemProgress, styles.weeklyCleaningLegendEmailItem])}></div>
            </div>
        </div>
    )
}

const useNoWeeklyCleaning = createUseStyles({
    noWeeklyCleaning: {
        color: 'var(--gd-text-dark-color)',
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-small-body-text)',
    },
})

const NoWeeklyCleaning = () => {
    const { lang } = useUser()
    const styles = useNoWeeklyCleaning()
    return (
        <div className={styles.noWeeklyCleaning}>
            <IllustrationAssets.WeeklyNoCleanPicture />
            <div>{lang.goal.weeklyCleaning.noCleaning}</div>
        </div>
    )
}
