import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { MailSendingByWorkingRangeSlot, MailSendingByWorkingRangeSlots } from '../../../models/MailSendingByWorkingRange'
import WorkingRange from '../../../models/WorkingRange'
import InfoBulle from '../../share/Infobulle'
import { getStringTimeAccordingToSlot } from '../../utils'
import { END_SLOT, START_SLOT } from './constants'

interface ChartBarProps {
    chartType: 'daily' | 'aggreate'
    value: MailSendingByWorkingRangeSlot
    workingRange: WorkingRange
    tooltipText: string
    getDayLetter: (date: Date) => string
    slotTooltip: (slot: number, from: string, to: string) => string
}

const useChartBarStyles = createUseStyles({
    chartBar: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    slot: {
        borderRadius: '2px',
        height: '8.5px',
    },
    slots: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '2px',
    },
    dayLetter: {
        height: '30px',
        textAlign: 'center',
        marginTop: '15px',
        fontSize: '15px',
    },
})

const toSlotInfo = (slotTooltip: (slot: number, from: string, to: string) => string, { slot, nbEmailSent }: MailSendingByWorkingRangeSlots) => {
    let backgroundColor: string
    let border = 'unset'
    if (nbEmailSent < 0.01) {
        backgroundColor = 'unset'
    } else if (nbEmailSent < 1) {
        backgroundColor = 'unset'
        border = 'solid 0.5px var(--gd-email-sending-range-small-range-color)'
    } else if (nbEmailSent < 3) {
        backgroundColor = 'var(--gd-email-sending-range-small-range-color)'
    } else if (nbEmailSent < 6) {
        backgroundColor = 'var(--gd-email-sending-range-medium-range-color)'
    } else if (nbEmailSent < 9) {
        backgroundColor = 'var(--gd-email-sending-range-large-range-color)'
    } else {
        backgroundColor = 'var(--gd-email-sending-range-height-range-color)'
    }
    return {
        border: border,
        backgroundColor: backgroundColor,
        toolTipe: nbEmailSent != 0 ? slotTooltip(nbEmailSent, getStringTimeAccordingToSlot(slot), getStringTimeAccordingToSlot(slot + 1)) : null,
    }
}

const ChartBar = ({ value, getDayLetter, workingRange, slotTooltip, chartType }: Readonly<ChartBarProps>) => {
    const styles = useChartBarStyles()
    const slotsIds = useMemo(() => Array.from({ length: END_SLOT - START_SLOT }, (_, i) => START_SLOT + i), [])
    const slotPropertiesMap = useMemo(() => {
        const slotPropertiesMap = new Map()
        value.slots?.forEach((s) => slotPropertiesMap.set(s.slot, toSlotInfo(slotTooltip, s)))
        return slotPropertiesMap
    }, [value, slotTooltip])

    const { barStyle, slotStyle } = useMemo(() => {
        if (chartType == 'aggreate' || !value.work) {
            return {
                barStyle: {
                    background: 'unset',
                },
                slotStyle: {},
            }
        }
        const workingRangeBottom = END_SLOT - workingRange.startSlot
        const workingRangeTop = END_SLOT - workingRange.endSlot
        const barHeight = slotsIds.length > 0 ? slotsIds.length : 1
        const bottomPercent = (workingRangeBottom * 100) / barHeight
        const topPercent = (workingRangeTop * 100) / barHeight
        return {
            barStyle: {
                background: `linear-gradient(to top, #66000000 ${topPercent}%, #EDF7EB 0%, #EDF7EB ${bottomPercent}%, #66000000 ${bottomPercent}%)`,
            },
            slotStyle: {
                margin: '0 6px 0 2px',
            },
        }
    }, [value])

    return (
        <div className={styles.chartBar}>
            <div className={styles.slots} style={{ ...barStyle }}>
                {slotsIds.map((slotId) => {
                    const slotProperties = slotPropertiesMap.get(slotId)
                    return (
                        <InfoBulle key={slotId} text={slotProperties?.toolTipe}>
                            <div
                                key={slotId}
                                className={styles.slot}
                                style={{ backgroundColor: slotProperties?.backgroundColor, border: slotProperties?.border, ...slotStyle }}
                            ></div>
                        </InfoBulle>
                    )
                })}
            </div>

            <div className={styles.dayLetter}>{getDayLetter(new Date(value.date)).toUpperCase()}</div>
        </div>
    )
}

export default ChartBar
