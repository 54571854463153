import { createUseStyles } from 'react-jss'
import { useInstance, useUser } from '../../contexts'

import IconAssets from '../../assets/icon/IconAssets'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import CoachingItem from './CoachingItem'
import CoachingEvents from './CoachingEvents'
import CoachingMeetingComponent from './CoachingMeeting'

import UnitedNationImg from '../../assets/illustration/UnitedNation.png'

const useStyles = createUseStyles({
    coachingContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '30px',
    },
    '.coachingContainer > *': {
        flex: '1 1 0',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        coachingContainer: {
            flexDirection: 'column',
        },
    },
    [MediaQueryBreakpoints.TABLETTE]: {
        coachingContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: '75%',
            alignSelf: 'center',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        coachingContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
            maxWidth: '75%',
            alignSelf: 'center',
        },
    },
})

const Coaching = () => {
    const { lang } = useUser()
    const { customer } = useInstance()
    const tip = lang.tipsContent.tips[Math.floor(Math.random() * lang.tipsContent.tips.length)]
    const styles = useStyles()
    return (
        <div className={styles.coachingContainer}>
            { customer.goalPagesLayout?.coachingOnCollective?.tips ? <CoachingItem
                title={lang.tipsContent.title}
                onEditClick={null}
                illustrationImg={<IconAssets.Bulb />}
                content={tip.title}
                subContent={tip.content}
                buttonLabel={lang.goal.tips.readMore}
                buttonLink={tip.link ?? lang.tipsContent.link}
            /> : null }
            { customer.goalPagesLayout?.coachingOnCollective?.training ? <CoachingEvents /> : null }
            { customer.goalPagesLayout?.coachingOnCollective?.coaching ?
                <CoachingMeetingComponent /> :
                <CoachingItem
                    title={lang.webinar.register}
                    illustrationSrc={UnitedNationImg}
                    content={lang.unitedNation.title}
                    buttonLabel={lang.goal.tips.readMore}
                    buttonLink={lang.unitedNation.link}
                />}
        </div>
    )
}

export default Coaching
