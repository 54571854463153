const Pen = ({ fillColor = 'var(--gd-secondary-color)' }: any) => {
    return (
        <svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M14.6401 1.98385C14.1668 1.45042 13.6639 0.956497 13.1413 0.452699C12.9835 0.294645 12.7764 0.176104 12.5693 0.106955C11.889 -0.130127 11.2677 0.0279277 10.7155 0.57124C7.79676 3.49525 4.878 6.41925 1.95923 9.34326C1.5155 9.79767 1.20982 10.2521 1.03233 10.7559C0.854834 11.2597 0.687203 11.7733 0.519571 12.287L0.440685 12.5142C0.401243 12.6328 0.3618 12.7612 0.322357 12.8797C0.213889 13.1958 0.105422 13.5119 0.0265362 13.8379C-0.0424887 14.1046 0.0265362 14.3911 0.194168 14.6183C0.381521 14.8653 0.677342 15.0135 0.983024 15.0135H0.992884L1.11121 14.9838C1.19996 14.9641 1.29857 14.9443 1.38731 14.9147C1.71271 14.806 2.03812 14.6974 2.35366 14.5887C2.87628 14.4109 3.40875 14.2331 3.94123 14.0651C4.62162 13.8478 5.2034 13.4823 5.71616 12.9686C7.69816 10.9732 9.69002 8.98764 11.672 7.00208L14.3245 4.34479C14.4429 4.22625 14.5612 4.09783 14.6598 3.96941C15.1134 3.40634 15.1134 2.48765 14.6598 1.97397L14.6401 1.98385ZM5.04563 12.0993C4.64134 12.5439 4.23705 12.8205 3.7736 12.9785L3.62569 13.0279C2.79739 13.3045 1.96909 13.5811 1.14079 13.8577C1.18024 13.7391 1.21968 13.6206 1.25912 13.5021C1.32815 13.3045 1.38731 13.1168 1.45634 12.9291C1.53522 12.7019 1.60425 12.4846 1.68313 12.2574C1.82118 11.8425 1.95923 11.4375 2.10714 11.0325C2.25505 10.6077 2.55087 10.2817 2.90586 9.94584L3.68485 10.7262C4.14831 11.1905 4.6019 11.6449 5.06535 12.1092L5.04563 12.0993ZM13.7033 3.4261C13.5653 3.57427 13.4075 3.72245 13.2596 3.86075C13.2103 3.91014 13.161 3.94965 13.1117 3.99904C12.4116 3.30756 11.7213 2.62595 11.0212 1.93446L10.9423 1.86531C10.9916 1.81592 11.0409 1.75665 11.1001 1.70726C11.2677 1.53932 11.4354 1.36151 11.6129 1.20346C11.8002 1.03552 12.1256 1.03552 12.3425 1.20346C12.4214 1.26273 12.5003 1.34176 12.5792 1.42078L13.0032 1.84555C13.2201 2.06288 13.4469 2.29008 13.6639 2.50741C13.9498 2.79388 13.9597 3.13962 13.6935 3.43598L13.7033 3.4261ZM12.3228 4.80907L5.84435 11.2794L3.70458 9.17533L10.1831 2.66546L12.3228 4.80907Z'
                fill={fillColor}
            />
        </svg>
    )
}

export default Pen
