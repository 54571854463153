import classNames from 'classnames'
import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

interface TitleProps {
    title: string
    subTitle?: string
    subTitleNode?: ReactNode
    withVerticalLine?: boolean
    classNames?: {
        container?: string
    }
}

const useStyles = createUseStyles({
    title: {
        display: 'flex',
    },
    verticalLine: {
        width: '5px',
        height: 'auto',
        minHeight: '10px',
        borderRadius: 'var(--gd-border-small-radius)',
        backgroundColor: 'var(--gd-primary-color)',
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10px',
        alignSelf: 'center',
    },
    mainTitle: {
        fontSize: 'var(--gd-size-highlighted-title)',
        lineHeight: '25px',
        color: 'var(--gd-text-dark-color)',
        fontWeight: 'bold',
        margin: 0,
    },
    subTitle: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
        color: 'var(--gd-text-main-subtitle-color)',
        fontWeight: 'bold',
        marginTop: '3px',
        lineHeight: '20px',
    },
})

export default function Title({ title, subTitle, subTitleNode, classNames: customClassNames, withVerticalLine = true }: TitleProps) {
    const styles = useStyles()

    return (
        <div className={classNames(styles.title, customClassNames?.container)}>
            {withVerticalLine ? <div className={styles.verticalLine}></div> : null}
            <div className={styles.textWrapper}>
                <h3 className={styles.mainTitle}>{title}</h3>
                {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
                {subTitleNode}
            </div>
        </div>
    )
}
