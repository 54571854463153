import { useCallback } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import AwardIcon from '../../assets/icon/welcomeback/AwardIcon'
import { useStore, useUser } from '../../contexts'
import EQVTSurveyFeel from '../../models/EQVTSurveyFeel'
import { usePut } from '../../services/api/useFetch'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import { getWeekStartEnd } from '../dateUtils'
import { GreetButton, GreetSubmitButton } from '../share/GreetButton'
import WorkloadEvaluationFormPart from './WorkloadEvaluationFormPart'

const useWorkloadEvaluationStyle = createUseStyles({
    workloadEvaluation: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '55vw',
        minHeight: '45vh',
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--gd-background-clear-color)',
    },
    workloadEvaluationContent: {
        backgroundColor: 'var(--gd-main-popup-background-color)',
    },
    // Header
    workloadEvaluationHeaderTitle: {
        lineHeight: '20px',
        padding: '20px 40px',
        backgroundColor: 'var(--gd-primary-color)',
        fontSize: '20px',
        fontWeight: '700',
        color: 'white',
        borderBottomRightRadius: 'var(--gd-border-normal-radius)',
    },
    workloadEvaluationHeader: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    workloadEvaluationSubtitle: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        fontWeight: 'bold',
        color: 'var(--gd-primary-color)',
        fontSize: '22px'
    },
    // Body
    workloadEvaluationBody: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        justifyContent: 'space-around',
        padding: '30px',
        gap: '25px',
        color: 'var(--gd-text-dark-color)',
    },
    // Footer
    workloadEvaluationFooter: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '20px 30px',
        gap: '25px',
    },
    workloadEvaluationFooterDescription: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    workloadEvaluationFooterActions: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '20px',
    },
    workloadEvaluationFooterActionsButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '20px',
        height: '100%',
        '&>div': {
            minWidth: '230px',
        },
    },
    workloadEvaluationFooterActionMessage: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: '700',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        workloadEvaluationBody: {
            flexDirection: 'column',
        },
    },
})

interface WorkloadEvaluationPopup {
    close: () => void
    userData: any
}

interface WorkloadEvaluationForm {
    question1: EQVTSurveyFeel
    question2: EQVTSurveyFeel
    question3: EQVTSurveyFeel
}

const WorkloadEvaluation = ({ close, userData }: Readonly<WorkloadEvaluationPopup>) => {
    const { lang } = useUser()
    const style = useWorkloadEvaluationStyle()
    const { state, dispatch } = useStore()
    const {
        register,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm<WorkloadEvaluationForm>({
        defaultValues: userData ?? {},
    })

    const onPutSuccess = useCallback(() => {
        dispatch({ type: 'user-payload-fetched', value: { ...state.userPayload } })
        close()
    }, [state])

    const { working, doPut } = usePut({
        endpoint: 'workload-evaluation',
        onSuccess: onPutSuccess,
    })
    const [weekStart, weekEnd] = getWeekStartEnd(lang)
    const hasAlreadyAnswered = !!userData

    const onSubmit: SubmitHandler<WorkloadEvaluationForm> = (data: WorkloadEvaluationForm) => doPut(data)
    return (
        <form className={style.workloadEvaluation} onSubmit={handleSubmit(onSubmit)}>
            <div className={style.workloadEvaluationContent}>
                <div className={style.workloadEvaluationHeader}>
                    <div className={style.workloadEvaluationHeaderTitle}>{lang.worloadEvaluation.title(weekStart, weekEnd)}</div>
                </div>
                <div className={style.workloadEvaluationSubtitle}>{lang.worloadEvaluation.subTitle}</div>
                <div className={style.workloadEvaluationBody}>
                    <WorkloadEvaluationFormPart name='question1' register={register} description={lang.worloadEvaluation.question1} />
                    <WorkloadEvaluationFormPart name='question2' register={register} description={lang.worloadEvaluation.question2} />
                    <WorkloadEvaluationFormPart name='question3' register={register} description={lang.worloadEvaluation.question3} />
                </div>
            </div>
            <div className={style.workloadEvaluationFooter}>
                <div className={style.workloadEvaluationFooterDescription}>
                    {isDirty && isValid ? (
                        <span>{lang.worloadEvaluation.validateToAnswer}</span>
                    ) : hasAlreadyAnswered ? (
                        <span>{lang.worloadEvaluation.modifyAnswerdEvaluation}</span>
                    ) : (
                        <span>{lang.worloadEvaluation.notAnswerdEvaluation}</span>
                    )}
                </div>

                <div className={style.workloadEvaluationFooterActions}>
                    <div className={style.workloadEvaluationFooterActionMessage}>
                        <AwardIcon />
                        <div>{lang.worloadEvaluation.actionsMessage}</div>
                    </div>
                    <div className={style.workloadEvaluationFooterActionsButton}>
                        <GreetSubmitButton label={lang.worloadEvaluation.submitButton} disabled={!isValid || !isDirty || working} loading={working} />
                        {hasAlreadyAnswered ? <GreetButton label={lang.worloadEvaluation.accessToIndicatorsButton} onClick={close} /> : null}
                    </div>
                </div>
            </div>
        </form>
    )
}

export default WorkloadEvaluation
