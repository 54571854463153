import Arnaud from '../../assets/illustration/CoachArnaud2.png'
import Christophe from '../../assets/illustration/CoachChristophe2.png'
import { CoachingMeeting } from '../../models'
import { Brick, BrickTypes } from '../../models/Brick'
import { BugReport } from '../../models/Customer'
import EDisplay from '../../models/coaching/EDisplay'

export const defaultBricks: Array<Brick> = [
    {
        type: BrickTypes.EMAIL,
        name: 'Email',
    },
    {
        type: BrickTypes.ONEDRIVE,
        name: 'OneDrive',
    },
    {
        type: BrickTypes.TEAMS,
        name: 'Teams',
    },
]

export const defaultCoachingMeeting: CoachingMeeting = {
    display: EDisplay.ONE_COACH,
    description: [
        {
            language: 'fr',
            part1: 'Prenez RDV pour une session de 30 minutes. ',
            part2: 'Notre expert vous aidera à progresser vers votre objectif.',
        },
        {
            language: 'en',
            part1: 'Make an appointment for a 30 minute session. ',
            part2: 'Our expert will help you progress towards your goal.',
        },
    ],
    coachContentList: [
        {
            firstname: 'Arnaud',
            calendarURL: 'https://calendar.app.google/Vqd8z6QsAMLqC6pB9',
            base64Picture: Arnaud,
        },
        {
            firstname: 'Christophe',
            calendarURL: 'https://calendar.app.google/',
            base64Picture: Christophe,
        },
    ],
}

export const defaultBugReport: BugReport = {
    fr: {
        contact: 'Vous pouvez adresser un email à ',
        description: [
            'Pour faciliter le diagnostic, n’hésitez pas à transmettre des captures d’écran.',
            'Si vous nous demandez des clarifications sur vos indicateurs, vous nous donnez l’autorisation de regarder vos données pour vous répondre.',
        ],
    },
    en: {
        contact: 'You can send an email to ',
        description: [
            'To facilitate the diagnosis, do not hesitate to send screenshots.',
            'If you ask us for clarification on your indicators, you give us permission to look at your data to respond to you.',
        ],
    },
}
// , <BlogAssets.Article2 key={1} />, <BlogAssets.Article3 key={2} />
export const defaultBlogs: any = {
    fr: [
        {
            title: 'Seuls 43% des collaborateurs se sentent concernés par la démarche environnementale de leur entreprise',
            description: 'Les entreprises se fixent (à raison) des objectifs de réduction de 50% à horizon 2030 ...',
            link: 'https://blog.lecko.fr/category/travailler-autrement/numerique-responsable',
            illustrationIndex: 0,
        },
        {
            title: 'En moyenne, les émissions CO2 des usages de Microsoft 365 augmentent de 30% / an',
            description: 'L’analyse des activités de 20 000 utilisateurs sur Microsoft 365 montrent une croissance de 60% sur 2 ans des émissions CO2 ...',
            link: 'https://blog.lecko.fr/category/travailler-autrement/numerique-responsable/',
            illustrationIndex: 1,
        },
        {
            title: '85% des collaborateurs aggravent leurs émissions liées aux usages Microsoft 365',
            description:
                'L’analyse de 20 000 utilisateurs de Microsoft 365 sur 2 années montrent que 85% des utilisateurs aggravent leurs émissions. 50% aggravent leur émissions jusqu’à les doubler et 35% d’entre eux les aggravent encore plus ...',
            link: 'https://blog.lecko.fr/category/travailler-autrement/numerique-responsable/',
            illustrationIndex: 2,
        },
    ],
    en: [
        {
            title: "Only 43% of employees feel concerned by their company's environmental approach",
            description: 'Companies are (rightly) setting targets of a 50% reduction by 2030 ...',
            link: 'https://blog.lecko.fr/category/travailler-autrement/numerique-responsable',
            illustrationIndex: 0,
        },
        {
            title: 'On average, CO2 emissions from Microsoft 365 use increase by 30%/year',
            description: 'Analysis of the activities of 20,000 users on Microsoft 365 show a 60% growth in CO2 emissions over 2 years...',
            link: 'https://blog.lecko.fr/category/travailler-autrement/numerique-responsable/',
            illustrationIndex: 1,
        },
        {
            title: '85% of employees aggravate their emissions related to Microsoft 365 usage',
            description:
                'Analysis of 20,000 Microsoft 365 users over 2 years shows that 85% of users make their emissions worse. 50% worsen their emissions to the point of doubling them and 35% of them worsen them even more...',
            link: 'https://blog.lecko.fr/category/travailler-autrement/numerique-responsable/',
            illustrationIndex: 2,
        },
    ],
}
