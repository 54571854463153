import { SingleColorProps } from '../../models'

export default function AwardedBadge({ color }: SingleColorProps) {
    return (
        <svg width='100%' height='100%' viewBox='0 0 89 82' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_3102_2149)'>
                <path
                    d='M40.9696 81.9665C63.4314 81.9665 81.6403 63.7576 81.6403 41.2958C81.6403 18.8339 63.4314 0.625 40.9696 0.625C18.5077 0.625 0.298828 18.8339 0.298828 41.2958C0.298828 63.7576 18.5077 81.9665 40.9696 81.9665Z'
                    fill={color}
                />
                <path
                    d='M40.9694 75.8598C60.0579 75.8598 75.5321 60.3855 75.5321 41.2971C75.5321 22.2086 60.0579 6.73438 40.9694 6.73438C21.881 6.73438 6.40674 22.2086 6.40674 41.2971C6.40674 60.3855 21.881 75.8598 40.9694 75.8598Z'
                    fill='white'
                />
                <path
                    d='M40.9693 72.7277C58.3299 72.7277 72.4035 58.6542 72.4035 41.2936C72.4035 23.9329 58.3299 9.85938 40.9693 9.85938C23.6087 9.85938 9.53516 23.9329 9.53516 41.2936C9.53516 58.6542 23.6087 72.7277 40.9693 72.7277Z'
                    fill={color}
                />
            </g>
            <path
                opacity='0.19'
                d='M88.9518 11.6555C88.9518 5.38815 83.846 0.296875 77.5274 0.296875C71.2088 0.296875 66.103 5.37326 66.103 11.6555C66.103 15.5707 68.0944 19.0244 71.119 21.0639V31.2911C71.119 31.9461 71.7778 32.363 72.3018 32.0355L76.8836 29.1028L81.4653 32.0355C81.9893 32.363 82.6481 31.961 82.6481 31.2911V21.8231C86.3764 19.9623 88.9518 16.1066 88.9518 11.6555Z'
                fill='black'
            />
            <path
                d='M82.6634 31.4895C82.5436 31.4895 82.4238 31.4598 82.319 31.3853L77.7822 28.4824L73.2454 31.3853C73.1406 31.4598 73.0208 31.4895 72.901 31.4895C72.7213 31.4895 72.5416 31.4151 72.4069 31.2662C72.2721 31.1174 72.1973 30.9238 72.1973 30.7005V13.4766H83.3671V30.7154C83.3671 31.1471 83.0527 31.4895 82.6634 31.4895Z'
                fill='#F0F6FF'
            />
            <path
                d='M83.307 13.548V30.7125C83.307 31.1591 82.9776 31.4271 82.6632 31.4271C82.5584 31.4271 82.4386 31.3973 82.3488 31.3377L77.8569 28.4646L77.782 28.4199L77.7071 28.4646L73.2153 31.3377C73.1104 31.3973 73.0056 31.4271 72.9008 31.4271C72.5864 31.4271 72.257 31.1591 72.257 30.7125V13.548H83.307ZM83.4418 13.4141H72.1372V30.7125C72.1372 31.2037 72.5115 31.561 72.9008 31.561C73.0356 31.561 73.1554 31.5313 73.2901 31.4419L77.782 28.5688L82.2739 31.4419C82.3937 31.5164 82.5284 31.561 82.6632 31.561C83.0675 31.561 83.4268 31.2037 83.4268 30.7125V13.4141H83.4418Z'
                fill='#F0F6FF'
            />
            <path d='M82.2736 31.4427L77.7817 28.5695L77.8416 13.4297H83.4415V30.7281C83.4415 31.3682 82.7977 31.7702 82.2736 31.4427Z' fill='#F0F6FF' />
            <path
                d='M77.7818 22.0774C83.868 22.0774 88.8019 17.1719 88.8019 11.1207C88.8019 5.06953 83.868 0.164062 77.7818 0.164062C71.6956 0.164062 66.7617 5.06953 66.7617 11.1207C66.7617 17.1719 71.6956 22.0774 77.7818 22.0774Z'
                fill='var(--gd-secondary-dark-color)'
            />
            <path
                d='M77.7819 21.0967C83.3223 21.0967 87.8138 16.6311 87.8138 11.1226C87.8138 5.61401 83.3223 1.14844 77.7819 1.14844C72.2414 1.14844 67.75 5.61401 67.75 11.1226C67.75 16.6311 72.2414 21.0967 77.7819 21.0967Z'
                fill='var(--gd-secondary-dark-color)'
            />
            <path
                opacity='0.1'
                d='M77.7819 11.1213L72.751 2.47208C77.5274 -0.267082 83.7262 1.38535 86.4812 6.13423C89.2213 10.8682 87.5892 17.0016 82.8578 19.7556L77.7819 11.1213Z'
                fill='white'
            />
            <path
                d='M85.8525 9.22673C85.8375 9.21184 85.8226 9.19696 85.7926 9.19696C85.7776 9.18207 85.7477 9.18207 85.7327 9.18207L85.4782 9.1523L84.1606 8.95877L81.0462 8.51217C80.9713 8.49728 80.8965 8.45262 80.8665 8.37818L78.7703 4.16523C78.6805 3.98659 78.4259 3.98659 78.351 4.16523L76.2548 8.37818C76.2249 8.45262 76.15 8.49728 76.0752 8.51217L71.3886 9.18207C71.194 9.21184 71.1191 9.45003 71.2539 9.58401L74.6527 12.8591C74.7126 12.9186 74.7276 12.9931 74.7276 13.0675L74.0239 17.1763L73.964 17.5038L73.934 17.6973C73.919 17.8015 73.964 17.8908 74.0388 17.9355C74.0538 17.9355 74.0538 17.9504 74.0688 17.9504C74.0688 17.9504 74.0838 17.9504 74.0987 17.9653C74.1287 17.9801 74.1586 17.9801 74.1886 17.9801C74.2185 17.9801 74.2485 17.9653 74.2934 17.9504L78.4858 15.762C78.5607 15.7322 78.6355 15.7322 78.7104 15.762L82.9028 17.9504C82.9927 17.995 83.0825 17.9801 83.1574 17.9355C83.1723 17.9355 83.1723 17.9206 83.1873 17.9206C83.2173 17.9057 83.2322 17.8759 83.2472 17.8462C83.2622 17.8015 83.2772 17.7568 83.2622 17.7122L82.5285 13.7523L82.4087 13.0675C82.4087 13.0526 82.4087 13.0229 82.4087 13.008C82.4087 12.9931 82.4087 12.9633 82.4237 12.9484C82.4387 12.9186 82.4387 12.9038 82.4536 12.874C82.4536 12.8591 82.4686 12.8591 82.4836 12.8442L85.8675 9.58401C85.8824 9.56913 85.8825 9.56913 85.8974 9.55424C85.9274 9.52447 85.9423 9.47981 85.9423 9.43515C85.9423 9.36071 85.9124 9.28628 85.8525 9.22673Z'
                fill='var(--gd-secondary-dark-color)'
            />
            <path
                d='M82.4988 17.5954C82.4688 17.5954 82.4389 17.5954 82.4239 17.5805L78.2315 15.3922C78.1866 15.3624 78.1417 15.3624 78.0967 15.3624C78.0518 15.3624 77.9919 15.3773 77.962 15.3922L73.7696 17.5805C73.7396 17.5954 73.7247 17.5954 73.6947 17.5954C73.6498 17.5954 73.5899 17.5657 73.56 17.5359C73.53 17.4912 73.515 17.4466 73.53 17.4019L74.3236 12.7572C74.3385 12.653 74.3086 12.5637 74.2337 12.4893L70.8498 9.19929C70.8049 9.15463 70.79 9.09509 70.8049 9.02065C70.8199 8.96111 70.8798 8.91645 70.9397 8.90156L75.6262 8.23165C75.731 8.21677 75.8059 8.15722 75.8508 8.0679L77.947 3.85495C77.977 3.7954 78.0369 3.76562 78.0967 3.76562C78.1566 3.76562 78.2165 3.7954 78.2465 3.85495L80.3427 8.0679C80.3876 8.15722 80.4774 8.21677 80.5673 8.23165L85.2538 8.90156C85.3137 8.91645 85.3736 8.96111 85.3886 9.02065C85.4035 9.0802 85.3886 9.15463 85.3436 9.19929L81.9598 12.4893C81.8849 12.5637 81.855 12.653 81.8699 12.7572L82.6635 17.4019C82.6785 17.4466 82.6635 17.5061 82.6335 17.5359C82.5886 17.5805 82.5437 17.5954 82.4988 17.5954Z'
                fill='white'
            />
            <path
                d='M78.0817 3.83711C78.0966 3.83711 78.1416 3.83711 78.1715 3.89665L80.2677 8.10961C80.3276 8.21381 80.4324 8.28825 80.5522 8.31802L85.2388 8.98793C85.2986 9.00281 85.3136 9.03259 85.3136 9.06236C85.3136 9.07725 85.3286 9.12191 85.2837 9.16657L81.8998 12.4565C81.8099 12.5459 81.765 12.665 81.795 12.7841L82.5885 17.4287C82.5885 17.4734 82.5736 17.4883 82.5736 17.5032C82.5586 17.5329 82.5286 17.5478 82.4987 17.5478C82.4837 17.5478 82.4688 17.5478 82.4538 17.5329L78.2613 15.3446C78.2015 15.3148 78.1416 15.2999 78.0817 15.2999C78.0218 15.2999 77.9619 15.3148 77.902 15.3446L73.7096 17.5329C73.6946 17.5478 73.6796 17.5478 73.6646 17.5478C73.6347 17.5478 73.6047 17.5329 73.5898 17.5032C73.5748 17.4883 73.5598 17.4585 73.5748 17.4287L74.3684 12.7841C74.3833 12.665 74.3534 12.5459 74.2636 12.4565L70.8797 9.16657C70.8348 9.12191 70.8497 9.07725 70.8497 9.06236C70.8497 9.04747 70.8797 9.00281 70.9246 8.98793L75.6111 8.31802C75.7309 8.30314 75.8357 8.2287 75.8956 8.10961L77.9918 3.89665C78.0218 3.83711 78.0667 3.83711 78.0817 3.83711ZM78.0817 3.70312C77.9918 3.70312 77.917 3.74779 77.872 3.83711L75.7758 8.05006C75.7459 8.12449 75.671 8.16915 75.5962 8.18404L70.9096 8.85395C70.715 8.88372 70.6401 9.12191 70.7749 9.25589L74.1588 12.5459C74.2186 12.6054 74.2336 12.6798 74.2336 12.7543L73.44 17.3989C73.4101 17.5478 73.5299 17.6818 73.6796 17.6818C73.7096 17.6818 73.7545 17.6669 73.7844 17.652L77.9769 15.4637C78.0068 15.4488 78.0517 15.4339 78.0817 15.4339C78.1116 15.4339 78.1565 15.4488 78.1865 15.4637L82.3789 17.652C82.4089 17.6669 82.4538 17.6818 82.4837 17.6818C82.6185 17.6818 82.7383 17.5627 82.7233 17.3989L81.9297 12.7543C81.9148 12.6798 81.9447 12.6054 82.0046 12.5459L85.3885 9.25589C85.5232 9.12191 85.4484 8.88372 85.2537 8.85395L80.5672 8.18404C80.4923 8.16915 80.4175 8.12449 80.3875 8.05006L78.2913 3.83711C78.2464 3.74779 78.1715 3.70312 78.0817 3.70312Z'
                fill='white'
            />
            <path
                d='M77.5729 5.8032L76.2253 8.40839C76.1205 8.60192 75.9409 8.72101 75.7312 8.7359L72.6768 9.01874C72.5869 9.03363 72.5869 9.15273 72.6768 9.15273L75.9558 9.30159C76.2253 9.31648 76.4799 9.15273 76.5697 8.89965L77.7077 5.86275C77.7226 5.77343 77.6028 5.72877 77.5729 5.8032Z'
                fill='white'
            />
            <path
                d='M73.5299 16.8652L73.4401 17.4011C73.4101 17.5946 73.6048 17.7435 73.7845 17.6542L77.9769 15.4658C78.0517 15.4361 78.1266 15.4361 78.2015 15.4658L82.3939 17.6542C82.5736 17.7435 82.7682 17.5946 82.7383 17.4011L81.9297 12.7564C81.9148 12.682 81.9447 12.6076 82.0046 12.548L85.3885 9.25806C85.5232 9.12408 85.4484 8.88589 85.2537 8.85611L83.6816 8.63281C81.1511 13.1584 77.2582 15.5403 73.5299 16.8652Z'
                fill='white'
            />
            <defs>
                <clipPath id='clip0_3102_2149'>
                    <rect width='81.3415' height='81.3415' fill='white' transform='translate(0.298828 0.625)' />
                </clipPath>
            </defs>
        </svg>
    )
}
