import { User } from '../../models'

const STORAGE_NAME = 'user'

const user = {
    store: (user: User): void => {
        localStorage.setItem(STORAGE_NAME, JSON.stringify(user))
    },
    get: (): User | null => {
        const userStringified = localStorage.getItem(STORAGE_NAME)
        if (userStringified !== null) {
            return JSON.parse(userStringified)
        } else {
            return null
        }
    },
    clear: () => {
        localStorage.removeItem(STORAGE_NAME)
    },
}

export default user
