const EmissionsIcon = () => {
    return (
        <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='17' cy='17' r='17' fill='var(--gd-primary-color)' />
            <path
                d='M19.6831 14.2255C16.2158 12.0795 12.6272 13.6201 9.31159 10.2564C8.53925 9.47156 8.98783 19.0579 13.1382 22.6907C16.1725 25.3442 20.8576 25.0482 22.4374 22.4694C24.0172 19.8906 23.1505 16.3711 19.6831 14.2255V14.2255Z'
                stroke='var(--gd-main-app-background-color)'
                strokeWidth='1.4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='M14.3242 17.3828C17.4603 20.3451 20.7057 22.1005 24.9723 22.7588' stroke='var(--gd-main-app-background-color)' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    )
}

export default EmissionsIcon
