import { ActiveElement, ChartEvent } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { createUseStyles } from 'react-jss'
import { useInstance } from '../../contexts'

import { Brick, BrickTypes } from '../../models/Brick'
import Payload from '../../models/Payload'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import { convertToGCO2 } from '../utils'

interface BrickDoughnutProps {
    bricks: Brick[]
    payload: Payload
    activeBrick: BrickTypes
    setActiveBrick: (brickTypes: BrickTypes) => void
}

const doughnutOptions = (setActiveBrick: (datesetIndex: number) => void) => ({
    responsive: true,
    maintainAspectRatio: false,
    cutout: '60%',
    layout: { padding: 3 },
    plugins: {
        tooltip: {
            enabled: false,
        },
        legend: {
            display: false,
        },
    },
    hoverOffset: 8,
    animation: { animateScale: true },
    onClick: (event: ChartEvent, elements: ActiveElement[]) => {
        if (!elements?.length) {
            return
        }
        setActiveBrick(elements[0].index)
    },
})

const useStyles = createUseStyles({
    brickDoughnutProps: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        width: '65%',
    },
    canvasContainer: {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        height: '40vh',
    },
    total: {
        position: 'absolute',
        bottom: 'calc(50% + 30px)',
        color: 'var(--gd-text-dark-color)',
        fontWeight: 'bold',
        height: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    totalMeasure: {
        fontSize: 'var(--gd-size-big-number)',
    },
    totalUnit: {
        fontSize: 'var(--gd-size-highlighted-title)',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        canvasContainer: {
            width: '80%',
        },
        total: {
            bottom: 'calc(50% + 20px)',
        },
        totalUnit: {
            fontSize: 'var(--gd-size-highlighted-small-title)',
        },
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        canvasWrapper: {
            flexDirection: 'column',
            padding: '1.5rem 0',
        },
        canvasContainer: {
            height: '30vh',
        },
    },
})

const BrickDoughnut = ({ payload, bricks, setActiveBrick }: BrickDoughnutProps) => {
    const styles = useStyles()
    const { customer } = useInstance()
    const customerTheme = customer.theme
    const dataSet: any = {
        data: [],
        backgroundColor: [],
        borderWidth: 0,
    }
    let totalCarbonImpact = 0
    const dataSetIndexes: BrickTypes[] = []
    bricks.forEach((brick) => {
        switch (brick.type) {
            case BrickTypes.EMAIL:
                dataSet.data.push(payload.mail.carbonImpact)
                totalCarbonImpact += payload.mail.carbonImpact
                dataSetIndexes.push(BrickTypes.EMAIL)
                dataSet.backgroundColor.push(customerTheme.brickEmailColor)
                break
            case BrickTypes.ONEDRIVE:
                dataSet.data.push(payload.drive.carbonImpact)
                totalCarbonImpact += payload.drive.carbonImpact
                dataSetIndexes.push(BrickTypes.ONEDRIVE)
                dataSet.backgroundColor.push(customerTheme.brickOneDriveColor)
                break
            case BrickTypes.TEAMS:
                dataSet.data.push(payload.teams.carbonImpact)
                totalCarbonImpact += payload.teams.carbonImpact
                dataSetIndexes.push(BrickTypes.TEAMS)
                dataSet.backgroundColor.push(customerTheme.brickTeamsColor)
                break
            case BrickTypes.GMAIL:
                dataSet.data.push(payload.mail.carbonImpact)
                totalCarbonImpact += payload.mail.carbonImpact
                dataSetIndexes.push(BrickTypes.GMAIL)
                dataSet.backgroundColor.push(customerTheme.brickGmailColor)
                break
            case BrickTypes.GDRIVE:
                dataSet.data.push(payload.drive.carbonImpact)
                totalCarbonImpact += payload.drive.carbonImpact
                dataSetIndexes.push(BrickTypes.GDRIVE)
                dataSet.backgroundColor.push(customerTheme.brickGDriveColor)
                break
            case BrickTypes.SHAREDGDRIVE:
                dataSet.data.push(payload.sharedDrive.carbonImpact)
                totalCarbonImpact += payload.sharedDrive.carbonImpact
                dataSetIndexes.push(BrickTypes.SHAREDGDRIVE)
                dataSet.backgroundColor.push(customerTheme.brickSharedGDriveColor)
                break
            case BrickTypes.COPILOTE:
                dataSet.data.push(payload.copilote?.carbonImpact ?? 0)
                totalCarbonImpact += payload.copilote?.carbonImpact ?? 0
                dataSetIndexes.push(BrickTypes.COPILOTE)
                dataSet.backgroundColor.push(customerTheme.brickCopiloteColor)
                break
        }
    })
    const convertedTotalCarbonImpact = convertToGCO2(totalCarbonImpact)

    const switchActiveBrick = (dataSetIndex: number) => {
        setActiveBrick(dataSetIndexes[dataSetIndex])
    }

    return (
        <div className={styles.brickDoughnutProps}>
            <div className={styles.canvasContainer}>
                <Doughnut data={{ datasets: [dataSet] }} options={doughnutOptions(switchActiveBrick)} updateMode='resize' />
            </div>
            {convertedTotalCarbonImpact && (
                <h3 className={styles.total}>
                    <span className={styles.totalMeasure}>{convertedTotalCarbonImpact.value}</span>
                    <span className={styles.totalUnit}>{convertedTotalCarbonImpact.measured}</span>
                </h3>
            )}
        </div>
    )
}

export default BrickDoughnut
