import { createUseStyles } from 'react-jss'
import { useUser } from '../../contexts'
import { Params, Role } from '../../models'
import { isAdmin, isRealAdmin } from '../rolesUtils'
import { useHistory, useParams } from 'react-router-dom'

const useStyles = createUseStyles({
    adminHeader: {
        display: 'flex',
        position: 'sticky',
        top: '0',
        zIndex: '100',
        width: '100%',
        height: '30px',
        backgroundColor: 'var(--gd-primary-color)',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
    },
    adminHeaderText: {
        color: 'white',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: '0 0 0 10px',
        whiteSpace: 'nowrap',
    },
    adminHeaderAction: {
        color: 'white',
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration: 'underline',
        whiteSpace: 'nowrap',
        margin: '0 10px 0 0 ',
    },
})

export default function AdminHeader() {
    const { user, switchRole } = useUser()
    return <AdminRoleSwicther user={user} switchRole={switchRole} />
}

const AdminRoleSwicther = ({ user, switchRole }: any) => {
    const styles = useStyles()
    const { lang } = useUser()
    const history = useHistory()
    const { langParam, customerParam } = useParams<Params>()

    if (!isRealAdmin(user) || !isAdmin(user)) {
        return null
    }
    return (
        <div className={styles.adminHeader}>
            <span className={styles.adminHeaderText}>{lang.headerMenu.administratorAlert}&nbsp;</span>
            <span
                className={styles.adminHeaderAction}
                onClick={async () => {
                    history.replace(`/${langParam}/${customerParam}`)
                    await switchRole(Role.ANIMATOR)
                }}
            >
                {lang.headerMenu.switchToAnimatorAlert}
            </span>
        </div>
    )
}
