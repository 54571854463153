import { createUseStyles } from 'react-jss'
import { convertToRem } from '../components/utils'

const useQVTIndividualGoalStyles = () =>
    createUseStyles({
        container: {
            width: '100%',
            maxWidth: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            gap: '30px',
        },
        individualComponentContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: '30px',
        },
        individualDarkTitledComponentContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
        },
        individualDarkComponentContainer: {
            backgroundColor: 'var(--gd-background-clear-color)',
            borderRadius: 'var(--gd-border-normal-radius)',
            padding: convertToRem(30)
        },
        graphContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            marginTop: '20px',
            marginBottom: '20px',
            width: '100%',
            height: '100%',
        }
    })()

export default useQVTIndividualGoalStyles
