import classNames from 'classnames'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import IconAssets from '../assets/icon/IconAssets'
import { useUser } from '../contexts'
import { useFetch, usePut } from '../services/api/useFetch'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import Popup from './Popup'
import SoftLoader from './SoftLoader'
import Title from './Title'
import { isAtLeastAnimator, requireAnimator } from './rolesUtils'
import { GreetSubmitButton } from './share/GreetButton'
import { TextAreaField } from './share/Inputs'
import { convertToRem } from './utils'

interface DescriptionProps {
    text: string
    emptyText: boolean
    clickable: boolean
    loading: boolean
    onClick?: () => void
}
interface GoalPresentationProps {
    title: string
    emptyText: boolean
    description: React.ReactNode
    editButton: ReactNode
}
interface GroupGoalPresentationProps {
    cohortId?: string
    goalId: string
}

const CONTAINER_WIDTH = 360
const DESCRIPTION_TEXT_MAX_NB_LINES = 3
const DEFAULT_DESCRIPTION_FONT_SIZE = 14

const useStyles = createUseStyles({
    goalPresentation: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyItems: 'center',
        minWidth: `${CONTAINER_WIDTH}px`,
        width: `${CONTAINER_WIDTH}px`,
        height: '280px',
        maxHeight: '280px',
        minHeight: '280px',
        borderRadius: '20px 20px 22px 22px',
        backgroundColor: 'var(--gd-primary-color)',
    },
    goalPresentationEmpty: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        borderRadius: 'var(--gd-border-normal-radius)',
        minWidth: `${CONTAINER_WIDTH}px`,
        width: `${CONTAINER_WIDTH}px`,
        height: '280px',
        maxHeight: '280px',
        minHeight: '280px',
    },
    icon: {
        position: 'relative',
        left: '16px',
        width: '45px',
        '--gd-benchmark-bulb-background-color': 'var(--gd-main-app-background-color)',
    },
    goalPresentationHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px',
        height: '101%',
        width: '101%',
        borderRadius: '20px',
        backgroundColor: 'var(--gd-background-clear-color)',
        fontSize: '18px',
        padding: '40px',
    },

    goalPresentationHeaderEmpty: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '40px',
        fontWeight: 'bold',
        color: 'white',
        alignItems: 'center',
    },

    goalPresentationHeaderTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignSelf: 'start',
        alignItems: 'center',
        color: 'var(--gd-text-dark-color);',
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
        lineHeight: '25px',
    },

    goalPresentationHeaderTitleEmpty: {
        display: 'flex',
        flex: '90',
    },
    goalPresentationHeaderAction: {
        flex: '10',
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        marginLeft: '10px',
    },
    goalPresentationContent: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        gap: '20px',
        width: '100%',
        height: '225px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clickableGoalPresentationContent: {
        cursor: 'pointer',
    },
    clickableGoalPresentationContentSimple: {
        cursor: 'default',
    },
    goalPresentationContentText: (props: { descriptionFontSize: number }) => ({
        color: 'var(--gd-text-dark-color)',
        display: 'flex',
        alignItems: 'flex-start',
        fontSize: convertToRem(props?.descriptionFontSize ?? DEFAULT_DESCRIPTION_FONT_SIZE),
        lineHeight: convertToRem(17.6),
        whiteSpace: 'pre-line',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }),
    goalPresentationContentNoDescription: {
        fontSize: 'var(--gd-size-body-text)',
        fontWeight: 'bold',
    },
    dart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '70%',
    },
    softLoaderWrapper: {
        width: '50px',
        height: '50px',
    },
    mobileGoalPresentationContent: {
        display: 'none',
    },
    mobileGoalPresentationContentEmptyText: {
        display: 'none',
    },
    mobileGoalPresentationContentCollapsed: {
        display: 'none',
    },
    mobileGoalPresentationContentTextCollapsed: {
        display: 'none',
    },
    mobileCollapse: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    mobileCollapseShadow: {
        position: 'relative',
        top: '-10px',
        opacity: 0.5,
        width: '100%',
        height: '15px',
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 33.33%)',
    },
    mobileCollapseIcon: {
        position: 'relative',
        top: '-10px',
        width: '21px',
        height: '21px',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        goalPresentation: {
            width: '100%',
            height: '100%',
            maxHeight: 'unset',
            minHeight: 'unset',
            minWidth: 'unset',
        },
        goalPresentationContent: {
            display: 'none',
        },
        mobileGoalPresentationContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
        },
        mobileGoalPresentationContentEmptyText: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'var(--gd-background-clear-color)',
            borderRadius: 'var(--gd-border-normal-radius)',
            gap: '20px',
            width: '100%',
            height: '225px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
        },
        mobileGoalPresentationContentCollapsed: {
            display: 'flex',
            gap: '20px',
        },
        goalPresentationContentText: {
            textAlign: 'start',
            margin: 'unset',
            height: '50px',
        },
        mobileGoalPresentationContentTextCollapsed: {
            display: 'unset',
            height: '100%',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        goalPresentation: {
            width: '45%',
        },
    },
})

const PlusIcon = () => {
    return (
        <svg width='100%' height='100%' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='10.5' cy='10.5' r='8.5' fill='var(--gd-background-clear-color)' />
            <path d='M9.74176 13.684V11.108H7.27776V9.652H9.74176V7.076H11.2538V9.652H13.7178V11.108H11.2538V13.684H9.74176Z' fill='var(--gd-secondary-dark-color)' />
        </svg>
    )
}
const MinusIcon = () => {
    return (
        <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='10.5' cy='10.5' r='8.5' fill='var(--gd-background-clear-color)' />
            <path d='M8.77778 12.186V10.73H12.2218V12.186H8.77778Z' fill='var(--gd-secondary-dark-color)' />
        </svg>
    )
}

const Description = ({ text, emptyText, loading, clickable, onClick }: DescriptionProps) => {
    const [collapsible, setCollapsible] = useState(false)
    const [collapse, setCollapse] = useState(false)
    const descriptionRef = useRef<HTMLDivElement>(null)
    const descriptionFontSize = text.length < 150 ? DEFAULT_DESCRIPTION_FONT_SIZE + 4 : DEFAULT_DESCRIPTION_FONT_SIZE
    const styles = useStyles({ descriptionFontSize })

    useEffect(() => {
        if (!emptyText && descriptionRef.current != null) {
            setCollapsible((text.length * descriptionFontSize) / CONTAINER_WIDTH > DESCRIPTION_TEXT_MAX_NB_LINES)
        }
    }, [text, emptyText, loading])

    if (loading) {
        return (
            <div className={classNames(styles.goalPresentationContent, styles.mobileGoalPresentationContentEmptyText)}>
                <div className={styles.softLoaderWrapper}>
                    <SoftLoader />
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={classNames(styles.goalPresentationContent, clickable && styles.clickableGoalPresentationContent)} onClick={onClick}>
                <>
                    <div className={classNames([styles.goalPresentationContentText, emptyText && styles.goalPresentationContentNoDescription])}>{text}</div>
                    {emptyText ? (
                        <div className={styles.dart}>
                            <IconAssets.Dart />
                        </div>
                    ) : null}
                </>
            </div>

            {/* mobile */}
            <div
                className={classNames(
                    emptyText ? styles.mobileGoalPresentationContentEmptyText : styles.mobileGoalPresentationContent,
                    collapse && styles.mobileGoalPresentationContentCollapsed,
                    clickable && styles.clickableGoalPresentationContent,
                )}
                ref={descriptionRef}
                onClick={onClick}
            >
                <>
                    <div
                        className={classNames([
                            styles.goalPresentationContentText,
                            emptyText && styles.goalPresentationContentNoDescription,
                            collapse && styles.mobileGoalPresentationContentTextCollapsed,
                        ])}
                    >
                        {text}
                    </div>
                    {emptyText ? (
                        <div className={styles.dart}>
                            <IconAssets.Dart />
                        </div>
                    ) : collapsible ? (
                        <div className={styles.mobileCollapse}>
                            {collapse ? null : <div className={styles.mobileCollapseShadow}></div>}
                            <div></div>
                            <div
                                className={styles.mobileCollapseIcon}
                                onClick={() => {
                                    setCollapse((prev) => !prev)
                                }}
                            >
                                {collapse ? <MinusIcon /> : <PlusIcon />}
                            </div>
                        </div>
                    ) : null}
                </>
            </div>
        </>
    )
}

const GoalPresentation = ({ title, description, editButton, emptyText }: GoalPresentationProps) => {
    const styles = useStyles()
    return !emptyText ? (
        <div className={styles.goalPresentation}>
            <div className={styles.icon}>
                <IconAssets.Objective />
            </div>
            <div className={styles.goalPresentationHeader}>
                <div className={styles.goalPresentationHeaderTitle}>
                    <span>{title}</span>
                    {editButton}
                </div>
                {description}
            </div>
        </div>
    ) : (
        <div className={styles.goalPresentationEmpty}>
            <div className={styles.goalPresentationHeaderEmpty}>
                <div className={styles.goalPresentationHeaderTitleEmpty}>
                    <Title title={title} />
                </div>
                {editButton}
            </div>
            {description}
        </div>
    )
}

const GroupGoalPresentation = ({ goalId, cohortId }: GroupGoalPresentationProps) => {
    const { user, lang } = useUser()
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false)
    const [description, setDescription] = useState<string>()
    const styles = useStyles()
    const { loading } = useFetch({
        endpoint: 'goal-cohort-description',
        params: { cohortId, goalId },
        onSuccess: (data) => {
            if (data == null || !data) {
                setDescription('')
            } else {
                setDescription(data)
            }
        },
    })

    function openEditPopup() {
        setShowEditPopup(true)
    }

    return (
        <>
            <GoalPresentation
                title={lang.goal.collective.presentation.title}
                description={
                    <Description
                        text={description || lang.goal.collective.presentation.noDescription}
                        emptyText={!description}
                        loading={loading || description == null}
                        clickable={!description && isAtLeastAnimator(user)}
                        onClick={isAtLeastAnimator(user) && !description ? openEditPopup : undefined}
                    />
                }
                editButton={requireAnimator(
                    user,
                    <div className={styles.goalPresentationHeaderAction} onClick={openEditPopup}>
                        <IconAssets.Pen />
                    </div>,
                )}
                emptyText={!description}
            />

            {showEditPopup ? (
                <GoalPresentationPopup
                    title={lang.goal.collective.presentation.title}
                    cohortId={cohortId}
                    goalId={goalId}
                    close={() => setShowEditPopup(false)}
                    description={description}
                    submitBtnLabel={lang.goal.collective.presentation.save}
                    onUpdateDescription={(data) => setDescription(data)}
                />
            ) : null}
        </>
    )
}

const useGoalPresentationPopupStyles = createUseStyles({
    goalPresentationPopup: {
        width: '100%',
        minWidth: '450px',
        backgroundColor: 'var(--gd-main-popup-background-color)',
        padding: '30px 25px',
        borderRadius: 'var(--gd-border-small-radius)',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
    },
    submitArea: {
        display: 'flex',
        justifyContent: 'end',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        goalPresentationPopup: {
            minWidth: '340px',
            borderRadius: '0px',
            '& div': {
                maxHeight: '80vh',
            },
        },
    },
})

interface GoalPresentationPopupProps {
    cohortId?: string
    goalId: string
    title: string
    description?: string
    submitBtnLabel: string
    onUpdateDescription: (data: any) => void
    close: () => void
}

interface IGoalPresentationPopupForm {
    description: string
}

const GoalPresentationPopup = ({ close, description, title, cohortId, goalId, submitBtnLabel, onUpdateDescription }: GoalPresentationPopupProps) => {
    const styles = useGoalPresentationPopupStyles()
    const { register, handleSubmit } = useForm<IGoalPresentationPopupForm>({
        defaultValues: {
            description: description,
        },
    })

    const { doPut: doUpdateDescription } = usePut({
        endpoint: 'update-goal-cohort-description',
        params: { cohortId, goalId },
        onSuccess: (data, payload) => {
            onUpdateDescription(payload)
            close()
        },
    })

    const onSubmit: SubmitHandler<IGoalPresentationPopupForm> = (data: IGoalPresentationPopupForm) => doUpdateDescription(data?.description)

    return (
        <Popup onCloseRequest={close}>
            <form className={styles.goalPresentationPopup} onSubmit={handleSubmit(onSubmit)}>
                <Title title={title} />
                <TextAreaField label='' required name='description' register={register} />
                <div className={styles.submitArea}>
                    <GreetSubmitButton label={submitBtnLabel} />
                </div>
            </form>
        </Popup>
    )
}

export { GroupGoalPresentation }
