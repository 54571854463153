const styles = {
    MAX_WIDTH: '1920px',
    GLOBAL_MARGIN: '50px',
    HEADER_HEIGHT: '100px',
    ADMIN_HEADER_HEIGHT: '130px',
    HOME_MARGIN: '50px',
    GOAL_BUTTON_HEIGHT: '75px',
    GOAL_BUTTON_WIDTH: '300px',
    SHADOW_HEIGHT: '150px',
    POPUP_MARGIN_LEFT: '40px',
}

export default styles
