import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser'
import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useInstance, useStore } from '.'
import { Loader } from '../components'
import AccessDeniedPopup from '../components/AccessDeniedPopup'
import { TimeUnit, add, isBefore } from '../components/dateUtils'
import { isAdmin } from '../components/rolesUtils'
import { extractFirstNameFromMail } from '../components/utils'
import { Customer, Lang, Params, Role, User } from '../models'
import { Cohort } from '../models/Cohort'
import { RoleScopes, Scope } from '../models/Role'
import { WorkingDaySettings } from '../models/WorkingDaySettings'
import { Authentication } from '../pages'
import services from '../services'
import { buildBasicAxiosInstance, buildHeader } from '../services/api/axiosService'
import { fireTaostError, useFetch } from '../services/api/useFetch'
import storage from '../services/storage'
import settings from '../settings'
import { doLogin, doRequestRefreshApiToken, exchangeApiToken, resetPreviousAuthData } from './AuthService'

const requestLogin = {
    scopes: ['user.read'],
}

export interface UserContext {
    user: User | null
    lang: Lang
    switchRole: (...args: any[]) => Promise<void>
    switchLang: (...args: any[]) => Promise<void>
    switchPopUp: (...args: any[]) => Promise<void>
    refreshToken: (...args: any[]) => Promise<User>
    acceptRulesAgreements: (...args: any[]) => void
    impersonate: (...args: any[]) => Promise<void>
    refreshUser: () => Promise<void>
    updateWorkingDays: (workingDaySettings: WorkingDaySettings) => void
}

const context = createContext<UserContext>({
    user: null,
    lang: settings.lang[0],
    switchRole: () => {
        // no-op
    },
    switchLang: () => {
        // no-op
    },
    switchPopUp: () => {
        // no-op
    },
    refreshToken: () => {
        // no-op
    },
    acceptRulesAgreements: () => {
        // no-op
    },
    impersonate: () => {
        // no-op
    },
    refreshUser: () => {
        // no-op
    },
} as UserContext)

const buildLangContext = (langParam: string, customer: Customer) => {
    const currentLang = (settings.lang.find((lang) => lang.code === langParam) as Lang) ?? (settings.lang.find((lang) => lang.code === 'fr') as Lang)
    if (customer.customerLang != null) {
        const customerLanguage = (customer.customerLang as any)[langParam]
        if (customerLanguage != null) {
            return customerLanguage
        }
    }
    return currentLang
}

const useUser = () => useContext(context)

const UserProvider = ({ children }: UserProviderProps) => {
    const { customer } = useInstance()
    const { langParam } = useParams<Params>()
    const currentLang = buildLangContext(langParam, customer)
    const [lang, setLang] = useState<UserContext['lang']>(currentLang)
    const [user, setUser] = useState<User | null>(null)
    const [accessDenied, setAccessDenied] = useState<boolean>(false)
    const [authenticationPending, setAuthenticationPending] = useState<boolean | null>(null)
    const { dispatch } = useStore()
    const axiosInstance = buildBasicAxiosInstance()
    const publicClientApplication = new PublicClientApplication(settings.global.O365_CONF(window.location.origin, `/${lang.code}/${customer.name}`))
    const history = useHistory()

    const exchangeToken = async (res?: AuthenticationResult): Promise<User> => {
        return await exchangeApiToken(customer, publicClientApplication, res)
    }

    const getUserExtras = async (newUser: User): Promise<User> => {
        const { data: foundedUser } = await axiosInstance.get('/greet-desktop/get-logged-user', { headers: buildHeader(newUser) })
        const avatar = foundedUser.avatar || (await fetchUserAvatar(newUser))
        const userWithAvatar = {
            ...newUser,
            rulesAgreements: foundedUser.rulesAgreements,
            rulesAgreementsDateTime: foundedUser.rulesAgreementsDateTime,
            lastLoginDateTime: foundedUser.lastLoginDateTime,
            hasAccessToGreetDesktop: foundedUser.hasAccessToGreetDesktop,
            isFirstMonthlyLogin: foundedUser.isFirstMonthlyLogin,
            isManager: foundedUser?.isManager,
            avatar: avatar ? `data:image/jpeg;base64,${avatar}` : null,
            inDemoMode: foundedUser.inDemoMode,
        }

        if (newUser.isFirstMonthlyLogin) {
            services.storage.appPreferences.store({
                ...services.storage.appPreferences.get(),
                showWelcomePopup: true,
            })
            switchPopUp(true)
        }

        if (isAdmin(userWithAvatar)) {
            const { data: cohortIds } = await axiosInstance.get('/cohort/names/all', { params: { userId: userWithAvatar?.userId }, headers: buildHeader(newUser) })
            userWithAvatar.cohortIds = cohortIds
        }
        services.storage.user.store(userWithAvatar)

        return userWithAvatar
    }

    const fetchUserAvatar = async (user: User): Promise<any> => {
        try {
            if (customer.useGoogleAuth) {
                const gUser = storage.gUser.get()
                if (gUser?.picture == null) {
                    return null
                }
                const { data } = await axios.get(gUser?.picture, {
                    headers: {
                        Authorization: `Bearer ${gUser.jwt ?? ''}`,
                    },
                    responseType: 'arraybuffer',
                })
                const rawData = String.fromCharCode(...new Uint8Array(data))
                return btoa(rawData)
            } else {
                const { data } = await axios.get('https://graph.microsoft.com/v1.0/me/photos/360x360/$value', {
                    headers: {
                        Authorization: `Bearer ${user.msGraphToken ?? ''}`,
                    },
                    responseType: 'arraybuffer',
                })
                const rawData = String.fromCharCode(...new Uint8Array(data))
                return btoa(rawData)
            }
        } catch (e) {
            return null
        }
    }

    const requestRefreshApiToken = async (user: User, scopes: Scope[]): Promise<User> => {
        return await doRequestRefreshApiToken(customer, user, scopes)
    }

    const checkAndRefreshMSGraphToken = async (user: User): Promise<User> => {
        if (new Date(user.tokensExpirationDate) < new Date()) {
            const account = publicClientApplication.getAllAccounts()[0]
            try {
                // Refresh token silently if possible (valid refresh token)
                const res = await publicClientApplication.acquireTokenSilent({
                    scopes: requestLogin.scopes,
                    account: account,
                })
                user.msGraphToken = res.accessToken
                user.tokensExpirationDate = add(new Date(), 1, TimeUnit.HOURS)
                user.msGraphRefreshTokenExpirationDate = add(new Date(), 24, TimeUnit.HOURS)
            } catch (err) {
                // If token can't be refreshed silently (refresh token expires every 24H) we request an authorization code from MS
                doLogin(customer, lang)
            }
        }
        return user
    }

    const refreshToken = async (user: User): Promise<User> => {
        try {
            const scopes = user.scopes ?? RoleScopes[Role.SUPER_ADMIN]
            user = await checkAndRefreshMSGraphToken(user)
            user = await requestRefreshApiToken(user, scopes)
            services.storage.user.store(user)
            setUser(user)
        } catch (err) {
            // If there is a problem with the login redirection or with Lecko API token request the local storage user is deleted to display authentication page
            services.storage.user.clear()
        }
        return user
    }

    const switchRole = async (role: Role) => {
        if (user == null || ![Role.SUPER_ADMIN, Role.ADMIN].includes(user.realRole) || role === Role.SIMPLE || user.role === role) {
            return
        }
        const scopes = RoleScopes[role]
        const newUser = await requestRefreshApiToken(await checkAndRefreshMSGraphToken(user), scopes)
        newUser.role = role
        setUser({ ...newUser })
        dispatch({ type: 'nuke' })
        services.storage.user.store(newUser)
    }

    const buildLangPath = (lng: Lang) => {
        const split = location.pathname.split('/')
        let path = `/${lng.code}`
        for (let i = 2; i < split.length; i++) {
            path += `/${split[i]}`
        }
        return path + location.search
    }

    const switchLang = async (newLang: Lang) => {
        if (user == null || user.lang == newLang.code || !settings.lang.includes(newLang)) return
        await axiosInstance.put('/greet-desktop/update-user-lang', null, {
            params: {
                'lang': newLang.code,
            },
            headers: {
                'Authorization': `${user.leckoApiToken} ${user.state}`,
            },
        })
        user.lang = newLang.code
        services.storage.user.store(user)
        history.push(buildLangPath(newLang))
    }

    const switchPopUp = async (showPopUp: boolean) => {
        if (user == null) return
        await axiosInstance.put('/greet-desktop/update-user-show-popup', null, {
            params: {
                'showPopup': showPopUp,
            },
            headers: {
                'Authorization': `${user.leckoApiToken} ${user.state}`,
            },
        })
        user.showPopup = showPopUp
        services.storage.user.store(user)
    }

    const checkLang = () => {
        if (user == null) return
        if (user.lang == null) {
            switchLang(lang)
        } else if (user.lang !== lang.code) {
            history.replace(`/${user.lang}/${customer.name}`)
        }
    }

    const handleRedirectLogin = async () => {
        let msResponse
        if (customer.useGoogleAuth) {
            const gUser = services.storage.gUser.get()
            if (gUser == null) {
                services.storage.user.clear()
                return
            } else {
                const gExpiration = new Date(0)
                gExpiration.setUTCSeconds(gUser.exp)
                const user = services.storage.user.get()
                if (new Date() < gExpiration && user != null) {
                    return
                }
            }
        } else {
            services.storage.gUser.clear()
            msResponse = await publicClientApplication.handleRedirectPromise()
            if (msResponse == null) {
                return
            }
        }

        setAuthenticationPending(true)
        const newUser = await exchangeToken(msResponse)
        const newUserWithExtras = await getUserExtras(newUser)
        setUser(newUserWithExtras)
        setAccessDenied(!newUserWithExtras.hasAccessToGreetDesktop)
        if (!newUserWithExtras.hasAccessToGreetDesktop) {
            services.storage.user.clear()
        }
        setAuthenticationPending(false)
    }

    const doAuthentication = async (): Promise<void> => {
        // 1. We check if user isn't logged in
        // 2. We check if it's a login redirection and handle it if it's the case
        // 3. if the user isn't logged in and it's not a login redirection but there is a user stored in local storage we refresh its tokens and log him in
        // Otherwise the authentication page will be displayed
        resetPreviousAuthData(customer)
        try {
            if (user === null) {
                await handleRedirectLogin()
                const userStored = services.storage.user.get()
                if (userStored !== null) {
                    // Check if MS Graph refresh token is expired, if it is lanch login redirection instead of trying to refresh ms graph token
                    // Otherwise check if MS Graph or Lecko api token need to be refreshed
                    if (new Date(userStored.msGraphRefreshTokenExpirationDate) < new Date()) {
                        doLogin(customer, lang)
                    } else {
                        if (new Date(userStored.apiTokenExpirationDate) < new Date() || new Date(userStored.tokensExpirationDate) < new Date()) {
                            setAuthenticationPending(true)
                            await refreshToken(userStored)
                        } else {
                            setUser(userStored)
                        }
                        // user is logged in
                        setAuthenticationPending(false)
                        Promise.resolve()
                    }
                } else {
                    // user is not logged in, not in local storage and there is no authorization code -> display authentication page
                    setAuthenticationPending(false)
                }
            } else {
                // user is logged in
                setAuthenticationPending(false)
                Promise.resolve()
            }
        } catch (error) {
            console.error(error)
            fireTaostError("Une erreur s'est produite")
        }
    }

    const acceptRulesAgreements = () => {
        if (user === null) {
            return
        }
        const newUser = { ...user, rulesAgreements: true }
        setUser(newUser)
        services.storage.user.store(newUser)
    }

    const impersonate = async (upn: string, userId: string) => {
        if (user == null) {
            return
        }

        const newUser = { ...user }
        const { data: greetResponse } = await axiosInstance.post('/user/impersonate-token', null, {
            params: {
                'upn': upn,
                'userId': userId,
                'state': newUser.state,
                'scopes': RoleScopes[Role.IMPERSONATOR].join(','),
            },
            headers: {
                'Authorization': `${newUser.leckoApiToken} ${newUser.state}`,
            },
        })
        newUser.leckoApiToken = greetResponse.token
        newUser.apiTokenExpirationDate = greetResponse.expirationDate
        const { data: foundedUser } = await axiosInstance.get('/greet-desktop/get-logged-user', { headers: buildHeader(newUser) })
        const { data: cohortIds } = await axiosInstance.get('/cohort/names/all', { params: { userId: foundedUser?.userId }, headers: buildHeader(newUser) })
        const newUserWithExtras: User = {
            ...foundedUser,
            cohortIds: cohortIds,
            firstName: extractFirstNameFromMail(upn),
            msGraphToken: newUser.msGraphToken,
            tokensExpirationDate: newUser.tokensExpirationDate,
            state: newUser.state,
            leckoApiToken: greetResponse.token,
            apiTokenExpirationDate: greetResponse.expirationDate,
            isImpersonate: true,
            showPopup: true,
            impersonateDebugInfo: greetResponse.debugInfo,
            lastLoginDateTime: foundedUser.lastLoginDateTime,
            avatar: `data:image/jpeg;base64,${foundedUser.avatar || (await fetchUserAvatar(newUser))}`,
        }
        dispatch({ type: 'impersonate', value: null })
        services.storage.user.store(newUserWithExtras)
        setUser({ ...newUserWithExtras })
    }

    const refreshUser = async () => {
        if (user == null) {
            return
        }

        const userWithExtras = await getUserExtras(user)
        const newUser = { ...user, ...userWithExtras }
        // trigger queries reload, payload are watched by useFetch.
        dispatch({ type: 'clearCacheData', value: null })
        services.storage.user.store(newUser)
        setUser(newUser)
    }

    const updateWorkingDays = (workingDaySettings: WorkingDaySettings) => {
        if (user != null && (workingDaySettings?.from != user?.workingDays?.from || workingDaySettings?.to != user?.workingDays?.to)) {
            user.workingDays = workingDaySettings
            services.storage.user.store(user)
        }
    }

    useEffect(() => {
        setAccessDenied(!user?.hasAccessToGreetDesktop)
    }, [user?.hasAccessToGreetDesktop])

    useEffect(() => {
        doAuthentication()
    }, [])

    useEffect(() => {
        checkLang()
    }, [user])

    useEffect(() => {
        const langSettings = buildLangContext(langParam, customer)
        if (langParam !== lang.code) {
            setLang(langSettings)
            // try to keep same place when lang is changed
            try {
                history.push(buildLangPath(langSettings))
            } catch (e) {
                history.push(`/${langParam}/${customer.name}`)
            }
        }
    }, [langParam])

    const render = () => {
        if (user == null && services.storage.user.get() == null && authenticationPending != null && !authenticationPending) {
            return <Authentication />
        }

        return authenticationPending != null ? (
            <PayloadLoader user={user} authenticationPending={authenticationPending}>
                {accessDenied ? (
                    <>
                        <Authentication diasableActions />
                        <AccessDeniedPopup />
                    </>
                ) : (
                    children
                )}
            </PayloadLoader>
        ) : null
    }

    return (
        <context.Provider value={{ user, lang, switchRole, switchLang, switchPopUp, refreshToken, acceptRulesAgreements, impersonate, refreshUser, updateWorkingDays }}>
            {render()}
        </context.Provider>
    )
}

export interface ContentProps {
    authenticationPending: boolean
    user: User | null
    children: any
}

const buildCohortIdsParams = (userCohorts?: Cohort[]) => {
    const result = userCohorts?.map(({ cohortName }: any) => cohortName)?.join(',')
    if (result == null || result === '') {
        return null
    }
    return result
}

const PayloadLoader = ({ authenticationPending, user, children }: PayloadLoader) => {
    const { state, dispatch } = useStore()
    const { customer } = useInstance()
    const [userCohorts, setUserChorts] = useState<any>(null)

    const { loading: coachingEventsLoading } = useFetch({
        endpoint: 'coaching-events',
        watchedParams: { user: user },
        params: { platformName: customer.platformName },
        onSuccess: (data) => {
            dispatch({ type: 'coaching-events-fetched', value: data })
        },
    })

    const { loading: coachingMeetingLoading } = useFetch({
        endpoint: 'coaching-meeting',
        params: { clientName: customer.platformName },
        watchedParams: { user: user },
        onSuccess: (data) => {
            dispatch({ type: 'coaching-meeting-fetched', value: data })
        },
    })

    const { loading: collectiveCohortGoalsCheckingLoading } = useFetch({
        endpoint: 'list-cohorts-goals',
        params: {},
        watchedParams: { user: user },
        onSuccess: (data) => {
            const userCohorts = data.map(
                (val: { name: any; displayName: any }) =>
                    ({
                        cohortId: val.name,
                        cohortName: val.name,
                        cohortDisplayName: val.displayName,
                    } as Cohort),
            )
            setUserChorts(userCohorts)
            dispatch({ type: 'user-cohorts-fetched', value: userCohorts })
        },
    })

    const { loading: userPayloadLoading } = useFetch({
        endpoint: 'payload-user',
        params: { userId: user?.userId },
        watchedParams: { role: user?.role, user: user },
        retrieveStoredData: (): any => {
            return getKeyFromLocalStoragePayload(user, 'userPayload')
        },
        onSuccess: (data) => {
            dispatch({ type: 'user-payload-fetched', value: data })
        },
    })

    const { loading: cohortPayloadLoading } = useFetch({
        endpoint: 'payload-cohorts',
        params: { cohortIds: buildCohortIdsParams(state?.userCohorts) },
        watchedParams: { role: user?.role, user: user, userCohorts },
        retrieveStoredData: (): any => {
            return getKeyFromLocalStoragePayload(user, 'cohortsPayload')
        },
        onSuccess: (cohortsPayload) => {
            const loadCohortNames = cohortsPayload.map((item: { cohortId: any }) => item.cohortId)
            const loadedUserCohorts = userCohorts.filter((uc: { cohortName: any }) => loadCohortNames.includes(uc.cohortName))
            dispatch({ type: 'cohorts-payload-fetched', value: cohortsPayload })
            dispatch({ type: 'user-cohorts-fetched', value: loadedUserCohorts })
        },
    })

    if (authenticationPending || collectiveCohortGoalsCheckingLoading || userPayloadLoading || cohortPayloadLoading || coachingEventsLoading || coachingMeetingLoading) {
        return <Loader isAuthenticationPending={authenticationPending} />
    } else {
        return children
    }
}

const getKeyFromLocalStoragePayload = (user: User | null, key: string): any => {
    if (user == null) {
        return null
    }
    const userId = user.userId
    const savedStore = services.storage.payload.get(userId)
    const lastCreationDate = savedStore?.creationDate != null ? new Date(savedStore?.creationDate) : null

    if (lastCreationDate == null || lastCreationDate?.getDate() == null || isNaN(lastCreationDate.getDay())) {
        return null
    }
    const now = add(new Date(), 0, TimeUnit.HOURS) // We add 0 hours to have the save format as expiration date
    const expirationDate = add(lastCreationDate, 1, TimeUnit.HOURS)
    const isLocalStorageNullOrExpired = savedStore == null || lastCreationDate == null || isBefore(expirationDate, now, true)
    if (!isLocalStorageNullOrExpired) {
        return (savedStore as any)[key]
    }
    return null
}

interface PayloadLoader {
    authenticationPending: boolean
    user: User | null
    children: any
}

export interface UserProviderProps {
    children: React.ReactNode
}

export { UserProvider, useUser }

