import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useStore, useUser } from '../../contexts'
import { BreathingTimeMeetingIndicators, EBreathingTimeMeetingPeriod, EBreathingTimeMeetingUnit } from '../../models/BreathingTimeMeeting'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import { convertToRem } from '../utils'
import IconAssets from '../../assets/icon/IconAssets'
import InfoBulle from '../share/Infobulle'

const useStyles = createUseStyles({
    meetingBreathingTime: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: '2px',
        backgroundColor: 'var(--gd-main-app-background-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        padding: '20px',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        alignContent: 'center',
        width: '100%',
        gap: '8px',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        lineHeight: '20px',
    },
    infobullIcon: {
        width: '30px',
        padding: '3px 0',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '309px',
        padding: '5px',
        marginTop: '14px',
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: '10px',
        padding: '10px 20px',
        fontSize: '14px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    modeActive: {
        padding: '3px',
        color: 'var(--gd-secondary-color)',
        borderBottom: '3px var(--gd-secondary-color) solid',
        outline: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        fontWeight: 'bold',
        fontSize: '14px',
    },
    modeInactive: {
        padding: '3px',
        cursor: 'pointer',
        outline: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        fontWeight: 'bold',
        fontSize: '14px',
    },
    barChartContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        padding: '10px 0 ',
    },
    barChartContent: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(85% - 1px)',
        width: '100%',
        gap: '10%',
        padding: '0 15px 0 15px',
    },
    barChartLinesContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
    },
    barChart: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    barChartLine: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '0 5% 0 5%',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '&:hover $tooltip': {
            visibility: 'visible',
        },
    },
    tooltipContainer: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        height: '5px',
    },
    tooltip: {
        position: 'absolute',
        visibility: 'hidden',
        zIndex: 10,
        transform: 'translateY(-100%)',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    tooltipRectangle: {
        padding: `${convertToRem(4)} ${convertToRem(7)}`,
        maxWidth: convertToRem(145),
        maxHeight: convertToRem(58),
        minWidth: convertToRem(104),
        minHeight: convertToRem(36),
        backgroundColor: 'var(--gd-background-dark-color)',
        borderRadius: convertToRem(5),
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'normal',
        color: 'var(--gd-text-dark-color)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tooltipTriangle: { position: 'relative', top: convertToRem(-2) },
    barWithoutBreathingTime: {
        backgroundColor: 'var(--gd-secondary-color)',
        width: '100%',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        transition: 'height 300ms ease',
    },
    barWithBreathingTime: {
        backgroundColor: 'var(--gd-primary-color)',
        width: '100%',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        transition: 'height 300ms ease',
    },
    barChartSeparator: {
        height: '1px',
        width: '100%',
        backgroundColor: 'var(--gd-background-dark-color)',
    },
    barChartLegend: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(15% - 1px)',
        width: '100%',
        '& div': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    legendContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '15px',
    },
    legendContent: {
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        fontSize: '14px',
        alignItems: 'center',
    },
    circle: {
        borderRadius: '50%',
        paddingLeft: '10px',
        height: '10px',
    },
    descriptionBulbContainer: {
        display: 'flex',
        width: '20%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        '& div': {
            display: 'flex',
            height: '60%',
            width: '100%',
            borderRadius: '5px',
            backgroundColor: 'var(--gd-main-app-background-color)',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
        },
        '& svg': {
            height: '100%',
        },
    },
    [MediaQueryBreakpoints.DESKTOP]: {
        container: {
            maxWidth: convertToRem(360),
        },
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        container: {
            maxWidth: 'unset',
        },
    },
})

const defaultIndicors: Record<EBreathingTimeMeetingUnit, BreathingTimeMeetingIndicators> = {
    [EBreathingTimeMeetingUnit.THIRTY_MINUTES]: {
        meetingWithBreathingTime: 0,
        meetingWithoutBreathingTime: 0,
    },
    [EBreathingTimeMeetingUnit.SIXTY_MINUTES]: {
        meetingWithBreathingTime: 0,
        meetingWithoutBreathingTime: 0,
    },
    [EBreathingTimeMeetingUnit.NINETY_MINUTES]: {
        meetingWithBreathingTime: 0,
        meetingWithoutBreathingTime: 0,
    },
    [EBreathingTimeMeetingUnit.TWO_HOURS]: {
        meetingWithBreathingTime: 0,
        meetingWithoutBreathingTime: 0,
    },
    [EBreathingTimeMeetingUnit.MORE_THAN_TWO_HOURS]: {
        meetingWithBreathingTime: 0,
        meetingWithoutBreathingTime: 0,
    },
}

export { defaultIndicors as BreathingTimeMeetingDefaultIndicators }

export default function MeetingBreathingTime() {
    const {
        state: { userPayload },
    } = useStore()
    const { lang } = useUser()
    const styles = useStyles()

    const [mode, setMode] = useState<EBreathingTimeMeetingPeriod>(EBreathingTimeMeetingPeriod.ONE_WEEK)

    const breathingTimeMeetings = userPayload.breathingTimeMeetings ?? []

    const oneWeekBreathingTimeMeetingsIndictors =
        breathingTimeMeetings.find((item) => item.breathingTimeMeetingPeriod === EBreathingTimeMeetingPeriod.ONE_WEEK)?.breathingTimeMeetingIndicatorsByUnits ?? defaultIndicors
    const oneMonthBreathingTimeMeetingsIndictors =
        breathingTimeMeetings.find((item) => item.breathingTimeMeetingPeriod === EBreathingTimeMeetingPeriod.ONE_MONTH)?.breathingTimeMeetingIndicatorsByUnits ?? defaultIndicors
    const oneQuarterBreathingTimeMeetingsIndictors =
        breathingTimeMeetings.find((item) => item.breathingTimeMeetingPeriod === EBreathingTimeMeetingPeriod.ONE_QUARTER)?.breathingTimeMeetingIndicatorsByUnits ?? defaultIndicors
    const oneYearBreathingTimeMeetingsIndictors =
        breathingTimeMeetings.find((item) => item.breathingTimeMeetingPeriod === EBreathingTimeMeetingPeriod.ONE_YEAR)?.breathingTimeMeetingIndicatorsByUnits ?? defaultIndicors

    const getIndicators = (): Record<EBreathingTimeMeetingUnit, BreathingTimeMeetingIndicators> => {
        switch (mode) {
            case EBreathingTimeMeetingPeriod.ONE_WEEK:
                return oneWeekBreathingTimeMeetingsIndictors
            case EBreathingTimeMeetingPeriod.ONE_MONTH:
                return oneMonthBreathingTimeMeetingsIndictors
            case EBreathingTimeMeetingPeriod.ONE_QUARTER:
                return oneQuarterBreathingTimeMeetingsIndictors
            case EBreathingTimeMeetingPeriod.ONE_YEAR:
                return oneYearBreathingTimeMeetingsIndictors
        }
    }

    const getWithBreathingTimeHeight = (breathingTimeMeetingIndicators: BreathingTimeMeetingIndicators) => {
        const total = breathingTimeMeetingIndicators?.meetingWithBreathingTime + breathingTimeMeetingIndicators?.meetingWithoutBreathingTime
        const res = total === 0 ? 0 : (breathingTimeMeetingIndicators?.meetingWithBreathingTime * 100) / total
        return isNaN(res) ? 0 : res
    }

    const getWithoutBreathingTimeHeight = (breathingTimeMeetingIndicators: BreathingTimeMeetingIndicators) => {
        const total = breathingTimeMeetingIndicators?.meetingWithBreathingTime + breathingTimeMeetingIndicators?.meetingWithoutBreathingTime
        const res = total === 0 ? 0 : (breathingTimeMeetingIndicators?.meetingWithoutBreathingTime * 100) / total
        return isNaN(res) ? 0 : res
    }

    const breathingUnites = [
        {
            type: EBreathingTimeMeetingUnit.THIRTY_MINUTES,
            unit: "30'",
            withoutBreathingTooltipDuration: "30'",
            withBreathingTooltipDuration: `${lang.goal.qvt.meetingBreathingTime.lessThan} 30'`,
        },

        {
            type: EBreathingTimeMeetingUnit.SIXTY_MINUTES,
            unit: "60'",
            withoutBreathingTooltipDuration: "60'",
            withBreathingTooltipDuration: `31' ${lang.goal.qvt.meetingBreathingTime.to} 59'`,
        },
        {
            type: EBreathingTimeMeetingUnit.NINETY_MINUTES,
            unit: "90'",
            withoutBreathingTooltipDuration: "90'",
            withBreathingTooltipDuration: `61' ${lang.goal.qvt.meetingBreathingTime.to} 89'`,
        },
        {
            type: EBreathingTimeMeetingUnit.TWO_HOURS,
            unit: "120'",
            withoutBreathingTooltipDuration: "120'",
            withBreathingTooltipDuration: `91' ${lang.goal.qvt.meetingBreathingTime.to} 119'`,
        },
        {
            type: EBreathingTimeMeetingUnit.MORE_THAN_TWO_HOURS,
            unit: "120'",
            unitPrefix: '>',
            hideWithBreathingColon: true,
            withoutBreathingTooltipDuration: `${lang.goal.qvt.meetingBreathingTime.moreThan} 120'`,
            withBreathingTooltipDuration: `${lang.goal.qvt.meetingBreathingTime.moreThan} 120'`,
        },
    ]

    return (
        <div className={styles.meetingBreathingTime}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{lang.goal.qvt.meetingBreathingTime.title}</div>
                <InfoBulle translateX='-50%' component={null}>
                    <div className={styles.infobullIcon}>
                        <IconAssets.Information />
                    </div>
                </InfoBulle>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.buttonContainer}>
                    <input
                        type='button'
                        className={mode === EBreathingTimeMeetingPeriod.ONE_WEEK ? styles.modeActive : styles.modeInactive}
                        value={lang.goal.qvt.meetingBreathingTime.buttonWeek}
                        onClick={() => setMode(EBreathingTimeMeetingPeriod.ONE_WEEK)}
                    />
                    <input
                        type='button'
                        className={mode === EBreathingTimeMeetingPeriod.ONE_MONTH ? styles.modeActive : styles.modeInactive}
                        value={lang.goal.qvt.meetingBreathingTime.buttonMonth}
                        onClick={() => setMode(EBreathingTimeMeetingPeriod.ONE_MONTH)}
                    />
                    <input
                        type='button'
                        className={mode === EBreathingTimeMeetingPeriod.ONE_QUARTER ? styles.modeActive : styles.modeInactive}
                        value={lang.goal.qvt.meetingBreathingTime.buttonQuarter}
                        onClick={() => setMode(EBreathingTimeMeetingPeriod.ONE_QUARTER)}
                    />
                    <input
                        type='button'
                        className={mode === EBreathingTimeMeetingPeriod.ONE_YEAR ? styles.modeActive : styles.modeInactive}
                        value={lang.goal.qvt.meetingBreathingTime.buttonYear}
                        onClick={() => setMode(EBreathingTimeMeetingPeriod.ONE_YEAR)}
                    />
                </div>
                <div className={styles.barChartContainer}>
                    <div className={styles.barChartContent}>
                        {breathingUnites.map(({ type, withoutBreathingTooltipDuration, withBreathingTooltipDuration, hideWithBreathingColon }) => {
                            const breathingIndicator = getIndicators()[type]
                            const withoutBreathing = getWithoutBreathingTimeHeight(breathingIndicator)
                            const withtBreathing = hideWithBreathingColon ? 0 : getWithBreathingTimeHeight(breathingIndicator)
                            const withoutBreathingToolipMessage = lang.goal.qvt.meetingBreathingTime.tooltipMessage(
                                breathingIndicator.meetingWithoutBreathingTime,
                                withoutBreathingTooltipDuration,
                            )
                            const withBreathingToolipMessage = lang.goal.qvt.meetingBreathingTime.tooltipMessage(
                                breathingIndicator.meetingWithBreathingTime,
                                withBreathingTooltipDuration,
                            )
                            return (
                                <div className={styles.barChartLinesContent} key={type}>
                                    <div className={styles.barChart} style={{ width: hideWithBreathingColon ? '50%' : '100%' }}>
                                        {hideWithBreathingColon ? null : (
                                            <div className={styles.barChartLine}>
                                                {withtBreathing > 0 ? (
                                                    <div className={styles.tooltipContainer}>
                                                        <div className={styles.tooltip}>
                                                            <div className={styles.tooltipRectangle}>{withBreathingToolipMessage} </div>
                                                            <TootipButtomTriagle className={styles.tooltipTriangle} />
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <div className={styles.barWithBreathingTime} style={{ height: `${withtBreathing}%` }}></div>
                                            </div>
                                        )}
                                        <div className={styles.barChartLine}>
                                            {withoutBreathing > 0 ? (
                                                <div className={styles.tooltipContainer}>
                                                    <div className={styles.tooltip}>
                                                        <div className={styles.tooltipRectangle}>{withoutBreathingToolipMessage}</div>
                                                        <TootipButtomTriagle className={styles.tooltipTriangle} />
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className={styles.barWithoutBreathingTime} style={{ height: `${withoutBreathing}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={styles.barChartSeparator}></div>
                    <div className={styles.barChartLegend}>
                        {breathingUnites.map(({ type, unit, unitPrefix }) => (
                            <div key={type}>
                                {unitPrefix} {unit}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.legendContainer}>
                    <div className={styles.legendContent}>
                        <div className={styles.circle} style={{ backgroundColor: 'var(--gd-primary-color)' }}></div>
                        <div>{lang.goal.qvt.meetingBreathingTime.legend1}</div>
                    </div>
                    <div className={styles.legendContent}>
                        <div className={styles.circle} style={{ backgroundColor: 'var(--gd-secondary-color)' }}></div>
                        <div>{lang.goal.qvt.meetingBreathingTime.legend2}</div>
                    </div>
                </div>
            </div>

            <div className={styles.descriptionContainer}>{lang.goal.qvt.meetingBreathingTime.description}</div>

            {/* <QVTBulbInformation textContent={lang.goal.qvt.meetingBreathingTime.description} withIcon={false} /> */}
        </div>
    )
}

const TootipButtomTriagle = ({ className }: any) => {
    return (
        <svg className={className} width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M10 12L0.473721 0.75L19.5263 0.749998L10 12Z' fill='var(--gd-background-dark-color)' />
        </svg>
    )
}
