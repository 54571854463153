import { createContext, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Customer, Params } from '../models'
import settings from '../settings'
import WaitingForPilote from '../pages/WaintingForPilote'

type InstanceContext = {
    customer: Customer
}

interface ProviderProps {
    children: React.ReactNode
}

const context = createContext<InstanceContext>({
    customer: settings.customer[0]
})

const useInstance = () => useContext(context)

const InstanceProvider = ({ children }: ProviderProps) => {
    const { customerParam, langParam } = useParams<Params>()
    const currentCustomer = buildInstanceContext(customerParam)
    const [customer, setCustomer] = useState<InstanceContext['customer']>(currentCustomer)
    
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const customerSettings = buildInstanceContext(customerParam)

        if (customerParam != customer.name) {
            setCustomer(customerSettings)
            // try to keep same place when lang is changed
            try {
                const splittedPathname = location.pathname.split('/')
                splittedPathname[1] = langParam
                splittedPathname[2] = customerSettings.name
                const newPath = splittedPathname.join('/') + location.search
                history.push(newPath)
            } catch(e) {
                history.push(`/${langParam}/${customerSettings.name}`)
            }
        }
    }, [customerParam])

    return <context.Provider value={{ customer }}>{customer.waitingForPilote ? <WaitingForPilote /> : children}</context.Provider>
}

const buildInstanceContext = (customerParam: string) => {
    return settings.customer.find((customer) => customer.name === customerParam) ?? settings.customer[0]
}

export { InstanceProvider, useInstance }
