import { ChartOptions } from 'chart.js'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { createUseStyles } from 'react-jss'
import SurveyAssets from '../../assets/icon/survey/SurveyAssets'
import { useInstance, useUser } from '../../contexts'
import { feelToScore } from '../../models/EQVTSurveyFeel'
import { NB_WEEK_TO_COMPUTE } from '../../pages/QVTManagerBoard'
import { buildWeeksYearNumber } from '../dateUtils'
import InfoBulle from '../share/Infobulle'
import { numberFormatter } from '../utils'

const useWorkloadEvolutionManagerGraphStyle = createUseStyles({
    workloadEvolutionManagerGraph: {
        width: '100%',
        display: 'flex',
        paddingRight: '50px',
        paddingLeft: '108px',
        gap: '5px',
    },
    workloadEvolutionManagerGraphAxeBar: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '38px auto',
    },
    workloadEvolutionManagerGraphSmileyBar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '13px',
        // '&> svg': {
        //     width: '30px',
        //     height: '30px',
        // },
    },
    workloadEvolutionManagerGraphLine: {
        width: '100%',
        height: '300px',
    },
    icon: {
        width: '30px',
        height: '30px',
        '&> svg': {
            width: '30px',
            height: '30px',
        },
    },
})

const average = (arr: any[]) => {
    const filteredArr = arr.filter((e) => e != null)
    return filteredArr.length === 0 ? undefined : filteredArr.reduce((curr, next) => curr + next, 0) / filteredArr.length
}

const buildGraphWeekData = (allUsersAnswers: any[], weekKey: any) => {
    const filteredAnswers = allUsersAnswers.filter((e) => e != null)
    return {
        x: weekKey.label,
        y: average(allUsersAnswers),
        weekKey: weekKey,
        nbAnswers: filteredAnswers.length ?? 0,
        min: Math.min(...filteredAnswers),
        max: Math.max(...filteredAnswers),
    }
}

const prepareData = (usersData: any[], weeks: any[]) => {
    const result = {
        resultQ1: [] as any[],
        resultQ2: [] as any[],
        resultQ3: [] as any[],
    }

    weeks.forEach((weekKey) => {
        const q1AllUser: any[] = []
        const q2AllUser: any[] = []
        const q3AllUser: any[] = []
        usersData.forEach((item) => {
            q1AllUser.push(feelToScore(item[weekKey.key]?.question1))
            q2AllUser.push(feelToScore(item[weekKey.key]?.question2))
            q3AllUser.push(feelToScore(item[weekKey.key]?.question3))
        })
        result.resultQ1.push(buildGraphWeekData(q1AllUser, weekKey))
        result.resultQ2.push(buildGraphWeekData(q2AllUser, weekKey))
        result.resultQ3.push(buildGraphWeekData(q3AllUser, weekKey))
    })

    return result
}

const WorkloadEvolutionManagerGraph = ({ data }: Readonly<any>) => {
    const style = useWorkloadEvolutionManagerGraphStyle()
    const { customer } = useInstance()
    const { lang } = useUser()
    const weeks = useMemo(() => buildWeeksYearNumber(NB_WEEK_TO_COMPUTE, lang), [lang, data])
    const customerTheme = customer.theme

    const graphData = useMemo(() => prepareData(data, weeks), [data, lang])
    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                display: true,
                grid: {
                    display: false,
                },
            },
            y: {
                display: true,
                min: 0,
                max: 6,
                ticks: {
                    display: true,
                    callback: function (val, index) {
                        return index > 0 && index < 6 ? val : ''
                    },
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
                title: {
                    display: true,
                    text: 'Datasets',
                    padding: {
                        top: 15,
                    },
                    font: {
                        size: 18,
                    },
                },
            },
            tooltip: {
                enabled: false,
                external: (context) => workloadTooltip(context),
                mode: 'index',
                intersect: false,
            },
            title: {
                display: true,
                text: 'Tooltip point style: ',
            },
        },
        spanGaps: false,
    }
    const dataSets = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
                align: 'start',
                labels: {
                    usePointStyle: true,
                },
            },
        },
        datasets: [
            {
                label: lang.qvtManagerBoard.graph.q1Label,
                data: graphData.resultQ1,
                fill: false,
                backgroundColor: customerTheme.primaryColor,
                borderColor: customerTheme.primaryColor,
                tension: 0.1,
                borderWidth: 4,
                pointRadius: 5,
                pointStyle: 'triangle',
            },
            {
                label: lang.qvtManagerBoard.graph.q2Label,
                data: graphData.resultQ2,
                fill: false,
                backgroundColor: customerTheme.primaryColor,
                borderColor: customerTheme.primaryColor,
                tension: 0.1,
                borderWidth: 4,
                pointRadius: 5,
                pointStyle: 'rect',
            },
            {
                label: lang.qvtManagerBoard.graph.q3Label,
                data: graphData.resultQ3,
                fill: false,
                backgroundColor: customerTheme.primaryColor,
                borderColor: customerTheme.primaryColor,
                tension: 0.1,
                borderWidth: 4,
                pointRadius: 5,
                pointStyle: 'circle',
            },
        ],
    }

    return (
        <div className={style.workloadEvolutionManagerGraph}>
            <div className={style.workloadEvolutionManagerGraphAxeBar}>
                <div className={style.workloadEvolutionManagerGraphSmileyBar}>
                    <InfoBulle text={lang.worloadEvaluation.answersInformations.happy} translateX='-25%'>
                        <div className={style.icon}>
                            <SurveyAssets.Happy />
                        </div>
                    </InfoBulle>
                    <InfoBulle text={lang.worloadEvaluation.answersInformations.smile} translateX='-25%'>
                        <div className={style.icon}>
                            <SurveyAssets.Smile />
                        </div>
                    </InfoBulle>
                    <InfoBulle text={lang.worloadEvaluation.answersInformations.medium} translateX='-25%'>
                        <div className={style.icon}>
                            <SurveyAssets.Medium />
                        </div>
                    </InfoBulle>
                    <InfoBulle text={lang.worloadEvaluation.answersInformations.disappointed} translateX='-25%'>
                        <div className={style.icon}>
                            <SurveyAssets.Disappointed />
                        </div>
                    </InfoBulle>
                    <InfoBulle text={lang.worloadEvaluation.answersInformations.cry} translateX='-25%'>
                        <div className={style.icon}>
                            <SurveyAssets.Cry />
                        </div>
                    </InfoBulle>
                </div>
            </div>
            <div className={style.workloadEvolutionManagerGraphLine}>
                <Line
                    // https://github.com/reactchartjs/react-chartjs-2/issues/90
                    key={[...Array(36)].map(() => (~~(Math.random() * 36)).toString(36)).join('')}
                    id='workload-evaluation-manager-chart'
                    options={options}
                    data={dataSets}
                    redraw
                    updateMode='resize'
                />
            </div>
        </div>
    )
}

export default WorkloadEvolutionManagerGraph

const workloadTooltip = (context: any) => {
    let tooltipEl = document.getElementById('chartjs-tooltip')
    if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = 'chartjs-tooltip'
        tooltipEl.innerHTML = '<div id="#workloadEvolutionManagerGraphTooltip"></div>'
        document.body.appendChild(tooltipEl)
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip
    if (tooltipModel.opacity == '0') {
        tooltipEl.style.opacity = '0'
        return
    }

    tooltipEl.classList.remove('above', 'below', 'no-transform')
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
    } else {
        tooltipEl.classList.add('no-transform')
    }

    if (tooltipModel.body) {
        const bodyLines = tooltipModel.body.map((b: any) => b.lines)
        const dataPoints = tooltipModel.dataPoints ?? []
        const weekLiteral = dataPoints[0]?.raw?.weekKey?.literal ?? ''
        const nbAnswers = dataPoints[0]?.raw?.nbAnswers ?? '-'
        const triangle = `<div style="width: 0px; height: 0px; border-style: solid; border-width: 0 5px 12px 5px;
         border-color: transparent transparent #1C1B1F transparent;transform: rotate(0deg);"></div>`
        const rect = '<div style="width: 10px; height: 10px; background-color: #1C1B1F"></div>'
        const circle = '<div style="width: 10px; height: 10px; background-color: #1C1B1F; border-collapse: separate; border-radius: 50%;" ></div>'
        const puces = [triangle, rect, circle]

        let innerHtml = `
        <div style="display: flex; flex-direction: column; gap: 2px; padding: 5px">
            <div style="font-weight: bold">${weekLiteral}</div>
            <div>${nbAnswers} Reponses</div>
            <div style="display: flex; flex-direction: row; gap: 8px; justify-content: center; align-items: center; text-align: center">
                <div style="width: 150px; text-align: start;">  </div>
                <div style="width: 35px;"> Avg </div>
                <div style="width: 35px;"> Min </div>
                <div style="width: 35px;"> Max </div>
            </div>
        </div>`
        bodyLines.forEach((body: any, i: any) => {
            const dataPoint = dataPoints[i]
            innerHtml += `
            <div style="display: flex; flex-direction: row; gap: 8px; justify-content: center; align-items: center; text-align: center">
                <div style="width: 150px; text-align: start; display: flex; justify-content: flex-start; align-items: center; gap:3px;">
                    ${puces[i]} ${dataPoint.dataset.label}:
                </div>
                <div style="width: 35px;">${numberFormatter(dataPoint.raw.y)}</div>
                <div style="width: 35px;">${numberFormatter(dataPoint.raw.min)}</div>
                <div style="width: 35px;">${numberFormatter(dataPoint.raw.max)}</div>
            </div>
            `
        })
        const tableDivRoot = tooltipEl.querySelector('div')
        if (tableDivRoot != null) {
            tableDivRoot.innerHTML = innerHtml
        }
    }

    const position = context.chart.canvas.getBoundingClientRect()
    const bodyFont = tooltipModel.options.bodyFont

    // Configurer cette couleur
    tooltipEl.style.backgroundColor = '#F0F6FF'
    tooltipEl.style.color = '#1C1B1F'
    tooltipEl.style.borderRadius = '10px'
    tooltipEl.style.opacity = '1'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.left = position.left + tooltipModel.caretX + 'px'
    tooltipEl.style.top = position.top + tooltipModel.y + 'px'
    tooltipEl.style.font = bodyFont.string
    tooltipEl.style.padding = '5px'

    tooltipEl.style.pointerEvents = 'none'
}
