import { createUseStyles } from 'react-jss'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import LorealDesktopLogo from '../../assets/logos/LorealDesktop.svg'
import LorealMobileLogo from '../../assets/logos/LorealMobile.svg'

const useLorealLogoStyles = createUseStyles({
    lorealLogo: {
        display: 'block',
        minHeight: '47px',
        height: '100%',
        width: '100%',
        maxWidth: '450px'
    },
    lorealSmallLogo: {
        display: 'none',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        lorealLogo: {
            display: 'none',
        },
        lorealSmallLogo: {
            display: 'block',
        },
    },
})

export const LorealLogo = () => {
    const styles = useLorealLogoStyles()
    return (
        <>
            <img className={styles.lorealLogo} src={LorealDesktopLogo} />
            <img className={styles.lorealSmallLogo} src={LorealMobileLogo} />
        </>
    )
}
