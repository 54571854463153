import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import IconAssets from '../assets/icon/IconAssets'
import { MediaQueryBreakpoints } from '../components/MediaQueryBreakpoints'
import Title from '../components/Title'
import { useStore, useUser } from '../contexts'
import { EGoal } from '../models'

import IllustrationAssets from '../assets/illustration/IllustrationAssets'
import SoftLoader from '../components/SoftLoader'
import LeckoUserDto from '../models/dto/LeckoUserDto'
import { useFetch } from '../services/api/useFetch'

type CohortMembersProps = {
    cohortId: string | undefined
    goalId: EGoal
    showJoinMessage?: boolean
}

const MAX_NB_MEMBERS_TO_SHOW = 11

export default function CohortMembers({ goalId, cohortId, showJoinMessage = true }: CohortMembersProps) {
    const { dispatch } = useStore()
    const { loading, data } = useFetch({
        endpoint: 'cohort-users',
        params: { cohortId: cohortId, goalId: goalId },
        onSuccess: (data) => {
            // avatars need more space in localStorage, juste ignore them if they not displayed
            dispatch({
                type: 'add-cohort-users',
                valueId: cohortId,
                value: data.map((item: any, index: number) => (index <= MAX_NB_MEMBERS_TO_SHOW ? item : { ...item, avatar: null })),
            })
        },
    })

    return <CohortUsers users={data} loading={loading} showJoinMessage={showJoinMessage} />
}

type CohortUsersProps = {
    users: LeckoUserDto[]
    loading?: boolean
    showJoinMessage?: boolean
}

const useCohortUsersStyles = createUseStyles({
    cohortUsers: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingTop: '0.5rem',
        gap: '10px',
        height: '280px',
    },
    mobileCohortUsers: {
        display: 'none',
    },
    cohortMembersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
    },
    cohortMembersLoader: {
        height: '70%',
        width: '100%',
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    cohortMember: {
        display: 'flex',
        minWidth: '80px',
        justifyContent: 'center',
    },
    noCohortMember: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        fontSize: 'var(--gd-size-button-text)',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    noCohortMessage: {
        color: 'var(--gd-text-dark-color)',
    },
    joinCohort: {
        fontSize: 'var(--gd-size-button-text)',
    },
    descriptionContainer: {
        color: 'var(--gd-text-dark-color)',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    informationIcon: {
        width: '34px',
        height: '33px',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        cohortUsers: {
            display: 'none',
        },
        mobileCohortUsers: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingTop: '0.5rem',
            width: '100%',
            gap: '1rem',
        },
        cohortMembersContainer: {
            display: 'flex',
            flexWrap: 'nowrap',
            padding: '10px 0',
            maxWidth: '370px',
            overflowY: 'scroll',
        },
        descriptionContainer: {
            fontSize: 'var(--gd-size-body-text)',
            lineHeight: '14px',
            paddingTop: '5px',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        cohortUsers: {
            height: 'unset',
        },
    },
})

const CohortUsers = ({ users, loading, showJoinMessage }: CohortUsersProps) => {
    const { lang } = useUser()
    const styles = useCohortUsersStyles()
    return (
        <>
            <div className={styles.cohortUsers}>
                <Title title={lang.goal.cohort.title} />
                {loading ? (
                    <div className={styles.cohortMembersLoader}>
                        <SoftLoader />
                    </div>
                ) : users?.length ? (
                    <div className={styles.cohortMembersContainer}>
                        {users.map(({ firstName, lastName, avatar, badgeCount }, index) => {
                            if (index < MAX_NB_MEMBERS_TO_SHOW) {
                                return (
                                    <div key={index} className={styles.cohortMember}>
                                        <UserBadge firstName={firstName} lastName={lastName} avatar={avatar} victories={badgeCount} />
                                    </div>
                                )
                            } else if (index === MAX_NB_MEMBERS_TO_SHOW) {
                                return (
                                    <div key={index} className={styles.cohortMember}>
                                        <MoreBadges users={users?.splice(MAX_NB_MEMBERS_TO_SHOW)} />
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}
                    </div>
                ) : (
                    <div className={styles.noCohortMember}>
                        <IllustrationAssets.NoCohortMembers />
                        <div>{lang.goal.cohort.noUserCohortMessage}</div>
                    </div>
                )}
                {showJoinMessage ? (
                    <div className={styles.descriptionContainer}>
                        <div className={styles.informationIcon}><IconAssets.Information /></div>
                        <span className={styles.joinCohort}>{lang.goal.cohort.joinCohort}</span>
                    </div>
                ) : null}
            </div>
            <div className={styles.mobileCohortUsers}>
                <Title title={lang.goal.cohort.title} subTitleNode={<div className={styles.descriptionContainer}>{lang.goal.cohort.joinCohort}</div>} />
                <div className={styles.cohortMembersContainer}>
                    {users?.length ? (
                        users.map(({ firstName, lastName, avatar, badgeCount }, index) => {
                            return (
                                <div key={index} className={styles.cohortMember}>
                                    <UserBadge firstName={firstName} lastName={lastName} avatar={avatar} victories={badgeCount} />
                                </div>
                            )
                        })
                    ) : (
                        <span className={styles.noCohortMessage}>{lang.goal.cohort.noUserCohortMessage}</span>
                    )}
                </div>
            </div>
        </>
    )
}

type UserBadgeProps = {
    firstName: string
    lastName: string
    avatar?: string
    victories?: number
}

const useUserBadgeStyle = createUseStyles({
    imageBadge: {
        width: '55px',
        height: '55px',
        borderRadius: '50%',
        display: 'block',
    },
    victoryAureole: {
        display: 'grid',
        gridArea: 'layer',
        borderRadius: '50%',
    },
    victoryContainer: {
        display: 'grid',
        gridArea: 'layer',
        placeItems: 'center',
        width: '67px',
        height: '67px',
    },
    star: {
        display: 'grid',
        gridArea: 'layer',
        '--d': 'calc(var(--i) / 12)',
        '--r-offset': '-0.25turn',
        '--r-amount': '1turn',
        '--radius': '36px',
        '--r': 'calc((var(--r-amount) * var(--d)) + var(--r-offset))',
        'transform': 'rotate(var(--r)) translate(var(--radius)) rotate(calc(-1 * var(--r)))',
    },
})

function UserBadge({ firstName, lastName, avatar, victories = 0 }: UserBadgeProps) {
    const styles = useUserBadgeStyle()
    return (
        <div title={`${firstName ?? ''} ${lastName ?? ''}`} className={classnames(styles.victoryAureole)}>
            <div className={styles.victoryContainer}>
                <div className={classnames(victories ? styles.star : '')} style={{ margin: '6px' }}>
                    {avatar == null ? (
                        <div className={styles.imageBadge}>
                            <IllustrationAssets.User />
                        </div>
                    ) : (
                        <img className={styles.imageBadge} src={`data:image/jpeg;base64,${avatar}`} alt='userAvatar' />
                    )}
                </div>
            </div>
        </div>
    )
}

const useMoreBadgesStyle = createUseStyles({
    badge: {
        width: '55px',
        height: '55px',
        background: 'var(--gd-background-clear-color)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '5px',
    },
    reamingNumber: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        color: 'var(--gd-secondary-color)',
        paddingRight: '3px',
        paddingBottom: '3px',
    },
})

function MoreBadges({ users }: CohortUsersProps) {
    const styles = useMoreBadgesStyle()
    return (
        <div title={users?.map((user) => `${user?.firstName ?? ''} ${user?.lastName ?? ''}`)?.join(', ')} className={styles.badge}>
            <div className={styles.reamingNumber}>
                <span>+</span>
                <span>{users?.length ?? 0}</span>
            </div>
        </div>
    )
}
