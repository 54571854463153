import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser'
import { TimeUnit, add } from '../components/dateUtils'
import { capitalizeFirstLetter } from '../components/utils'
import { Lang, User } from '../models'
import Customer from '../models/Customer'
import Role, { RoleScopes, Scope } from '../models/Role'
import services from '../services'
import { buildBasicAxiosInstance } from '../services/api/axiosService'
import settings from '../settings'

const msRequestLogin = {
    scopes: ['user.read'],
}

const generateRandomState = () => [...Array(36)].map(() => (~~(Math.random() * 36)).toString(36)).join('')

const extractFirstNameFromMail = (email: string) => {
    const emailParts = email.split('@')
    if (emailParts.length == 0) {
        return null
    }

    const userEmail = emailParts[0].split('.')
    if (userEmail.length <= 1) {
        return null
    }

    return capitalizeFirstLetter(userEmail[0])
}

const axiosInstance = buildBasicAxiosInstance()

const isGoogleSilentAuth = (gUser: any) => {
    // No refresh Token available for Gsuite Auth
    return gUser?.exp != null
}

const buildEchangeApiTokenParams = (customer: Customer, response?: AuthenticationResult) => {
    const state = generateRandomState()
    const scopes = RoleScopes[Role.SUPER_ADMIN]
    if (customer.useGoogleAuth) {
        const gUser = services.storage.gUser.get()
        return {
            url: '/user/google-exchange-tokens',
            params: {
                jwt: gUser?.jwt ?? '',
                userId: gUser?.sub,
                state: state,
                scopes: scopes.join(','),
                app: 'GREET_DESKTOP',
                silentMode: isGoogleSilentAuth(gUser),
            },
            state: state,
            authenticatorToken: gUser?.jwt,
        }
    }
    return {
        url: '/user/exchange-tokens',
        params: {
            graphToken: response?.accessToken,
            userId: (response?.idTokenClaims as any)?.oid,
            state: state,
            scopes: scopes.join(','),
            app: 'GREET_DESKTOP',
        },
        state: state,
        authenticatorToken: response?.accessToken,
    }
}

const buildRefreshApiTokenParams = (customer: Customer, user: User, scopes: Scope[]) => {
    const state = generateRandomState()
    if (customer.useGoogleAuth) {
        return {
            url: '/user/google-exchange-tokens',
            params: {
                jwt: user.msGraphToken,
                userId: user.userId,
                state: state,
                scopes: scopes.join(','),
                silentMode: true, // to not count user connection
            },
            state: state,
        }
    }
    return {
        url: '/user/exchange-tokens',
        params: {
            graphToken: user.msGraphToken,
            userId: user.userId,
            state: state,
            scopes: scopes.join(','),
            silentMode: true, // to not count user connection
        },
        state: state,
    }
}

const buildCurrentUser = (
    customer: Customer,
    publicClientApplication: PublicClientApplication,
    exchangeApiTokenParams: any,
    apiResponse: any,
    msResponse?: AuthenticationResult,
): User => {
    if (customer.useGoogleAuth) {
        const gUser = services.storage.gUser.get()
        return {
            state: exchangeApiTokenParams.state,
            scopes: apiResponse.scopes,
            msGraphToken: gUser.jwt,
            upn: gUser.email,
            leckoApiToken: apiResponse.token,
            apiTokenExpirationDate: apiResponse.expirationDate,
            userId: gUser.sub,
            platformName: apiResponse.platformName,
            name: gUser.name,
            firstName: extractFirstNameFromMail(gUser.email),
            mail: gUser.email,
            role: apiResponse.role,
            realRole: apiResponse.role,
            avatar: null,
            rulesAgreements: false,
            hasAccessToGreetDesktop: false,
            isFirstMonthlyLogin: true,
            showPopup: true,
            tokensExpirationDate: add(new Date(), 1, TimeUnit.HOURS),
            msGraphRefreshTokenExpirationDate: add(new Date(), 24, TimeUnit.HOURS),
            cohortIds: [],
            isImpersonate: false,
            lang: apiResponse.lang,
            inDemoMode: apiResponse.inDemoMode,
        } as User
    }

    const accounts = publicClientApplication.getAllAccounts()
    const idTokenClaims = msResponse?.idTokenClaims as any
    const account = accounts[0] as any
    return {
        state: exchangeApiTokenParams.state,
        scopes: apiResponse.scopes,
        msGraphToken: msResponse?.accessToken,
        upn: idTokenClaims.preferred_username,
        leckoApiToken: apiResponse.token,
        apiTokenExpirationDate: apiResponse.expirationDate,
        userId: idTokenClaims.oid,
        platformName: apiResponse.platformName,
        name: account.name,
        firstName: extractFirstNameFromMail(idTokenClaims.preferred_username),
        mail: account.mail,
        role: apiResponse.role,
        realRole: apiResponse.role,
        avatar: null,
        rulesAgreements: false,
        hasAccessToGreetDesktop: false,
        isFirstMonthlyLogin: true,
        showPopup: true,
        tokensExpirationDate: add(new Date(), 1, TimeUnit.HOURS),
        msGraphRefreshTokenExpirationDate: add(new Date(), 24, TimeUnit.HOURS),
        cohortIds: [],
        isImpersonate: false,
        lang: apiResponse.lang,
        inDemoMode: apiResponse.inDemoMode,
    } as User
}

export const resetPreviousAuthData = (customer: Customer) => {
    if (customer.useGoogleAuth) {
        const gUser = services.storage.gUser.get()
        if (gUser != null) {
            const gExpiration = new Date(0)
            gExpiration.setUTCSeconds(gUser.exp)
            if (new Date() > gExpiration) {
                services.storage.gUser.clear()
                services.storage.user.clear()
            }
        } else {
            services.storage.user.clear()
        }
    } else if (services.storage.gUser.get() != null) {
        services.storage.user.clear()
        services.storage.gUser.clear()
    }
}

export const exchangeApiToken = async (customer: Customer, publicClientApplication: PublicClientApplication, msResponse?: AuthenticationResult) => {
    const exchangeApiTokenParams = buildEchangeApiTokenParams(customer, msResponse)
    const { data: apiResponse } = await axiosInstance.post(exchangeApiTokenParams.url, null, {
        params: exchangeApiTokenParams.params,
    })
    const newUser = buildCurrentUser(customer, publicClientApplication, exchangeApiTokenParams, apiResponse, msResponse)
    services.storage.user.store(newUser)
    return newUser
}

export const doRequestRefreshApiToken = async (customer: Customer, user: User, scopes: Scope[]): Promise<User> => {
    const refreshTokenParams = buildRefreshApiTokenParams(customer, user, scopes)
    const { data: greetResponse } = (await axiosInstance.post(refreshTokenParams.url, null, {
        params: refreshTokenParams.params,
    })) as any
    user.state = refreshTokenParams.state
    user.scopes = greetResponse.scopes
    user.role = greetResponse.role
    user.realRole = greetResponse.role
    user.lang = greetResponse.lang
    user.leckoApiToken = greetResponse.token
    user.apiTokenExpirationDate = greetResponse.expirationDate
    return user
}

export const doLogin = (customer: Customer, lang: Lang) => {
    if (customer.useGoogleAuth) {
        localStorage.clear()
        location.reload()
    } else {
        const publicClientApplication = new PublicClientApplication(settings.global.O365_CONF(window.location.origin, `/${lang.code}/${customer.name}`))
        publicClientApplication.loginRedirect(msRequestLogin)
    }
}

export const doLogout = async (customer: Customer, lang: Lang) => {
    if (customer.useGoogleAuth) {
        localStorage.clear()
        services.storage.appPreferences.clear()
        location.reload()
    } else {
        const publicClientApplication = new PublicClientApplication(settings.global.O365_CONF(window.location.origin, `/${lang.code}/${customer.name}`))
        localStorage.clear()
        services.storage.appPreferences.clear()
        location.reload()
        publicClientApplication.logoutRedirect()
    }

}
