import { createUseStyles } from 'react-jss'
import { fireTaostError, fireTaostSuccess } from '../../services/api/useFetch'
import { useUser } from '../../contexts'
import IconAssets from '../../assets/icon/IconAssets'

const useStyles = createUseStyles({
    container: {
        cursor: 'pointer',
    },
})

interface CopyProps {
    content: string
}

export default function Copy({ content }: CopyProps) {
    const { lang } = useUser()
    const styles = useStyles()

    const copyContentToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(content)
            fireTaostSuccess(lang.clipboardCopy.success)
        } catch (err) {
            fireTaostError(lang.clipboardCopy.error)
        }
    }

    return (
        <span className={styles.container} onClick={copyContentToClipboard}>
            <IconAssets.CopyIcon />
        </span>
    )
}
