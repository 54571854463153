import { ReactNode } from 'react'

import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'

const useStyles = () =>
    createUseStyles({
        lowerCard: {
            width: '100%',
            minHeight: '132px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '11px',
            paddingLeft: '13px',
            backgroundColor: 'var(--gd-background-clear-color)',
            borderRadius: '5px 5px 20px 20px',
        },
        infoContent: {
            fontSize: '14px',
            marginRight: '18px',
        },
        icon: {
            minWidth: 'max-content',
        },
    })()

interface QVTBulbInformationProps {
    classNames?: {
        container?: string
        info?: string
        icon?: string
    }
    textContent: ReactNode
    withIcon?: boolean
    icon?: JSX.Element
}
const QVTBulbInformation = ({ textContent, icon, classNames: customClassNames, withIcon = true }: QVTBulbInformationProps) => {
    const styles = useStyles()
    return (
        <div className={classNames(styles.lowerCard, customClassNames?.container)}>
            {withIcon ? icon ?? <IconAssets.NewLightBulb className={classNames(styles.icon, customClassNames?.icon)} /> : null}
            <p className={classNames(styles.infoContent, customClassNames?.info)}>{textContent}</p>
        </div>
    )
}

export default QVTBulbInformation
