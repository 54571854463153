import { createUseStyles } from 'react-jss'
import { useStore, useUser } from '../contexts'
import { usePut } from '../services/api/useFetch'
import Popup from './Popup'
import { GreetButton } from './share/GreetButton'

const useStyles = createUseStyles({
    welcomePopupWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--gd-main-popup-background-color)',
    },
    welcomePopup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        rowGap: '30px',
        backgroundColor: 'var(--gd-main-popup-background-color)',
        marginBottom: '35px',
    },
    title: {
        color: 'var(--gd-secondary-dark-color)',
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
        marginTop: '35px',
    },
    message: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        color: 'var(--gd-text-dark-color)',
        fontSize: 'var(--gd-size-body-text)',
        textAlign: 'center',
        rowGap: '25px',
    },
})

export default function WelcomePopup() {
    const { user, acceptRulesAgreements, lang } = useUser()
    const styles = useStyles()
    const { dispatch } = useStore()

    const { doPut: acceptRules, working: acceptingRules } = usePut({
        endpoint: 'accept-rules-agreements',
        enableTaostSuccess: false,
        params: { userId: user?.userId },
        onSuccess: (data) => {
            dispatch({ type: 'user-payload-fetched', value: data })
            acceptRulesAgreements()
        },
    })

    if (user == null || user.rulesAgreements) {
        return null
    }

    return (
        <Popup
            closable={false}
            onCloseRequest={() => {
                return
            }}
        >
            <div className={styles.welcomePopupWrapper}>
                <div className={styles.welcomePopup}>
                    <span className={styles.title}>{lang.welcomePopup.title}</span>
                    <div className={styles.message}>
                        {lang.welcomePopupMessage.map((message, index) => (
                            <span key={index}>{message}</span>
                        ))}
                    </div>

                    <GreetButton
                        label={lang.welcomePopup.confirmBtn}
                        disabled={acceptingRules}
                        loading={acceptingRules}
                        onClick={() => acceptRules(null)}
                    />
                </div>
            </div>
        </Popup>
    )
}
