import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// react router v5 doc
// https://v5.reactrouter.com/web/example/query-parameters

export default function useQueryParams() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}
