import { createUseStyles } from 'react-jss'
import { MediaQueryBreakpoints } from '../components/MediaQueryBreakpoints'
import ChallengeResults from '../components/animationChallenge/ChallengeResults'
import { useUser } from '../contexts'

const useStyles = createUseStyles({
    annimationChallenge: {
        maxWidth: '1142px',
        margin: '0 auto',
        paddingBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
    },
    mainTitle: {
        display: 'flex',
        width: '100%',
        padding: '2rem 0',
        gap: '1rem',
        fontSize: 'var(--gd-size-big-title)',
        fontWeight: 'bold',
        color: 'var(--gd-big-text-dark-color)',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        annimationChallenge: {
            padding: '0',
            paddingBottom: '20px',
        },
    },
    [MediaQueryBreakpoints.TABLETTE]: {
        annimationChallenge: {
            padding: '0',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        annimationChallenge: {
            padding: '0',
        },
    },
})

const AnimationChallenge = () => {
    const styles = useStyles()
    const { lang } = useUser()
    return (
        <div className={styles.annimationChallenge}>
            <div className={styles.mainTitle}>{lang.animationChallenge.title}</div>
            <ChallengeResults />
        </div>
    )
}

export default AnimationChallenge
