import classNames from 'classnames'
import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Footer } from './components'
import AdminHeader from './components/headers/AdminHeader'
import Header from './components/headers/Header'
import { UserProvider, useUser } from './contexts'
import { StoreProvider, useStore } from './contexts/PayloadStoreProvider'
import { Goal, Home } from './pages'

import 'react-toastify/dist/ReactToastify.css'

import { MediaQueryBreakpoints } from './components/MediaQueryBreakpoints'
import WelcomeBack from './components/WelcomeBack'
import WelcomePopup from './components/WelcomePopup'
import ChallengeEndPopup from './components/challenge/ChallengeEndPopup'
import AnnouncementsHeader from './components/headers/AnnouncementsHeader'
import { GreetFeaturingTarget } from './components/share/GreetFeaturingComponent'
import useImpersonation from './components/useImpersonation'
import WorkloadEvaluationPopup from './components/workloadEvaluation/WorkloadEvaluationPopup'
import { InstanceProvider, useInstance } from './contexts/InstanceProvider'
import CohortPayload from './models/CohortPayload'
import { CustomerTheme } from './models/Customer'
import AdminHome from './pages/AdminHome'
import AnimationChallenge from './pages/AnimationChallenge'
import LegalInformation from './pages/LegalInformations'
import { SseEvent, useSse } from './services/api/useSse'
import settings from './settings'

const DEFAULT_LANGUAGE = 'fr'
const DEFAULT_CUSTOMER = settings.customer[0].name

export default function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={'/:langParam/:customerParam'} component={AppRouter} />
                <Route
                    render={() => {
                        console.error('No main route')
                        return <Redirect to={`/${DEFAULT_LANGUAGE}/${DEFAULT_CUSTOMER}`} />
                    }}
                />
            </Switch>
        </BrowserRouter>
    )
}

const AppRouter = () => {
    return (
        <InstanceProvider>
            <StoreProvider>
                <UserProvider>
                    <ToastContainer autoClose={2000} />
                    <WebBody />
                </UserProvider>
            </StoreProvider>
        </InstanceProvider>
    )
}

export const useClientThemeStyles = (customerTheme: CustomerTheme) =>
    createUseStyles({
        clientTheme: {
            '--gd-primary-color': customerTheme.primaryColor,
            '--gd-primary-dark-color': customerTheme.primaryDarkColor,
            '--gd-secondary-color': customerTheme.secondaryColor,
            '--gd-secondary-dark-color': customerTheme.secondaryDarkColor,
            '--gd-tertiary-color': customerTheme.tertiaryColor,
            '--gd-border-small-radius': customerTheme.borderSmallRadius,
            '--gd-border-normal-radius': customerTheme.borderNormalRadius,
            '--gd-border-big-radius': customerTheme.borderBigRadius,
            '--gd-positive-color': customerTheme.positiveColor,
            '--gd-intermediary-color': customerTheme.intermediaryColor,
            '--gd-negative-color': customerTheme.negativeColor,
            '--gd-primary-button-color': customerTheme.primaryButtonColor,
            '--gd-secondary-button-color': customerTheme.secondaryButtonColor,
            '--gd-danger-button-color': customerTheme.dangerButtonColor,
            '--gd-main-app-background-color': customerTheme.mainAppBackgroundColor,
            '--gd-main-app-shadowed-content': customerTheme.mainAppShadowedContent,
            '--gd-main-active-tab-color': customerTheme.mainActiveTabColor,
            '--gd-main-active-tab-background-color': customerTheme.mainActiveTabBackgroundColor,
            '--gd-main-popup-background-color': customerTheme.mainPopupBackgroundColor,
            '--gd-blogs-primary-color': customerTheme.blogsPrimaryColor,
            '--gd-blogs-primary-dark-color': customerTheme.blogsPrimaryDarkColor,
            '--gd-blogs-secondary-color': customerTheme.blogsSecondaryColor,
            '--gd-background-clear-color': customerTheme.backgroundClearColor,
            '--gd-background-dark-color': customerTheme.backgroundDarkColor,
            '--gd-brick-email-color': customerTheme.brickEmailColor,
            '--gd-brick-email-active-color': customerTheme.brickEmailActiveColor,
            '--gd-brick-onedrive-color': customerTheme.brickOneDriveColor,
            '--gd-brick-onedrive-active-color': customerTheme.brickOneDriveActiveColor,
            '--gd-brick-teams-color': customerTheme.brickTeamsColor,
            '--gd-brick-teams-active-color': customerTheme.brickTeamsActiveColor,
            '--gd-brick-sharepoint-color': customerTheme.brickSharepointColor,
            '--gd-brick-sharepoint-active-color': customerTheme.brickSharepointActiveColor,
            '--gd-brick-vision-color': customerTheme.brickVisionColor,
            '--gd-brick-vision-active-color': customerTheme.brickVisionActiveColor,
            '--gd-brick-chat-color': customerTheme.brickChatColor,
            '--gd-brick-gmail-color': customerTheme.brickGmailColor,
            '--gd-brick-gmail-active-color': customerTheme.brickGmailActiveColor,
            '--gd-brick-gdrive-color': customerTheme.brickGDriveColor,
            '--gd-brick-gdrive-active-color': customerTheme.brickGDriveActiveColor,
            '--gd-brick-shared-gdrive-color': customerTheme.brickSharedGDriveColor,
            '--gd-brick-shared-gdrive-active-color': customerTheme.brickSharedGDriveActiveColor,
            '--gd-brick-copilote-color': customerTheme.brickCopiloteColor,
            '--gd-brick-copilote-active-color': customerTheme.brickCopiloteActiveColor,

            '--gd-text-clear-color': customerTheme.textClearColor,
            '--gd-text-dark-color': customerTheme.textDarkColor,
            '--gd-text-main-subtitle-color': customerTheme.textMainSubTitleColor,
            '--gd-big-text-dark-color': customerTheme.bigTextDarkColor,
            '--gd-goal-text-color': customerTheme.goalTextColor,
            '--gd-goal-fill-color': customerTheme.goalFillColor,
            '--gd-goal-carbon-footprint-color': customerTheme.goalCarbonFootprintColor,
            '--gd-goal-infobesity-color': customerTheme.goalInfobisityColor,
            '--gd-goal-deconnection-time-color': customerTheme.goalDeconnectionTimeColor,
            '--gd-badge-text-color': customerTheme.badgeTextColor,
            '--gd-badge-fill-color': customerTheme.badgeFillColor,
            '--gd-badge-mails-on-diet-color': customerTheme.badgeMailsOnDietColor,
            '--gd-badge-mails-on-diet-line-color': customerTheme.badgeMailsOnDietLineColor,
            '--gd-badge-mails-on-diet-fill-color': customerTheme.badgeMailsOnDietFillColor,
            '--gd-badge-cleanup-color': customerTheme.badgeCleanUpColor,
            '--gd-badge-cleanup-line-color': customerTheme.badgeCleanUpLineColor,
            '--gd-badge-cleanup-fill-color': customerTheme.badgeCleanUpFillColor,
            '--gd-badge-planet-friendly-color': customerTheme.badgePlanetFriendlyColor,
            '--gd-badge-planet-friendly-line-color': customerTheme.badgePlanetFriendlyLineColor,
            '--gd-badge-planet-friendly-fill-color': customerTheme.badgePlanetFriendlyFillColor,
            '--gd-badge-intern-attachment-color': customerTheme.badgeInternAttachmentColor,
            '--gd-badge-intern-attachment-line-color': customerTheme.badgeInternAttachmentLineColor,
            '--gd-badge-intern-attachment-fill-color': customerTheme.badgeInternAttachmentFillColor,
            '--gd-badge-mailshot-color': customerTheme.badgeMailShotColor,
            '--gd-badge-mailshot-line-color': customerTheme.badgeMailShotLineColor,
            '--gd-badge-mailshot-fill-color': customerTheme.badgeMailShotFillColor,
            '--gd-badge-imagehunt-color': customerTheme.badgeImageHuntColor,
            '--gd-badge-imagehunt-line-color': customerTheme.badgeImageHuntLineColor,
            '--gd-badge-imagehunt-fill-color': customerTheme.badgeImageHuntFillColor,
            '--gd-badge-video-hunt-color': customerTheme.badgeVideoHuntColor,
            '--gd-badge-video-hunt-line-color': customerTheme.badgeVideoHuntLineColor,
            '--gd-badge-video-hunt-fill-color': customerTheme.badgeVideoHuntFillColor,
            '--gd-email-sending-range-stick-less-than-ten-fill-color': customerTheme.emailSendingRangeLessThanTen,
            '--gd-email-sending-range-stick-between-ten-and-thirty-fill-color': customerTheme.emailSendingRangeBetweenTenAndThirty,
            '--gd-email-sending-range-stick-more-than-thirty-fill-color': customerTheme.emailSendingRangeMoreThanThirty,

            '--gd-email-sending-range-small-range-color': customerTheme.emailSendingRangeSmallRange,
            '--gd-email-sending-range-medium-range-color': customerTheme.emailSendingRangeMediumRange,
            '--gd-email-sending-range-large-range-color': customerTheme.emailSendingRangeLargeRange,
            '--gd-email-sending-range-height-range-color': customerTheme.emailSendingRangeHeightRange,

            // text size
            '--gd-size-big-title': '36px',
            '--gd-size-highlighted-title': '20px',
            '--gd-size-highlighted-small-title': '16px',
            '--gd-size-big-number': '32px',
            '--gd-size-highlighted-number': '20px',
            '--gd-size-highlighted-small-number': '16px',
            '--gd-size-body-text': '16px',
            '--gd-size-small-body-text': '14px',
            '--gd-fixed-size-small-body-text': '14px',
            '--gd-size-tiny-body-text': '12px',
            '--gd-size-verry-tiny-body-text': '10px',
            '--gd-size-legend-text': '12px',
            '--gd-size-button-text': '16px',
            '--gd-size-input-text': '16px',
            '--gd-size-table-text': '16px',

            [MediaQueryBreakpoints.MOBILE]: {
                '--gd-size-big-title': '20px',
                '--gd-size-highlighted-title': '16px',
                '--gd-size-highlighted-small-title': '14px',
                '--gd-size-big-number': '20px',
                '--gd-size-highlighted-number': '16px',
                '--gd-size-highlighted-small-number': '14px',
                '--gd-size-body-text': '14px',
                '--gd-size-small-body-text': '12px',
                '--gd-size-tiny-body-text': '11px',
                '--gd-size-legend-text': '11px',
                '--gd-size-button-text': '14px',
            },
        },
    })()

const useWebBodyStyles = (featuring: GreetFeaturingTarget[]) => {
    const styles = {
        container: {
            display: 'grid',
            gridTemplateRows: 'repeat(3,auto)',
            width: '100%',
            margin: 'auto',
        },
        bodyContainer: {
            width: '100%',
            minHeight: '100vh',
            background: 'var(--gd-main-app-background-color)',
        },
        shadowed: featuring.includes(GreetFeaturingTarget.RANKING)
            ? {}
            : {
                  boxShadow: 'var(--gd-main-app-shadowed-content)',
                  borderTopRightRadius: '45px',
                  borderTopLeftRadius: '45px',
              },
    }
    return createUseStyles(styles)()
}

const Impersonate = () => {
    const history = useHistory()
    const location = useLocation()
    const redirectPath = location.pathname.replace('/impersonate', '')
    const { doImpersonation } = useImpersonation()

    useEffect(() => {
        doImpersonation().finally(() => history.replace(redirectPath))
    }, [])

    return null
}

const WebBody = () => {
    const { path } = useRouteMatch()
    const { customer } = useInstance()
    const styles = useWebBodyStyles(customer.featuring ?? [])
    const themeStyles = useClientThemeStyles(customer.theme)
    const { user } = useUser()
    const { dispatch, state } = useStore()
    const { sseCohortPayload } = useSse(SseEvent.UPDATED_COHORT_PAYLOAD, user)

    const handleCloseEventToDeleteUserWhenRulesAgreementsRefused = () => {
        if (user != null && !user.rulesAgreements) {
            localStorage.clear()
        }
    }

    useEffect(() => {
        window.addEventListener('beforeunload', handleCloseEventToDeleteUserWhenRulesAgreementsRefused, true)
        return () => {
            window.removeEventListener('beforeunload', handleCloseEventToDeleteUserWhenRulesAgreementsRefused, true)
        }
    }, [handleCloseEventToDeleteUserWhenRulesAgreementsRefused])

    useEffect(() => {
        if (sseCohortPayload) {
            const newState = state.cohortsPayload.map((cohort: CohortPayload) => {
                return cohort.cohortId === sseCohortPayload.cohortId ? sseCohortPayload : cohort
            })
            dispatch({ type: 'cohorts-payload-fetched', value: newState })
        }
    }, [sseCohortPayload])

    return (
        <div className={classNames([styles.container, themeStyles.clientTheme])}>
            <AdminHeader />
            <AnnouncementsHeader />
            <WelcomePopup />
            <WorkloadEvaluationPopup />
            <ChallengeEndPopup />
            <WelcomeBack />
            <Header />
            <div className={classNames([styles.shadowed, styles.bodyContainer])}>
                <Switch>
                    {/* App routes */}
                    <Route exact path={`${path}`} component={Home} />
                    <Route path={`${path}/administration`} component={AdminHome} />
                    <Route path={`${path}/goal/:goalId`} component={Goal} />
                    <Route path={`${path}/legal-information`} component={LegalInformation} />
                    <Route path={`${path}/animation`} component={AnimationChallenge} />
                    <Route path={`${path}/impersonate`} component={Impersonate} />
                    <Route
                        render={() => {
                            console.error('No route')
                            return <Redirect to={`/${DEFAULT_LANGUAGE}/${DEFAULT_CUSTOMER}`} />
                        }}
                    />
                </Switch>
            </div>
            <Footer />
        </div>
    )
}
