import { ReactElement, ReactNode, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { convertToRem } from '../../utils'
import GreetTab from './GreetTab'
import classNames from 'classnames'

interface ITab<T> {
    id: string
    model: T
    label?: string | ReactElement
    component?: ReactElement
    classNames?: {
        container?: string
        componentContainer?: string
        label?: string
        bar?: string
    }
}

interface IPanel {
    id: string
    component: ReactNode
}

interface Props<T> {
    initialTab: string
    tabs: ITab<T>[]
    panels?: IPanel[]
    onChange?: (item: ITab<T>) => void
    classNames?: {
        container?: string
        tabs?: string
        panel?: string
    }
    tabItemCommonClasseNames?: {
        container?: string
        componentContainer?: string
        label?: string
        bar?: string
    }
}

const useStyles = createUseStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: convertToRem(32),
    },
    tabs: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        columnGap: convertToRem(16),
    },
    panel: {
        width: '100%',
    },
})

export default function GreetTabs<T>({ classNames: customClassNames, tabItemCommonClasseNames, initialTab, tabs, onChange, panels = [] }: Readonly<Props<T>>) {
    const [activeTab, setActiveTab] = useState(() => initialTab)
    const styles = useStyles()
    const activePanel = panels.find((panel) => panel.id === activeTab)

    function handleChange(item: ITab<T>) {
        setActiveTab(item.id)
        if (onChange != null) {
            onChange(item)
        }
    }

    return (
        <div className={classNames(styles.container, customClassNames?.container)}>
            <div className={classNames(styles.tabs, customClassNames?.tabs)}>
                {tabs.map((item) => {
                    return (
                        <GreetTab
                            classNames={{
                                container: classNames(tabItemCommonClasseNames?.container, item.classNames?.container),
                                componentContainer: classNames(tabItemCommonClasseNames?.componentContainer, item.classNames?.componentContainer),
                                label: classNames(tabItemCommonClasseNames?.label, item.classNames?.label),
                                bar: classNames(tabItemCommonClasseNames?.bar, item.classNames?.bar),
                            }}
                            key={item.id}
                            label={item.label}
                            component={item.component}
                            active={activeTab === item.id}
                            onClick={() => {
                                handleChange(item)
                            }}
                        />
                    )
                })}
            </div>
            {activePanel != null ? <div className={classNames(styles.panel, customClassNames?.panel)}>{activePanel.component}</div> : null}
        </div>
    )
}
