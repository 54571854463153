import { Plugin } from 'chart.js'

interface GreetLegendOptions {
    objectifValue: number
    text: string
    color: string
    fontSize: string
}

export default function buildGreetLegend({
    objectifValue,
    text,
    color,
    fontSize,
}: GreetLegendOptions): Plugin<'line'> {
    return {
        id: 'greetlegend',
        afterDraw(chart) {
            const { ctx, chartArea, scales } = chart
            const x = chartArea.left
            const y = scales.y.getPixelForValue(objectifValue)
            const space = 12
            const paddingL = 5

            ctx.save()
            ctx.font = `bold ${fontSize} Source Sans Pro`
            const textW = ctx.measureText(text).width

            // text
            ctx.fillStyle = color
            ctx.fillText(text, x + paddingL, y + 5.5) // text height is not the same as font size (set from observing text rendering)
            ctx.restore()

            // line
            ctx.save()
            ctx.beginPath()
            ctx.strokeStyle = color
            ctx.beginPath()
            ctx.lineWidth = 3
            ctx.setLineDash([8])
            ctx.moveTo(x + paddingL + textW + space, y)
            ctx.lineTo(chartArea.right, y)
            ctx.stroke()
            ctx.closePath()
            ctx.restore()
        },
    }
}
