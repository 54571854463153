import { createUseStyles } from 'react-jss'
import IconAssets from '../assets/icon/IconAssets'
import { numberFormatter } from './utils'

interface IconProps {
    value: number
}

interface Props {
    value: number | undefined
}

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '10px',
    },
    iconWrapper: {
        width: '20px',
        height: '20px',
    },
    text: {
        fontSize: 'var(--gd-size-big-number)',
        fontWeight: 'bold',
        color: 'var(--gd-primary-color)',
    },
    textSucceed: {
        fontSize: 'var(--gd-size-big-number)',
        fontWeight: 'bold',
        color: 'var(--gd-positive-color)',
    },
    textFailed: {
        fontSize: 'var(--gd-size-big-number)',
        fontWeight: 'bold',
        color: 'var(--gd-negative-color)',
    },
})

function Icon({ value }: IconProps): JSX.Element {
    if (value <= -1) {
        return <IconAssets.Down />
    } else if (value >= 1) {
        return <IconAssets.Up />
    }
    return <IconAssets.Equal />
}

export default function Rate({ value }: Props) {
    if (value == null) {
        return null
    }
    const styles = useStyles()
    const roundedValue = Math.round(value)
    return (
        <div className={styles.container}>
            <div className={styles.iconWrapper}>
                <Icon value={roundedValue} />
            </div>
            <span className={roundedValue <= -1 ? styles.textSucceed : roundedValue >= 1 ? styles.textFailed : styles.text}>{`${numberFormatter(roundedValue, true)}%`}</span>
        </div>
    )
}
