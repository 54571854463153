import { Tips } from '../../../../models/Tip'
import * as defaultTips from '../../../lang/tipsContent'

const tipsFr: Tips = {
    ...defaultTips.tipsFr,
    title: 'Mes guides de nettoyage des fichiers',
    link: 'https://swisslive.swisslife.com/content/dam/intranet_slfr/newsroom/actus-swisslive/par-annee/2023/Guide%20de%20nettoyage%20Outlook,%20OneDrive%20et%20Teams.pdf',
}

const tipsEn: Tips = {
    ...defaultTips.tipsEn,
    title: 'My file cleanup guides',
    link: 'https://swisslive.swisslife.com/content/dam/intranet_slfr/newsroom/actus-swisslive/par-annee/2023/Guide%20de%20nettoyage%20Outlook,%20OneDrive%20et%20Teams.pdf',
}

export { tipsEn, tipsFr }
