import { useHistory, useParams } from 'react-router-dom'
import { GreetFeaturingTarget } from '../components/share/GreetFeaturingComponent'
import { useInstance } from '../contexts'
import { EGoal, Params } from '../models'
import CarbonFootprintIndividualGoal from './CarbonFootprintIndividualGoal'


export default function IndividualGoal() {
    const { goalId } = useParams<{ goalId: EGoal }>()
    const { customer } = useInstance()
    const history = useHistory()
    const { langParam, customerParam } = useParams<Params>()

    const individualGoalRouter = () => {
        switch(goalId) {
            case EGoal.CARBON_FOOTPRINT:
                return <CarbonFootprintIndividualGoal />
            case EGoal.QVT:
                if(!customer.featuring?.includes(GreetFeaturingTarget.QVT)){
                    history.push(`/${langParam}/${customerParam}`)
                }
                return <customer.QVTIndividualGoal />
        }
    }

    return (
        <>
            { individualGoalRouter() }
        </>
    )
}