import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { Customer } from '../../models'
import loreal from './loreal'

const lorealBeta: Customer = {
    ...loreal,
    name: 'loreal_beta',
    disablePwa: true,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.DRIVE_FOLDER_DELETION, GreetFeaturingTarget.DRIVE_VERSION]
}

export default lorealBeta
