import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import Info from './Info'

interface Props {
    text: string
    callback: () => void
}

const useStyles = createUseStyles({
    icon: {
        width: '90px',
        height: '90px',
        cursor: 'pointer',
    },
    text: {
        fontWeight: 'bold',
        color: 'var(--gd-primary-color)',
        textAlign: 'center',
        marginTop: '15px',
        maxWidth: '95%',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        icon: {
            width: '100px',
            height: '100px',
        },
    },
})

export default function AddChallenge({ text, callback }: Props) {
    const styles = useStyles()

    return (
        <Info>
            <div
                className={styles.icon}
                onClick={() => {
                    callback()
                }}
            >
                <IconAssets.AddChallengeIcon />
            </div>
            <span className={styles.text}>{text}</span>
        </Info>
    )
}
