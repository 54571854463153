export default interface BreathingTimeMeeting {
    breathingTimeMeetingIndicatorsByUnits: Record<EBreathingTimeMeetingUnit, BreathingTimeMeetingIndicators>;
    breathingTimeMeetingPeriod: EBreathingTimeMeetingPeriod;
}

export interface BreathingTimeMeetingIndicators {
    meetingWithBreathingTime: number;
    meetingWithoutBreathingTime: number;
}

export enum EBreathingTimeMeetingPeriod {
    ONE_WEEK = 'ONE_WEEK',
    ONE_MONTH = 'ONE_MONTH',
    ONE_QUARTER = 'ONE_QUARTER',
    ONE_YEAR = 'ONE_YEAR'
}

export enum EBreathingTimeMeetingUnit {
    THIRTY_MINUTES = 'THIRTY_MINUTES',
    SIXTY_MINUTES = 'SIXTY_MINUTES',
    NINETY_MINUTES = 'NINETY_MINUTES',
    TWO_HOURS = 'TWO_HOURS',
    MORE_THAN_TWO_HOURS = 'MORE_THAN_TWO_HOURS',
}