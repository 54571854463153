import { Tips } from '../../../../models/Tip'

const tipsFr: Tips = {
    title: 'Tips',
    tips: [
        {
            title: 'Limiter l’envoi de documents en pièce jointe.',
            content: 'Éviter les PJ par email et utilisez OneDrive pour partager vos documents même les plus volumineux par un simple lien',
        },
        {
            title: 'Collaborer dans Teams, pas dans l’email.',
            content: 'Réduire au possible l’usage des emails pour communiquer et collaborer en interne. Privilégier les conversations au sein des espaces d’équipe.',
            link: 'https://loreal.sharepoint.com/sites/DW/Documents%20partages/Sustainability/Infographies/EN/FOCUS%20ON%20EMAILS.pdf?cid=30ef6d75-e4a0-48a4-9b59-7184316063ee'
        },
        {
            title: 'Maîtriser la taille de son espace OneDrive.',
            content: 'Éviter de dupliquer les documents qui sont déjà partagés dans un espace d’équipe.',
            link: 'https://loreal.sharepoint.com/:b:/r/sites/DW/Documents%20partages/Sustainability/Infographies/EN/FOCUS%20ON%20FILE%20CREATION%20STORAGE.pdf '
        },
        {
            title: 'OneDrive est un système de sauvegarde dont l’usage doit être modéré.',
            content: 'OneDrive assure la sauvegarde de ses documents y compris ceux enregistrés sur le poste de travail. Cette facilité se justifie pour les documents que l’on produit soi-même, pas pour les documents téléchargés et encore moins pour les vidéos ou photos volumineuses. D’autres solutions moins énergivores peuvent être trouvées. Il s’agit donc de configurer la synchronisation de son disque dur de manière ciblée.',
            link: 'https://hcm55.sapsf.eu/sf/learning?destUrl=https://lorealsa01.lms.sapsf.eu/learning/user/deeplink_redirect.jsp?linkId%3dITEM_DETAILS%26componentID%3d7531%26componentTypeID%3dONLINE%26revisionDate%3d1664207400000%26fromSF%3dY&company=loreal'
        },
        {
            title: 'Organiser mes espaces partagés.',
            content: 'Adopter des conventions d’organisation de l’information dans les espaces d’équipe pour que chacun puisse s’y référer sans dupliquer l’information dans son espace OneDrive.',
            link: 'https://loreal.sharepoint.com/:b:/r/sites/DW/Documents%20partages/Sustainability/Infographies/EN/FOCUS%20ON%20FILE%20CREATION%20STORAGE.pdf '
        },
        {
            title: 'Collaborer dans les documents.',
            content: 'Converser directement dans les documents et les co-éditer en ligne, limite la duplication de version et fédère en un seul endroit toute l’information. C’est une pratique sobre numériquement et qui fait gagner du temps à chacun.',
            link: 'https://loreal.sharepoint.com/:b:/r/sites/DW/Documents%20partages/Sustainability/Infographies/EN/FOCUS%20ON%20FILE%20CREATION%20STORAGE.pdf'
        },
    ],
    link: 'https://blog.lecko.fr/quels-sont-les-impacts-de-nos-pratiques-de-travail-dans-microsoft-365-sur-nos-emissions-carbone/',
}

const tipsEn: Tips = {
    title: 'Tips',
    tips: [
        {
            title: 'Limit document attachments.',
            content: 'Avoid emailing PJs and use OneDrive to share even the largest documents via a simple link.',
        },
        {
            title: 'Collaborate in Teams, not in email.',
            content: 'Minimize the use of email for internal communication and collaboration. Give priority to conversations within team spaces.',
            link: 'https://loreal.sharepoint.com/sites/DW/Documents%20partages/Sustainability/Infographies/EN/FOCUS%20ON%20EMAILS.pdf?cid=30ef6d75-e4a0-48a4-9b59-7184316063ee'
        },
        {
            title: 'Control the size of your OneDrive space.',
            content: 'Avoid duplicating documents already shared in a team space.',
            link: 'https://loreal.sharepoint.com/:b:/r/sites/DW/Documents%20partages/Sustainability/Infographies/EN/FOCUS%20ON%20FILE%20CREATION%20STORAGE.pdf'
        },
        {
            title: 'OneDrive is a backup system that should be used sparingly.',
            content: 'OneDrive backs up all your documents, including those stored on your workstation. This facility is justified for self-produced documents, but not for downloaded documents, and even less so for large videos or photos. Other, less energy-intensive solutions can be found. The key is to configure your hard disk synchronization in a targeted way.',
            link: 'https://hcm55.sapsf.eu/sf/learning?destUrl=https://lorealsa01.lms.sapsf.eu/learning/user/deeplink_redirect.jsp?linkId%3dITEM_DETAILS%26componentID%3d7531%26componentTypeID%3dONLINE%26revisionDate%3d1664207400000%26fromSF%3dY&company=loreal'
        },
        {
            title: 'Organize my shared spaces.',
            content: 'Adopt conventions for organizing information in team spaces so that everyone can refer to it without duplicating information in their OneDrive space.',
            link: 'https://loreal.sharepoint.com/:b:/r/sites/DW/Documents%20partages/Sustainability/Infographies/EN/FOCUS%20ON%20FILE%20CREATION%20STORAGE.pdf '
        },
        {
            title: 'Collaborate on documents.',
            content: 'Conversing directly in documents and co-editing them online, limits duplication of versions and federates all information in a single place. It\'s a practice that saves everyone time and money.',
            link: 'https://loreal.sharepoint.com/:b:/r/sites/DW/Documents%20partages/Sustainability/Infographies/EN/FOCUS%20ON%20FILE%20CREATION%20STORAGE.pdf'
        },
    ],
    link: 'https://blog.lecko.fr/quels-sont-les-impacts-de-nos-pratiques-de-travail-dans-microsoft-365-sur-nos-emissions-carbone/',
}

export { tipsEn, tipsFr }

