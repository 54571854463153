export default function CrossIcon() {
    return (
        <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M9.66732 1.2735L8.72732 0.333496L5.00065 4.06016L1.27398 0.333496L0.333984 1.2735L4.06065 5.00016L0.333984 8.72683L1.27398 9.66683L5.00065 5.94016L8.72732 9.66683L9.66732 8.72683L5.94065 5.00016L9.66732 1.2735Z'
                fill='var(--gd-main-app-background-color)'
            />
        </svg>
    )
}
