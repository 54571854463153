import customer from './customer'
import goals from './goals'
import lang from './lang'
import payload from './payload'
import cohortName from './cohortName'
import user from './user'
import gUser from './guser'
import deleteFolderProposal from './deleteFolderProposal'
import appPreferences from './appPreferences'

const storage = {
    user,
    gUser,
    lang,
    customer,
    goals,
    payload,
    cohortName,
    deleteFolderProposal,
    appPreferences
}

export default storage
