import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'

interface InfoBoxProps {
    children: ReactNode
    width?: string
    height?: string
}

const useStyles = (width: string, height: string) =>
    createUseStyles({
        wrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: '210px',
            height: '100%',
            backgroundColor: 'var(--gd-background-clear-color)',
            borderRadius: 'var(--gd-border-normal-radius)',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '4px dashed var(--gd-background-dark-color)',
            borderRadius: 'var(--gd-border-normal-radius)',
            width: `${width}`,
            height: `${height}`,
        },
        [MediaQueryBreakpoints.MOBILE]: {
            wrapper: {
                width: '95%',
            },
        },
        [MediaQueryBreakpoints.TABLETTE]: {
            wrapper: {
                maxWidth: '80%',
            },
        },
        [MediaQueryBreakpoints.MOBILE_AND_TABLETTE]: {
            wrapper: {
                alignSelf: 'center',
            },
        },
    })()

export default function Info({ children, width = '90%', height = '90%' }: InfoBoxProps) {
    const styles = useStyles(width, height)

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>{children}</div>
        </div>
    )
}
