import classnames from 'classnames'
import IllustrationAssets from '../../assets/illustration/IllustrationAssets'
import { useInstance, useUser } from '../../contexts'
import { GoalProps } from '../../models'
import UserRanking from '../../models/UserRanking'
import { GreetButton } from '../share/GreetButton'
import GoalCardContainer from './GoalCardContainer'
import GoalTitle from './GoalTitle'
import { useGoalCardStyle } from './styles'

interface GoalCardProps {
    goal: GoalProps
    ranking?: UserRanking
    showCardTitle?: boolean
    separateCard?: boolean
    showPeriod?: boolean
}

export default function GoalCard(props: GoalCardProps) {
    const { customer } = useInstance()
    const { lang } = useUser()
    const styles = useGoalCardStyle()
    return (
        <div className={styles.goalCard}>
            <div className={styles.goalTitle}>
                <GoalTitle
                    showCardTitle={props.showCardTitle}
                    shortBorder={props.separateCard}
                    goal={props.goal}
                    showPeriod={props.showPeriod}
                />
            </div>
            {props.goal.active ? (
                <GoalCardContainer goal={props.goal} customer={customer} lang={lang} ranking={props.ranking} />
            ) : (
                <div className={styles.inactiveContainer}>
                    <div className={props.goal.cohort ? styles.inactiveContent : classnames(styles.inactiveContent, styles.individualInactiveContent)}>
                        <IllustrationAssets.CarbonFootprintImage />
                        <div className={styles.inactiveDescription}>{lang.goal.home.addindividualIndicatorsDescription}</div>
                        <GreetButton
                            type='primary'
                            label={lang.goal.home.addindividualIndicators}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
