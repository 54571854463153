import { createUseStyles } from 'react-jss'
import { useUser } from '../../../contexts'

import IconAssets from '../../../assets/icon/IconAssets'
import { DailyStatusChallenge } from '../../../models'
import { BadgeType } from '../../../models/Badge'
import { diff, TimeUnit, toDayLiteralMonthString } from '../../dateUtils'
import { MediaQueryBreakpoints } from '../../MediaQueryBreakpoints'

interface DailyStatusProps {
    challenge: DailyStatusChallenge
}

const useStyles = () =>
    createUseStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        },
        periodContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '45px',
            backgroundColor: '#fff',
            borderRadius: 'var(--gd-border-big-radius)',
            padding: '0px 25px 0px 25px',
        },
        periodText: {
            fontWeight: 700,
            color: 'var(--gd-primary-color)',
        },
        description: {
            width: '65%',
            textAlign: 'center',
            marginTop: '12px',
            marginBottom: '12px',
        },
        details: {
            display: 'flex',
            flexDirection: 'row',
            width: '75%',
            marginBottom: '20px',
            marginTop: '20px',
            alignItems: 'center',
        },
        icon: {
            width: '35%',
            height: '75px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        progressBarContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '65%',
            height: '100%',
            marginLeft: '10px',
        },
        progressBar: {
            display: 'flex',
            flexDirection: 'row',
            columnGap: '10px',
            width: '75%',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            periodContainer: {
                height: '35px',
            },
            description: {
                width: '70%',
            },
            icon: {
                width: '100px',
                height: '100px',
            },
        },
        [MediaQueryBreakpoints.TABLETTE]: {
            progressBarContainer: {
                alignItems: undefined,
                marginLeft: '10%',
            },
        },
    })()

export default function DailyStatus({ challenge }: DailyStatusProps) {
    if (challenge.startDate == null || challenge.endDate == null) {
        return null
    }

    const { lang } = useUser()
    const styles = useStyles()

    const period = {
        startDate: new Date(challenge.startDate),
        endDate: new Date(challenge.endDate),
    }

    const getIcon = () => {
        switch(challenge.badgeType){
            case BadgeType.MAIL:
                return (<IconAssets.Attachement />)
            case BadgeType.INTERN_ATTACHMENT:
                return (<IconAssets.InternAttachment />)
            case BadgeType.MAILSHOT:
                return (<IconAssets.MailShot />)
            default:
                return null
        }
    }

    // plus one day to count last day
    const days = diff(period.endDate, period.startDate, TimeUnit.DAYS) + 1
    const progress = diff(new Date(), period.startDate, TimeUnit.DAYS) + 1

    const renderProgressBar = (): Array<JSX.Element> => {
        const items: Array<JSX.Element> = []

        const dailyStatusesAreCalculated = Object.prototype.hasOwnProperty.call(challenge, 'dailyStatus') && challenge.dailyStatus !== null
        for (let i = 0; i < days; i++) {
            items.push(
                <div
                    key={i}
                    style={{
                        width: `calc(100% / ${days} - 10px * ${days - 1} / ${days})`,
                        paddingTop: `calc(100% / ${days} - 10px * ${days - 1} / ${days})`,
                        borderRadius: '50%',
                        backgroundColor: i < progress - 1 && dailyStatusesAreCalculated ? (challenge.dailyStatus[i] ? 'var(--gd-primary-color)' : '#ED6E2C') : '#fff',
                        boxShadow: i < progress - 1 ? undefined : 'inset 0px 0px 0px 2px var(--gd-background-dark-color)',
                    }}
                ></div>,
            )
        }

        return items
    }

    return (
        <div className={styles.container}>
            <div className={styles.periodContainer}>
                <span className={styles.periodText}>
                    {lang.date.range(toDayLiteralMonthString(period.startDate, lang.locale), toDayLiteralMonthString(period.endDate, lang.locale))}
                </span>
            </div>
            <span className={styles.description}>{lang.goal.challenge.description(challenge)}</span>
            <div className={styles.details}>
                <div className={styles.icon}>{getIcon()}</div>
                <div className={styles.progressBarContainer}>
                    <div className={styles.progressBar}>{renderProgressBar()}</div>
                </div>
            </div>
        </div>
    )
}
