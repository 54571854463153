import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import BlogAssets from '../assets/blog/BlogsAssets'
import { useInstance, useUser } from '../contexts'
import { Article as IArticle } from '../models/BlogArticle'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import Title from './Title'
import Article from './blog/Article'

const useStyles = createUseStyles({
    blogContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        paddingTop: '30px',
    },
    articles: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        gap: '25px',
        justifyContent: 'space-between',
    },
    article: {
        width: '48.5%',
    },
    lastArticleInUnpairTotal: {
        width: '100%',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE]: {
        blogContainer: {
            padding: '0 1rem',
        },
        articles: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
        article: {
            width: '100%',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        blogContainer: {
            width: '100%',
            padding: '0 20px',
        },
        article: {
            width: '100%',
        },
    },
})

export default function Blog() {
    const { lang } = useUser()
    const { customer } = useInstance()
    const styles = useStyles()
    const images: JSX.Element[] = [
        <BlogAssets.Article1 key={0} />,
        <BlogAssets.Article2 key={1} />,
        <BlogAssets.Article3 key={2} />,
        <BlogAssets.LorealArticle1 key={3} />,
        <BlogAssets.LorealArticle2 key={4} />,
    ]
    const blogs = lang.code == 'fr' ? customer.blogs.fr : customer.blogs.en

    return (
        <div className={styles.blogContainer}>
            <Title title={lang.home.blog.title} />
            <div className={styles.articles}>
                {blogs?.map((article: IArticle, index: number) => {
                    const articlesLength = blogs.length
                    const isLastInUnpairTotalLength = articlesLength % 2 === 1 && index + 1 === articlesLength
                    return (
                        <div key={index} className={classnames([styles.article, isLastInUnpairTotalLength ? styles.lastArticleInUnpairTotal : null])}>
                            <Article title={article.title} description={article.description} link={article.link} illustrationImage={images[article.illustrationIndex]} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
