export default function CaretLeft({ fillColor = 'var(--gd-secondary-dark-color)' }: any) {
    return (
        <svg width='100%' height='100%' viewBox='0 0 24 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M1.5 10.9019C-0.500004 12.0566 -0.500001 14.9434 1.5 16.0981L18.75 26.0574C20.75 27.2121 23.25 25.7687 23.25 23.4593L23.25 3.54071C23.25 1.2313 20.75 -0.212067 18.75 0.942633L1.5 10.9019Z'
                fill={fillColor}
            />
        </svg>
    )
}
