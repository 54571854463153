import { Lang } from '../../../../models'
import defaultLang from '../../../lang/en'
import { tipsEn } from './tipsContents'

const swisslifeEn: Lang = {
    ...defaultLang,
    webinar: {
        ...defaultLang.webinar,
        title: 'The commitment journey',
        startAt: '',
        contentTitle: '#ActForLife',
        description: `Fighting digital pollution also means reducing our use of IT devices and trying to make them last longer. 
            Via the challenges of our #ActForLife commitment platform, discover all the keys to adopting a more responsible approach, at every stage in the life of a digital device.`,
        registerationUrl: 'https://app.vendredi.cc/collaborateur/swisslife/defis/804-numerique-responsable-conference-sensibilisation',
        register: "I'm going for it!",
    },
    tipsContent: { ...tipsEn },
}

export default swisslifeEn
