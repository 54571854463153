import classnames from 'classnames'
import { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useClientThemeStyles } from '../App'
import { MediaQueryBreakpoints } from '../components/MediaQueryBreakpoints'
import Presentation from '../components/Presentation'
import { GreetButton } from '../components/share/GreetButton'
import { useInstance, useUser } from '../contexts'
import { doLogin } from '../contexts/AuthService'
import services from '../services'
import settings from '../settings'

const useStyles = createUseStyles({
    mainContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 25px',
        backgroundColor: 'var(--gd-main-app-background-color)',
    },
    content: {
        width: '100%',
        maxWidth: '1142px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0',
    },
    presentation: {
        padding: 'unset',
    },
    presentationTitle: {
        fontSize: 'var(--gd-size-big-title)',
        lineHeight: '42px',
    },
    presentationDescription: {
        paddingTop: '10px',
        fontSize: 'var(--gd-size-body-text)',
        lineHeight: '20px',
    },
    illustrationContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'end',
    },
    textContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
    },
    buttonContent: {
        width: '100%',
    },
    loginButton: {
        width: '50%',
        maxWidth: '400px',
    },
    illustration: {
        height: '400px',
        width: '550px',
    },
    logo: {
        display: 'flex',
        justifyContent: 'flex-start',
        height: '75px',
        width: '195px',
        marginBottom: '30px',
        '& svg': {
            maxWidth: '450px',
        },
    },
    [MediaQueryBreakpoints.MOBILE]: {
        textContent: {
            padding: '0',
            gap: '5px',
            '& img': {
                height: '75px',
                maxWidth: '290px  !important',
            },
        },
        illustration: {
            height: '230px',
            width: '300px',
        },
        presentationTitle: {
            lineHeight: '25px',
        },
        presentationDescription: {
            display: 'block',
            lineHeight: '17px',
            marginBottom: '20px',
        },
        illustrationContainer: {
            width: '100%',
            alignItems: 'center',
            marginTop: '20px',
        },
        logo: {
            display: 'flex',
            justifyContent: 'flex-start',
            height: '75px',
            width: '150px',
            marginBottom: '10px',
        },
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE]: {
        content: {
            flexDirection: 'column',
            gap: '50px',
        },
        loginButton: {
            fontSize: 'var(--gd-size-button-text)',
            width: '100%',
            whiteSpace: 'nowrap',
        },
    },
})

export default function Authentication({ diasableActions = false }: any) {
    const { customer } = useInstance()
    const styles = useStyles()
    const themeStyles = useClientThemeStyles(customer.theme)

    return (
        <div className={classnames([styles.mainContainer, themeStyles.clientTheme])}>
            <div className={styles.content}>
                <Presentation
                    classNames={{
                        textContent: styles.textContent,
                        container: styles.presentation,
                        title: styles.presentationTitle,
                        description: styles.presentationDescription,
                        illustrationContainer: styles.illustrationContainer,
                        illustration: styles.illustration,
                        logo: styles.logo,
                    }}
                    hideLogo={false}
                    location='authentication'
                />
                {diasableActions ? null : <div className={styles.buttonContent}>{customer.useGoogleAuth ? <GoogleAuth /> : <MicrosoftAuth />}</div>}
            </div>
        </div>
    )
}

const MicrosoftAuth = () => {
    const { customer } = useInstance()
    const { lang } = useUser()
    const styles = useStyles()
    return <GreetButton label={lang.authentication.buttonLabel} onClick={() => doLogin(customer, lang)} className={styles.loginButton} />
}

const loadScript = (src: any) =>
    new Promise<void>((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
            return resolve()
        }
        const script = document.createElement('script')
        script.setAttribute('async', 'true')
        script.src = src
        script.onload = () => resolve()
        script.onerror = (err) => reject(err)
        document.body.appendChild(script)
    })

const handleGoogleCredentialResponse = (response: any, callBack?: () => void) => {
    const tokens = response.credential.split('.')
    const payload = JSON.parse(atob(tokens[1]))
    const newUser = { ...payload, jwt: response.credential }
    services.storage.gUser.store(newUser)
    if (callBack != null) {
        callBack()
    }
}

const GoogleAuth = () => {
    const googleButtonRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const { authority, clientId } = settings.global.GOOGLE_CONF()
        loadScript(authority)
            .then(() => {
                google.accounts.id.initialize({
                    'client_id': clientId,
                    'context': 'use',
                    'auto_select': false,
                    'use_fedcm_for_prompt': true,
                    callback: (response) => handleGoogleCredentialResponse(response, () => location.reload()),
                })
                google.accounts.id.prompt()
                if (googleButtonRef.current != null) {
                    google.accounts.id.renderButton(googleButtonRef.current, { theme: 'filled_blue', size: 'medium', type: 'standard', width: 350, shape: 'pill' })
                }
            })
            .catch(console.error)
        return () => {
            const scriptTag = document.querySelector(`script[src="${authority}"]`)
            if (scriptTag) {
                document.body.removeChild(scriptTag)
            }
        }
    }, [])
    return <div ref={googleButtonRef}></div>
}
