enum EQVTSurveyFeel {
    HAPPY = 'HAPPY',
    SMILE = 'SMILE',
    MEDIUM = 'MEDIUM',
    DISAPPOINTED = 'DISAPPOINTED',
    CRY = 'CRY',
}

export const feelToScore = (feel: EQVTSurveyFeel) => {
    if (feel == null) {
        return undefined
    }
    switch (feel) {
        case EQVTSurveyFeel.HAPPY:
            return 5
        case EQVTSurveyFeel.SMILE:
            return 4
        case EQVTSurveyFeel.MEDIUM:
            return 3
        case EQVTSurveyFeel.DISAPPOINTED:
            return 2
        case EQVTSurveyFeel.CRY:
            return 1
    }
}

export default EQVTSurveyFeel
