import axios, { AxiosInstance } from 'axios'
import { User } from '../../models'
import settings from '../../settings'

export const buildHeader = (user: User | null): any => {
    const header = {
        'Accept-Language': 'fr-FR',
        'Content-type': 'application/json',
        'Access-Control-Allow-Headers': 'X-Requested-With',
    }
    if (user?.leckoApiToken != null && user?.state != null) {
        return {
            ...header,
            'Authorization': `${user.leckoApiToken} ${user.state}`,
        }
    }
    return header
}

const CancelationToken = axios.CancelToken
export const cancelationTokenSource = CancelationToken.source()

export const buildBasicAxiosInstance = (): AxiosInstance => {
    return axios.create({
        withCredentials: true,
        baseURL: settings.global.API_URL,
    })
}

export const buildUserAxiosInstance = (user: User | null, refreshToken: (...args: any[]) => Promise<User>) => {
    const instance = buildBasicAxiosInstance()
    applyAuthInterceptors(instance, user, refreshToken)
    return instance
}

const applyAuthInterceptors = (instance: AxiosInstance, user: User | null, refreshToken: (...args: any[]) => Promise<User>) => {
    instance.interceptors.request.use(
        (config) => {
            config = {
                ...config,
                headers: buildHeader(user),
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        },
    )

    instance.interceptors.response.use(
        (res) => res,
        async (responseError) => {
            const previousConfig = responseError.config
            if (previousConfig != null && previousConfig.url !== '/user/exchange-tokens' && responseError.response) {
                if ((responseError.response.status === 401 || responseError.response.status === 403) && !previousConfig.retry) {
                    previousConfig.retry = true
                    try {
                        const newUser = await refreshToken(user)
                        return instance({
                            ...previousConfig,
                            headers: buildHeader(newUser),
                        })
                    } catch (refreshTokenError) {
                        return Promise.reject(refreshTokenError)
                    }
                }
            }
            return Promise.reject(responseError)
        },
    )
}
