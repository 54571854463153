import { createUseStyles } from 'react-jss'
import IllustrationAssets from '../assets/illustration/IllustrationAssets'
import { MediaQueryBreakpoints } from '../components/MediaQueryBreakpoints'
import { useInstance, useUser } from '../contexts'

const useStyles = createUseStyles({
    legalInformation: {
        maxWidth: '1140px',
        margin: '0 auto',
        paddingBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        rowGap: '25px',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '30px',
        width: '100%',
        marginTop: '40px',
    },
    mainTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '1rem',
        fontSize: 'var(--gd-size-big-title)',
        fontWeight: 'bold',
        color: 'var(--gd-big-text-dark-color)',
    },
    description: {
        color: 'var(--gd-text-dark-color)',
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 400,
        userSelect: 'text',
    },
    illustrationWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    illustration: {
        width: '192px',
        height: '183px',
    },
    cards: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '30px',
    },
    informationParagraphes: {
        display: 'flex',
        flexDirection: 'column',
        textJustify: 'inter-word',
        gap: '30px',
    },
    informationTitle: {
        color: 'var(--gd-text-dark-color)',
        fontSize: 'var(--gd-size-body-text)',
        fontWeight: 700,
        paddingBottom: '10px',
    },
    informationContent: {
        color: 'var(--gd-text-dark-color)',
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 400,
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE]: {
        legalInformation: {
            padding: '0 20px',
            paddingBottom: '20px',
            alignItems: 'center',
        },
        header: {
            flexDirection: 'column',
            rowGap: '30px',
        },
        cards: {
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '30px',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        legalInformation: {
            padding: '0 20px',
        },
    },
})

const LegalInformation = () => {
    const { customer } = useInstance()
    const { lang } = useUser()
    const styles = useStyles()
    const description = lang.legalInformations.description(`${customer.contact.support.name} (${customer.contact.support.mail})`)
    return (
        <div className={styles.legalInformation}>
            <div className={styles.header}>
                <div className={styles.mainTitle}>
                    <span>{lang.legalInformations.title}</span>
                    <span className={styles.description}>{description}</span>
                </div>
                <div className={styles.illustrationWrapper}>
                    <div className={styles.illustration}>
                        <IllustrationAssets.LegalNotice />
                    </div>
                </div>
            </div>
            <div className={styles.informationParagraphes}>
                {lang.legalInformations.paragraphes?.map((item, index) => {
                    return (
                        <div key={index}>
                            <div className={styles.informationTitle}>{item.title}</div>
                            <div className={styles.informationContent}>{item.content}</div>
                        </div>
                    )
                })}
            </div>
            <div className={styles.cards}>
                {customer.legalInformation.cgu ? (
                    <LinkCard title={lang.legalInformations.cgu.title} link={{ title: lang.legalInformations.cgu.link, url: customer.legalInformation.cgu }} />
                ) : null}
                {customer.legalInformation.charter ? (
                    <LinkCard title={lang.legalInformations.charter.title} link={{ title: lang.legalInformations.charter.link, url: customer.legalInformation.charter }} />
                ) : null}
            </div>
        </div>
    )
}

interface CardProps {
    title: string
    link: {
        title: string
        url: string
    }
}

const useCardStyles = createUseStyles({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: '35px',
        width: '100%',
        height: '170px',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: '20px',
        color: 'var(--gd-secondary-color)',
        fontSize: 'var(--gd-size-body-text)',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '25px',
        paddingInline: '30px',
        paddingTop: '5px',
        paddingBottom: '5px',
        backgroundColor: 'var(--gd-main-app-background-color)',
        borderRadius: 'var(--gd-border-big-radius)',
    },
    link: {
        color: 'var(--gd-secondary-color)',
        textDecoration: 'underline',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        title: {
            width: '85%',
            height: 'unset',
        },
        link: {
            width: '85%',
        },
    },
})

const LinkCard = ({ title, link }: CardProps) => {
    const styles = useCardStyles()

    return (
        <div className={styles.card}>
            <div className={styles.title}>
                <span>{title}</span>
            </div>
            <a className={styles.link} href={link.url} target='_blank' rel='noopener noreferrer' download>
                {link.title}
            </a>
        </div>
    )
}

export default LegalInformation
