const BenchMarkSubTabIcon = ({ active = false }: any) => {
    const fillColor = active ? '#FFFFFF' : 'var(--gd-secondary-dark-color)'
    return (
        <svg width='100%' height='24px' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M16 8.91286V17.8077C16 18.3891 15.7629 18.9468 15.341 19.3579C14.919 19.769 14.3467 20 13.75 20H3.25C2.65326 20 2.08097 19.769 1.65901 19.3579C1.23705 18.9468 1 18.3891 1 17.8077V3.19231C1 2.61087 1.23705 2.05325 1.65901 1.64211C2.08097 1.23097 2.65326 1 3.25 1H7.87891C8.27659 1.00006 8.65798 1.15399 8.93922 1.42796L15.5608 7.87974C15.842 8.15376 15.9999 8.52537 16 8.91286Z'
                stroke={fillColor}
                strokeWidth='1.5'
                strokeLinejoin='round'
            />
            <path
                d='M8.7931 1.94922V7.42999C8.7931 7.81761 8.95296 8.18936 9.23749 8.46345C9.52203 8.73754 9.90795 8.89153 10.3103 8.89153H16M5 12.5454H12.5862M5 16.1992H12.5862'
                stroke={fillColor}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default BenchMarkSubTabIcon
