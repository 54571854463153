import PayloadStore from '../../models/PayloadStore'

const STORAGE_NAME = 'payload'

const buildUserStorageName = (userId: string) => `${STORAGE_NAME}-${userId}`

const payload = {
    store: (userId: string | null, payload: PayloadStore | null): void => {
        if (userId == null) {
            console.warn('trying to save in local storage with null id')
            return
        }
        localStorage.setItem(buildUserStorageName(userId), JSON.stringify(payload))
    },
    get: (userId: string): PayloadStore | null => {
        if (userId == null) {
            return null
        }
        const payloadStringified = localStorage.getItem(buildUserStorageName(userId))
        if (payloadStringified !== null) {
            return JSON.parse(payloadStringified)
        } else {
            return null
        }
    },
    clear: (userId: string | null) => {
        if (userId == null) {
            console.warn('trying to delete in local storage with null id')
            return
        }
        localStorage.removeItem(buildUserStorageName(userId))
    },
}

export default payload
