const DoughnutSubTabIcon = ({ active = false }: any) => {
    const fillColor = active ? '#FFFFFF' : 'var(--gd-secondary-dark-color)'
    return (
        <svg width='100%' height='24px' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.13054 2.77794C6.74546 2.92195 5.42952 3.45547 4.33521 4.31667C3.24089 5.17788 2.41298 6.33152 1.94741 7.64394C1.48183 8.95635 1.39764 10.3738 1.70459 11.7321C2.01155 13.0904 2.69708 14.334 3.68176 15.3186C4.66644 16.3033 5.90998 16.9889 7.26828 17.2958C8.62658 17.6028 10.044 17.5186 11.3565 17.053C12.6689 16.5874 13.8225 15.7595 14.6837 14.6652C15.5449 13.5709 16.0784 12.2549 16.2225 10.8699H8.89278C8.69062 10.8699 8.49674 10.7896 8.3538 10.6466C8.21085 10.5037 8.13054 10.3098 8.13054 10.1076V2.77794ZM0 10.1076C0 7.74911 0.936915 5.4872 2.60464 3.81948C4.27235 2.15176 6.53427 1.21484 8.89278 1.21484C9.09494 1.21484 9.28882 1.29515 9.43176 1.4381C9.57471 1.58105 9.65502 1.77492 9.65502 1.97708V9.34539H17.0233C17.2255 9.34539 17.4194 9.42569 17.5623 9.56864C17.7053 9.71159 17.7856 9.90547 17.7856 10.1076C17.7856 12.4661 16.8486 14.7281 15.1809 16.3958C13.5132 18.0635 11.2513 19.0004 8.89278 19.0004C6.53427 19.0004 4.27235 18.0635 2.60464 16.3958C0.936915 14.7281 0 12.4661 0 10.1076Z'
                fill={fillColor}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.4498 1.64536V6.55011H17.3545C17.0708 5.35883 16.4621 4.26963 15.5962 3.40371C14.7302 2.53779 13.641 1.92909 12.4498 1.64536ZM11.9396 0.00705473C15.6186 0.464398 18.5355 3.38123 18.9928 7.0603C19.063 7.61724 18.6005 8.07458 18.0395 8.07458H11.4335C11.2987 8.07458 11.1694 8.02105 11.0741 7.92575C10.9788 7.83045 10.9253 7.7012 10.9253 7.56643V0.960361C10.9253 0.399353 11.3826 -0.0630712 11.9396 0.00705473Z'
                fill={fillColor}
            />
        </svg>
    )
}

export default DoughnutSubTabIcon
