import { GoalDto } from '../../models'

const STORAGE_NAME = 'goals'

const goals = {
    store: (goals: GoalDto[]): void => {
        localStorage.setItem(STORAGE_NAME, JSON.stringify(goals))
    },
    get: (): GoalDto[] | null => {
        const goalsStringified = localStorage.getItem(STORAGE_NAME)
        if (goalsStringified !== null) {
            return JSON.parse(goalsStringified)
        } else {
            return null
        }
    },
    clear: () => {
        localStorage.removeItem(STORAGE_NAME)
    },
}

export default goals
