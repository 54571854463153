import { ReactComponent as Article1 } from './Article1.svg'
import { ReactComponent as Article2 } from './Article2.svg'
import { ReactComponent as Article3 } from './Article3.svg'
import { LorealArticle1, LorealArticle2 } from './LorealArticles'

export default {
    Article1,
    Article2,
    Article3,
    LorealArticle1,
    LorealArticle2,
}
