const STORAGE_NAME = 'appPreferences'

interface AppPreferences {
    showWelcomePopup: boolean
}

const defaultAppPreferences =  {
    showWelcomePopup: true,
}

const appPreferences = {
    store: (appPreferences: AppPreferences): void => {
        localStorage.setItem(STORAGE_NAME, JSON.stringify(appPreferences))
    },
    get: (): AppPreferences | null => {
        const str = localStorage.getItem(STORAGE_NAME)
        return str == null
            ? defaultAppPreferences
            : JSON.parse(str)
    },
    clear: () => {
        localStorage.removeItem(STORAGE_NAME)
    },
}

export default appPreferences
