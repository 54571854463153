import classNames from 'classnames'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../../assets/icon/IconAssets'
import SurveyAssets from '../../../assets/icon/survey/SurveyAssets'
import { useStore, useUser } from '../../../contexts'
import { Lang } from '../../../models'
import EQVTSurveyFeel from '../../../models/EQVTSurveyFeel'
import { useFetch } from '../../../services/api/useFetch'
import Title from '../../Title'
import { buildWeeksYearNumber } from '../../dateUtils'
import InfoBulle from '../../share/Infobulle'

interface PersonnalWorkloadEvaluationProps {
    includeLegende: boolean
}

const useQvtPersonnalWorkloadGraphStyle = createUseStyles({
    qvtPersonnalWorkloadGraph: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
    },
    qvtPersonnalWorkloadGraphContent: {
        padding: '10px',
        borderRadius: 'var(--gd-border-normal-radius)',
        border: '2px solid #C3DFFF',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    qvtPersonnalWorkloadGraphContentMainGraph: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '25px',
        overflow: 'auto',
    },
    qvtPersonnalWorkloadGraphContentMainGraphQuestions: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'end',
        width: '20%',
        gap: '55px',
        paddingBottom: '20px',
    },
    qvtPersonnalWorkloadGraphContentMainGraphInfos: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'end',
        width: '20%',
        paddingBottom: '0px',
        color: 'var(--gd-primary-color)',
        fontWeight: 'bold',
    },
    qvtPersonnalWorkloadGraphContentMainGraphQuestion: {
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-highlighted-small-title)',
    },
})

const PersonnalWorkloadEvaluation = ({ includeLegende }: PersonnalWorkloadEvaluationProps) => {
    const { lang } = useUser()
    const { state } = useStore()
    const styles = useQvtPersonnalWorkloadGraphStyle()
    const weekToCompute = useMemo(() => (includeLegende ? 7 : 12), [includeLegende])
    const { data } = useFetch({ endpoint: 'workload-evaluations', params: { weeksAgo: weekToCompute }, watchedParams: { payload: state.userPayload } })
    return (
        <div className={styles.qvtPersonnalWorkloadGraph}>
            <Title title={lang.worloadEvaluationPersonalResult.title} />
            <div className={styles.qvtPersonnalWorkloadGraphContent}>
                <div className={styles.qvtPersonnalWorkloadGraphContentMainGraphInfos}>{lang.worloadEvaluationPersonalResult.lastWeeks}</div>
                <div className={styles.qvtPersonnalWorkloadGraphContentMainGraph}>
                    <div className={styles.qvtPersonnalWorkloadGraphContentMainGraphQuestions}>
                        <div className={styles.qvtPersonnalWorkloadGraphContentMainGraphQuestion}>{lang.worloadEvaluationPersonalResult.graph.q1Label}</div>
                        <div className={styles.qvtPersonnalWorkloadGraphContentMainGraphQuestion}>{lang.worloadEvaluationPersonalResult.graph.q2Label}</div>
                        <div className={styles.qvtPersonnalWorkloadGraphContentMainGraphQuestion}>{lang.worloadEvaluationPersonalResult.graph.q3Label}</div>
                    </div>
                    <PersonnalWorkloadEvaluationGraph evaluations={data} weekToCompute={weekToCompute} />
                    {includeLegende ? <PersonnalWorkloadEvaluationGraphLegend /> : null}
                </div>
            </div>
        </div>
    )
}

const usePersonnalWorkloadEvaluationGraphStyle = createUseStyles({
    personnalWorkloadEvaluationGraph: {
        display: 'flex',
        flexDirection: 'column',
    },
    personnalWorkloadEvaluationGraphArea: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    personnalWorkloadEvaluationGraphBar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    personnalWorkloadEvaluationGraphEmojis: {
        display: 'flex',
        flexDirection: 'column',
        gap: '50px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 18px',
    },
    personnalWorkloadEvaluationGraphFirstEmojisBar: {
        borderLeft: '3px solid #C3DFFF',
    },
    personnalWorkloadEvaluationGraphBarFooter: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: '3px solid #C3DFFF',
    },
})

const PersonnalWorkloadEvaluationGraph = ({ evaluations, weekToCompute }: any) => {
    const styles = usePersonnalWorkloadEvaluationGraphStyle()
    const { lang, user } = useUser()
    const weeks = useMemo(() => buildWeeksYearNumber(weekToCompute, lang, user?.workingDays), [weekToCompute, user?.workingDays])
    const evaluationsMap = useMemo(() => {
        const resutl = new Map()
        evaluations?.forEach((item: any) => {
            resutl.set(`${item.yearWeekNumber}`, item)
        })
        return resutl
    }, [evaluations])
    return (
        <div className={styles.personnalWorkloadEvaluationGraph}>
            <div className={styles.personnalWorkloadEvaluationGraphArea}>
                {weeks.map((item, index) => {
                    const weekEvaluation = evaluationsMap.get(item.key)
                    return (
                        <div key={item.key} className={styles.personnalWorkloadEvaluationGraphBar}>
                            <div className={classNames([styles.personnalWorkloadEvaluationGraphEmojis, index == 0 ? styles.personnalWorkloadEvaluationGraphFirstEmojisBar : ''])}>
                                <PersonnalWorkloadEvaluationGraphQuestionEmojis feels={weekEvaluation?.question1} lang={lang} />
                                <PersonnalWorkloadEvaluationGraphQuestionEmojis feels={weekEvaluation?.question2} lang={lang} />
                                <PersonnalWorkloadEvaluationGraphQuestionEmojis feels={weekEvaluation?.question3} lang={lang} />
                            </div>
                            <div className={styles.personnalWorkloadEvaluationGraphBarFooter}>
                                <InfoBulle translateX='-35%' text={item.literalDay}>
                                    <div>{item.label}</div>
                                </InfoBulle>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const usePersonnalWorkloadEvaluationGraphQuestionEmojisStyle = createUseStyles({
    icon: {
        width: '35px',
        height: '35px',
        '&> svg': {
            width: '35px',
            height: '35px',
        },
    },
})

const PersonnalWorkloadEvaluationGraphQuestionEmojis = ({ feels, lang }: Readonly<{ feels: EQVTSurveyFeel; lang: Lang }>) => {
    const styles = usePersonnalWorkloadEvaluationGraphQuestionEmojisStyle()
    switch (feels) {
        case EQVTSurveyFeel.HAPPY:
            return (
                <InfoBulle text={lang.worloadEvaluation.answersInformations.happy} translateX='-25%'>
                    <div className={styles.icon}>
                        <SurveyAssets.Happy />
                    </div>
                </InfoBulle>
            )
        case EQVTSurveyFeel.SMILE:
            return (
                <InfoBulle text={lang.worloadEvaluation.answersInformations.smile} translateX='-25%'>
                    <div className={styles.icon}>
                        <SurveyAssets.Smile />
                    </div>
                </InfoBulle>
            )

        case EQVTSurveyFeel.MEDIUM:
            return (
                <InfoBulle text={lang.worloadEvaluation.answersInformations.medium} translateX='-25%'>
                    <div className={styles.icon}>
                        <SurveyAssets.Medium />
                    </div>
                </InfoBulle>
            )

        case EQVTSurveyFeel.DISAPPOINTED:
            return (
                <InfoBulle text={lang.worloadEvaluation.answersInformations.disappointed} translateX='-25%'>
                    <div className={styles.icon}>
                        <SurveyAssets.Disappointed />
                    </div>
                </InfoBulle>
            )
        case EQVTSurveyFeel.CRY:
            return (
                <InfoBulle text={lang.worloadEvaluation.answersInformations.cry} translateX='-25%'>
                    <div className={styles.icon}>
                        <SurveyAssets.Cry />
                    </div>
                </InfoBulle>
            )
        default:
            return <div className={styles.icon}></div>
    }
}

const usePersonnalWorkloadEvaluationGraphLegend = createUseStyles({
    personnalWorkloadEvaluationGraphLegend: {
        width: '25%',
        height: '90%',
        borderRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: '#C3DFFF',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    personnalWorkloadEvaluationGraphLegendPolygone: {
        position: 'relative',
        width: '20px',
        height: '20px',
        background: '#C3DFFF',
        transform: 'rotate(45deg)',
        left: '-10px',
    },
    personnalWorkloadEvaluationGraphLegendContent: {
        position: 'relative',
        width: '95%',
        height: '100%',
        whiteSpace: 'pre-line',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: '10px',
        padding: '20px',
        borderRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: 'var(--gd-background-clear-color)',
    },

    personnalWorkloadEvaluationGraphLegendMainText: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    personnalWorkloadEvaluationGraphLegendLink: {
        fontWeight: 'bold',
        display: 'flex',
        gap: '5px',
        textAlign: 'center',
        alignItems: 'center',
        '&> svg': {
            width: '20px',
            height: '20px',
        },
    },
})

const PersonnalWorkloadEvaluationGraphLegend = () => {
    const { lang } = useUser()
    const styles = usePersonnalWorkloadEvaluationGraphLegend()
    return (
        <div className={styles.personnalWorkloadEvaluationGraphLegend}>
            <div className={styles.personnalWorkloadEvaluationGraphLegendPolygone} />
            <div className={styles.personnalWorkloadEvaluationGraphLegendContent}>
                <div className={styles.personnalWorkloadEvaluationGraphLegendMainText}>{lang.worloadEvaluationPersonalResult.legend.text1}</div>
                <div className={styles.personnalWorkloadEvaluationGraphLegendMainText}>{lang.worloadEvaluationPersonalResult.legend.text2}</div>
                <div className={styles.personnalWorkloadEvaluationGraphLegendLink}>
                    {lang.worloadEvaluationPersonalResult.legend.textLink} <IconAssets.ArrowRight color='var(--gd-primary-color)' />
                </div>
            </div>
        </div>
    )
}

export default PersonnalWorkloadEvaluation
