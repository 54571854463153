import classnames from 'classnames'
import { colorify } from 'lottie-colorify'
import lottie from 'lottie-web'
import { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useClientThemeStyles } from '../App'
import { useInstance, useUser } from '../contexts'
import loading from './loader/loading.json'

const useStyles = createUseStyles({
    container: {
        height: '100vh',
        width: '100%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--gd-main-app-background-color)',
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
    },
    text: {
        fontSize: 30,
        color: 'var(--gd-text-dark-color)',
        padding: '20px',
    },
})

export default function Loader({ isAuthenticationPending }: LoaderProps) {
    const { customer } = useInstance()
    const { lang } = useUser()
    const styles = useStyles()
    const themeStyles = useClientThemeStyles(customer.theme)
    const animRef = useRef<any>()
    useEffect(() => {
        const currentTheme = customer.theme
        const lodingData = colorify(currentTheme.loaderColors, loading)
        lottie.loadAnimation({
            container: animRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: lodingData,
        })
        return () => {
            animRef.current = null
        }
    }, [customer.name])
    return (
        <div className={classnames([styles.container, themeStyles])}>
            <div className={styles.subContainer} ref={animRef}></div>
            <div className={styles.text}>{isAuthenticationPending ? lang.loader.authentication : lang.loader.loading}</div>
        </div>
    )
}

interface LoaderProps {
    isAuthenticationPending: boolean
}
