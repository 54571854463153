import lang from './lang'
import customer from './customer'
import styles from './styles'
import global from './global'

const settings = {
    lang,
    customer,
    styles,
    global,
}

export default settings
