import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useUser } from '../../../contexts'
import { Lang } from '../../../models'
import UserPayload from '../../../models/UserPayload'
import { MediaQueryBreakpoints } from '../../MediaQueryBreakpoints'
import Title from '../../Title'
import { numberFormatter } from '../../utils'
import referentiel from './ReferentielEmissions'

const useStyles = createUseStyles({
    benchMark: {
        display: 'flex',
        flexDirection: 'column',
        width: '35%',
        maxWidth: '100vm',
        gap: '1rem',
        color: '#FFFFFF',
        '--gd-benchmark-bulb-background-color': '#FFFFFF',
    },
    benchMarkContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyItems: 'center',
        height: '100%',
        borderRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: 'var(--gd-primary-color)',
    },
    title: {
        display: 'block',
    },
    mobileTitle: {
        display: 'none',
    },
    mainTitle: {
        display: 'none',
    },
    icon: {
        position: 'relative',
        left: '13px',
        width: '50px',
    },
    emoji: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '50px',
        backgroundColor: 'var(--gd-benchmark-bulb-background-color)',
        borderRadius: '50px',
        width: '80px',
        height: '80px',
    },
    shuffleButton: {
        cursor: 'pointer',
    },
    equivalence: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px',
        height: '101%',
        width: '101%',
        borderRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: 'var(--gd-background-clear-color)',
        color: 'var(--gd-text-dark-color)',
        fontWeight: 'bold',
        fontSize: 18,
        padding: '30px',
    },
    equivalenceValue: {
        fontSize: 'var(--gd-size-big-number)',
    },
    equivalenceDescription: {
        fontSize: 'var(--gd-size-body-text)',
        fontWeight: 'bold',
    },
    equivalenceSrouce: {
        fontSize: 'var(--gd-size-verry-tiny-body-text)',
        fontWeight: 'bold',
    },
    description: {
        fontSize: 'var(--gd-size-body-text)',
        fontWeight: 'bold',
        padding: '0 10px',
        textAlign: 'center',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        benchMark: {
            width: '100%',
        },
        benchMarkContent: {
            height: '313px',
        },
    },
    [MediaQueryBreakpoints.MOBILE]: {
        benchMark: {
            gap: '1.5rem',
            height: '400px',
            '--gd-benchmark-bulb-background-color': 'var(--gd-background-clear-color)',
        },
        title: {
            display: 'none',
        },
        mobileTitle: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontWeight: 'bold',
            color: 'var(--gd-secondary-color)',
            gap: '10px',
        },
        mainTitle: {
            display: 'block',
            fontSize: 22,
            lineHeight: '25px',
        },
        equivalence: {
            backgroundColor: '#FFFFFF',
        },
    },
})

export default function BenchMark({ payload }: Readonly<Props>) {
    const { lang } = useUser()
    const styles = useStyles()
    const elements = referentiel as any[]
    const [elementIndex, setElementIndex] = useState<number>(0)
    const totalCumulCarbonOfTheYear = payload?.consolidatedCarbonImpact?.daysOfCurrentYear?.reduce((partialSum, a) => partialSum + a.ci, 0) / 1000
    const incrementElement = () => setElementIndex((elementIndex + 1) % elements.length)
    return (
        <div className={styles.benchMark}>
            <div className={styles.title}>
                <Title title={lang.goal.benchMark.title} />
            </div>
            <div className={styles.mobileTitle}>
                <div className={styles.mainTitle}>{lang.goal.benchMark.title}</div>
            </div>
            <div className={styles.benchMarkContent}>
                <div className={styles.icon}>
                    <div className={styles.emoji}>{elements[elementIndex].emoji}</div>
                </div>
                <div className={styles.equivalence}>
                    <span className={styles.description}>
                        {lang.goal.benchMark.description} {elements[elementIndex].usageAction[lang.code]}
                    </span>
                    <span className={styles.equivalenceValue}>{numberFormatter(totalCumulCarbonOfTheYear / formatTotalByMultiplier(elements[elementIndex]))}</span>
                    <span className={styles.equivalenceDescription}>
                        {formatName(lang, elements[elementIndex], totalCumulCarbonOfTheYear / formatTotalByMultiplier(elements[elementIndex]))}
                    </span>
                    <a target='_blank' rel='noreferrer' href='https://impactco2.fr/' className={styles.equivalenceSrouce}>
                        Source : impactCO2.fr (ADEME)
                    </a>
                    <div className={styles.shuffleButton} onKeyPress={incrementElement} onClick={incrementElement}>
                        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M21.924 6.617a.997.997 0 0 0-.217-.324l-3-3a1 1 0 1 0-1.414 1.414L18.586 6h-3.321a5 5 0 0 0-4.288 2.428l-3.67 6.115A3 3 0 0 1 4.736 16H3a1 1 0 1 0 0 2h1.735a5 5 0 0 0 4.288-2.428l3.67-6.115A3 3 0 0 1 15.264 8h3.32l-1.292 1.293a1 1 0 0 0 1.414 1.414l3-3A.997.997 0 0 0 22 7m-.076-.383a.996.996 0 0 1 .076.38l-.076-.38z'
                                fill='var(--gd-primary-color)'
                            />
                            <path
                                d='M21.706 17.708l-2.999 3a1 1 0 0 1-1.414-1.415L18.586 18h-3.321a5 5 0 0 1-4.288-2.428l-3.67-6.115A3 3 0 0 0 4.736 8H3a1 1 0 0 1 0-2h1.735a5 5 0 0 1 4.288 2.428l3.67 6.115A3 3 0 0 0 15.264 16h3.32l-1.292-1.293a1 1 0 0 1 1.414-1.414l3 3c.195.194.292.45.293.704V17a.997.997 0 0 1-.294.708z'
                                fill='var(--gd-primary-color)'
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function formatName(lang: Lang, element: any, value = 1, capital?: boolean) {
    const name = (element.prefix ? element?.prefix[lang.code] || '' : '') + element.name[lang.code]
    const newName = name.replaceAll('[s]', value > 1 ? 's' : '').replaceAll('[x]', value > 1 ? 'x' : '')
    return capital ? newName : newName.toLowerCase()
}

export function formatTotalByMultiplier(element: any) {
    let total = element.total
    if (element.usage) {
        total += element.usage.defaultyears * element.usage.peryear
    }

    return element.multiplier ? total * element.multiplier : total
}

interface Props {
    payload: UserPayload
}
