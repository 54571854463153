import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import Circle from '../../../assets/icon/Circle'
import ChartLegend from '../../share/qvt/ChartLegend'
import { convertToRem } from '../../utils'

interface IDataset {
    label: string
    value: number
    backgroundColor: string
}

interface Props {
    totalTeams: number
    activeTeams: IDataset
    inactiveTeams: IDataset
}

interface StyleProps {
    activeTeamsBarWidth: number
    inactiveTeamsBarWidth: number
}

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: convertToRem(12),
    },
    chart: {
        position: 'relative',
        width: '100%',
        height: convertToRem(28),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    activeTeamsBar: ({ activeTeamsBarWidth }: StyleProps) => ({
        width: `${activeTeamsBarWidth}%`,
        height: '100%',
        backgroundColor: 'var(--gd-primary-color)',
        borderTopLeftRadius: convertToRem(5),
        borderBottomLeftRadius: convertToRem(5),
    }),
    inactiveTeamsBar: ({ inactiveTeamsBarWidth }: StyleProps) => ({
        width: `${inactiveTeamsBarWidth}%`,
        height: '100%',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderTopRightRadius: convertToRem(5),
        borderBottomRightRadius: convertToRem(5),
    }),
    activeTeamsLabel: ({ activeTeamsBarWidth: activeWidth }: StyleProps) => ({
        position: 'absolute',
        left: `calc(${activeWidth}% + ${convertToRem(5)})`,
        fontWeight: 'bold',
        color: 'var(--gd-primary-color)',
    }),
    label: {
        marginLeft: convertToRem(5),
        fontSize: 'var(--gd-size-small-body-text)',
        color: 'var(--gd-big-text-dark-color)',
    },
    legend: {
        flexDirection: 'row',
        columnGap: convertToRem(14),
    },
    legendItem: {
        columnGap: convertToRem(8),
        justifyContent: 'start',
    },
})

export default function Chart({ totalTeams, activeTeams, inactiveTeams }: Readonly<Props>) {
    const sizes = useMemo(() => {
        const total = activeTeams.value + inactiveTeams.value
        return {
            active: (activeTeams.value * 100) / total,
            inactive: (inactiveTeams.value * 100) / total,
        }
    }, [activeTeams, inactiveTeams])

    const styles = useStyles({ activeTeamsBarWidth: sizes.active, inactiveTeamsBarWidth: sizes.inactive })

    return (
        <div className={styles.container}>
            <div className={styles.chart}>
                <div className={styles.activeTeamsBar}></div>
                {activeTeams.value > 0 ? <span className={styles.activeTeamsLabel}>{activeTeams.value}</span> : null}
                <div className={styles.inactiveTeamsBar}></div>
                {totalTeams > 0 ? <span className={styles.label}>{totalTeams}</span> : null}
            </div>
            <ChartLegend
                classNames={{ container: styles.legend }}
                items={[
                    { icon: <Circle color={activeTeams.backgroundColor} size={9} />, label: activeTeams.label, classNames: { container: styles.legendItem } },
                    { icon: <Circle color={inactiveTeams.backgroundColor} size={9} />, label: inactiveTeams.label, classNames: { container: styles.legendItem } },
                ]}
            />
        </div>
    )
}
