import { createUseStyles } from 'react-jss'
import Blog from '../components/Blog'
import Goals from '../components/Goals'
import { MediaQueryBreakpoints } from '../components/MediaQueryBreakpoints'
import Presentation from '../components/Presentation'
import Victory from '../components/victories/Victory'
import withRedirectToQvt from '../components/share/withRedirectToQvt'

const useStyles = createUseStyles({
    content: {
        maxWidth: '1142px',
        margin: '0 auto',
        paddingBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    victoryContainer: {
        marginTop: '40px',
        marginBottom: '20px',
    },
    blogContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        content: {
            padding: '0',
            paddingBottom: '20px',
        },
        goalContainer: {
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        victoryContainer: {
            padding: '0 25px',
        },
    },
    [MediaQueryBreakpoints.TABLETTE]: {
        content: {
            padding: '0',
            paddingBottom: '20px',
        },
        goalContainer: {
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        victoryContainer: {
            padding: '0 25px',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        content: {
            padding: '0',
            paddingBottom: '20px',
        },
        goalContainer: {
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflowX: 'auto',
        },
        victoryContainer: {
            padding: '0 25px',
        },
    },
})

function Home() {
    const styles = useStyles()

    return (
        <div className={styles.content}>
            <Presentation />
            <Goals />
            <Victory />
            <Blog />
        </div>
    )
}

export default withRedirectToQvt(Home)
