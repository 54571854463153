import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../../assets/icon/IconAssets'
import { MediaQueryBreakpoints } from '../../MediaQueryBreakpoints'
import { convertToRem } from '../../utils'

interface Props {
    teams: string[]
    noTeamsLabel: string
    caption?: string
    tip: string
    suggestionLabel: string
}

const useStyles = createUseStyles({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    table: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderBottom: `${convertToRem(2)} solid var(--gd-background-dark-color)`,
    },
    cell: {
        paddingInline: convertToRem(50),
        paddingTop: convertToRem(13),
        paddingBottom: convertToRem(16),
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 'var(--gd-size-small-body-text)',
        textAlign: 'center',
    },
    cellFillRightBorder: {
        borderRight: `${convertToRem(2)} solid var(--gd-background-dark-color)`,
    },
    cellFillBottomBorder: {
        borderBottom: `${convertToRem(2)} solid var(--gd-background-dark-color)`,
    },
    noTeamsLabel: {
        marginTop: convertToRem(32),
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'bold',
        color: 'var(--gd-text-dark-color)',
        textAlign: 'center',
    },
    caption: {
        paddingInline: convertToRem(30),
        marginTop: convertToRem(32),
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'lighter',
        color: 'var(--gd-text-dark-color)',
        textAlign: 'center',
    },
    tip: {
        paddingInline: convertToRem(30),
        marginTop: convertToRem(24),
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'lighter',
        color: 'var(--gd-text-dark-color)',
        textAlign: 'center',
    },
    suggestionWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    suggestion: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: convertToRem(6),
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'bold',
        color: 'var(--gd-secondary-color)',
        cursor: 'pointer',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        suggestionWrapper: {
            minHeight: convertToRem(100),
        },
    },
})

const MAX_NB_TEAMS_IN_TABLE = 10

export default function TeamsList({ teams, noTeamsLabel, caption, tip, suggestionLabel }: Readonly<Props>) {
    const styles = useStyles()
    const [offset, setOffset] = useState(0)
    const teamsChunks = useMemo(() => {
        if (teams.length === 0) {
            return []
        }
        return teams.reduce((acc: string[][], teamName: string) => {
            if (acc.length === 0) {
                return [[teamName]]
            }

            const lastChunk = acc[acc.length - 1]
            if (acc[acc.length - 1].length === MAX_NB_TEAMS_IN_TABLE) {
                acc.push([teamName])
            } else {
                lastChunk.push(teamName)
            }
            return acc
        }, [])
    }, [teams])

    function suggestTeams() {
        if (teamsChunks.length < 1) {
            return
        }

        if (offset + 1 >= teamsChunks.length) {
            setOffset(0)
        } else {
            setOffset((prev) => prev + 1)
        }
    }

    return (
        <div className={styles.container}>
            {teamsChunks.length === 0 ? (
                <span className={styles.noTeamsLabel}>{noTeamsLabel}</span>
            ) : (
                <div className={styles.table}>
                    {teamsChunks[offset].map((item, i) => (
                        <div
                            className={classNames(
                                styles.cell,
                                (i + 1) % 2 != 0 ? styles.cellFillRightBorder : undefined,
                                Math.round((i + 1) / 2) < Math.round(teamsChunks[offset].length / 2) ? styles.cellFillBottomBorder : undefined,
                            )}
                            key={i}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            )}
            {teamsChunks.length > 0 && caption ? <span className={styles.caption}>{caption}</span> : null}
            <p className={styles.tip}>{tip}</p>
            <div className={styles.suggestionWrapper}>
                {teamsChunks.length > 1 ? (
                    <div onClick={suggestTeams} className={styles.suggestion}>
                        <div>{suggestionLabel}</div>
                        <IconAssets.Refresh />
                    </div>
                ) : null}
            </div>
        </div>
    )
}
