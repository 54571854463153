import { createUseStyles } from 'react-jss'

import { useCSVReader } from 'react-papaparse'

interface Props {
    onUpload: (data: any) => void
}

const useStyles = createUseStyles({
    zone: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        border: '2px dashed var(--gd-text-dark-color)',
        borderRadius: 'var(--gd-border-small-radius)',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8,
        },
    },
    label: {
        color: 'var(--gd-text-dark-color)',
        fontSize: '16px',
    },
    specification: {
        color: 'var(--gd-text-dark-color)',
        fontSize: '12px',
    },
})

export default function GreetCSVUpload({ onUpload }: Props) {
    const { CSVReader } = useCSVReader()

    const styles = useStyles()

    return (
        <CSVReader
            onUploadAccepted={(results: any) => {
                onUpload(results.data)
            }}
        >
            {({ getRootProps, acceptedFile }: any) => (
                <div {...getRootProps()} className={styles.zone}>
                    {acceptedFile ? (
                        <div className={styles.label}>{acceptedFile.name}</div>
                    ) : (
                        <>
                            <div className={styles.label}>Déposez le fichier CSV ici ou cliquez pour le télécharger</div>
                            <div className={styles.specification}>Le fichier doit contenir un email par ligne qui est suivi d&apos;une virgule et aussi d&apos;un retour à la ligne.</div>
                        </>
                    )}
                </div>
            )}
        </CSVReader>
    )
}
