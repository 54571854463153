interface NewLightBulbProps {
    className: string
}
export default function NewLightBulb({ className }: NewLightBulbProps) {
    return (
        <svg width='69' height='64' viewBox='0 0 69 64' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
            <path d='M0 10C0 4.47715 4.47715 0 10 0H59C64.5228 0 69 4.47715 69 10V54C69 59.5228 64.5229 64 59 64H10C4.47715 64 0 59.5228 0 54V10Z' fill='white' />
            <path
                d='M33.7083 50.564C32.7191 50.564 31.8661 50.2286 31.1493 49.5578C30.4325 48.8869 30.0427 48.0674 29.9799 47.0993H37.4367C37.3739 48.0674 36.9841 48.8869 36.2673 49.5578C35.5505 50.2286 34.6975 50.564 33.7083 50.564ZM27.8333 44.4454C27.4165 44.4454 27.0675 44.3078 26.7864 44.0326C26.5052 43.7574 26.3646 43.4158 26.3646 43.0079C26.3646 42.6 26.5052 42.2585 26.7864 41.9832C27.0675 41.708 27.4165 41.5704 27.8333 41.5704H39.5833C40 41.5704 40.349 41.708 40.6302 41.9832C40.9114 42.2585 41.052 42.6 41.052 43.0079C41.052 43.4158 40.9114 43.7574 40.6302 44.0326C40.349 44.3078 40 44.4454 39.5833 44.4454H27.8333ZM26.6658 38.9166C24.6146 37.6683 22.9909 36.0373 21.7945 34.0236C20.5982 32.0098 20 29.8075 20 27.4166C20 23.6816 21.3307 20.5117 23.992 17.907C26.6533 15.3023 29.8921 14 33.7083 14C37.5245 14 40.7633 15.3023 43.4246 17.907C46.0859 20.5117 47.4166 23.6816 47.4166 27.4166C47.4166 29.8075 46.8184 32.0098 45.622 34.0236C44.4257 36.0373 42.8019 37.6683 40.7507 38.9166H26.6658ZM27.5395 36.0416H39.877C41.3458 35.0194 42.48 33.7576 43.2796 32.2562C44.0793 30.7548 44.4791 29.1416 44.4791 27.4166C44.4791 24.4777 43.4347 21.9861 41.3458 19.9416C39.2569 17.8972 36.7111 16.875 33.7083 16.875C30.7055 16.875 28.1597 17.8972 26.0708 19.9416C23.9819 21.9861 22.9375 24.4777 22.9375 27.4166C22.9375 29.1416 23.3373 30.7548 24.1369 32.2562C24.9366 33.7576 26.0708 35.0194 27.5395 36.0416Z'
                fill='var(--gd-primary-color)'
            />
        </svg>
    )
}
