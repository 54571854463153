import { createUseStyles } from 'react-jss'
import { User } from '../models'

interface ImpersonateDebugInfoProps {
    user: User | null
}

const useStyles = createUseStyles({
    debugMenu: { display: 'flex', flexDirection: 'column', width: '30%', color: 'white' },
    logInfo: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    extractorDates: {
        marginLeft: '20px',
    },
})
export default function ImpersonateDebugInfo({ user }: Readonly<ImpersonateDebugInfoProps>) {
    if (!user?.isImpersonate) {
        return null
    }

    const styles = useStyles()

    return (
        <div className={styles.debugMenu}>
            <div className={styles.logInfo}>
                <p>Last connexion date:</p>
                <p>
                    {user?.impersonateDebugInfo?.lastLoginDate == null || isNaN(new Date(user?.impersonateDebugInfo?.lastLoginDate).getTime())
                        ? 'N/A'
                        : new Date(user?.impersonateDebugInfo?.lastLoginDate).toLocaleString()}
                </p>
            </div>
            <hr />
            <p>Last extractions dates:</p>
            <br />
            <div className={styles.extractorDates}>
                {Object.entries(user?.impersonateDebugInfo?.lastExtractionDateByCluster ?? {}).map(([key, val]) => {
                    if (key === 'SHAREPOINT') return
                    return (
                        <div key={key} className={styles.logInfo}>
                            <p>{key}</p>
                            <p>{val == null || isNaN(new Date(val).getTime()) ? 'N/A' : new Date(val).toLocaleString()}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
