import { createUseStyles } from 'react-jss'
import IconAssets from '../../../assets/icon/IconAssets'
import { useUser } from '../../../contexts'
import { DriveChallenge, ImageHuntChallenge, VideoHuntChallenge } from '../../../models'
import { BadgeType } from '../../../models/Badge'
import { MediaQueryBreakpoints } from '../../MediaQueryBreakpoints'
import { toDayLiteralMonthString } from '../../dateUtils'

interface SpaceCleaningProps {
    challenge: DriveChallenge | ImageHuntChallenge | VideoHuntChallenge
}

const useStyles = (proportion: number, progress: number, goalSize: number) =>
    createUseStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        },
        periodContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '45px',
            backgroundColor: '#fff',
            borderRadius: 'var(--gd-border-big-radius)',
            padding: '0px 25px 0px 25px',
        },
        periodText: {
            fontWeight: 700,
            color: 'var(--gd-primary-color)',
        },
        description: {
            width: '60%',
            textAlign: 'center',
            marginTop: '12px',
        },
        details: {
            display: 'flex',
            flexDirection: 'row',
            width: '75%',
            marginTop: '20px',
            marginBottom: '20px',
        },
        icon: {
            width: '75px',
            height: '75px',
        },
        progressBarContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '70%',
            marginLeft: '10px',
        },
        progressBar: {
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            height: '15px',
        },
        progressValue: {
            width: `${proportion}%`,
            height: '15px',
            borderRadius: progress < goalSize ? 'var(--gd-border-small-radius) 0px 0px var(--gd-border-small-radius)' : 'var(--gd-border-small-radius)',
            backgroundColor: 'var(--gd-primary-color)',
            border: progress <= 0 ? undefined : '2px solid var(--gd-primary-color)',
        },
        remainingValue: {
            width: `${100 - proportion}%`,
            height: '15px',
            border: progress < goalSize ? '2px solid var(--gd-primary-color)' : undefined,
            borderRadius: progress <= 0 ? 'var(--gd-border-small-radius)' : '0px var(--gd-border-small-radius) var(--gd-border-small-radius) 0px',
            backgroundColor: '#fff',
        },
        progressBarText: {
            fontWeight: 700,
            color: 'var(--gd-primary-color)',
            marginTop: '5px',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            periodContainer: {
                height: '35px',
            },
            description: {
                width: '70%',
            },
            icon: {
                width: '100px',
                height: '100px',
            },
        },
    })()

export default function SpaceCleaning({ challenge }: SpaceCleaningProps) {
    if (challenge.startDate == null || challenge.endDate == null) {
        return null
    }

    if (challenge.badgeType === BadgeType.DRIVE) {
        return <Drive challenge={challenge as DriveChallenge} />
    }

    if (challenge.badgeType === BadgeType.IMAGE_HUNT) {
        return <ImageHunt challenge={challenge as ImageHuntChallenge} />
    }

    if (challenge.badgeType === BadgeType.VIDEO_HUNT) {
        return <VideoHunt challenge={challenge as VideoHuntChallenge} />
    }

    return null
}

function Drive({ challenge }: { challenge: DriveChallenge }) {
    const { lang } = useUser()

    const progress = Math.round(challenge.value)
    const goalSize = challenge.driveGoalSize
    const proportion = (challenge.value / challenge.driveGoalSize) * 100

    const period = {
        startDate: new Date(challenge.startDate),
        endDate: new Date(challenge.endDate),
    }

    return (
        <View
            goalSize={goalSize}
            progress={progress}
            proportion={proportion}
            period={lang.date.range(toDayLiteralMonthString(period.startDate, lang.locale), toDayLiteralMonthString(period.endDate, lang.locale))}
            description={lang.goal.challenge.description(challenge)}
            icon={<IconAssets.Garbage />}
        />
    )
}

function ImageHunt({ challenge }: { challenge: ImageHuntChallenge }) {
    const { lang } = useUser()

    const progress = Math.round(challenge.value)
    const goalSize = challenge.imageHuntGoalSize
    const proportion = (challenge.value / challenge.imageHuntGoalSize) * 100

    const period = {
        startDate: new Date(challenge.startDate),
        endDate: new Date(challenge.endDate),
    }

    return (
        <View
            goalSize={goalSize}
            progress={progress}
            proportion={proportion}
            period={lang.date.range(toDayLiteralMonthString(period.startDate, lang.locale), toDayLiteralMonthString(period.endDate, lang.locale))}
            description={lang.goal.challenge.description(challenge)}
            icon={<IconAssets.ImageHunt />}
        />
    )
}

function VideoHunt({ challenge }: { challenge: VideoHuntChallenge }) {
    const { lang } = useUser()

    const progress = Math.round(challenge.value)
    const goalSize = challenge.videosHuntGoalSize
    const proportion = (challenge.value / challenge.videosHuntGoalSize) * 100

    const period = {
        startDate: new Date(challenge.startDate),
        endDate: new Date(challenge.endDate),
    }

    return (
        <View
            goalSize={goalSize}
            progress={progress}
            proportion={proportion}
            period={lang.date.range(toDayLiteralMonthString(period.startDate, lang.locale), toDayLiteralMonthString(period.endDate, lang.locale))}
            description={lang.goal.challenge.description(challenge)}
            icon={<IconAssets.VideoHunt />}
        />
    )
}

interface ViewProps {
    goalSize: number
    progress: number
    proportion: number
    period: string
    description: string
    icon: JSX.Element
}

function View({ goalSize, progress, proportion, period, description, icon }: ViewProps) {
    const { lang } = useUser()
    const styles = useStyles(proportion, progress, goalSize)

    return (
        <div className={styles.container}>
            <div className={styles.periodContainer}>
                <span className={styles.periodText}>{period}</span>
            </div>
            <span className={styles.description}>{description}</span>
            <div className={styles.details}>
                <div className={styles.icon}>{icon}</div>
                <div className={styles.progressBarContainer}>
                    <div className={styles.progressBar}>
                        <div className={styles.progressValue}></div>
                        <div className={styles.remainingValue}></div>
                    </div>
                    <span className={styles.progressBarText}>{`${progress}/${goalSize} ${lang.volumeUnit.megaBytes}`}</span>
                </div>
            </div>
        </div>
    )
}
