export enum BadgeType {
    PLANET_FRIENDLY = 'PLANET_FRIENDLY',
    DRIVE = 'DRIVE',
    MAIL = 'MAIL',
    DIGITAL_CLEANUP_DAY = 'DIGITAL_CLEANUP_DAY',
    INTERN_ATTACHMENT = 'INTERN_ATTACHMENT',
    MAILSHOT = 'MAILSHOT',
    IMAGE_HUNT = 'IMAGE_HUNT',
    VIDEO_HUNT = 'VIDEO_HUNT'
}

export interface Badge {
    id: string
    type: BadgeType
    leckoUserId: string
    period: string
    reducedBy: number
    createdAt: string
}
