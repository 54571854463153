import { Lang } from '../../../../models'
import defaultLang from '../../../lang/fr'

const fftFr: Lang = {
    ...defaultLang,
    webinar: {
        ...defaultLang.webinar,
        startAt: '',
        contentTitle: 'Prochainement',
        description: '',
    },
}

export default fftFr
