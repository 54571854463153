const CloseIcon = ({ strokeColor = 'var(--gd-secondary-color)' }: any) => {
    return (
        <svg width='100%' height='100%' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1 1L13 13' stroke={strokeColor} strokeWidth='2' strokeLinecap='round' />
            <path d='M1 13L13 0.999999' stroke={strokeColor} strokeWidth='2' strokeLinecap='round' />
        </svg>
    )
}

export default CloseIcon
