import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useStore, useUser } from '../contexts'
import DriveFolderEnhanced from '../models/DriveFolderEnhanced'
import services from '../services'
import { useFetch } from '../services/api/useFetch'
import { areOnSameDay, toLiteralDateString } from './dateUtils'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import ConfirmPopup from './share/ConfirmPopup'
import GreetFeaturingComponent, { GreetFeaturingTarget } from './share/GreetFeaturingComponent'
import { convertToGCO2, convertToRem, octetsConverter } from './utils'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        width: '100%',
        padding: '30px',
    },
    title: {
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
        color: 'var(--gd-text-dark-color)',
    },
    description: {
        marginTop: '15px',
        marginBottom: '15px',
        fontSize: 'var(--gd-size-body-text)',
        color: 'var(--gd-text-dark-color)',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '60%',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
    },
    buttonOk: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: 'var(--gd-secondary-color)',
        marginLeft: convertToRem(30),
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: 'var(--gd-size-body-text)',
    },
    buttonNext: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: 'var(--gd-secondary-color)',
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: 'var(--gd-size-body-text)',
    },
    inactiveButton: {
        opacity: '0.5',
        cursor: 'none',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        container: {
            backgroundColor: 'var(--gd-main-app-background-color)',
            width: 'calc(100% - 40px)',
            alignSelf: 'center',
        },
        description: {
            textAlign: 'center',
        },
        buttonsContainer: {
            flexDirection: 'column',
            width: '100%',
            textAlign: 'center',
        },
        buttonOk: {
            marginRight: '0px',
            marginTop: '5px',
            marginBottom: '10px',
        },
    },
})

const DeleteFolderProposalsComponent = () => {
    const { state } = useStore()
    const { lang } = useUser()
    const styles = useStyles()
    const [deleteFolderIndex, setDeleteFolderIndex] = useState<number>(0)
    const [deleteFolder, setDeleteFolder] = useState<DriveFolderEnhanced | null>(null)
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false)
    const { data } = useFetch({ endpoint: 'drive-folder-month-limit-filter' })

    const setDriveFolder = (index: number) => {
        services.storage.deleteFolderProposal.store({
            updatedDate: new Date().toUTCString(),
            index: index,
        })
        setDeleteFolderIndex(index)
        if (state.userPayload != null) {
            setDeleteFolder(state.userPayload.driveFoldersToBeDeleted[index])
        }
    }

    useEffect(() => {
        const deleteFolderProposal = services.storage.deleteFolderProposal.get()
        if (deleteFolderProposal != null && areOnSameDay(new Date(deleteFolderProposal.updatedDate), new Date())) {
            setDriveFolder(deleteFolderProposal.index)
        } else {
            setDriveFolder(0)
        }
    })

    return (
        <div className={styles.container}>
            <div className={styles.title}>{lang.goal.individual.deleteFolderProposal.title}</div>
            {deleteFolder == null ? (
                <div className={styles.description}>{lang.goal.individual.deleteFolderProposal.noMoreFolders(data)}</div>
            ) : (
                <>
                    <div className={styles.description}>
                        {lang.goal.individual.deleteFolderProposal.description(
                            toLiteralDateString(deleteFolder.lastUpdatedDate, lang.locale),
                            deleteFolder.nbChildren,
                            octetsConverter(deleteFolder.size, lang.code).value + ' ' + octetsConverter(deleteFolder.size, lang.code).measured,
                            new Date().getFullYear().toString(),
                            convertToGCO2(deleteFolder.carbonFootprint).value + ' ' + convertToGCO2(deleteFolder.carbonFootprint).measured,
                        )}
                    </div>
                    <div className={styles.buttonsContainer}>
                        {(
                            <button className={styles.buttonNext} onClick={() => setDriveFolder(deleteFolderIndex + 1)}>
                                {lang.goal.individual.deleteFolderProposal.next}
                            </button>
                        )}

                        <button
                            className={styles.buttonOk}
                            onClick={() => {
                                window.open(deleteFolder.webUrl)
                                setTimeout(() => {
                                    setPopupIsOpen(true)
                                }, 2000)
                            }}
                        >
                            {lang.goal.individual.deleteFolderProposal.goToFolder}
                        </button>
                    </div>
                </>
            )}
            {popupIsOpen ? (
                <ConfirmPopup
                    message={lang.goal.individual.deleteFolderProposal.popup.description}
                    cancelLabel={lang.goal.individual.deleteFolderProposal.popup.no}
                    confirmLabel={lang.goal.individual.deleteFolderProposal.popup.yes}
                    onCancel={() => {
                        setPopupIsOpen(false)
                    }}
                    onConfirm={() => {
                        setDriveFolder(deleteFolderIndex + 1)
                        setPopupIsOpen(false)
                    }}
                />
            ) : null}
        </div>
    )
}

const DeleteFolderProposalsComponentLegacy = () => <></>

export default function DeleteFolderProposals() {
    return <GreetFeaturingComponent target={GreetFeaturingTarget.DRIVE_FOLDER_DELETION} legacyDef={DeleteFolderProposalsComponentLegacy} nextDef={DeleteFolderProposalsComponent} />
}
