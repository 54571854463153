import classNames from 'classnames'
import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useInstance, useUser } from '../contexts'
import ImpersonateDebugInfo from './ImpersonateDebugInfo'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import Popup from './Popup'
import services from '../services'
import Copy from './share/Copy'

enum PopupType {
    BUG_REPORT = 'BUG_REPORT',
    NONE = 'NONE',
}

const useStyles = createUseStyles({
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: '20px',
        width: '100%',
        padding: '25px 0px 25px 0px',
        backgroundColor: 'var(--gd-primary-color)',
    },
    links: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '15px',
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'bold',
        color: 'var(--gd-text-clear-color)',
    },
    seperator: {
        height: '20px',
        borderLeft: '1px solid var(--gd-text-clear-color)',
    },
    linkLabel: {
        cursor: 'pointer',
        textDecorationLine: 'underline',
    },
    cacheLabel: {
        cursor: 'pointer',
        textDecorationLine: 'underline',

        '&:hover $infobulle': {
            visibility: 'visible',
        },
    },
    infobulle: {
        visibility: 'hidden',
        position: 'absolute',
        transform: 'translateY(-75%)',
        zIndex: 10,
        maxWidth: '160px',
        padding: '5px 10px',
        borderRadius: 'var(--gd-border-normal-radius)',
        textAlign: 'center',
        fontSize: 'var(--gd-size-tiny-body-text)',
        fontWeight: 'normal',
        color: 'var(--gd-text-dark-color)',
        textDecorationLine: 'none',
        backgroundColor: 'var(--gd-background-clear-color)',
    },
    copyright: {
        fontSize: 'var(--gd-size-small-body-text)',
        color: 'var(--gd-text-clear-color)',
    },

    popup: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'var(--gd-primary-color)',
        width: '100%',
        height: '100%',
        minWidth: '600px',
        maxWidth: '600px',
        minHeight: '300px',
        maxHeight: '500px',
    },
    popupTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50px',
        minHeight: '50px',
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
        color: 'var(--gd-text-clear-color)',
        textAlign: 'center',
    },
    popupContent: {
        flexGrow: 1,
        padding: '60px 50px 30px 50px',
        backgroundColor: 'var(--gd-main-app-background-color)',
        overflowY: 'auto',
    },
    popupContentTitle: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 'bold',
    },
    popupContentTextWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
    },
    popupContentText: {
        fontSize: 'var(--gd-size-small-body-text)',
        overflowWrap: 'break-word',
    },
    popupContentTextHeading: {
        fontSize: 'var(--gd-size-small-body-text)',
        overflowWrap: 'break-word',
        marginBottom: '20px',
        display: 'flex',
        gap: '5px'
    },
    popupContentTextHeadingLink: {
        fontWeight: 'bold',
        color: 'var(--gd-text-dark-color)',
        userSelect: 'text'
    },
    releaseNotePopupContent: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        paddingTop: '6px',
    },
    editIconWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
        height: '43px',
    },
    editIcon: {
        width: '35px',
        height: '43px',
        cursor: 'pointer',
    },
    releaseNotePopupContentNoteWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '40px',
    },
    releaseNotePopupContentNote: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
    },

    bugReportPopupContent: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '30px',
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        popup: {
            minWidth: 'unset',
            maxWidth: 'unset',
        },
    },
    [MediaQueryBreakpoints.MOBILE]: {
        popup: {
            minWidth: 'unset',
            maxWidth: 'unset',
            minHeight: 'unset',
            maxHeight: 'unset',
        },
        links: {
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '20px',
        },
        seperator: {
            display: 'none',
        },
        popupContent: {
            padding: '6px 30px 30px 30px',
        },
        bugReportPopupContent: {
            padding: '60px 30px 30px 30px',
        },
    },
})

export default function Footer() {
    const { user, refreshUser, lang } = useUser()
    const { customer } = useInstance()
    const [popup, setPopup] = useState<{ type: PopupType; show: boolean }>({ type: PopupType.BUG_REPORT, show: false })
    const styles = useStyles()

    function goToRealsesNotes() {
        window.open(lang.footer.releaseNote.link, '_blank', 'noopener,noreferrer')
    }

    function showBugReportPopup() {
        setPopup({ type: PopupType.BUG_REPORT, show: true })
    }

    function closePopup() {
        setPopup({ type: PopupType.NONE, show: false })
    }

    async function clearSiteData() {
        try {
            const keys = await caches.keys()
            if (keys.length > 0) {
                await Promise.all(keys.map((k) => caches.delete(k)))
            }
        } catch (error) {
            // do nothing
        }
        sessionStorage.clear()
        services.storage.deleteFolderProposal.clear()

        await refreshUser()
    }

    return (
        <div className={styles.footer}>
            <div className={styles.links}>
                <div className={styles.linkLabel} onClick={goToRealsesNotes}>
                    {lang.footer.releaseNote.label}
                </div>
                <div className={styles.seperator}></div>
                <div className={styles.linkLabel} onClick={showBugReportPopup}>
                    {lang.footer.bugReport.label}
                </div>
                <div className={styles.seperator}></div>
                <div className={styles.cacheLabel} onClick={clearSiteData}>
                    {lang.footer.cache.label}
                    <span className={styles.infobulle}>{lang.footer.cache.infoBulle}</span>
                </div>
            </div>
            <div className={styles.copyright}>{lang.footer.copyright}</div>
            <ImpersonateDebugInfo user={user} />
            {popup.show && popup.type !== PopupType.NONE ? (
                <Popup onCloseRequest={closePopup} closeIconColor='var(--gd-text-clear-color)'>
                    <div className={styles.popup}>
                        <div className={styles.popupTitle}>{{ [PopupType.BUG_REPORT]: lang.footer.bugReport.label }[popup.type]}</div>
                        {
                            {
                                [PopupType.BUG_REPORT]: (
                                    <div className={classNames(styles.popupContent, styles.bugReportPopupContent)}>
                                        <div className={styles.popupContentTitle}>{lang.footer.bugReport.title}</div>
                                        <div className={styles.popupContentTextWrapper}>
                                            <span className={styles.popupContentTextHeading}>
                                                {customer.bugReport[lang.code as 'fr' | 'en'].contact}
                                                <span className={styles.popupContentTextHeadingLink}>{`${customer.contact.support.name} (${customer.contact.support.mail})`}</span>
                                                <Copy content={customer.contact.support.mail} />
                                            </span>
                                            {customer.bugReport[lang.code as 'fr' | 'en'].description.map((text, index) => (
                                                <span className={styles.popupContentText} key={index}>
                                                    {text}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                ),
                            }[popup.type]
                        }
                    </div>
                </Popup>
            ) : null}
        </div>
    )
}
