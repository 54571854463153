const EvolutionIcon = () => {
    return (
        <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='17' cy='17' r='17' fill='var(--gd-primary-color)' />
            <g clipPath='url(#clip0_6341_39822xx)'>
                <path
                    d='M25.4285 13.7811L20.2981 19.054C19.734 19.6298 19.0529 19.9191 18.2534 19.9226C17.4546 19.9261 16.7762 19.6396 16.2188 19.0645L14.9878 17.7928C14.7355 17.5321 14.4291 17.4035 14.0679 17.407C13.7074 17.4105 13.401 17.5391 13.148 17.7928L8.33082 22.7708C8.18608 22.9203 8.00345 22.9972 7.78363 23.0007C7.5638 23.0042 7.37847 22.9273 7.22696 22.7708C7.07545 22.6143 6.99902 22.4242 6.99902 22.2006C6.99902 21.977 7.07478 21.7869 7.22696 21.6304L12.0455 16.6524C12.6096 16.0766 13.2894 15.7873 14.0848 15.7838C14.8803 15.7803 15.56 16.0696 16.1241 16.6524L17.3288 17.8969C17.5919 18.1687 17.903 18.3029 18.2615 18.2994C18.62 18.2959 18.9311 18.1617 19.1942 17.8969L24.3084 12.6239H22.0242C21.801 12.6239 21.6143 12.5464 21.4642 12.3906C21.314 12.2354 21.2383 12.0426 21.2383 11.812C21.2383 11.5814 21.3133 11.3885 21.4642 11.2334C21.6143 11.0783 21.801 11 22.0242 11H26.0528C26.3226 11 26.5479 11.0936 26.7285 11.2802C26.9091 11.4668 26.9997 11.6995 26.9997 11.9783V16.1402C26.9997 16.3708 26.9246 16.5636 26.7738 16.7187C26.6236 16.8739 26.4369 16.9521 26.2137 16.9521C25.9905 16.9521 25.8039 16.8746 25.6537 16.7187C25.5035 16.5636 25.4278 16.3708 25.4278 16.1402V13.7804L25.4285 13.7811Z'
                    fill='var(--gd-main-app-background-color)'
                />
            </g>
            <defs>
                <clipPath id='clip0_6341_39822xx'>
                    <rect width='20' height='12' fill='var(--gd-main-app-background-color)' transform='translate(7 11)' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default EvolutionIcon
