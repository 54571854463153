const CarbonFootprintIcon = (color: string) => {
    return (
        <svg width='100%' height='100%' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='19' cy='19' r='19' fill={color} />
            <path
                d='M22.3633 16.3578C18.896 14.1661 15.3073 15.7395 11.9918 12.3043C11.2194 11.5027 11.668 21.2931 15.8183 25.0031C18.8527 27.7131 23.5378 27.4108 25.1176 24.7771C26.6974 22.1435 25.8306 18.5491 22.3633 16.3578V16.3578Z'
                stroke='white'
                strokeWidth='1.4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='M17.0044 19.5781C20.1405 22.6034 23.3859 24.3962 27.6524 25.0685' stroke='white' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    )
}

export default CarbonFootprintIcon
