import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import WorkloadEvolutionManagerGraph from '../components/workloadEvolutionManager/WorkloadEvolutionManagerGraph'
import WorkloadEvolutionManagerTable from '../components/workloadEvolutionManager/WorkloadEvolutionManagerTable'
import { useStore, useUser } from '../contexts'
import { WorkingDaySettings } from '../models/WorkingDaySettings'
import { useFetch } from '../services/api/useFetch'
import IconAssets from '../assets/icon/IconAssets'
export const NB_WEEK_TO_COMPUTE = 11

const useStyles = createUseStyles({
    qVTManagerBoard: {
        maxWidth: '1142px',
        margin: '0 auto',
        paddingBottom: '20px',
        display: 'flex',
        gap: '30px',
        flexDirection: 'column',
        justifyContent: 'start',
    },
    qVTManagerBoardTitle: {
        display: 'flex',
        width: '100%',
        padding: '2rem 0',
        gap: '1rem',
        fontSize: 'var(--gd-size-big-title)',
        fontWeight: 'bold',
        color: 'var(--gd-big-text-dark-color)',
        justifyContent: 'space-between',
    },
    button: {
        color: 'var(--gd-secondary-color)',
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
    },
    qVTManagerBoardTable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
    },
})

const QVTManagerBoard = () => {
    const { user, lang } = useUser()
    const styles = useStyles()
    const { state } = useStore()
    const { data, loading } = useFetch({ endpoint: 'managed-users-workload-evaluation', watchedParams: { payload: state.userPayload } })
    const csvResponses = useFetch({
        endpoint: 'stats/response-survey-manager',
        watchedParams: { payload: state.userPayload },
        params: {
            userId: user?.userId
        },
    })
    const memozedData = useMemo(() => prepareData(data), [data])
    const upload = async () => {createFileLink(csvResponses)};

    return (
        <div className={styles.qVTManagerBoard}>
            <div className={styles.qVTManagerBoardTitle}>{lang.qvtManagerBoard.title}
            <button onClick={upload} className={styles.button}>
                <div>
                    <IconAssets.Upload />
                </div>
            </button>
            </div>
            
            <div className={styles.qVTManagerBoardTable}>
                <WorkloadEvolutionManagerGraph data={memozedData} loading={loading} />
            </div>
            <div className={styles.qVTManagerBoardTable}>
                <WorkloadEvolutionManagerTable data={memozedData} loading={loading} />
            </div>
        </div>
    )
}

export default QVTManagerBoard

const prepareData = (data: any) => {
    return (
        data?.map((e: { email: string; firstName: string; lastName: string; userEvaluation: any[]; workingDays: WorkingDaySettings }) => {
            const evaluations = e.userEvaluation.map((ev) => ({ [ev.yearWeekNumber]: ev }))
            let result = {
                user: {
                    email: e.email,
                    firstName: e.firstName,
                    lastName: e.lastName,
                    workingDays: e.workingDays,
                },
            }
            evaluations.forEach((ev) => {
                result = { ...result, ...ev }
            })
            return result
        }) ?? []
    )
}

function createFileLink(csvResponses:any){
    try {
        const blob = new Blob([csvResponses.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'reponses-survey.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Upload failed:', error);
    }
}