import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { RadioButton } from './Inputs'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import SoftLoader from '../SoftLoader'
import { convertToRem } from '../utils'

interface GreetButtonProps {
    label: string
    type?: 'primary' | 'secondary' | 'secondary-inv' | 'danger'
    icon?: JSX.Element
    disabled?: boolean
    loading?: boolean
    [x: string]: any
}

interface GreetFileInputButtonProps extends GreetButtonProps {
    id: string
    base64Callback: (base64: string) => void
}

interface GreetRadioButtonProps<T extends FieldValues> {
    id: string
    name: Path<T>
    value: string
    label?: string
    required: boolean
    register: UseFormRegister<T>
}

const useGreetButtonStyle = createUseStyles({
    greetButton: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        borderRadius: 'var(--gd-border-big-radius)',
        cursor: 'pointer',
        height: '44px',
        padding: '0 25px',
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-button-text)',
        lineHeight: '25px',
        color: '#FFFFFF',
    },
    greetSubmitButton: {
        border: 'none',
    },
    greetButtonIcon: {
        width: '22px',
        height: '22px',
    },
    primary: {
        backgroundColor: 'var(--gd-primary-button-color)',
    },
    secondary: {
        backgroundColor: 'var(--gd-secondary-button-color)',
    },
    'secondary-inv': {
        backgroundColor: 'var(--gd-background-clear-color)',
        color: 'var(--gd-secondary-color)',
    },
    danger: {
        backgroundColor: 'var(--gd-danger-button-color)',
    },
    greetInputFileButton: {
        width: '0.1px',
        height: '0.1px',
        opacity: '0',
        overflow: 'hidden',
        position: 'absolute',
        zIndex: '-1',
    },
    radioContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    disabledButton: {
        cursor: 'default',
        opacity: 0.75,
    },
    transparentLabel: {
        color: 'transparent',
    },
    loaderContainer: (props: { isLoading?: boolean }) => ({
        display: props?.isLoading ? 'unset' : 'none',
        position: 'absolute',
        width: convertToRem(30),
        height: convertToRem(30),
    }),
    loaderSpinner: {
        borderColor: 'currentcolor',
        borderBottomColor: 'transparent',
    },
})

export const GreetButton = ({ label, type = 'secondary', disabled = false, icon, loading = false, ...rest }: GreetButtonProps) => {
    const styles = useGreetButtonStyle({ isLoading: loading })
    const { className, ...restProps } = rest
    const classes = [styles.greetButton, styles[type], className]
    if (disabled) {
        classes.push(styles.disabledButton)
        if (restProps.onClick) {
            restProps.onClick = null
        }
    }

    return (
        <div className={classnames(classes)} {...restProps}>
            <div className={loading ? styles.transparentLabel : undefined}>{label}</div>
            {icon != null ? <div className={styles.greetButtonIcon}>{icon}</div> : null}
            <SoftLoader customStyles={{ container: styles.loaderContainer, spinner: styles.loaderSpinner }} />
        </div>
    )
}

export const GreetFileInputToBase64Button = ({ id, label, base64Callback, type = 'secondary', ...rest }: GreetFileInputButtonProps) => {
    const styles = useGreetButtonStyle()
    const { className } = rest

    const authorizedFileTypes = ['image/png', 'image/jpg', 'image/jpeg']

    const getBase64 = (file: File) => {
        return new Promise((resolve: (base64: string) => void) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                if (reader.result !== null) {
                    resolve(reader.result as string)
                }
            }
        })
    }

    const choosePicture = async (ev: Event) => {
        if (ev.target !== null) {
            const files = (ev.target as HTMLInputElement).files
            if (files !== null && files.length > 0) {
                const file = files[0]
                if (authorizedFileTypes.includes(file.type)) {
                    const base64 = await getBase64(file)
                    base64Callback(base64)
                }
            }
        }
    }

    return (
        <>
            <input
                {...rest}
                className={styles.greetInputFileButton}
                type='file'
                id={id}
                onChange={(ev: any) => {
                    choosePicture(ev)
                }}
            />
            <label className={classnames([styles.greetButton, styles[type], className])} htmlFor={id}>
                {label}
            </label>
        </>
    )
}

export const GreetRadioButton = <T extends FieldValues>({ id, name, value, label, required = false, register }: GreetRadioButtonProps<T>) => {
    const styles = useGreetButtonStyle()

    return (
        <div className={styles.radioContainer}>
            <RadioButton id={id} name={name} value={value} required={required} register={register} />
            {label != null ? <label htmlFor={id}>{label}</label> : null}
        </div>
    )
}

export const GreetSubmitButton = ({ label, type = 'secondary', disabled = false, loading = false, ...rest }: GreetButtonProps) => {
    const styles = useGreetButtonStyle({ isLoading: loading })
    const { className, ...restProps } = rest
    return (
        <button className={classnames([styles.greetButton, styles.greetSubmitButton, styles[type], disabled ? styles.disabledButton : '', className])} disabled={disabled} {...restProps} type='submit'>
            <div className={loading ? styles.transparentLabel : undefined}>{label}</div>
            <SoftLoader customStyles={{ container: styles.loaderContainer, spinner: styles.loaderSpinner }} />
        </button>
    )
}
