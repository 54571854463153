import classNames from 'classnames'
import { createUseStyles } from 'react-jss'

interface Props {
    customStyles?: {
        container?: string
        spinner?: string
    }
}

const useStyles = createUseStyles({
    softLoader: {
        width: '50px',
        height: '50px',
    },
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
    rotation: {
        display: 'inline-block',
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: 'var(--gd-secondary-dark-color)',
        borderBottomColor: 'transparent !important',
        borderRadius: '50%',
        animation: '$rotation 1s linear infinite',
    },
})

export default function SoftLoader({ customStyles }: Readonly<Props>) {
    const styles = useStyles()
    return (
        <div className={classNames(styles.softLoader, customStyles?.container)}>
            <div className={classNames(styles.rotation, customStyles?.spinner)}></div>
        </div>
    )
}
