import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { Customer } from '../../models'
import { BrickTypes } from '../../models/Brick'
import swisslife from './swisslife'

const swisslifeBeta: Customer = {
    ...swisslife,
    bricks: [
        ...swisslife.bricks,
        {
            type: BrickTypes.COPILOTE,
            name: 'Copilot',
        },
    ],
    name: 'swisslife_beta',
    disablePwa: true,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES, GreetFeaturingTarget.DRIVE_FOLDER_DELETION, GreetFeaturingTarget.DRIVE_VERSION],
}

export default swisslifeBeta
