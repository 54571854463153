import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { Brick, BrickStyle, BrickTypes } from '../../models/Brick'
import Payload from '../../models/Payload'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import { convertToGCO2 } from '../utils'

interface BrickDoughnutLegendProps {
    bricks: Brick[]
    payload: Payload
    activeBrick: BrickTypes
    setActiveBrick: (brickTypes: BrickTypes) => void
}

const useDoughnutLegendStyles = createUseStyles({
    doughnutLegend: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'space-around',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        doughnutLegend: {
            flexDirection: 'column',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        doughnutLegend: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    },
})

const BrickDoughnutLegend = ({ bricks, payload, activeBrick, setActiveBrick }: BrickDoughnutLegendProps) => {
    const styles = useDoughnutLegendStyles()
    return (
        <div className={styles.doughnutLegend}>
            {bricks.map((brick) => {
                let carbonImpact: number
                let brickStyle = {
                    brickColor: 'var(--gd-brick-teams-color)',
                    activeBrickColor: 'var(--gd-brick-teams-active-color)',
                    icon: IconAssets.TeamsIcon,
                    backgroundColor: 'var(--gd-background-clear-color)',
                    backgroundColorAlt: '#FFFFFF',
                }
                switch (brick.type) {
                    case BrickTypes.EMAIL:
                        carbonImpact = payload.mail.carbonImpact
                        brickStyle = {
                            brickColor: 'var(--gd-brick-email-color)',
                            activeBrickColor: 'var(--gd-brick-email-active-color)',
                            icon: IconAssets.EmailIcon,
                            backgroundColor: 'var(--gd-background-clear-color)',
                            backgroundColorAlt: '#FFFFFF',
                        } as BrickStyle
                        break
                    case BrickTypes.ONEDRIVE:
                        carbonImpact = payload.drive.carbonImpact
                        brickStyle = {
                            brickColor: 'var(--gd-brick-onedrive-color)',
                            activeBrickColor: 'var(--gd-brick-onedrive-active-color)',
                            icon: IconAssets.OneDriveIcon,
                            backgroundColor: 'var(--gd-background-clear-color)',
                            backgroundColorAlt: '#FFFFFF',
                        } as BrickStyle
                        break
                    case BrickTypes.TEAMS:
                        carbonImpact = payload.teams.carbonImpact
                        brickStyle = {
                            brickColor: 'var(--gd-brick-teams-color)',
                            activeBrickColor: 'var(--gd-brick-teams-active-color)',
                            icon: IconAssets.TeamsIcon,
                            backgroundColor: 'var(--gd-background-clear-color)',
                            backgroundColorAlt: '#FFFFFF',
                        } as BrickStyle
                        break
                    case BrickTypes.GMAIL:
                        carbonImpact = payload.mail.carbonImpact
                        brickStyle = {
                            brickColor: 'var(--gd-brick-gmail-color)',
                            activeBrickColor: 'var(--gd-brick-gmail-active-color)',
                            icon: IconAssets.Gmail,
                            backgroundColor: 'var(--gd-background-clear-color)',
                            backgroundColorAlt: '#FFFFFF',
                        } as BrickStyle
                        break
                    case BrickTypes.GDRIVE:
                        carbonImpact = payload.drive.carbonImpact
                        brickStyle = {
                            brickColor: 'var(--gd-brick-gdrive-color)',
                            activeBrickColor: 'var(--gd-brick-gdrive-active-color)',
                            icon: IconAssets.GDrive,
                            backgroundColor: 'var(--gd-background-clear-color)',
                            backgroundColorAlt: '#FFFFFF',
                        } as BrickStyle
                        break
                    case BrickTypes.SHAREDGDRIVE:
                        carbonImpact = payload.sharedDrive.carbonImpact
                        brickStyle = {
                            brickColor: 'var(--gd-brick-shared-gdrive-color)',
                            activeBrickColor: 'var(--gd-brick-shared-gdrive-active-color)',
                            icon: IconAssets.SharedGDrive,
                            backgroundColor: 'var(--gd-background-clear-color)',
                            backgroundColorAlt: '#FFFFFF',
                        } as BrickStyle
                        break
                    case BrickTypes.COPILOTE:
                        carbonImpact = payload.copilote?.carbonImpact ?? 0
                        brickStyle = {
                            brickColor: 'var(--gd-brick-copilote-color)',
                            activeBrickColor: 'var(--gd-brick-copilote-active-color)',
                            icon: IconAssets.Copilote,
                            backgroundColor: 'var(--gd-background-clear-color)',
                            backgroundColorAlt: '#FFFFFF',
                        } as BrickStyle
                        break
                    default:
                        carbonImpact = 0
                        brickStyle = {
                            brickColor: 'var(--gd-brick-teams-color)',
                            activeBrickColor: 'var(--gd-brick-teams-active-color)',
                            icon: IconAssets.TeamsIcon,
                            backgroundColor: 'var(--gd-background-clear-color)',
                            backgroundColorAlt: '#FFFFFF',
                        } as BrickStyle
                }
                return (
                    <div key={brick.type} onClick={() => setActiveBrick(brick.type)}>
                        <BrickLegend carbonImpact={carbonImpact} brick={brick} brickStyle={brickStyle} active={activeBrick === brick.type} />
                    </div>
                )
            })}
        </div>
    )
}

export default BrickDoughnutLegend

interface BrickLegendProps {
    carbonImpact: number
    active: boolean
    brick: Brick
    brickStyle: BrickStyle
}

const useBrickLegendStyles = (brick: BrickStyle) =>
    createUseStyles({
        brickLegend: {
            display: 'flex',
            flexDirection: 'row',
            width: '230px',
            height: '68px',
            justifyContent: 'space-between',
            alignSelf: 'center',
            borderRadius: 'var(--gd-border-small-radius)',
            backgroundColor: brick.backgroundColor,
            alignItems: 'center',
        },
        brickLegendIcon: {
            display: 'flex',
            height: '100%',
            width: '44px',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            backgroundColor: brick.backgroundColor,
            borderRadius: 'var(--gd-border-small-radius) 0 0 var(--gd-border-small-radius)',
        },
        brickLegendMetrics: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: brick.backgroundColor,
            color: 'var(--gd-text-dark-color)',
            borderRadius: 'var(--gd-border-small-radius) 0 0 var(--gd-border-small-radius)',
        },
        brickLegendMetricsActive: {
            backgroundColor: brick.brickColor,
            color: brick.activeBrickColor,
        },
        brickLegendMetricsName: {
            fontSize: 'var(--gd-size-small-body-text)',
            fontWeight: 'bold',
        },
        brickLegendMetricsMeasure: {
            fontSize: 'var(--gd-size-highlighted-title)',
            fontWeight: 'bold',
        },
        brickLegendLisere: {
            width: '12px',
            height: '100%',
            backgroundColor: brick.brickColor,
            borderRadius: '0 var(--gd-border-small-radius) var(--gd-border-small-radius) 0',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            brickLegend: {
                width: '110px',
                height: '50px',
                backgroundColor: brick.backgroundColorAlt,
            },
            brickLegendMetrics: {
                backgroundColor: brick.backgroundColorAlt,
            },
            brickLegendIcon: {
                display: 'none',
                backgroundColor: brick.backgroundColorAlt,
            },
            brickLegendMetricsActive: {
                backgroundColor: brick.brickColor,
            },
        },
        [MediaQueryBreakpoints.TABLETTE]: {
            brickLegend: {
                width: '200px',
            },
        },
    })()

const BrickLegend = ({ carbonImpact, active, brick, brickStyle }: BrickLegendProps) => {
    const styles = useBrickLegendStyles(brickStyle)
    const convertedCarbonImpactValue = convertToGCO2(carbonImpact)
    const BrickIcon = brickStyle.icon
    return (
        <div className={styles.brickLegend}>
            <div className={styles.brickLegendIcon}>
                <BrickIcon />
            </div>
            <div className={classnames(styles.brickLegendMetrics, active ? styles.brickLegendMetricsActive : '')}>
                <span className={styles.brickLegendMetricsName}>{brick.name}</span>
                <span className={styles.brickLegendMetricsMeasure}>
                    {convertedCarbonImpactValue.value} {convertedCarbonImpactValue.measured}
                </span>
            </div>
            <div className={styles.brickLegendLisere}></div>
        </div>
    )
}
