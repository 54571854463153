import { ReactElement } from 'react'
import { Role, User } from '../models'

const requireRoles = (
    user: User | null,
    roles: Role[],
    children: ReactElement,
): ReactElement | null => {
    return rolesIn(user, roles) ? children : null
}

const rolesIn = (user: User | null, roles: Role[]) : boolean => {
    return user != null && (roles ?? []).includes(user!.role)
}

const requireSuperAdmin = (user: User | null, children: ReactElement): ReactElement | null => {
    return requireRoles(user, [Role.SUPER_ADMIN], children)
}

const requireAdmin = (user: User | null, children: ReactElement): ReactElement | null => {
    return requireRoles(user, [Role.SUPER_ADMIN, Role.ADMIN], children)
}

const requireAnimator = (user: User | null, children: ReactElement): ReactElement | null => {
    return requireRoles(user, [Role.SUPER_ADMIN, Role.ADMIN, Role.ANIMATOR], children)
}

const isAtLeastAnimator = (user: User | null): boolean => {
    return rolesIn(user, [Role.SUPER_ADMIN, Role.ADMIN, Role.ANIMATOR])
}

const isRealAdmin = (user: User | null): boolean =>
    [Role.ADMIN, Role.SUPER_ADMIN].includes(user?.realRole ?? Role.SIMPLE)

const isAdmin = (user: User | null): boolean =>
    [Role.ADMIN, Role.SUPER_ADMIN].includes(user?.role ?? Role.SIMPLE)

const isSuperAdmin = (user: User | null): boolean =>
    [Role.SUPER_ADMIN].includes(user?.role ?? Role.SIMPLE)

const isSimple = (user: User | null): boolean => Role.SIMPLE === user?.role
const isAnimator = (user: User | null): boolean => Role.ANIMATOR === user?.role

const isAdminUsingAnimatorMode = (user : User | null): boolean => {
    return isRealAdmin(user) && isAnimator(user)
}

export {
    requireSuperAdmin,
    requireAdmin,
    requireAnimator,
    isRealAdmin,
    isAdmin,
    isSuperAdmin,
    isSimple,
    isAnimator,
    isAdminUsingAnimatorMode,
    isAtLeastAnimator,
}
