import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { MediaQueryBreakpoints } from '../../components/MediaQueryBreakpoints'
import Chart from '../../components/qvt/TeamsConfiguration/Chart'
import TeamsList from '../../components/qvt/TeamsConfiguration/TeamsList'
import GreetTabs from '../../components/share/GreetTabs/GreetTabs'
import { convertToRem } from '../../components/utils'
import { useUser } from '../../contexts'
import { ActiveInactiveTeams, EPeriod, ETeamsAction } from '../../models/QvtTeamsConfiguration'
import UserPayload from '../../models/UserPayload'

interface Props {
    payload: UserPayload | null
    className?: string
}

const useStyles = createUseStyles({
    container: {
        width: '100%',
        height: '100%',
        maxWidth: convertToRem(556),
        display: 'flex',
        flexDirection: 'column',
        border: `${convertToRem(2)} solid var(--gd-background-dark-color)`,
        borderRadius: 'var(--gd-border-normal-radius)',
    },
    tip: {
        marginTop: convertToRem(46),
        paddingLeft: convertToRem(49),
        paddingRight: convertToRem(30),
        fontSize: 'var(--gd-size-small-body-text)',
        color: 'var(--gd-text-dark-color)',
        textAlign: 'center',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        container: {
            maxWidth: 'unset',
        },
        tip: {
            paddingLeft: convertToRem(24),
            paddingRight: convertToRem(15),
        },
    },
})

const useChartTabStyles = createUseStyles({
    container: {
        marginTop: convertToRem(10),
    },
    tabs: {
        marginTop: convertToRem(30),
        justifyContent: 'center',
        columnGap: convertToRem(72),
    },
    panel: {
        paddingLeft: convertToRem(49),
        paddingRight: convertToRem(30),
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE]: {
        panel: {
            paddingLeft: convertToRem(24),
            paddingRight: convertToRem(15),
        },
    },
    [MediaQueryBreakpoints.MOBILE]: {
        tabs: {
            columnGap: convertToRem(15),
        },
    },
})

const useTeamsTabStyles = createUseStyles({
    container: {
        height: '100%',
        marginTop: convertToRem(20),
        rowGap: 'unset',
    },
    tabs: {
        paddingInline: convertToRem(48),
        justifyContent: 'center',
        columnGap: convertToRem(96),
    },
    panel: {
        height: '100%',
        borderTop: `${convertToRem(1)} solid var(--gd-secondary-color)`,
        borderBottomLeftRadius: 'var(--gd-border-normal-radius)',
        borderBottomRightRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: 'var(--gd-background-clear-color)',
    },
    tabItemLabel: {
        paddingBottom: convertToRem(12),
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        tabs: {
            paddingLeft: convertToRem(24),
            paddingRight: convertToRem(15),
            justifyContent: 'center',
            columnGap: convertToRem(24),
        },
    },
})

export default function TeamsConfiguration({ payload, className }: Readonly<Props>) {
    const { lang } = useUser()
    const styles = useStyles()
    const chartTabStyles = useChartTabStyles()
    const teamsTabStyles = useTeamsTabStyles()

    const totalTeams = payload?.qvtTeamsConfiguration?.numberOfTeams ?? 0
    const activeInactiveTeamsByPeriod: Record<EPeriod, ActiveInactiveTeams> = {
        [EPeriod.ONE_MONTH]: {
            activeTeams: payload?.qvtTeamsConfiguration?.oneMonthActiveTeams ?? 0,
            inactiveTeams: totalTeams - (payload?.qvtTeamsConfiguration?.oneMonthActiveTeams ?? 0),
        },
        [EPeriod.ONE_QUARTER]: {
            activeTeams: payload?.qvtTeamsConfiguration?.oneQuarterActiveTeams ?? 0,
            inactiveTeams: totalTeams - (payload?.qvtTeamsConfiguration?.oneQuarterActiveTeams ?? 0),
        },
        [EPeriod.ONE_YEAR]: {
            activeTeams: payload?.qvtTeamsConfiguration?.oneYearActiveTeams ?? 0,
            inactiveTeams: totalTeams - (payload?.qvtTeamsConfiguration?.oneYearActiveTeams ?? 0),
        },
    }

    const teamsByAction: Record<ETeamsAction, string[]> = {
        [ETeamsAction.PIN]: payload?.qvtTeamsConfiguration?.teamsToPin ?? [],
        [ETeamsAction.LEAVE]: payload?.qvtTeamsConfiguration?.teamsToLeave ?? [],
        [ETeamsAction.ARCHIVE]: payload?.qvtTeamsConfiguration?.teamsToArchive ?? [],
    }

    function buildTabId(suffix: string) {
        return 'tab-' + suffix
    }

    return (
        <div className={classNames(styles.container, className)}>
            <GreetTabs
                classNames={{ tabs: chartTabStyles.tabs, panel: chartTabStyles.panel }}
                initialTab={buildTabId(EPeriod.ONE_MONTH)}
                tabs={[
                    { id: buildTabId(EPeriod.ONE_MONTH), label: lang.goal.qvt.teamsConfiguration.tabs.oneMonth, model: EPeriod.ONE_MONTH },
                    { id: buildTabId(EPeriod.ONE_QUARTER), label: lang.goal.qvt.teamsConfiguration.tabs.oneQuarter, model: EPeriod.ONE_QUARTER },
                    { id: buildTabId(EPeriod.ONE_YEAR), label: lang.goal.qvt.teamsConfiguration.tabs.oneYear, model: EPeriod.ONE_YEAR },
                ]}
                panels={[
                    {
                        id: buildTabId(EPeriod.ONE_MONTH),
                        component: (
                            <Chart
                                activeTeams={{
                                    label: lang.goal.qvt.teamsConfiguration.chartLegend.activeTeams,
                                    value: activeInactiveTeamsByPeriod.ONE_MONTH.activeTeams,
                                    backgroundColor: 'var(--gd-primary-color)',
                                }}
                                inactiveTeams={{
                                    label: lang.goal.qvt.teamsConfiguration.chartLegend.inactiveTeams,
                                    value: activeInactiveTeamsByPeriod.ONE_MONTH.inactiveTeams,
                                    backgroundColor: 'var(--gd-background-clear-color)',
                                }}
                                totalTeams={totalTeams}
                            />
                        ),
                    },
                    {
                        id: buildTabId(EPeriod.ONE_QUARTER),
                        component: (
                            <Chart
                                activeTeams={{
                                    label: lang.goal.qvt.teamsConfiguration.chartLegend.activeTeams,
                                    value: activeInactiveTeamsByPeriod.ONE_QUARTER.activeTeams,
                                    backgroundColor: 'var(--gd-primary-color)',
                                }}
                                inactiveTeams={{
                                    label: lang.goal.qvt.teamsConfiguration.chartLegend.inactiveTeams,
                                    value: activeInactiveTeamsByPeriod.ONE_QUARTER.inactiveTeams,
                                    backgroundColor: 'var(--gd-background-clear-color)',
                                }}
                                totalTeams={totalTeams}
                            />
                        ),
                    },
                    {
                        id: buildTabId(EPeriod.ONE_YEAR),
                        component: (
                            <Chart
                                activeTeams={{
                                    label: lang.goal.qvt.teamsConfiguration.chartLegend.activeTeams,
                                    value: activeInactiveTeamsByPeriod.ONE_YEAR.activeTeams,
                                    backgroundColor: 'var(--gd-primary-color)',
                                }}
                                inactiveTeams={{
                                    label: lang.goal.qvt.teamsConfiguration.chartLegend.inactiveTeams,
                                    value: activeInactiveTeamsByPeriod.ONE_YEAR.inactiveTeams,
                                    backgroundColor: 'var(--gd-background-clear-color)',
                                }}
                                totalTeams={totalTeams}
                            />
                        ),
                    },
                ]}
            />
            <div className={styles.tip}>{lang.goal.qvt.teamsConfiguration.tip}</div>
            <GreetTabs
                classNames={{ container: teamsTabStyles.container, tabs: teamsTabStyles.tabs, panel: teamsTabStyles.panel }}
                tabItemCommonClasseNames={{ label: teamsTabStyles.tabItemLabel }}
                initialTab={buildTabId(ETeamsAction.PIN)}
                tabs={[
                    { id: buildTabId(ETeamsAction.PIN), label: lang.goal.qvt.teamsConfiguration.tabs.teamsToPin, model: ETeamsAction.PIN },
                    { id: buildTabId(ETeamsAction.LEAVE), label: lang.goal.qvt.teamsConfiguration.tabs.teamsToLeave, model: ETeamsAction.LEAVE },
                    { id: buildTabId(ETeamsAction.ARCHIVE), label: lang.goal.qvt.teamsConfiguration.tabs.teamsToArchive, model: ETeamsAction.ARCHIVE },
                ]}
                panels={[
                    {
                        id: buildTabId(ETeamsAction.PIN),
                        component: (
                            <TeamsList
                                teams={teamsByAction.PIN}
                                noTeamsLabel={lang.goal.qvt.teamsConfiguration.teams.toPin.noTeams}
                                tip={lang.goal.qvt.teamsConfiguration.teams.toPin.tip}
                                suggestionLabel={lang.goal.qvt.teamsConfiguration.teams.suggest}
                            />
                        ),
                    },
                    {
                        id: buildTabId(ETeamsAction.LEAVE),
                        component: (
                            <TeamsList
                                teams={teamsByAction.LEAVE}
                                noTeamsLabel={lang.goal.qvt.teamsConfiguration.teams.toLeave.noTeams}
                                caption={lang.goal.qvt.teamsConfiguration.teams.toLeave.caption}
                                tip={lang.goal.qvt.teamsConfiguration.teams.toLeave.tip}
                                suggestionLabel={lang.goal.qvt.teamsConfiguration.teams.suggest}
                            />
                        ),
                    },
                    {
                        id: buildTabId(ETeamsAction.ARCHIVE),
                        component: (
                            <TeamsList
                                teams={teamsByAction.ARCHIVE}
                                noTeamsLabel={lang.goal.qvt.teamsConfiguration.teams.toArchive.noTeams}
                                caption={lang.goal.qvt.teamsConfiguration.teams.toArchive.caption}
                                tip={lang.goal.qvt.teamsConfiguration.teams.toArchive.tip}
                                suggestionLabel={lang.goal.qvt.teamsConfiguration.teams.suggest}
                            />
                        ),
                    },
                ]}
            />
        </div>
    )
}
