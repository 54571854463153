import { createUseStyles } from 'react-jss'
import { useInstance, useUser } from '../../contexts'
import { useFetch } from '../../services/api/useFetch'
import { isAdmin, isRealAdmin } from '../rolesUtils'

const useStyles = (isAdmin: boolean) =>
    createUseStyles({
        announcementsHeader: {
            display: 'flex',
            position: 'sticky',
            top: isAdmin ? '31px' : '0',
            zIndex: '101',
            width: '100%',
            height: '30px',
            backgroundColor: 'var(--gd-primary-color)',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
        },
        announcementsHeaderText: {
            color: 'var(--gd-text-clear-color)',
            overflow: 'auto',
            margin: '0 10px',
            whiteSpace: 'nowrap',
        },
    })()

export default function AnnouncementsHeader() {
    const { user } = useUser()
    const { customer } = useInstance()
    const isUserAdmin = isRealAdmin(user) && isAdmin(user)
    const styles = useStyles(isUserAdmin)
    const { data } = useFetch({ endpoint: 'announcements', params: { platformName: customer.platformName } })

    if (data?.message == null) {
        return null
    }

    return (
        <div className={styles.announcementsHeader}>
            <span className={styles.announcementsHeaderText}>{data?.message}</span>
        </div>
    )
}
