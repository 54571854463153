import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useParams } from 'react-router-dom'
import IconAssets from '../assets/icon/IconAssets'
import CarbonCumulGraph from '../components/CarbonCumulGraph'
import CarbonFootprint from '../components/CarbonFootprint'
import CarbonFootprintRate from '../components/CarbonFootprintRate'
import ChallengeComponent from '../components/ChallengeComponent'
import { GroupGoalPresentation } from '../components/GoalPresentation'
import { MediaQueryBreakpoints } from '../components/MediaQueryBreakpoints'
import Coaching from '../components/coaching/Coaching'
import { isAdminUsingAnimatorMode } from '../components/rolesUtils'
import useQueryParams from '../components/useQueryParams'
import { filterCohortsOnIds } from '../components/utils'
import { useStore, useUser } from '../contexts'
import { EGoalType } from '../models'
import { Cohort } from '../models/Cohort'
import CohortPayload from '../models/CohortPayload'
import { CollectiveGoalDto } from '../models/dto/GoalDto'
import CohortMembers from './CohortMembers'

interface StateCohort {
    cohorts: Cohort[]
    currentCohort?: Cohort
    currentCohortPayload?: CohortPayload
    currentCohortGoal?: CollectiveGoalDto
}

const useStyles = createUseStyles({
    container: {
        width: '100%',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: '30px',
    },
    presentationHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        rowGap: '50px',
        columnGap: '30px',
    },
    carbonFootprintContainerCoaches: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: '50px',
        columnGap: '30px',
    },
    challengeCarbonCumulGraphContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        rowGap: '30px',
        columnGap: '30px',
    },
    mobileContainer: {
        display: 'none',
    },
    mobileSubContainer: {
        display: 'none',
    },
    challengeCarbonCumulGraphContentContainer: {
        display: 'none',
    },
    challengeCarbonCumulGraphContentContainerMenu: {
        display: 'none',
    },
    challengeCarbonCumulGraphContentContainerMenuItem: {
        display: 'none',
    },
    challengeCarbonCumulGraphContentContainerMenuActiveItem: {
        display: 'none',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        container: {
            display: 'none',
        },
        mobileContainer: {
            maxWidth: '100vw',
            width: '100%',
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            gap: '30px',
        },
        mobileSubContainer: {
            display: 'flex',
            gap: '20px',
            borderRadius: 'var(--gd-border-normal-radius)',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: 'var(--gd-background-clear-color)',
        },
        presentationHeaderContainer: {
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0 20px',
            gap: '20px',
        },
        carbonFootprintContainerCoaches: {
            padding: '20px',
            backgroundColor: 'var(--gd-main-app-background-color)',
        },
        challengeCarbonCumulGraphContainer: {
            backgroundColor: 'var(--gd-background-clear-color)',
            borderRadius: 'var(--gd-border-normal-radius) var(--gd-border-normal-radius) 0px 0px',
        },
        challengeCarbonCumulGraphContentContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            width: '100%',
            padding: '20px',
            rowGap: '30px',
        },
        challengeCarbonCumulGraphContentContainerMenu: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '36px',
            margin: 'auto',
            backgroundColor: '#FFFFFF',
            width: '75%',
            borderRadius: 'var(--gd-border-big-radius)',
        },
        challengeCarbonCumulGraphContentContainerMenuItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '30%',
            borderRadius: 'var(--gd-border-big-radius)',
        },
        challengeCarbonCumulGraphContentContainerMenuActiveItem: {
            backgroundColor: 'var(--gd-secondary-dark-color)',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        presentationHeaderContainer: {
            flexDirection: 'row',
            rowGap: '20px',
            columnGap: '30px',
            flexWrap: 'wrap',
            padding: '0 20px',
        },
        challengeCarbonCumulGraphContainer: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'unset',
            width: '100%',
            padding: '0 20px',
        },
        carbonFootprintContainerCoaches: {
            padding: '0 20px',
        },
    },
})

export default function CollectiveGoal() {
    const { user, lang } = useUser()
    const styles = useStyles()
    const { langParam, customerParam, goalId } = useParams<any>()
    const queryParams = useQueryParams()
    const { state } = useStore()
    const history = useHistory()
    const [stateCohort, setStateCohort] = useState<StateCohort>({
        cohorts: [],
        currentCohort: undefined,
        currentCohortPayload: undefined,
        currentCohortGoal: undefined,
    })
    const [activeTab, setActiveTab] = useState<GoalTab>(GoalTab.CARBON_CUMUL_GRAPH)
    const [hasIndividualGoal, setHasIndividualGoal] = useState<boolean>(false)
    const { userPayload, cohortsPayload } = state
    const cohortName = decodeURIComponent(queryParams.get('cohortName') ?? '')
    useEffect(() => {
        const currentGoals = userPayload?.goals?.filter((item) => item.egoal === goalId) ?? []
        let userCohorts = currentGoals
            .map((item) =>
                (item?.collectiveGoals ?? []).map(
                    (val) =>
                        ({
                            cohortId: val.cohortId,
                            cohortName: val.cohortName,
                            cohortDisplayName: val.cohortDisplayName,
                            link: val.link,
                        } as Cohort),
                ),
            )
            .reduce((acc, val) => [...acc, ...val], [])

        if (isAdminUsingAnimatorMode(user)) {
            userCohorts = filterCohortsOnIds(userCohorts, user?.cohortIds)
        }

        const userGoals = userPayload?.goals.find((goal) => goal?.egoal === goalId)
        const currentCohort: Cohort | undefined = userCohorts?.find((item) => [item.cohortName, item.cohortId].includes(cohortName))
        const currentCohortPayload = cohortsPayload?.find((cp) => [cp.cohortName, cp.cohortId].includes(cohortName))
        const currentCohortGoal = userGoals?.collectiveGoals?.find((item) => [item.cohortName, item.cohortId].includes(cohortName))
        setHasIndividualGoal(userGoals?.individualGoal != null)

        if (currentCohort && currentCohortPayload) {
            setStateCohort({
                cohorts: userCohorts,
                currentCohort: currentCohort,
                currentCohortPayload: currentCohortPayload,
                currentCohortGoal: currentCohortGoal,
            })
        }
        if (currentCohort == null || !cohortName) {
            return history.replace(`/${langParam}/${customerParam}`)
        }
    }, [userPayload, cohortsPayload, cohortName])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.presentationHeaderContainer}>
                    <GroupGoalPresentation cohortId={stateCohort.currentCohort?.cohortId} goalId={goalId} />
                    <CarbonFootprintRate payload={stateCohort.currentCohortPayload} />
                    <CohortMembers goalId={goalId} cohortId={stateCohort.currentCohort?.cohortName} showJoinMessage={!hasIndividualGoal} />
                </div>
                <div className={styles.challengeCarbonCumulGraphContainer}>
                    <CarbonCumulGraph
                        title={lang.goal.carbonCumul.titleGroup}
                        payload={stateCohort.currentCohortPayload}
                        carbonEmissionsLeftText={lang.goal.carbonCumul.leftTextGroup}
                        carbonEmissionsText={lang.goal.carbonCumul.carbonEmissions(EGoalType.COLLECTIVE)}
                    />
                    <ChallengeComponent cohortId={stateCohort.currentCohort?.cohortName ?? ''} />
                </div>

                <div className={styles.carbonFootprintContainerCoaches}>
                    <CarbonFootprint payload={stateCohort.currentCohortPayload} title={lang.goal.carbonFootprint.groupetitle} />
                    <Coaching />
                </div>
            </div>
            {/* mobile container */}
            <div className={styles.mobileContainer}>
                <div className={styles.presentationHeaderContainer}>
                    <GroupGoalPresentation cohortId={stateCohort.currentCohort?.cohortId} goalId={goalId} />
                    <CarbonFootprintRate payload={stateCohort.currentCohortPayload} />
                    <CohortMembers goalId={goalId} cohortId={stateCohort.currentCohort?.cohortName} showJoinMessage={!hasIndividualGoal} />
                </div>
                <div className={styles.mobileSubContainer}>
                    <div className={styles.challengeCarbonCumulGraphContainer}>
                        <div className={styles.challengeCarbonCumulGraphContentContainer}>
                            <div className={styles.challengeCarbonCumulGraphContentContainerMenu}>
                                <div
                                    className={classnames([
                                        styles.challengeCarbonCumulGraphContentContainerMenuItem,
                                        activeTab === GoalTab.CARBON_CUMUL_GRAPH ? styles.challengeCarbonCumulGraphContentContainerMenuActiveItem : '',
                                    ])}
                                    onClick={() => setActiveTab(GoalTab.CARBON_CUMUL_GRAPH)}
                                >
                                    <IconAssets.CarbonCumulGraphSubTabIcon active={activeTab === GoalTab.CARBON_CUMUL_GRAPH} />
                                </div>
                                <div
                                    className={classnames([
                                        styles.challengeCarbonCumulGraphContentContainerMenuItem,
                                        activeTab === GoalTab.CHALLENGES ? styles.challengeCarbonCumulGraphContentContainerMenuActiveItem : '',
                                    ])}
                                    onClick={() => setActiveTab(GoalTab.CHALLENGES)}
                                >
                                    <IconAssets.ChallengeSubTabIcon active={activeTab === GoalTab.CHALLENGES} />
                                </div>
                                <div
                                    className={classnames([
                                        styles.challengeCarbonCumulGraphContentContainerMenuItem,
                                        activeTab === GoalTab.DOUGHNUT ? styles.challengeCarbonCumulGraphContentContainerMenuActiveItem : '',
                                    ])}
                                    onClick={() => setActiveTab(GoalTab.DOUGHNUT)}
                                >
                                    <IconAssets.DoughnutSubTabIcon active={activeTab === GoalTab.DOUGHNUT} />
                                </div>
                            </div>
                            <ChallengeCarbonCumulGraphMobileSelector activeTab={activeTab} stateCohort={stateCohort} lang={lang} />
                        </div>
                    </div>
                    <div className={styles.carbonFootprintContainerCoaches}>
                        <Coaching />
                    </div>
                </div>
            </div>
        </>
    )
}

enum GoalTab {
    CARBON_CUMUL_GRAPH,
    CHALLENGES,
    DOUGHNUT,
}

const ChallengeCarbonCumulGraphMobileSelector = ({ activeTab, stateCohort, lang }: any) => {
    switch (activeTab) {
        case GoalTab.CARBON_CUMUL_GRAPH:
            return (
                <CarbonCumulGraph
                    title={lang.goal.carbonCumul.titleGroup}
                    payload={stateCohort.currentCohortPayload}
                    carbonEmissionsLeftText={lang.goal.carbonCumul.leftTextGroup}
                    carbonEmissionsText={lang.goal.carbonCumul.carbonEmissions(EGoalType.COLLECTIVE)}
                />
            )
        case GoalTab.CHALLENGES:
            return (
                <div>
                    <ChallengeComponent cohortId={stateCohort.currentCohort?.cohortName ?? ''} />
                </div>
            )
        case GoalTab.DOUGHNUT:
            return <CarbonFootprint payload={stateCohort.currentCohortPayload} title={lang.goal.carbonFootprint.Groupetitle} />
        default:
            return null
    }
}
