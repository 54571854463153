import axios from 'axios'
import forge from 'node-forge'
import { GoalDto, User } from '../models'
import { Badge, BadgeType } from '../models/Badge'
import { Cohort } from '../models/Cohort'
import CohortPayload from '../models/CohortPayload'
import { CollectiveGoalDto } from '../models/dto/GoalDto'
import { addDays, daysInMonth } from './dateUtils'
import { isAdminUsingAnimatorMode } from './rolesUtils'

export function formatDate(date: Array<number>) {
    // 0: year
    // 1: month
    // 2: day
    return `${date[0]}-${date[1]}-${date[2]}`
}

// Formatte un nombre en utilisant les différentes règles définie (Arrondi, espacement, nombre significatif)
// Ajout d'un espacement tous les 3 digits
// Affiche les 2 nombres significatifs
export const numberFormatter = (input: number | string, forceSign = false) => {
    const number = Number(input)
    let numberFormatterOptions = {
        decimal: 'decimal',
        useGrouping: 'true',
        maximumFractionDigits: 0,
        trailingZeroDisplay: 'stripIfInteger',
        roundingMode: 'halfExpand',
        signDisplay: forceSign ? 'exceptZero' : 'auto',
    } as any

    if (number < 1) {
        numberFormatterOptions = {
            ...numberFormatterOptions,
            maximumSignificantDigits: 2,
        }
    } else if (number < 10) {
        numberFormatterOptions = {
            ...numberFormatterOptions,
            maximumFractionDigits: 1,
        }
    }
    // Idealement utiliser la local du navigateur
    return new Intl.NumberFormat('fr-FR', { ...numberFormatterOptions }).format(number ?? 0).replaceAll(',', '.')
}

/**
 * From Arnaud : Pas de chiffre à virgule, choisir la bonne unité, séparer les milliers, préférer 1 263 kg à 1 t.
 * (Afficher X XXX et pas X t, mais XX t plutot que XX XXX kg)
 */
export function convertToGCO2(gramsNumber: number): {
    value: string
    measured: string
} {
    let value, measured
    if (gramsNumber >= 10_000_000) {
        value = numberFormatter(gramsNumber / 1_000_000)
        measured = 'tCO₂e'
    } else if (gramsNumber >= 10_000) {
        value = numberFormatter(gramsNumber / 1_000)
        measured = 'kgCO₂e'
    } else {
        value = numberFormatter(gramsNumber)
        measured = 'gCO₂e'
    }

    return { value, measured }
}

export function octetsConverter(bytes: number, languageCode: string) {
    if (bytes === 0) {
        return { value: 0, measured: { 'en': 'byte', 'fr': 'octet' }[languageCode] }
    }

    const sizes: Record<string, string[]> = {
        'en': ['bytes', 'KB', 'MB', 'GB', 'TB'],
        'fr': ['octets', 'Ko', 'Mo', 'Go', 'To'],
    }
    const k = 1024
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return {
        value: numberFormatter(bytes / Math.pow(k, i)),
        measured: sizes[languageCode][i],
    }
}

export function isEmailValide(email: string): boolean {
    return email?.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) != null
}

/**
 * Returns true if the provided cohort payload matches the provided cohort.
 * This method consider null or undefined cohort as not matching, hence it such edge case it will return false
 * @param cohortPayload cohortPayload to compare
 * @param cohort cohort to compare
 * @returns true if cohort payload matches provided cohort, false otherwise
 */
export function matchingCohort(cohortPayload: Cohort | CohortPayload, cohort: Cohort | CollectiveGoalDto | undefined): boolean {
    if (!cohortPayload || !cohort) {
        return false
    }
    return cohortPayload.cohortName === cohort.cohortName || cohortPayload.cohortId === cohort.cohortId || cohortPayload.cohortId === cohort.cohortName
}

/**
 * Filters the provided Cohort array to remove all items from
 * which name of ID does not match one of the string of the provided array
 * @param cohorts The cohorts to filter
 * @param cohortIds Cohorts IDs to find either as ID or NAME in the cohort array
 * @returns Array representing the filtered provided cohort array
 */
export function filterCohortsOnIds(cohorts: Cohort[], cohortIds: string[] | undefined) {
    if (!cohorts || !cohortIds) {
        return []
    }
    return cohorts.filter((c) => cohortIds.includes(c.cohortName) || cohortIds.includes(c.cohortId))
}

export function filterCollectiveGoalsOnUserRole(goal: GoalDto | undefined, user: User | null) {
    if (!goal || !goal?.collectiveGoals) {
        return []
    }

    if (isAdminUsingAnimatorMode(user)) {
        return goal.collectiveGoals?.filter((g) => user?.cohortIds?.includes(g.cohortName) || user?.cohortIds?.includes(g.cohortId))
    } else {
        return goal.collectiveGoals
    }
}

export const capitalizeFirstLetter = (value: string) => {
    if (!value || value.length < 2) {
        return value
    }
    return value.charAt(0).toUpperCase() + value.slice(1).toLocaleLowerCase()
}

export const positionDecorator = (position: number, locale = 'fr-FR') => {
    if (locale === 'fr-FR') {
        if (position === 1) {
            return 'ère'
        }
        return 'ème'
    }

    if (locale === 'en-GB') {
        if ([1, 2, 3].includes(position % 10) && ![11, 12, 13].includes(position)) {
            return { 1: 'st', 2: 'nd', 3: 'rd' }[position % 10]
        }
        return 'th'
    }

    return ''
}

function isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item)
}

export const deepMerge = (target: any, ...sources: any): any => {
    if (!sources.length) {
        return target
    }
    const source = sources.shift()
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} })
                }
                deepMerge(target[key], source[key])
            } else {
                Object.assign(target, { [key]: source[key] })
            }
        }
    }
    return deepMerge(target, ...sources)
}
export const rsaEncrypt = async (clearString: string) => {
    try {
        const publicKeyPem = (await axios.get('/rsa/public.pem')).data
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem)
        const encrypted = publicKey.encrypt(clearString, 'RSA-OAEP', {
            md: forge.md.sha256.create(),
        })
        const encryptedString = forge.util.encode64(encrypted)
        return encryptedString
    } catch (err) {
        console.error(err)
        return clearString
    }
}

export function getCohortName(badge: Badge, cohortPayloads: CohortPayload[], userCohorts: Cohort[]): string | null {
    if (cohortPayloads == null || cohortPayloads.length === 0 || badge.type === BadgeType.PLANET_FRIENDLY) {
        return null
    }

    // badge id = userId_type_period_cohortId
    const NB_ID_PARTS = 2
    const SEPERATOR = '_'
    const badgeIdEnd = badge.id.split(badge.type + SEPERATOR)
    if (badgeIdEnd.length < NB_ID_PARTS) {
        return null
    }

    const NB_ID_END_PARTS = 2
    const badgeIdEndParts = badgeIdEnd[NB_ID_PARTS - 1].split(SEPERATOR)

    // cohort id may contain the seperator (_)
    const badgeCohortId = badgeIdEndParts.slice(NB_ID_END_PARTS - 1).join(SEPERATOR)
    return userCohorts.find((item) => item.cohortId === badgeCohortId)?.cohortDisplayName ?? cohortPayloads.find((p) => p.cohortId === badgeCohortId)?.cohortName ?? null
}

export function getPeriod(badge: Badge) {
    // period = challenge start day

    // planet friendly
    if ([BadgeType.PLANET_FRIENDLY].includes(badge.type)) {
        return {
            from: new Date(badge.period),
            to: addDays(badge.period, daysInMonth(new Date(badge.period)) - 1),
        }
    }

    return {
        from: new Date(badge.period),
        to: addDays(badge.period, 4), // days in week = 5
    }
}

// css utils
export function convertToRem(pixel: number): string {
    // 1rem = 16px
    return pixel / 16 + 'rem'
}

export function getStringTimeAccordingToSlot(slot: number) {
    if (slot == null) {
        return ''
    }
    if (slot % 2 === 0) {
        return `${String(slot / 2)}h`
    } else {
        const hour = (slot - 1) / 2
        return `${String(hour)}h30`
    }
}

export const extractFirstNameFromMail = (email: string) => {
    const emailParts = email.split('@')
    if (emailParts.length == 0) {
        return null
    }

    const userEmail = emailParts[0].split('.')
    if (userEmail.length <= 1) {
        return null
    }

    return capitalizeFirstLetter(userEmail[0])
}

export const extractFullNameFromMail = (email: string) => {
    const emailParts = email.split('@')
    if (emailParts.length == 0) {
        return null
    }

    const userEmail = emailParts[0].split('.')
    if (userEmail.length <= 1) {
        return null
    }

    if (userEmail.length == 1) {
        return capitalizeFirstLetter(userEmail[0])
    }
    return userEmail.map((part) => capitalizeFirstLetter(part)).join(' ')
}
