import { createUseStyles } from 'react-jss'
import { MediaQueryBreakpoints } from '../../MediaQueryBreakpoints'
import { convertToRem } from '../../utils'
import { Doughnut } from 'react-chartjs-2'

interface Props {
    dataset: { data: number[]; colors: string[] }
}

const useStyles = createUseStyles({
    canvas: {
        marginTop: convertToRem(27),
        display: 'inline-block',
        position: 'relative',
        width: convertToRem(148),
        height: convertToRem(148),
    },
    [MediaQueryBreakpoints.MOBILE]: {
        canvas: {
            width: '40%',
            height: '40%',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        canvas: {
            width: '30%',
            height: '30%',
        },
    },
})

export default function Chart({ dataset }: Readonly<Props>) {
    const styles = useStyles()

    return (
        <div className={styles.canvas}>
            <Doughnut
                data={{
                    datasets: [
                        {
                            data: dataset.data,
                            backgroundColor: dataset.colors,
                        },
                    ],
                }}
                updateMode='resize'
                options={{
                    cutout: '60%',
                    hover: { mode: undefined },
                    plugins: {
                        tooltip: {
                            enabled: false,
                        },
                        legend: {
                            display: false,
                        },
                    },
                }}
            />
        </div>
    )
}
