import React, { useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useParams } from 'react-router-dom'
import { useStore, useUser } from '../contexts'
import { DailyStatusChallenge, DigitalCleanupDayChallenge, DriveChallenge, EGoal, ImageHuntChallenge, User, VideoHuntChallenge } from '../models'
import { BadgeType } from '../models/Badge'
import { Challenge } from '../models/Challenge'
import { ChallengeStatus } from '../models/UserPayload'
import { useFetch } from '../services/api/useFetch'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import Popup from './Popup'
import SetupChallenge from './SetupChallenge'
import SoftLoader from './SoftLoader'
import Title from './Title'
import AddChallenge from './challenge/AddChallenge'
import ChallengeDetails from './challenge/ChallengeDetails'
import DayCounter from './challenge/DayCounter'
import NoChallenge from './challenge/NoChallenge'
import OngoingChallenge from './challenge/OngoingChallenge'
import DailyStatus from './challenge/environmental-challenges/DailyStatus'
import DigitalCleanupDay from './challenge/environmental-challenges/DigitalCleanupDay'
import SpaceCleaning from './challenge/environmental-challenges/SpaceCleaning'
import { addDays, areOnSameWeek, areSame, isAfter, isBefore } from './dateUtils'
import { isAdmin, isAtLeastAnimator, isSimple, requireAnimator } from './rolesUtils'

const useStyles = createUseStyles({
    challengeComponent: {
        display: 'flex',
        flexDirection: 'column',
        width: '35%',
        maxWidth: '400px',
        gap: '1rem',
    },
    container: ({ blackAndWhite }: { blackAndWhite: boolean }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        justifyItems: 'center',
        height: '313px',
        filter: blackAndWhite ? 'grayscale(100%)' : 'unset',
    }),
    arrowIcon: {
        width: '20px',
        height: '20px',
        alignSelf: 'center',
        cursor: 'pointer',
        margin: '10px',
    },
    carousselContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
    },
    contentContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    title: {
        display: 'block',
    },
    mobileTitle: {
        display: 'none',
    },
    softLoader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        challengeComponent: {
            width: '100%',
            padding: '10px 0',
        },
        title: {
            display: 'none',
        },
        mobileTitle: {
            display: 'block',
            alignContent: 'center',
            textAlign: 'center',
            fontSize: 22,
            lineHeight: '25px',
            color: 'var(--gd-secondary-dark-color)',
            fontWeight: 'bold',
            paddingBottom: '15px',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        challengeComponent: {
            flexDirection: 'column',
            width: '100%',
            maxWidth: 'unset',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'unset',
            alignItems: 'flex-start',
            justifyItems: 'unset',
        },
    },
})

export default function ChallengeComponent({ cohortId }: ChallengeProps) {
    const { state } = useStore()
    const { user, lang } = useUser()
    const [show, setShow] = useState(false) // to show challenge setup popup
    const [challenges, setChallenges] = useState<Challenge[]>([])
    const { goalId } = useParams<{ goalId: EGoal }>()
    const { loading } = useFetch({
        endpoint: 'list-challenge-by-cohort',
        params: { cohortId: cohortId ?? null, goal: goalId },
        onSuccess(data) {
            setChallenges(filterChallenges(data))
        },
    })
    const styles = useStyles({ blackAndWhite: isAdminAndNotCohortMember(user, cohortId) })

    const commingChallenges = useMemo(() => getCommingChallenges(challenges), [challenges])
    const challengeStatus = useMemo(() => getChallengeStatus(state.userPayload?.challengeStatuses ?? [], cohortId), [challenges, state.userPayload?.challengeStatuses])
    const ongoingChallenge = useMemo(() => (isAdminAndNotCohortMember(user, cohortId) ? getOngoingChallenge(challenges) : null), [challenges])
    const commingChallenge = useMemo(() => (commingChallenges.length > 0 ? commingChallenges[0] : null), [commingChallenges])
    const challenge = useMemo(
        () => [challengeStatus, ongoingChallenge, commingChallenge].find((challenge) => challenge != null) ?? null,
        [challengeStatus, ongoingChallenge, commingChallenge],
    )

    if (!cohortId) {
        return null
    }

    const wrapWithChallengeDetails = (title: string, children: React.ReactElement) => {
        return (
            <ChallengeDetails
                title={title}
                component={children}
                onToothWheel={() => {
                    if (isAtLeastAnimator(user)) {
                        setShow(true)
                    }
                }}
            />
        )
    }

    const renderStatusChallenge = () => {
        if (challengeStatus == null) return null

        switch (challengeStatus.badgeType) {
            case BadgeType.MAIL:
            case BadgeType.INTERN_ATTACHMENT:
            case BadgeType.MAILSHOT:
                return <DailyStatus key={challengeStatus.id} challenge={challengeStatus as DailyStatusChallenge} />
            case BadgeType.DRIVE:
            case BadgeType.IMAGE_HUNT:
            case BadgeType.VIDEO_HUNT:
                return <SpaceCleaning key={challengeStatus.id} challenge={challengeStatus as DriveChallenge | ImageHuntChallenge | VideoHuntChallenge} />
            case BadgeType.DIGITAL_CLEANUP_DAY:
                return <DigitalCleanupDay key={challengeStatus.id} challenge={challengeStatus as DigitalCleanupDayChallenge} />
            default:
                return null
        }
    }

    const renderChallenges = () => {
        const label = challenge?.badgeType ? lang.goal.challenge.label(challenge.badgeType) : ''

        return wrapWithChallengeDetails(
            label,
            <div className={styles.carousselContainer}>
                <div className={styles.contentContainer}>
                    {challengeStatus != null ? (
                        renderStatusChallenge()
                    ) : ongoingChallenge != null ? (
                        <OngoingChallenge challenge={ongoingChallenge} />
                    ) : commingChallenge != null ? (
                        <DayCounter key={commingChallenge.id} challenge={commingChallenge} />
                    ) : null}
                </div>
            </div>,
        )
    }

    const orderByDate = (challenges: Challenge[]): Challenge[] => {
        const sortedChallenges = challenges.sort((a: Challenge, b: Challenge) => {
            if (a.startDate == null || b.startDate == null) {
                return NaN
            }
            const aPeriod = new Date(a.startDate)
            const bPeriod = new Date(a.startDate)
            return aPeriod.getTime() - bPeriod.getTime()
        })
        return sortedChallenges
    }

    const addChallengeToList = (challenge: Challenge | null) => {
        if (challenge !== null) {
            setChallenges((prev) => [...prev, challenge])
        }
    }

    const updateChallengeInList = (updatedChallenge: Challenge | null) => {
        if (updatedChallenge !== null) {
            setChallenges((prev) =>
                orderByDate(prev.map((challenge) => (challenge?.id === updatedChallenge?.id || challenge?.id === updatedChallenge?.oldId ? updatedChallenge : challenge))),
            )
        }
    }

    const removeChallenge = (challengeId: string) => {
        setChallenges((prev) => prev.filter((challenge) => challenge.id !== challengeId))
    }

    return (
        <div className={styles.challengeComponent}>
            <div className={styles.title}>
                <Title title={lang.goal.challenge.title} />
            </div>
            <div className={styles.mobileTitle}>{lang.goal.challenge.title}</div>
            {loading ? (
                <div className={styles.softLoader}>
                    <SoftLoader />
                </div>
            ) : (
                <div className={styles.container}>
                    {challenge == null && isSimple(user) ? <NoChallenge text={lang.goal.challenge.noChallenge.simple} textColor={'var(--gd-primary-color)'} /> : null}
                    {challenge == null && isAtLeastAnimator(user) ? <AddChallenge text={lang.goal.challenge.noChallenge.animator} callback={() => setShow(true)} /> : null}
                    {challenge != null ? renderChallenges() : null}

                    {show
                        ? requireAnimator(
                              user,
                              <Popup onCloseRequest={() => setShow(false)} closeIconColor='#FFF'>
                                  <SetupChallenge
                                      cohortId={cohortId}
                                      challenges={commingChallenges}
                                      addChallenge={(challenge: Challenge) => addChallengeToList(challenge)}
                                      updateChallenge={(challenge: Challenge) => updateChallengeInList(challenge)}
                                      removeChallenge={(challengeId: string) => removeChallenge(challengeId)}
                                  />
                              </Popup>,
                          )
                        : null}
                </div>
            )}
        </div>
    )
}

function filterChallenges(challenges: any): Challenge[] {
    return challenges.filter((challenge: any) => {
        if (challenge.startDate == null) {
            return false
        }
        return true
    })
}

function getCommingChallenges(challenges: Challenge[]): Challenge[] {
    return challenges.filter((challenge) => {
        if (challenge.startDate == null) {
            return false
        }
        return isAfter(new Date(challenge.startDate), new Date())
    })
}

function getOngoingChallenge(challenges: Challenge[]): Challenge | null {
    return challenges.find((challenge: any) => {
        if (challenge.startDate == null) {
            return false
        }
        const isOnSameWeek = areOnSameWeek(new Date(challenge.startDate), new Date())
        const isEndAfterNow = isAfter(addDays(new Date(challenge.endDate), 1), new Date())
        return isOnSameWeek && isEndAfterNow
    }) ?? null
}

function getChallengeStatus(challengeStatuses: ChallengeStatus[], cohortId: string): ChallengeStatus | null {
    return (
        challengeStatuses.find(
            (challengeStatus) =>
                challengeStatus.cohortId === cohortId &&
                (areSame(new Date(challengeStatus.startDate), new Date()) ||
                    areSame(new Date(challengeStatus.endDate), new Date()) ||
                    (isBefore(new Date(challengeStatus.startDate), new Date()) && isAfter(new Date(challengeStatus.endDate), new Date()))),
        ) ?? null
    )
}

function isAdminAndNotCohortMember(user: User | null, cohortId: string): boolean {
    return isAdmin(user) && !isCohortMember(user, cohortId)
}

function isCohortMember(user: User | null, cohortId: string): boolean {
    if (!cohortId || user?.cohortIds == null) {
        return false
    }

    return user.cohortIds.find((id) => id === cohortId) != null
}

interface ChallengeProps {
    cohortId: string
}
