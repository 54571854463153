import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import { useUser } from '../../contexts'
import { requireAnimator } from '../rolesUtils'

interface ChallengeBoxProps {
    title: string
    component?: React.ReactElement
    onToothWheel: () => void
}

const useStyle = createUseStyles({
    challengeDetailscontainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '400px',
        height: '100%',
        backgroundColor: 'var(--gd-primary-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '15%',
    },
    title: {
        color: 'var(--gd-text-clear-color)',
        textAlign: 'center',
        width: '80%',
        margin: '10px 0px 10px 10%',
        fontSize: 18,
        fontWeight: 700,
    },
    iconWrapper: {
        width: '15%',
    },
    icon: {
        width: '38px',
        height: '38px',
        cursor: 'pointer',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '85%',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        body: {
            backgroundColor: '#FFFFFF',
        },
    },
})

export default function ChallengeDetails({ title, component, onToothWheel }: ChallengeBoxProps) {
    const styles = useStyle()
    const { user } = useUser()

    return (
        <div className={styles.challengeDetailscontainer}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <span>{title}</span>
                </div>
                {requireAnimator(
                    user,
                    <div className={styles.iconWrapper} onClick={() => onToothWheel()}>
                        <div className={styles.icon}>
                            <IconAssets.ToothWheel />
                        </div>
                    </div>,
                )}
            </div>
            <div className={styles.body}>{component}</div>
        </div>
    )
}
