import { createUseStyles } from 'react-jss'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'

export interface UsageBoxProps {
    measureValue: string
    measureUnit?: string
    measureDesciption: string
    textColor: string
    primayColor: string
    secondaryColor: string
    icon: JSX.Element
}

const useStyles = (props: UsageBoxProps) =>
    createUseStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: props.primayColor,
            height: '95px',
            width: '165px',
            borderRadius: 'var(--gd-border-small-radius)',
        },
        textWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: props.secondaryColor,
            borderRadius: 'var(--gd-border-small-radius)',
        },
        iconContainer: {
            position: 'relative',
            alignSelf: 'center',
            left: '5px',
            top: 'unset',
            width: '40px',
            height: '40px',
        },
        indicator: {
            fontSize: 'var(--gd-size-highlighted-number)',
            color: props.textColor,
        },
        indicatorUnit: {
            fontSize: 'var(--gd-size-highlighted-small-title)',
        },
        indicatorDescription: {
            fontSize: 'var(--gd-size-highlighted-small-title)',
            color: props.textColor,
            textAlign: 'center',
            width: '90%',
            whiteSpace: 'pre-wrap',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            container: {
                flexDirection: 'column',
                height: '120px',
                maxWidth: '160px',
            },
            iconContainer: {
                left: 'unset',
                top: '15px',
                width: '30px',
                height: '30px',
            },
        },
        [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
            container: {
                flexDirection: 'column',
                height: '120px',
                mawWidth: '175px',
            },
        },
    })()

export default function UsageBox(props: UsageBoxProps) {
    const styles = useStyles(props)
    return (
        <div className={styles.container}>
            <div className={styles.iconContainer}>{props.icon}</div>
            <div className={styles.textWrapper}>
                <h4 className={styles.indicator}>
                    {props.measureValue} <span className={styles.indicatorUnit}>{props.measureUnit}</span>
                </h4>
                <span className={styles.indicatorDescription}>{props.measureDesciption}</span>
            </div>
        </div>
    )
}
