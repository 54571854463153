export default function CaretRight({ fillColor = 'var(--gd-secondary-dark-color)' }: any) {
    return (
        <svg width='100%' height='100%' viewBox='0 0 24 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M22.5 16.0981C24.5 14.9434 24.5 12.0566 22.5 10.9019L5.25 0.942629C3.25 -0.212071 0.75 1.23131 0.75 3.54071L0.75 23.4593C0.75 25.7687 3.25 27.2121 5.25 26.0574L22.5 16.0981Z'
                fill={fillColor}
            />
        </svg>
    )
}
