import { createUseStyles } from 'react-jss'
import { convertToRem } from '../../utils'
import classNames from 'classnames'
import { ReactElement } from 'react'

const useStyles = createUseStyles({
    tab: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'black',
        fontSize: 'var(--gd-size-highlighted-small-title)',
    },
    label: ({ active }: { active: boolean }) => ({
        paddingInline: convertToRem(8),
        paddingBottom: convertToRem(4),
        color: active ? 'var(--gd-secondary-color)' : 'var(--gd-text-dark-color)',
    }),
    bar: {
        position: 'absolute',
        bottom: convertToRem(0),
        width: '100%',
        height: convertToRem(3),
        borderTopLeftRadius: convertToRem(3),
        borderTopRightRadius: convertToRem(3),
        backgroundColor: 'var(--gd-secondary-color)',
        overflow: 'hidden',
    },
})

interface Props {
    active: boolean
    label?: string | ReactElement
    component?: ReactElement
    classNames?: {
        container?: string
        componentContainer?: string
        label?: string
        bar?: string
    }
    onClick?: () => void
}

export default function GreetTab({ label, active, component, onClick, classNames: customClassNames }: Readonly<Props>) {
    const styles = useStyles({ active })

    if (component != null) {
        return (
            <div className={customClassNames?.componentContainer} onClick={onClick}>
                {component}
            </div>
        )
    }

    return (
        <div className={classNames(styles.tab, customClassNames?.container)} onClick={onClick}>
            {typeof label === 'string' ? <div className={classNames(styles.label, customClassNames?.label)}>{label}</div> : label}
            {active ? <div className={classNames(styles.bar, customClassNames?.bar)}></div> : null}
        </div>
    )
}
