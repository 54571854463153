const AwardIcon = () => {
    return (
        <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M25.676 15.8585C25.676 17.2792 25.2547 18.5823 24.5297 19.6699C23.4715 21.2376 21.7961 22.3447 19.8463 22.6289C19.5132 22.6876 19.1702 22.717 18.8175 22.717C18.4648 22.717 18.1219 22.6876 17.7887 22.6289C15.8389 22.3447 14.1635 21.2376 13.1053 19.6699C12.3803 18.5823 11.959 17.2792 11.959 15.8585C11.959 12.0667 15.0257 9 18.8175 9C22.6093 9 25.676 12.0667 25.676 15.8585Z'
            stroke='var(--gd-primary-color)'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M27.8811 25.1371L26.2645 25.5192C25.902 25.6074 25.6178 25.8817 25.5394 26.2443L25.1965 27.6846C25.0103 28.4684 24.011 28.7035 23.4917 28.0863L18.8181 22.717L14.1445 28.0961C13.6252 28.7133 12.6258 28.4782 12.4396 27.6944L12.0967 26.2541C12.0085 25.8915 11.7244 25.6074 11.3717 25.529L9.75501 25.1469C9.01037 24.9705 8.74583 24.0397 9.28471 23.5009L13.1059 19.6797C14.1641 21.2473 15.8395 22.3545 17.7893 22.6386C18.1224 22.6974 18.4654 22.7268 18.8181 22.7268C19.1708 22.7268 19.5137 22.6974 19.8469 22.6386C21.7966 22.3545 23.4721 21.2473 24.5303 19.6797L28.3514 23.5009C28.8903 24.0299 28.6258 24.9607 27.8811 25.1371Z'
            stroke='var(--gd-primary-color)'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M19.3861 12.9L19.9641 14.0561C20.0425 14.2129 20.2483 14.3697 20.4344 14.3991L21.4828 14.5754C22.1491 14.6832 22.3058 15.1731 21.8257 15.6532L21.0125 16.4664C20.8753 16.6036 20.7969 16.8681 20.8459 17.0641L21.0811 18.0733C21.2672 18.8669 20.8459 19.1804 20.1405 18.7591L19.1607 18.181C18.9843 18.0733 18.6904 18.0733 18.514 18.181L17.5343 18.7591C16.8288 19.1706 16.4075 18.8669 16.5936 18.0733L16.8288 17.0641C16.868 16.8779 16.7994 16.6036 16.6622 16.4664L15.849 15.6532C15.3689 15.1731 15.5257 14.693 16.1919 14.5754L17.2403 14.3991C17.4167 14.3697 17.6224 14.2129 17.7008 14.0561L18.2789 12.9C18.563 12.2729 19.0725 12.2729 19.3861 12.9Z'
            stroke='var(--gd-primary-color)'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
    )
}

export default AwardIcon
