import { Lang } from '../../../../models'
import { BadgeType } from '../../../../models/Badge'
import defaultLang from '../../../lang/en'

const LeckoGsuiteEn: Lang = {
    ...defaultLang,
    authentication: {
        ...defaultLang.authentication,
        description: ['Measure the CO2 emissions of your uses on Google to develop sustainable digital work practices'],
        buttonLabel: 'Sign in with Google',
    },
    presentation: {
        ...defaultLang.presentation,
        description: ['Measure the CO2 emissions of your uses on Google to develop sustainable digital work practices'],
    },
    deleteAccountPopup: {
        ...defaultLang.deleteAccountPopup,
        messages: [
            'By confirming, your Greet account will be deleted, your personal data will be erased.',
            'To finalize the operation, we ask you in the next screen to log out of your Google account.',
        ],
    },
    home: {
        ...defaultLang.home,
        victory: {
            ...defaultLang.home.victory,
            badges: {
                ...defaultLang.home.victory.badges,
                drive: {
                    ...defaultLang.home.victory.badges.drive,
                    comparedToLastYear: (removedDataMB: number) => `${removedDataMB} MB deleted in your Drive`,
                },
                imageHunt: {
                    ...defaultLang.home.victory.badges.imageHunt,
                    comparedToLastYear: (deletedImagesVolume: number) => `${deletedImagesVolume} MB of deleted images on your personal Drive `,
                },
                videoHunt: {
                    ...defaultLang.home.victory.badges.imageHunt,
                    comparedToLastYear: (deletedVideosVolume: number) => `${deletedVideosVolume} MB of deleted videos on your personal Drive `,
                },
            },
        },
    },
    goal: {
        ...defaultLang.goal,
        weeklyCleaning: {
            ...defaultLang.goal.weeklyCleaning,
            description: 'Clean up your mailbox and Drive space',
        },
        carbonFootprint: {
            ...defaultLang.goal.carbonFootprint,
            evolution: {
                ...defaultLang.goal.carbonFootprint.evolution,
                details: 'emitted with the sending of emails, the use of Drive and Shared Drive',
            },
        },
        individual: {
            ...defaultLang.goal.individual,
            deleteFolderProposal: {
                ...defaultLang.goal.individual.deleteFolderProposal,
                title: 'Drive Cleaning',
                goToFolder: 'Open in Drive',
            },
        },
        challenge: {
            ...defaultLang.goal.challenge,
            digitalCleanupDay: {
                ...defaultLang.goal.challenge.digitalCleanupDay,
                after: {
                    ...defaultLang.goal.challenge.digitalCleanupDay.after,
                    title: 'Clean up your mailbox and Drive space',
                },
            },
            description(challenge) {
                switch (challenge.badgeType) {
                    case BadgeType.MAIL:
                    case BadgeType.MAILSHOT:
                    case BadgeType.INTERN_ATTACHMENT:
                        return defaultLang.goal.challenge.description(challenge)
                    case BadgeType.DRIVE:
                        return `Delete ${challenge.driveGoalSize} MB in your personal Drive`
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Nettoyez votre boite email et votre espace Drive'
                    case BadgeType.IMAGE_HUNT:
                        return `Delete ${challenge.imageHuntGoalSize} MB of images from your personal Drive this week`
                    case BadgeType.VIDEO_HUNT:
                        return `Delete ${challenge.videosHuntGoalSize} MB of videos from your personal Drive this week`
                    default:
                        return ''
                }
            },
            badgeDescription(badgeType) {
                switch (badgeType) {
                    case BadgeType.MAIL:
                    case BadgeType.MAILSHOT:
                    case BadgeType.INTERN_ATTACHMENT:
                    case BadgeType.PLANET_FRIENDLY:
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return defaultLang.goal.challenge.badgeDescription(badgeType)
                    case BadgeType.DRIVE:
                        return 'Badges related to file deletion in your personal Drive'
                    case BadgeType.IMAGE_HUNT:
                        return 'Badges related to reducing the image volume on your personal Drive'
                    case BadgeType.VIDEO_HUNT:
                        return 'Badges related to reducing the video volume on your personal Drive'
                    default:
                        return ''
                }
            },
        },
    },
    welcomePopupMessage: [
        'Greet application collects profile information from your Google account (last name, first name, photo) as well as usage data from Gmail, Shared Drive, Drive applications to measure their impact on CO2 emissions.',
        'You can delete your account at any time via the menu available by clicking on your profile picture.',
    ],
    badgeMonitor: {
        ...defaultLang.badgeMonitor,
        popup: {
            ...defaultLang.badgeMonitor.popup,
            drive: (removedDataMB: number) => `${removedDataMB} MB deleted in your Drive`,
            imageHunt: (deletedImagesVolume: number) => `${deletedImagesVolume} MB of deleted images on your personal Drive`,
            videoHunt: (deletedVideosVolume: number) => `${deletedVideosVolume} MB of deleted videos on your personal Drive`,
        },
    },
}

export default LeckoGsuiteEn
