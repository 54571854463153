import React from 'react'
import { useInstance } from '../../contexts'

export enum GreetFeaturingTarget {
    RANKING,
    RANKING_VICTORIES,
    DRIVE_FOLDER_DELETION,
    QVT,
    DRIVE_VERSION
}

interface GreetFeaturingComponentProps {
    target?: GreetFeaturingTarget
    onlyTarget?: boolean
    nextDef: (props?: any) => React.ReactElement | null
    legacyDef: (props?: any) => React.ReactElement | null
}

const GreetFeaturingComponent = ({ target = GreetFeaturingTarget.RANKING, onlyTarget = false, nextDef, legacyDef, ...rest }: GreetFeaturingComponentProps) => {
    const { customer } = useInstance()
    const isLegacy = target == null || !customer?.featuring?.includes(target)
    const hideComponent = onlyTarget && !customer?.featuring?.includes(target)
    if (hideComponent) {
        return null
    }
    const nextWithProps = nextDef(rest)
    const legacyWithProps = legacyDef(rest)

    return <>{isLegacy ? legacyWithProps : nextWithProps}</>
}

export default GreetFeaturingComponent
