import { createUseStyles } from 'react-jss'
import IllustrationAssets from '../../assets/illustration/IllustrationAssets'
import { useUser } from '../../contexts'
import { Challenge } from '../../models/Challenge'
import { toDayLiteralMonthString } from '../dateUtils'

interface Props {
    challenge: Challenge | null
}

const useStyles = createUseStyles({
    ongoingChallenge: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: '35px',
        width: '100%',
        height: '100%',
    },
    period: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '35px',
        backgroundColor: '#fff',
        borderRadius: 'var(--gd-border-big-radius)',
        padding: '0px 25px 0px 25px',
    },
    periodText: {
        fontWeight: 700,
        color: 'var(--gd-bw-challenge-text-clear-color)',
    },
    illustrationText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '15px',
        height: '60%',
    },
    illustration: {
        width: '94px',
        height: '99px',
    },
    text: {
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'bold',
        color: 'var(--gd-bw-challenge-text-dark-color)',
    },
})

export default function OngoingChallenge({ challenge }: Props) {
    if (challenge?.startDate == null || challenge.endDate == null) {
        return null
    }

    const { lang } = useUser()
    const styles = useStyles()

    const period = {
        startDate: new Date(challenge.startDate),
        endDate: new Date(challenge.endDate),
    }

    return (
        <div className={styles.ongoingChallenge}>
            <div className={styles.period}>
                <span className={styles.periodText}>
                    {lang.date.range(toDayLiteralMonthString(period.startDate, lang.locale), toDayLiteralMonthString(period.endDate, lang.locale))}
                </span>
            </div>
            <div className={styles.illustrationText}>
                <div className={styles.illustration}>
                    <IllustrationAssets.OngoingChallenge />
                </div>
                <span className={styles.text}>{lang.goal.challenge.ongoingChallenge}</span>
            </div>
        </div>
    )
}
