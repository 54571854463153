import { ReactComponent as ColibrisGoutte } from './ColibrisGoutte.svg'
import { ReactComponent as ColibrisLance } from './ColibrisLance.svg'
import { ReactComponent as ColibrisSeau } from './ColibrisSeau.svg'
import { ReactComponent as ColibrisVerre } from './ColibrisVerre.svg'

export default {
    ColibrisGoutte,
    ColibrisLance,
    ColibrisSeau,
    ColibrisVerre,
}
