import { Fragment, createElement } from 'react'
import { DayOfWeek, toLastYearString, toYearString } from '../../components/dateUtils'
import { EGoalType, Lang } from '../../models'
import { BadgeType } from '../../models/Badge'
import { Challenge } from '../../models/Challenge'
import { tipsFr } from './tipsContent'

const fr: Lang = {
    code: 'fr',
    locale: 'fr-FR',
    display: 'fr',
    headerMenu: {
        info: 'Informations légales',
        help: {
            title: 'Aide',
            link: 'https://lecko.notion.site/Aide-aux-utilisateurs-3a6a5a71434444669543cfc0b155ae5d',
        },
        admin: 'Administration',
        animation: 'Animation',
        logout: 'Se déconnecter',
        deleteAccount: 'Supprimer mon compte',
        administratorAlert: 'Mode Administrateur.',
        animatorAlert: 'Mode Animateur.',
        switchToAnimatorAlert: 'Basculer en mode Animateur',
        switchToAdministratorAlert: 'Revenir en mode Administrateur',
        switchToStandardMode: 'Revenir en mode classique',
        switchToDemoMode: 'Passer en mode démo',
    },
    loader: {
        authentication: 'En cours de connexion...',
        loading: 'Chargement...',
    },
    errors: {
        globalMessage: "Une erreur s'est produite, veuillez réessayer ultérieurement",
    },
    authentication: {
        title: 'Vous avez la capacité de transformer votre organisation !',
        description: ['Mesurez les émissions CO2 de vos usages sur Microsoft 365 pour développer des pratiques numériques de travail tenables sur la durée'],
        buttonLabel: 'Se connecter avec Microsoft 365',
    },
    presentation: {
        title: 'Vous avez la capacité de transformer votre organisation !',
        description: ['Mesurez les émissions CO2 de vos usages sur Microsoft 365 pour développer des pratiques numériques de travail tenables sur la durée'],
    },
    piloteWaiting: {
        title: 'Un peu de patience, le pilote démarre dans quelques jours !',
        description: '',
    },
    deleteAccountPopup: {
        title: 'Suppression de compte',
        messages: [
            'En confirmant, votre compte Greet sera supprimé, vos données personnelles seront effacées.',
            "Pour finaliser l'opération, nous vous demandons dans l'écran suivant de vous déconnecter de votre compte Microsoft 365.",
        ],
        confirmBtnText: 'Continuer',
        cancelBtnText: 'Annuler',
    },
    administration: {
        title: 'Administration',
        subTitle: 'Gestion des cohortes',
        editDescription:
            "Vous devez saisir la totalité des membres de la cohorte telle que souhaitée. La modification des membres de la cohorte ne porte que sur les activités futures. L'historique avec les membres existant préalablement est conservé.",
        globalDescriptionSentence1: (minimumCohortMembersAuthorized: string) =>
            `Une cohorte nécessite ${minimumCohortMembersAuthorized} membres au minimum pour être créée afin de respecter les engagements RGPD.`,
        globalDescriptionSentence2: (domains: string[]) =>
            `Vous devez saisir des emails valides avec ${domains.length > 1 ? `un des domaines suivants: ${domains.join(', ')}.` : `le domaine suivant: ${domains}.`}`,
        globalDescriptionSentence3: "Les équipes reposant sur les cohortes seront visibles des membres depuis l'interface à partir du lendemain.",
        deleteFilter: 'Supprimer les filtres',
        addCohort: 'Ajouter une cohorte',
        fieldName: {
            label: 'Libellé',
            name: 'Nom',
            size: 'Taille',
            lastUpdate: 'Mise à jour',
        },
        secret: {
            invalid: 'Le secret est invalide !',
            confirm: 'Confirmer',
            cancel: 'Annuler',
            title: 'Saisir le secret',
            description: 'Le secret permet de chiffrer et déchiffrer les emails.',
        },
        search: 'Rechercher...',
    },
    home: {
        goals: {
            displayAllGoals: 'Afficher tous les objectifs',
            reduceDisplayedGoals: 'Réduire l’affichage des objectifs',
        },
        victory: {
            title: 'Mes victoires',
            badges: {
                displayAllBadges: 'Afficher toutes mes victoires',
                reduceDisplayedBadges: 'Réduire toutes mes victoires',
                awardPeriod: (from: string, to: string) => `Du ${from} au ${to}`,
                planetFriendly: {
                    title: 'Planet friendly',
                    reducedBy: 'Réduction des émissions de',
                    comparedToLastYear: (decreaseValue: any, lastYear: any) => `${decreaseValue}% par rapport à ${lastYear}`,
                },
                drive: {
                    title: 'Un peu de ménage',
                    reducedBy: '',
                    comparedToLastYear: (removedDataMB: number) => `${removedDataMB} Mo supprimé${removedDataMB > 1 ? 's' : ''} sur votre OneDrive`,
                },
                mail: {
                    title: 'Nos emails au régime',
                    reducedBy: '',
                    comparedToLastYear: (maxAttachementMB: number) => `Une semaine sans envoyer de pièce jointe de plus de ${maxAttachementMB} Mo`,
                },
                digitalCleanupDay: {
                    title: 'Digital cleanup Day',
                    reducedBy: '',
                    comparedToLastYear: (removedDataMB: number) => `${removedDataMB} Mo nettoyé${removedDataMB > 1 ? 's' : ''}`,
                },
                internAttachment: {
                    title: 'Stop aux pièces jointes en interne',
                    reducedBy: '',
                    comparedToLastYear: () => 'Aucune pièce jointe envoyée en interne cette semaine',
                },
                mailShot: {
                    title: 'Pas de publi-postage',
                    reducedBy: '',
                    comparedToLastYear: (mailShotGoal: number) => `Nombre de destinataires par email limité à ${mailShotGoal} cette semaine`,
                },
                imageHunt: {
                    title: 'Chasse aux images',
                    reducedBy: '',
                    comparedToLastYear: (deletedImagesVolume: number) => `${deletedImagesVolume} Mo d'images supprimées sur votre OneDrive personnel`,
                },
                videoHunt: {
                    title: 'Haro sur les vidéos',
                    reducedBy: '',
                    comparedToLastYear: (deletedVideosVolume: number) => `${deletedVideosVolume} Mo de vidéos supprimées sur votre OneDrive personnel`,
                },
            },
            mateVictoriesTitle: 'Nos champions du moment',
            mateVictory: 'a remporté le badge',
        },
        blog: {
            title: 'Blog',
            readMore: 'Lire la suite',
        },
    },
    goal: {
        typeInfo: {
            carbonFootPrint: {
                title: 'Réduire ses émissions CO2',
                theme: 'Environnement',
            },
            infobesity: {
                title: 'Maîtriser l’infobésité',
                theme: 'Efficacité opérationnelle',
            },
            disconnectionTime: {
                title: 'Assurer des temps de déconnexion',
                theme: 'Bien être au travail',
            },
        },
        home: {
            addGoal: 'Ajouter',
            addGroupGoal: 'Ajouter un objectif pour un groupe',
            see: 'Consulter les détails',
            precision: 'par rapport aux 30 jours précédents',
            groupProgress: 'Progression du groupe :',
            individualProgress: 'Votre progression',
            addindividualIndicators: 'Ajouter mes indicateurs',
            addindividualIndicatorsDescription: 'Suivez votre propre progression avec vos indicateurs',
        },
        individual: {
            button: {
                title: 'Moi',
                addIndicators: 'Ajoutez vos indicateurs',
                qvt: 'Mes indicateurs QVT',
            },
            planetFriendlyBadge: {
                title: 'Réduire mes émissions CO2',
                description: `Chaque mois obtenez votre badge en réduisant vos
                 émissions de 4% sur le mois comparé à l’année dernière.`,
                seeAllBadges: 'Consulter mes badges précédents',
                noBadge: `Chaque mois, obtiens ton badge Planet friendly
                en réduisant tes émissions de 4% sur le mois
                comparé à l’année dernière.`,
            },
            addIndicatorsPopup: {
                title: 'Ajouter un objectif personnel',
                description:
                    'Cette vue rassemble vos indicateurs suivant le même modèle que l’objectif collectif. Vous pourrez suivre vos progrès et bénéficier d’un accompagnement individualisé. En rejoignant la démarche, vous serez informés des nouveaux challenges lancés par l’animateur des entités dont vous êtes membres.',
                descriptionSupp: 'Vous êtes le seul à voir vos indicateurs individuels. A tout moment vous pouvez supprimer votre compte.',
                add: 'Ajouter',
                cancel: 'Annuler',
            },
            deleteFolderProposal: {
                title: 'Nettoyer OneDrive',
                description: function (lastUpdateDateTime: string, nbChildren: number, size: string, year: string, carbonFootprint: string): string {
                    return `Vous avez un dossier inchangé depuis le ${lastUpdateDateTime} contenant ${nbChildren} fichier${nbChildren > 1 ? 's' : ''} pour un volume total de ${size}. Le supprimer réduirait vos émissions de ${year} de ${carbonFootprint}.`
                },
                goToFolder: 'Ouvrir dans OneDrive',
                next: 'Me proposer un autre dossier à supprimer',
                noMoreFolders: function (monthLimitFilter: number): string {
                    return `Vous n’avez pas de dossier inactif depuis ${monthLimitFilter} mois`
                },
                popup: {
                    description: 'Avez-vous supprimer votre dossier ?',
                    yes: 'Oui',
                    no: 'Non',
                },
            },
        },
        collective: {
            button: {
                title: 'Mes groupes',
            },
            presentation: {
                title: 'Objectif de l’équipe',
                noDescription: 'Définissez l’objectif de l’équipe',
                save: 'Enregistrer',
            },
        },
        carbonFootprint: {
            title: 'Détail des émissions',
            groupetitle: 'Détail des émissions',
            measuredDataMissing: 'Données manquantes',
            correspondence: 'Cela correspond aux usages suivants :',
            emailBrick: {
                comparedToLastYear: (lastYear: string) => `par rapport à ${lastYear}`,
                usage: {
                    mail: 'email envoyé en interne',
                    mails: 'emails envoyés en interne',
                    attachment: 'pièce jointe envoyée',
                    attachments: 'pièces jointes envoyées',
                    receipt: 'destinataire par email',
                    receipts: 'destinataires par email',
                    volumeAttachment: 'de pièces jointes envoyées',
                },
            },
            oneDriveBrick: {
                comparedToLastYear: (lastYear: string) => `par rapport à ${lastYear}`,
                usage: {
                    file: 'Nombre de fichier conservé',
                    files: 'Nombre de fichiers conservés',
                    volumeFiles: 'Volume de fichiers conservés',
                    versionFiles: 'Nombre de versions cumulées',
                    versionVolumeFiles: 'Volume des versions cumulées',
                },
            },
            teamsBrick: {
                comparedToLastYear: (lastYear: string) => `par rapport à ${lastYear}`,
                usage: {
                    activity: "Nombre d'activité dans les canaux",
                    activities: "Nombre d'activités dans les canaux",
                    file: 'Nombre de fichier conservé',
                    files: 'Nombre de fichiers conservés',
                    volumeFiles: 'Volume de fichiers conservé',
                },
            },
            copiloteBrick: {
                comparedToLastYear: (days: string) => `Par rapport aux ${days} jours précédents`,
                usage: {
                    transcription: 'Nombre de transcripts de réunions',
                    transcriptions: 'Nombre de transcripts de réunions',
                },
            },
            evolution: {
                details: 'émis avec l’envoi d’emails, l’usage de OneDrive et Teams',
                comparedTo: 'par rapport aux 30 jours précédents',
            },
        },
        qvt: {
            measuredDataMissing: 'Données manquantes',
            evolution: {
                title: 'La place des réunions dans mon quotidien',
                details: 'passées en réunions',
                comparedTo: 'par rapport aux 30 jours précédents',
                inMeetingTime: 'Temps en réunion',
                mobilizedTime: 'Temps mobilisé',
                tooltipLabel: (hour, minute, startDate, endDate, inMeeting) => [
                    `${hour > 0 ? hour + ' h ' : 'Pas '}${minute > 0 ? minute + ' min ' : ''}${inMeeting ? 'de réunion' : 'de temps mobilisé'} du ${startDate}`,
                    `au ${endDate} inclus`,
                ],
                meetingLegend:
                    'Le temps de réunion comprime le temps disponible pour se concentrer sur ses activités. Utilisez la réunion à bon escient et soyez attentif au temps demandé à vos collègues.',
            },
            meetingEvolution: {
                title: 'Évolution du temps passé en réunion',
                details: 'passées en réunions',
                comparedTo: 'par rapport aux 30 jours précédents',
                inMeetingTime: 'Participant',
                mobilizedTime: 'Organisateur',
                tooltipLabel: (hour, minute, startDate, endDate, inMeeting) => [
                    `${hour > 0 ? hour + ' h ' : 'Pas '}${minute > 0 ? minute + ' min ' : ''}${inMeeting ? 'de réunion' : 'de temps mobilisé'} du ${startDate}`,
                    `au ${endDate} inclus`,
                ],
                tooltipLabelUserSpentTime: (hour, minute, startDate, endDate, isParticipant) => [
                    `${hour > 0 ? hour + ' h ' : ''}${minute > 0 ? minute + ' min ' : ''}${isParticipant ? 'de réunion' : 'de réunion organisée'} du ${startDate}`,
                    `au ${endDate} inclus`,
                ],
                meetingLegend: 'Un volume de réunions trop important comprime le temps restant pour se concentrer sur des tâches de fond.',
            },
            meetingEvolutionCount: {
                title: 'Part des réunions facilitant un temps de transition',
                meetingLegend:
                    'Réduire de quelques minutes ses réunions offre à chacun un temps de respiration avant la prochaine réunion. Ces temps de transition sont nécessaires au bien-être et à l’efficacité de chacun.',
            },
            survey: {
                question: 'La charge de réunions de la semaine dernière était-elle tenable dans la durée ?',
                description: 'Collecter le feedback permet d’étalonner les objectifs de plages de réunions optimums',
                errorMessage: "Une erreur s'est produite, veuillez réessayer ultérieurement",
                successMessage: 'Votre réponse a été enregistrée',
            },
            emailSendingRange: {
                title: "Plage d'envoi d’emails",
                tooltip: 'Journée non travaillée',
                legend: {
                    text1: 'Moins de 10 emails envoyés dans la journée',
                    text2: '10 à 30 emails envoyés dans la journée',
                    text3: 'Plus de 30 emails envoyés dans la journée',
                    microRange: 'Moins de 1 interaction',
                    smallRange: '1 à 3 interaction',
                    mediumRange: '3 à 6 interactions',
                    largeRange: '6 à 9 interactions',
                    heightRange: 'Plus de 9 interactions',
                },
                slotsTooltip: {
                    oneSlot: (slot: number, from: string, to: string) => `${slot} interaction${slot > 1 ? 's' : ''} de ${from} à ${to}`,
                    averageSlot: (slot: number, from: string, to: string) => `${slot} interaction${slot > 1 ? 's' : ''} de ${from} à ${to}`,
                },
                button1: '3 sem.',
                button2: '3 mois',
                button3: '1 année',
                description1:
                    'Ce chronogramme des mails envoyés cartographie les temps d’interactions. La possibilité de travailler à distance tend à faire disparaître les frontières du temps de travail. Cette facilité d’organisation personnelle ne doit pas se faire au détriment des temps de récupération nécessaire à notre cerveau pour notre équilibre personnel.',
                description2: 'Il est préférable de favoriser les échanges sur les plages usuelles de travail pour le bien-être de tous dans la durée.',
            },
            meetingBreathingTime: {
                title: 'Répartition des réunions intégrant un temps de respiration',
                buttonWeek: '1 sem.',
                buttonMonth: '1 mois',
                buttonQuarter: '1 trim.',
                buttonYear: '1 année',
                legend1: 'Avec respirations',
                legend2: 'Sans respirations',
                lessThan: 'moins de',
                to: 'à',
                tooltipMessage: (nbMeeting: number, interval: string) => `${nbMeeting} réunion${nbMeeting > 1 ? 's' : ''} d'une durée de ${interval}`,
                moreThan: 'plus de',
                description:
                    "Réduire de quelques minutes ses réunions offre à chacun un temps de respiration avant la prochaine réunion. Ces temps de pause sont nécessaires au bien-être et à l'efficacité de chacun.",
            },
            relationshipPositioning: {
                title: 'Se situer par rapport à mes collègues',
                description:
                    "Ces personnes ont des contextes de travail proches du vôtre. Cela permet de vous situer et de mieux comprendre votre entourage. Ces graphiques mettent en avant vos marges de progrès ou l'importance d'impliquer votre entourage pour réduire la culture de débordement.",
                weeklyMeetingTime: {
                    title: 'Répartition des proches par durée hebdomadaire en réunion',
                    description: {
                        title: 'À vous de jouer !',
                        content: 'Pour agir efficacement sur les réunions, il est indispensable de mobiliser ses collègues afin de questionner les modalités de collaboration.',
                    },
                    tooltip: function (nbUser: number, from: number, to: number): string {
                        return `${nbUser} ${nbUser > 1 ? 'utilisateurs consacrent' : 'utilisateur consacre'} ${from} à ${to} heures en réunions par semaine`
                    },
                    legend: 'Vous',
                },
                mobilizedMeetingTime: {
                    title: 'Répartition des proches par temps mobilisé en réunion',
                    description: {
                        title: 'À vous de jouer !',
                        content: 'Réduire le nombre de participants et la durée des réunions',
                    },
                    tooltip: function (nbUser: number, from: number, to: number): string {
                        return `${nbUser} ${nbUser > 1 ? 'utilisateurs ont' : 'utilisateur a'} mobilisé ${from} à ${to} heures de réunion par semaine`
                    },
                    legend: 'Vous',
                },
                wellBeingScore: {
                    title: 'Répartition des proches par score de bien-être',
                    description: {
                        title: 'À vous de jouer !',
                        content: "Configurer les réunions par défaut sur un format de 45 min plutôt qu'une heure",
                    },
                    tooltip: function (nbUser: number): string {
                        return `${nbUser} ${nbUser > 1 ? 'proches' : 'proche'}`
                    },
                    legend: 'Vous',
                },

                checkDescription:
                    "La liste des collègues les plus proches est établie en comptabilisant les échanges par emails sur le dernier trimestre. Les mentions “collègue anonyme” désigne un collègue qui n'utilise pas Greet ou qui n'a pas accepté que son nom s'affiche.",
                checkDescriptionTip: "Pour consulter leurs noms, acceptez d'être visible dans la liste de vos collègues.",
                footer: {
                    active: {
                        description:
                            "La liste des collègues les plus proches est établie en comptabilisant les échanges par emails sur le dernier trimestre. Les mentions “collègue anonyme” désigne un collègue qui n'utilise pas Greet ou qui n'a pas accepté que son nom s'affiche. Vous accédez à cette liste parce que vous avez accepté d'être visible dans la liste de vos collègues.",
                        action: 'Masquer votre nom dans la liste de vos collègues proches.',
                        title: 'Vos 20 collègues les plus proches :',
                        noColleague: "Aucun de vos proches n'a encore accepté d'être visible",
                    },
                    inactive: {
                        description: 'La listes des collègues les plus proches est établie en comptabilisant les échanges par emails sur le dernier trimestre.',
                        action: "Pour consulter leurs noms, acceptez d'être visible dans la liste de vos collègues.",
                    },
                },
            },
            mobilizeTeam: 'Le temps mobilisés auprès de mes collègues',
            dailyMobilizeTeamMeetingEvolution: {
                title: 'Temps mobilisés par les réunions organisées',
                description: {
                    title: 'À vous de jouer',
                    text: 'Commencer par agir sur les réunions dont vous êtes organisateur',
                },
            },
            mobilizeTeamMeeting: {
                information:
                    'Réduire de quelques minutes ses réunions offre à chacun un peu de temps de respiration avant la prochaine réunion. Ces temps de pause sont nécessaires au bien-être',
                text: 'heures mobilisées sur les 30 derniers jours',
            },
            meetingAttendeesDistribution: {
                title: 'Répartition des nombres de réunions par nombre de participants',
                information: 'Réduire le nombre de participants permet de gagner en efficacité et libère du temps.',
                tooltip: function (nbMeetings: number, nbAttendees): string {
                    return `${nbMeetings} ${nbMeetings > 1 ? 'réunions' : 'réunion'} avec ${nbAttendees} participants`
                },
            },
            communicationModesDistribution: {
                title: 'Adopter le bon mode de communication',
                subtitle: (dateFrom, dateTo) => `Répartition des modes de communication utilisés du ${dateFrom} au ${dateTo}`,
                charts: {
                    titles: {
                        closestPeople: 'Avec les 20 personnes les plus proches',
                        allContacts: 'Avec l’ensemble de ses interlocuteurs',
                    },
                    legend: {
                        teamsChannels: 'Canaux Teams',
                        chat: 'Chat',
                        email: 'Email',
                    },
                    description: {
                        title: 'À vous de jouer !',
                        description:
                            'Créer des Teams (ou des canaux) pour échanger avec vos interlocuteurs quotidiens pour classer l’information par sujet et limiter les interruptions durant la journée.',
                    },
                    tip: {
                        title: createElement('strong', null, 'Utilisez-vous les bons canaux ? Chaque support à sa finalité'),
                        modes: {
                            chat: createElement(Fragment, null, createElement('strong', null, 'Chat :'), ' informel, urgence, micro-tâches'),
                            teamsChannels: createElement(
                                Fragment,
                                null,
                                createElement('strong', null, 'Canaux Teams :'),
                                ' activités thématiques, discussions asynchrones, collaboration explicite sur une activité ou un projet',
                            ),
                            email: createElement(
                                Fragment,
                                null,
                                createElement('strong', null, 'Email :'),
                                ' échange plus formel, support universel permettant de communiquer avec l’externe, canal de prise de contacts ou d’échange ponctuel.',
                            ),
                        },
                        message:
                            '« Les sollicitations incessantes » sont irrésistibles pour notre cerveau, comme le sucre, une addiction qui nuit à notre efficacité collective et notre bien-être.',
                    },
                },
            },
            teamsConfiguration: {
                title: 'Configurer Teams pour gagner en efficacité',
                tip: 'Pour gagner en lisibilité nous vous suggérons d’épingler les Teams les plus importantes, de quitter les Teams inactives ou d’archiver vos anciennes Teams.',
                tabs: {
                    oneMonth: '1 mois',
                    oneQuarter: '1 semestre',
                    oneYear: '1 année',
                    teamsToPin: 'Teams à épingler',
                    teamsToLeave: 'Teams à quitter',
                    teamsToArchive: 'Teams à archiver',
                },
                chartLegend: {
                    activeTeams: 'Teams actives',
                    inactiveTeams: 'Teams inactives',
                },
                teams: {
                    toPin: {
                        noTeams: 'Aucune Teams à épingler.',
                        tip: 'Epingler les Teams traitant de vos activités prioritaires du moment permet de hiérarchiser votre attention.',
                    },
                    toLeave: {
                        noTeams: 'Aucune Teams à quitter.',
                        caption: 'Ces Teams sont inactives depuis plus de 6 mois.',
                        tip: 'Quitter les Teams inactives permet d’alléger le ruban Teams et de gagner du temps lorsque vous les balayez.',
                    },
                    toArchive: {
                        noTeams: 'Aucune Teams à archiver.',
                        caption: 'Ces Teams dont vous êtes propriétaires, sont inactives depuis plus de 6 mois.',
                        tip: 'Une fois l’activité terminée, il est préférable d’isoler les documents à conserver et supprimer les versions de travail. Dans la majorité des cas, il est recommandé de supprimer la Teams.',
                    },
                    suggest: 'Proposer d’autres Teams',
                },
            },
        },
        carbonCumul: {
            titleIndividuel: 'Ma trajectoire',
            leftTextIndividuel: 'Réduire de 4% ses émissions CO2 / an pour s’aligner avec les Accords de Paris.',
            titleGroup: 'Trajectoire annuelle',
            leftTextGroup: 'Réduire de 4% ses émissions CO2 / an pour s’aligner avec les Accords de Paris.',
            carbonEmissions: (goalType: EGoalType) => {
                switch (goalType) {
                    case EGoalType.COLLECTIVE:
                        return `Emissions CO2 moyenne par utilisateur depuis le 1er janvier ${new Date().getFullYear()} :`
                    case EGoalType.INDIVIDUAL:
                        return `Mes émissions cumulées depuis le 1er janvier ${new Date().getFullYear()} :`
                    default:
                        return ''
                }
            },
            toolTip: 'sur les 30 derniers jours',
            annee: 'Année',
            goal: 'Objectif',
            decile: {
                title: {
                    myPosition: 'Ma position :',
                    info: 'Un décile regroupe un 10ième de la population totale. Il y a donc 10 déciles',
                },
                position(firstQuintil, position) {
                    return `Vous avez émis ${firstQuintil ? 'moins' : 'plus'} que ${position}% des utilisateurs de Greet dans l’entreprise.`
                },
            },
        },
        benchMark: {
            title: 'Repères',
            description: 'Mes émissions cumulées depuis le 1er janvier correspondent ',
            hour: 'heure',
            hours: 'heures',
            measureAgainst: "d'ampoule de 10W allumée",
        },
        challenge: {
            title: 'Challenge de la semaine',
            noChallenge: {
                animator: 'Pour faire progresser votre groupe dans son objectif ajouter un challenge',
                simple: 'Pas de challenge programmé',
            },
            beforeChallengeStartText: 'avant le début du challenge',
            digitalCleanupDay: {
                noDataTitle: 'Allégez votre espace numérique de travail ! ',
                noDataText: 'Pensez à nettoyer votre Onedrive et votre boîte email.',
                mails: 'd’emails supprimés',
                files: 'de fichiers supprimés',
                after: {
                    title: 'Nettoyez votre boite email et votre espace OneDrive',
                    titleGroup: 'Résultat du ',
                    groupName: 'groupe',
                    titleCompany: 'Résultat de ',
                    companyName: "l'entreprise",
                },
            },
            label: (badgeType: BadgeType) => {
                switch (badgeType) {
                    case BadgeType.PLANET_FRIENDLY:
                        return 'Planet friendly'
                    case BadgeType.MAIL:
                        return 'Nos emails au régime'
                    case BadgeType.DRIVE:
                        return 'Un peu de ménage'
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Digital Cleanup'
                    case BadgeType.INTERN_ATTACHMENT:
                        return 'Stop aux pièces jointes en interne'
                    case BadgeType.MAILSHOT:
                        return 'Pas de publi-postage'
                    case BadgeType.IMAGE_HUNT:
                        return 'Chasse aux images'
                    case BadgeType.VIDEO_HUNT:
                        return 'Haro sur les vidéos'
                }
            },
            description: (challenge: Challenge) => {
                switch (challenge.badgeType) {
                    case BadgeType.MAIL:
                        return `Limitez les pièces jointes à ${challenge.mailGoalSize} Mo`
                    case BadgeType.DRIVE:
                        return `Supprimez ${challenge.driveGoalSize} Mo dans votre OneDrive personnel`
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Nettoyez votre boite email et votre espace OneDrive'
                    case BadgeType.INTERN_ATTACHMENT:
                        return "N'envoyez aucune pièce jointe en interne cette semaine"
                    case BadgeType.MAILSHOT:
                        return `Limitez cette semaine le nombre maximum de destinataires à ${challenge.mailShotGoalSize} par email.`
                    case BadgeType.IMAGE_HUNT:
                        return `Supprimez cette semaine ${challenge.imageHuntGoalSize} Mo d’images dans votre OneDrive personnel`
                    case BadgeType.VIDEO_HUNT:
                        return `Supprimez cette semaine ${challenge.videosHuntGoalSize} Mo de vidéos dans votre OneDrive personnel`
                    default:
                        return ''
                }
            },
            badgeDescription: (badgeType: BadgeType) => {
                switch (badgeType) {
                    case BadgeType.MAIL:
                        return "Les badges liés a la réduction d'envoi d’email avec pièces-jointes"
                    case BadgeType.DRIVE:
                        return 'Les badges liés à la suppression de fichier dans votre OneDrive personnel'
                    case BadgeType.PLANET_FRIENDLY:
                        return "Chaque mois, obtiens ton badge Planet friendly en réduisant tes émissions de 4% sur le mois comparé à l'année dernière."
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Les badges liés au digital cleanup day'
                    case BadgeType.INTERN_ATTACHMENT:
                        return 'Les badges liés à la réduction des pièces jointes envoyées en interne'
                    case BadgeType.MAILSHOT:
                        return 'Les badges liés à la réduction du publi-postage'
                    case BadgeType.IMAGE_HUNT:
                        return "Les badges liés à la réduction du volume d'image sur votre OneDrive personnel"
                    case BadgeType.VIDEO_HUNT:
                        return 'Les badges liés à la réduction du volume de vidéo sur votre OneDrive personnel'
                    default:
                        return ''
                }
            },
            ongoingChallenge: 'challenge en cours',
        },
        setupChallenge: {
            title: 'Configuration des challenges',
            save: 'Enregistrer',
            scheduled: (nb: number) => {
                // Prefer plural when no challenges scheduled, hence use !== instead of > 1
                const suffix = nb !== 1 ? 's' : ''
                return `Challenge${suffix} programmé${suffix}`
            },
            to: 'au',
            form: {
                title: 'Programmer un challenge',
                labels: {
                    period: 'Période',
                    category: 'Catégorie',
                },
                sizeDrive: 'Volume de documents à supprimer dans le drive (Mo)',
                sizeMail: 'Taille maximale des pièces jointes (Mo)',
                sizeMailDigitalCleanupDay: 'Volume de pièces jointes à supprimer (Mo)',
                sizeMailShot: 'Nombre maximum de destinaires par email',
                sizeImageHunt: "Volume d'images à supprimer dans le drive (Mo)",
                sizeVideoHunt: 'Volume de vidéos à supprimer dans le drive (Mo)',
            },
        },
        cohort: {
            title: 'Ils sont mobilisés',
            joinCohort: 'Rejoignez la démarche en ajoutant vos indicateurs personnels',
            noUserCohortMessage: 'Parce qu’on va plus loin ensemble, mobilisez vos collègues sur l’objectif d’équipe !',
        },
        tips: {
            title: 'Tips',
            readMore: 'Aller sur le centre de ressources',
        },
        coach: {
            form: {
                description: {
                    part1: 'Description (partie 1)',
                    part2: 'Description (partie 2)',
                },
                coachContent: {
                    description: 'Gestion des facilitateurs',
                    recommendedSize: '(Taille recommendée des images: 369 x 239)',
                    firstname: 'Prénom',
                    calendarURL: 'URL du calendrier',
                    base64Picture: 'Image en base64',
                    addPicture: 'Choisir une image',
                    addCoach: 'Ajouter un facilitateur',
                    removeCoach: 'Supprimer',
                },
                display: {
                    description: 'Choix du nombre de facilitateurs',
                    oneCoach: 'Un facilitateur',
                    twoCoach: 'Deux facilitateurs',
                },
                save: 'Enregistrer',
                cancel: 'Annuler',
            },
            textBtn: 'Prendre RDV avec ',
        },
        weeklyCleaning: {
            title: 'Vos dernières suppressions',
            cleanPeriod: (from: string, to: string) => `Du ${from} au ${to}`,
            description: 'Nettoyez votre boite email et votre espace OneDrive',
            noCleaning: 'Rien n’a été supprimé cette semaine',
            emailDeleted: 'd’emails supprimés',
            driveDeleted: 'de fichiers supprimés',
        },
    },
    tipsContent: tipsFr,
    webinar: {
        title: 'Webinaire',
        startAt: '12 juin à 14h00',
        contentTitle: 'Comment organiser les espaces de partage pour que chacun retrouve facilement l’information',
        description:
            'Adopter des conventions d’usage simples facilite l’organisation et la recherche d’information. ' +
            'Chacun gagne du temps et reste confiant dans sa capacité à y retrouver l’information utile.',
        registerationUrl: '#',
        register: 'Se connecter',
        addToAgenda: "Ajouter à l'agenda",
        form: {
            eventDate: 'Date',
            content: 'Contenu',
            description: 'Description',
            registerationUrl: "Url de l'event",

            save: 'Enregistrer',
            cancel: 'Annuler',
        },
    },
    unitedNation: {
        title: 'Accord de Paris - Convention-cadre des Nations unies sur le changement climatique',
        link: 'https://www.un.org/fr/climatechange/paris-agreement',
    },

    date: {
        range: (from: string, to: string) => `Du ${from} au ${to}`,
    },
    daysAbbreviation: 'jrs',
    dayFirstLetter: 'j',
    components: {
        select: {
            selectCohortDefaultLabel: 'Mes groupes',
            defaultNoChoicesLabel: 'Aucun de vos groupes ne suit cet objectif pour l’instant',
            AddYourOwnIndicatorsMsg: 'Ajoutez vos indicateurs pour démarer un objectif individuel',
        },
    },
    thanksForInterest: 'Merci pour votre intérêt !',
    accessDeniedPopupMessage: (supportMail) =>
        `L'accès à Greet est actuellement réservé aux participants du pilote. Vous pouvez contacter ${supportMail} qui mène le pilote pour toute question. Stay tuned !`,
    accessDeniedPopupMessageWithLink: "L'accès à Greet est actuellement réservé aux participants du pilote. Pour toute question vous pouvez vous diriger vers",
    welcomePopupMessage: [
        "L'application Greet collecte les informations de profil de votre compte Microsoft 365 (nom, prénom, photo) ainsi que des données d'usage des applications Outlook, Teams, Onedrive, Sharepoint pour mesurer leur impact sur les émissions de CO2.",
        'Vous pourrez à tout moment supprimer votre compte via le menu disponible en cliquant sur votre photo de profil.',
    ],
    welcomePopup: {
        title: 'Informations',
        confirmBtn: "J'ai compris",
    },
    legalInformations: {
        title: 'Informations légales',
        description: (supportMail) =>
            `Les conditions générales d'utilisation et les engagements pour la protection de vos données personnelles sont accessibles ci-dessous. Pour plus d'information, vous pouvez également contacter : ${supportMail}`,
        cgu: {
            title: "Conditions générales d'utilisation",
            link: 'Télécharger les CGU (pdf)',
        },
        charter: {
            title: "Charte d'utilisation des données personnelles",
            link: "Télécharger la charte d'utilisation des données personelles (pdf)",
        },
    },
    animationChallenge: {
        title: 'Animation',
        tableTitle: 'Résultat des challenges',
        tableHeaders: {
            utilisateur: 'Utilisateur',
            email: 'Email',
            cohortId: 'Groupe',
            challenge: 'Challenge',
            result: 'Résultat',
            dates: 'Dates',
            gagne: 'Gagné',
        },
        filters: {
            search: 'Rechercher...',
            cohort: 'Cohort...',
            allCohort: 'Toutes les cohortes',
            challenge: 'Challenge...',
            allChallenges: 'Tous les challenges',
            deleteFilters: 'Supprimer les filtres',
            export: 'Export (csv)',
        },
    },
    challengeEndPopup: {
        text(earned: boolean, cohortName: string, period: { from: string; to: string }) {
            const common = [createElement('strong', null, cohortName), ' organisé du ', createElement('strong', null, `${period.from} au ${period.to}`)]

            if (earned) {
                return {
                    title: 'Félicitation !',
                    description: createElement(Fragment, null, ...["Vous avez réussi le dernier challenge de l'équipe ", ...common]),
                }
            }

            return {
                title: 'On compte sur vous pour le prochain challenge !',
                description: createElement(Fragment, null, ...["Vous n'êtes pas allé au bout du challenge de l'équipe ", ...common]),
            }
        },
        button: 'Ok',
    },
    footer: {
        releaseNote: {
            label: 'Note de version',
            link: 'https://lecko.notion.site/Change-log-73a06879d9f0467a8d8697c804b0ff94',
        },
        bugReport: {
            label: 'Rapporter un problème',
            title: 'Rapporter un problème ou un étonnement',
        },
        cache: {
            label: 'Vider le cache',
            infoBulle: 'Supprime les informations mémorisées par le navigateur',
        },
        copyright: '© 2023 Greet by lecko - Tous droits réservés',
    },
    badgeMonitor: {
        title: 'Mes badges',
        seeAll: 'Tout voir',
        resultForOneBadge: 'badge remporté',
        resultForServeralBadges: 'badges remportés',
        popup: {
            awardPeriod: (from: string, to: string) => `du ${from} au ${to}`,
            planetFriendly: (decreaseValue: any, lastYear: any) => `Réduction des émissions de ${decreaseValue}% par rapport à ${lastYear}`,
            drive: (removedDataMB: number) => `${removedDataMB} Mo supprimé${removedDataMB > 1 ? 's' : ''} sur votre OneDrive`,
            mail: (maxAttachementMB: number) => `Pas d'envoi de pièce jointe de plus de ${maxAttachementMB} Mo`,
            digitalCleanupDay: (removedDataMB: number) => `${removedDataMB} Mo nettoyé${removedDataMB > 1 ? 's' : ''}`,
            internAttachment: 'Aucune pièce jointe envoyée en interne',
            mailShot: (mailShotGoal: number) => `Nombre de destinataires par email limité à ${mailShotGoal}`,
            imageHunt: (deletedImagesVolume: number) => `${deletedImagesVolume} Mo d'images supprimées sur votre OneDrive personnel`,
            videoHunt: (deletedVideosVolume: number) => `${deletedVideosVolume} Mo de vidéos supprimées sur votre OneDrive personnel`,
        },
        noBadge: "Vous n'avez pas encore de badge pour ce challenge",
        seeMore: 'Voir plus',
        seeLess: 'Voir moins',
    },

    personalScoring: {
        pointsDescription: {
            carbonFootprint: 'Engagement:',
            qvt: 'Score QVCT numérique',
        },
        positionDescription: {
            carbonFootprint: 'Niveau d’engagement dans l’entreprise :',
            qvt: 'Préserver ses ressources cognitives face à l’hyperconnexion et gagner en efficacité',
        },
        maxPositonDescription: 'utilisateurs',
        rankUnit: 'places',
        rankEqual: 'Aucun changement par rapport au mois dernier',
        rankUp: (rankDiff: number) => `${rankDiff} point${rankDiff > 1 ? 's' : ''} gagné${rankDiff > 1 ? 's' : ''} par rapport au mois dernier`,
        rankDown: (rankDiff: number) => `${rankDiff} point${rankDiff > 1 ? 's' : ''} perdu${rankDiff > 1 ? 's' : ''} par rapport au mois dernier`,
        positionDecorator: (position: number) => {
            if (position === 1) {
                return 'ère'
            }
            return 'ème'
        },
        tooltip: {
            carbonFootprint:
                'Gagnez des points en relevant les challenges, en nettoyant vos espaces ou en vous connectant régulièrement ! Le détail des règles d’affectation est présenté dans l’aide.',
            qvt: 'Gagnez des points en réduisant le nombre d’email envoyés en dehors des plages de travail, en ajoutant des espaces entre les réunions et en réduisant le nombre de email envoyés en réunions.',
        },
    },

    welcomeBack: {
        emissionTitle: 'Emissions',
        emissionCard1: {
            title: `Vos émissions cumulées en ${new Date().getMonth() === 0 ? toLastYearString(new Date()) : toYearString(new Date())} sont de`,
        },
        emissionCard2: {
            title: 'Vous avez émis',
            description: 'des utilisateurs de Greet dans l’entreprise.',
        },
        emissionCard3: {
            description: "Votre métier et votre ancienneté dans l'organisation ont une incidence sur vos émissions au même titre que vos usages.",
        },

        evolutionTitle: 'Evolution',
        evolutionCoparaison: (evolution) => (evolution < 0 ? 'augmenté de' : 'réduit de'),
        evolutionCard1: {
            title: 'Vos émissions mensuelles ont',
            description: "par rapport à l'année dernière",
        },
        evolutionCard2: {
            description: "Chacun doit adapter ses pratiques pour réduire ses émissions de 4% par an pour s'aligner avec les accords de Paris",
        },
        evolutionCard3: {
            descriptionParams: (users) => `Rejoignez les ${users} utilisateurs qui réduisent leurs émissions d'au moins 4% / an.`,
        },

        engagementTitle: 'Engagement',
        engagementCard1: {
            descriptionParams: () => 'Grâce à vos actions de nettoyage et les challenges remportés, vous avez gagné',
        },
        engagementCard2: {
            title: "Votre niveau d'engagement vous situe à la",
            descriptionParams: (maxRank) => `sur ${maxRank} utilisateurs.`,
        },
        engagementCard3: {
            description: 'Introduire de nouvelles habitudes dans ses routines est la clef aux changements de comportement.',
        },
        footerText: 'Avec Greet, chaque effort est rendu visible et vos progrès sont récompensés !',
        buttonLabel: 'A vous de jouer !',
        checkboxPopUp: 'Ne plus afficher ce message lors de la prochaine connexion',
        lessThan: 'moins que',
        moreThan: 'plus que ',
    },
    clipboardCopy: {
        success: 'Copié',
        error: 'Une erreur est survenue durant la copie',
    },
    worloadEvaluation: {
        title: (debut, fin) => `Semaine du ${debut} au ${fin}`,
        subTitle: 'Cette semaine…',
        question1: 'Ma charge de travail était…',
        question2: 'Mon équilibre vie pro / perso était…',
        question3: 'Mon plaisir au travail était…',
        answersInformations: {
            cry: "Pas du tout d'accord",
            disappointed: "Pas d'accord",
            medium: "Ni d'accord ni pas d'accord",
            smile: "D'accord",
            happy: "Tout à fait d'accord",
        },
        notAnswerdEvaluation: "Vous n'avez pas encore évalué votre semaine",
        modifyAnswerdEvaluation: 'Vous pouvez modifier votre évaluation de la semaine en cours',
        validateToAnswer: 'Valider pour confirmer votre nouvelle évaluation',
        answerdEvaluation: 'Votre validation a été prise en compte',
        submitButton: 'Valider',
        closeButton: 'Fermer',
        accessToIndicatorsButton: 'Accéder à vos indicateurs',
        actionsMessage: 'Adapter ses pratiques pour préserver ses ressources cognitives et gagner en efficacité !',
    },
    qvtManagerBoard: {
        title: "Evaluation de la charge ressentie par l'équipe",
        tabTitle: 'Mon équipe',
        answer: 'Reponse',
        weekFormat: (from, to) => `Format des semaines: ${from ?? ''} à ${to ?? ''}`,
        tableHeaders: {
            utilisateur: 'Utilisateur',
            weekPrefix: 'S',
        },
        graph: {
            q1Label: 'Charge de travail',
            q2Label: 'Equilibre pro/perso',
            q3Label: 'Satifaction',
        },
    },
    worloadEvaluationPersonalResult: {
        title: 'Charge de travail ressentie les semaines passées',
        lastWeeks: 'Les semaines passées...',
        weekFormat: 'Plage usuelle de travail',
        weekFormatRange: (from, to, dayCount, hourStart, hoursEnd) => `Du ${from} au ${to} (${dayCount} jours) horaire: ${hourStart} - ${hoursEnd}`,
        graph: {
            q1Label: 'Ma charge de travail était',
            q2Label: 'Mon équilibre vie pro / vie perso était',
            q3Label: 'Mon plaisir au travail était',
        },
        legend: {
            text1: 'Les pratiques numériques individuelles et collectives impactent vos ressources cognitives.',
            text2: 'Les indicateurs ci-dessous sont là pour vous aider à les préserver.',
            textLink: 'En savoir plus',
        },
    },
    qvtFutureMeeting: {
        description: 'Volume de réunions estimées sur les 7 prochains jours',
        comparaison: 'moyenne',
    },
    volumeUnit: {
        bytes: 'octets',
        megaBytes: 'Mo',
        gigaBytes: 'Go',
    },
    daysOfWeek: [
        { label: 'Lundi', value: DayOfWeek.MONDAY },
        { label: 'Mardi', value: DayOfWeek.TUESDAY },
        { label: 'Mercredi', value: DayOfWeek.WEDNESDAY },
        { label: 'Jeudi', value: DayOfWeek.THURSDAY },
        { label: 'Vendredi', value: DayOfWeek.FRIDAY },
        { label: 'Samedi', value: DayOfWeek.SATURDAY },
        { label: 'Dimanche', value: DayOfWeek.SUNDAY },
    ],
}

export default fr
