import classnames from 'classnames'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useUser } from '../../contexts'
import { Lang } from '../../models'
import EQVTSurveyFeel from '../../models/EQVTSurveyFeel'
import { NB_WEEK_TO_COMPUTE } from '../../pages/QVTManagerBoard'
import { TimeUnit, buildWeeksYearNumber, getWeekNumber, substract } from '../dateUtils'
import { GreetTable } from '../share/GreetTable'
import InfoBulle from '../share/Infobulle'
import { extractFullNameFromMail } from '../utils'

const WorkloadEvolutionManagerTable = ({ data, loading }: any) => {
    const { lang } = useUser()
    const weeks = useMemo(() => buildWeeksYearNumber(NB_WEEK_TO_COMPUTE, lang), [lang, data])
    const columns = useMemo(() => buildTableColumns(lang, weeks), [lang])
    return <GreetTable columns={columns} data={data} loading={loading} />
}

const useEvaluationCell = createUseStyles({
    evaluationCell: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        height: '35px',
        maxWidth: '45px',
        '&> div': {
            width: '100%',
            height: '100%',
        },
    },
    evaluationQuestionCell: {
        minWidth: '15px',
        width: '100%',
        height: '80%',
    },
    happy: {
        backgroundColor: '#4DAD33',
    },
    smile: {
        backgroundColor: '#93BA25',
    },
    medium: {
        backgroundColor: '#F4991D',
    },
    disappointed: {
        backgroundColor: '#ed7834',
    },
    cry: {
        backgroundColor: '#ED2C2C',
    },
})

const EvaluationCell = ({ data }: any) => {
    const styles = useEvaluationCell()
    const { lang } = useUser()
    if (data == null) {
        return null
    }

    const question1Answer = (data.question1 as EQVTSurveyFeel).toLocaleLowerCase()
    const question2Answer = (data.question2 as EQVTSurveyFeel).toLocaleLowerCase()
    const question3Answer = (data.question3 as EQVTSurveyFeel).toLocaleLowerCase()

    return (
        <div className={styles.evaluationCell}>
            <InfoBulle translateX='-25%' text={lang.qvtManagerBoard.answer + ': ' + (lang.worloadEvaluation.answersInformations as any)[question1Answer]}>
                <div className={classnames((styles as any)[question1Answer], styles.evaluationQuestionCell)}></div>
            </InfoBulle>
            <InfoBulle translateX='-25%' text={lang.qvtManagerBoard.answer + ': ' + (lang.worloadEvaluation.answersInformations as any)[question2Answer]}>
                <div className={classnames((styles as any)[question2Answer], styles.evaluationQuestionCell)}></div>
            </InfoBulle>
            <InfoBulle translateX='-25%' text={lang.qvtManagerBoard.answer + ': ' + (lang.worloadEvaluation.answersInformations as any)[question3Answer]}>
                <div className={classnames((styles as any)[question3Answer], styles.evaluationQuestionCell)}></div>
            </InfoBulle>
        </div>
    )
}

const UserCell = ({ data, lang }: { data: any; lang: Lang }) => {
    if (data == null) {
        return null
    }
    const firstDay = lang.daysOfWeek.find((day: any) => day.value === data?.workingDays?.from) ?? lang.daysOfWeek[0]
    const lastDay = lang.daysOfWeek.find((day: any) => day.value === data?.workingDays?.to) ?? lang.daysOfWeek[4]

    const toolTipText = `
    email: ${data.email}
    Lieu de travail: France
    ${lang.qvtManagerBoard.weekFormat(firstDay.label, lastDay.label)}
    `
    const displayUserName = data.firstName != null && data.lastName != null ? `${data.firstName} ${data.lastName}` : extractFullNameFromMail(data.email)
    return (
        <InfoBulle translateX='-25%' text={toolTipText}>
            {displayUserName}
        </InfoBulle>
    )
}

const buildTableColumns = (lang: Lang, weeks: any[]) => {
    const headers = lang.qvtManagerBoard.tableHeaders
    return [
        {
            id: 'email',
            header: headers.utilisateur,
            accessorKey: 'user',
            cell: (props: any) => {
                const cellData = props.getValue()
                return <UserCell data={cellData} lang={lang} />
            },
        },
        ...Array.from(Array(NB_WEEK_TO_COMPUTE).keys())
            .reverse()
            .map((index) => {
                const dateToCompute = substract(new Date(), index, TimeUnit.WEEKS)
                const weekNumberToCompute = getWeekNumber(dateToCompute)
                const yearToCompute = dateToCompute.getFullYear()
                const columnId = `${yearToCompute}${weekNumberToCompute}`
                return {
                    id: columnId,
                    header: () => {
                        const label = headers.weekPrefix + weekNumberToCompute
                        return <InfoBulle text={weeks.find(x => label === x.label)?.literal || ''}>
                            {label}
                        </InfoBulle>
                    },
                    accessorKey: columnId,
                    cell: (props: any) => {
                        const cellData = props.getValue()
                        return <EvaluationCell data={cellData} />
                    },
                }
            }),
    ]
}

export default WorkloadEvolutionManagerTable
