import { FunctionComponent, SVGProps } from 'react'

export enum BrickTypes {
    EMAIL = 'EMAIL',
    GMAIL = 'GMAIL',
    ONEDRIVE = 'ONEDRIVE',
    SHAREPOINT = 'SHAREPOINT',
    TEAMS = 'TEAMS',
    VISIO = 'VISIO',
    GDRIVE = 'GDRIVE',
    SHAREDGDRIVE = 'SHAREDGDRIVE',
    COPILOTE = 'COPILOTE',
}

export interface Brick {
    type: BrickTypes
    name: string
}

export interface BrickStyle {
    backgroundColor: string
    backgroundColorAlt: string
    brickColor: string
    activeBrickColor: string
    icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>
}
