import TenLogo from '../../components/headers/TenLogo'
import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { Customer } from '../../models'
import QVCTIndividualGoal from '../../pages/QVCTIndividualGoal'
import tenEn from './cutomerLangs/ten/tenEn'
import tenFr from './cutomerLangs/ten/tenFr'

import { defaultBricks } from './defaultCustomerValues'
import lecko from './lecko'

const ten: Customer = {
    ...lecko,
    name: 'ten',
    platformName: 'technip_energies',
    disablePwa: true,
    canViewOnlyQVTGoal: true,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES, GreetFeaturingTarget.QVT],
    bricks: defaultBricks,
    QVTIndividualGoal: QVCTIndividualGoal,
    logo: TenLogo,
    customerLang: {
        fr: tenFr,
        en: tenEn,
    },
    theme: {
        primaryColor: '#0070EF', //                                            --gd-primary-color
        primaryDarkColor: '#004C83', //                                        --gd-primary-dark-color
        secondaryColor: '#80C7A0', //                                          --gd-secondary-color
        secondaryDarkColor: '#4A8766', //                                      --gd-secondary-dark-color
        tertiaryColor: '#EE7766', //                                           --gd-tertiary-color

        borderSmallRadius: '10px', //                                          --gd-border-small-radius
        borderNormalRadius: '20px', //                                         --gd-border-normal-radius
        borderBigRadius: '50px', //                                            --gd-border-big-radius

        positiveColor: '#4DAD33', //                                           --gd-positive-color
        intermediaryColor: '#F4991D', //                                       --gd-intermediary-color
        negativeColor: '#ED2C2C', //                                           --gd-negative-color

        primaryButtonColor: 'var(--gd-secondary-color)', //                    --gd-primary-button-color
        secondaryButtonColor: 'var(--gd-primary-color)', //                    --gd-secondary-button-color
        dangerButtonColor: 'var(--gd-negative-color)', //                      --gd-danger-button-color

        mainAppBackgroundColor: '#FFFFFF', //                                  --gd-main-app-background-color
        mainAppShadowedContent: 'rgb(0 0 0 / 8%) 0px -15px 15px 0px', //       --gd-main-app-shadowed-content
        mainActiveTabBackgroundColor: 'var(--gd-background-clear-color)', //   --gd-main-active-tab-background-color
        mainActiveTabColor: 'var(--gd-primary-color)', //                      --gd-main-active-tab-color
        mainPopupBackgroundColor: '#FFFFFF', //                                --gd-main-popup-background-color

        blogsPrimaryColor: 'var(--gd-secondary-color)', //                     --gd-blogs-primary-color
        blogsPrimaryDarkColor: 'var(--gd-secondary-dark-color)', //            --gd-blogs-primary-color
        blogsSecondaryColor: 'var(--gd-primary-color)', //                     --gd-blogs-secondary-color

        backgroundClearColor: '#EDF6FF', //                                    --gd-background-clear-color
        backgroundDarkColor: '#C3DFFF', //                                     --gd-background-dark-color

        textClearColor: '#FFFFFF', //                                          --gd-text-clear-color
        textDarkColor: '#1C1B1F', //                                           --gd-text-dark-color
        textMainSubTitleColor: '#3E9097', //                                   --gd-text-main-subtitle-color
        bigTextDarkColor: '#1C1B1F', //                                        --gd-big-text-dark-color

        brickEmailColor: '#002F7D', //                                         --gd-brick-email-color
        brickEmailActiveColor: '#EDF6FF', //                                   --gd-brick-email-active-color
        brickOneDriveColor: '#EA9386', //                                      --gd-brick-onedrive-color
        brickOneDriveActiveColor: '#EDF6FF', //                                --gd-brick-onedrive-active-color
        brickTeamsColor: '#EC705E', //                                         --gd-brick-teams-color
        brickTeamsActiveColor: '#EDF6FF', //                                   --gd-brick-teams-active-color
        brickSharepointColor: '#267379', //                                    --gd-brick-sharepoint-color
        brickSharepointActiveColor: '#EDF6FF', //                              --gd-brick-sharepoint-active-color
        brickVisionColor: '#58BDC5', //                                        --gd-brick-vision-color
        brickVisionActiveColor: '#58BDC5', //                                  --gd-brick-vision-active-color
        brickChatColor: '#59ABB1', //                                          --gd-brick-chat-color
        brickGmailColor: '#4285f4', //                                         --gd-brick-gmail-color
        brickGmailActiveColor: '#1C1B1F', //                                   --gd-brick-gmail-active-color
        brickGDriveColor: '#FBBC04', //                                        --gd-brick-gdrive-color
        brickGDriveActiveColor: '#1C1B1F', //                                  --gd-brick-gdrive-active-color
        brickSharedGDriveColor: '#9AA0A6', //                                  --gd-brick-shared-gdrive-color
        brickSharedGDriveActiveColor: '#1C1B1F', //                            --gd-brick-shared-gdrive-active-color

        brickCopiloteColor: '#A86EDD', //                                      --gd-brick-copilote-color
        brickCopiloteActiveColor: '#EDF6FF', //                                --gd-brick-copilote-active-color

        goalTextColor: 'var(--gd-text-dark-color)', //                         --gd-goal-text-color
        goalFillColor: '#FFF', //                                              --gd-goal-fill-color
        goalCarbonFootprintColor: '#073C93', //                                --gd-goal-carbon-footprint-color
        goalInfobisityColor: '#629CFD', //                                     --gd-goal-infobesity-color
        goalDeconnectionTimeColor: '#00AEE4', //                               --gd-goal-deconnection-time-color

        badgeTextColor: 'var(--gd-text-dark-color)', //                        --gd-badge-text-color
        badgeFillColor: '#FFF', //                                             --gd-badge-fill-color
        badgeMailsOnDietColor: '#0070EF', //                                   --gd-badge-mails-on-diet-color
        badgeMailsOnDietLineColor: '#0070EF', //                               --gd-badge-mails-on-diet-line-color
        badgeMailsOnDietFillColor: '#0070EF', //                               --gd-badge-mails-on-diet-fill-color
        badgeCleanUpColor: '#0070EF', //                                       --gd-badge-cleanup-color
        badgeCleanUpLineColor: '#0070EF', //                                   --gd-badge-cleanup-line-color
        badgeCleanUpFillColor: '#0070EF', //                                   --gd-badge-cleanup-fill-color
        badgePlanetFriendlyColor: '#0070EF', //                                --gd-badge-planet-friendly
        badgePlanetFriendlyLineColor: '#0070EF', //                            --gd-badge-planet-line-friendly
        badgePlanetFriendlyFillColor: '#0070EF', //                            --gd-badge-planet-fill-friendly
        badgeInternAttachmentColor: '#0070EF', //                              --gd-badge-intern-attachment-color
        badgeInternAttachmentLineColor: '#0070EF', //                          --gd-badge-intern-attachment-line-color
        badgeInternAttachmentFillColor: '#0070EF', //                          --gd-badge-intern-attachment-fill-color
        badgeMailShotColor: '#0070EF', //                                      --gd-badge-mailshot-color
        badgeMailShotLineColor: '#0070EF', //                                  --gd-badge-mailshot-line-color
        badgeMailShotFillColor: '#0070EF', //                                  --gd-badge-mailshot-fill-color
        badgeImageHuntColor: '#0070EF', //                                     --gd-badge-imagehunt-color
        badgeImageHuntLineColor: '#0070EF', //                                 --gd-badge-imagehunt-line-color
        badgeImageHuntFillColor: '#0070EF', //                                 --gd-badge-imagehunt-fill-color
        badgeVideoHuntColor: '#0070EF', //                                     --gd-badge-video-hunt-color
        badgeVideoHuntLineColor: '#0070EF', //                                 --gd-badge-video-hunt-line-color
        badgeVideoHuntFillColor: '#0070EF', //                                 --gd-badge-video-hunt-fill-color
        loaderColors: ['#2067DC', '#AED5D8', '#AED5D8', '#FFFFFF', '#AED5D8'],

        emailSendingRangeLessThanTen: '#9BCDD0', //                            --gd-email-sending-range-stick-less-than-ten-fill-color
        emailSendingRangeBetweenTenAndThirty: '#59ABB1', //                    --gd-email-sending-range-stick-beetwen-ten-and-thirty-fill-color
        emailSendingRangeMoreThanThirty: '#2D5659', //                         --gd-email-sending-range-stick-more-than-thirty-fill-color

        emailSendingRangeSmallRange: '#d6d6d6', //                             --gd-email-sending-range-small-range-color
        emailSendingRangeMediumRange: '#939393', //                            --gd-email-sending-range-medium-range-color
        emailSendingRangeLargeRange: '#555555', //                             --gd-email-sending-range-large-range-color
        emailSendingRangeHeightRange: '#1C1B1F', //                            --gd-email-sending-range-height-range-color
    },
}

export default ten
