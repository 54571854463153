import { ReactComponent as CarbonFootprint } from './CarbonFootprint.svg'
import { ReactComponent as CarbonFootprintImage } from './CarbonFootprintImage.svg'
import { ReactComponent as ChallengeEndCongratulation } from './ChallengeEndCongratulation.svg'
import { ReactComponent as DisconnectionTimeImage } from './DisconnectionTimeImage.svg'
import { ReactComponent as HowIsWork } from './HowIsWork.svg'
import { ReactComponent as GreetMainImage } from './GreetMainImage.svg'
import { ReactComponent as LegalNotice } from './LegalNotice.svg'
import { ReactComponent as InfobesityImage } from './InfobesityImage.svg'
import { ReactComponent as NoCohortMembers } from './NoCohortMembers.svg'
import { ReactComponent as NoDeletionChallengeData } from './NoDeletionChallengeData.svg'
import { ReactComponent as OngoingChallenge } from './OngoingChallenge.svg'
import { ReactComponent as User } from './User.svg'
import { ReactComponent as WeeklyNoCleanPicture } from './WeeklyNoCleanPicture.svg'

export default {
    CarbonFootprint,
    CarbonFootprintImage,
    ChallengeEndCongratulation,
    DisconnectionTimeImage,
    HowIsWork,
    GreetMainImage,
    LegalNotice,
    InfobesityImage,
    NoCohortMembers,
    NoDeletionChallengeData,
    OngoingChallenge,
    User,
    WeeklyNoCleanPicture,
}
