import { CSSProperties } from 'react'
import LorealArticle1Img from './LorealArticle1.jpg'
import LorealArticle2Img from './LorealArticle2.jpg'

const imageArticle1Style: CSSProperties = {
    width: '200px',
    height: '185px',
    objectFit: 'cover',
    borderRadius: '15px',
}

const imageArticle2Style: CSSProperties = {
    width: '200px',
    height: '185px',
    objectFit: 'fill',
    borderRadius: '15px',
}

const LorealArticle1 = () => {
    return <img style={imageArticle1Style} alt='article-1' src={LorealArticle1Img} />
}

const LorealArticle2 = () => {
    return <img style={imageArticle2Style} alt='article-2' src={LorealArticle2Img} />
}

export { LorealArticle1, LorealArticle2 }
