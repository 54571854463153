export enum EPeriod {
    ONE_MONTH = 'ONE_MONTH',
    ONE_QUARTER = 'ONE_QUARTER',
    ONE_YEAR = 'ONE_YEAR',
}

export enum ETeamsAction {
    PIN = 'PIN',
    LEAVE = 'LEAVE',
    ARCHIVE = 'ARCHIVE',
}

export interface ActiveInactiveTeams {
    activeTeams: number
    inactiveTeams: number
}

export interface QvtTeamsConfiguration {
    numberOfTeams: number
    oneMonthActiveTeams: number
    oneQuarterActiveTeams: number
    oneYearActiveTeams: number
    teamsToPin: string[]
    teamsToLeave: string[]
    teamsToArchive: string[]
}
