enum Role {
    SIMPLE = 'SIMPLE',
    ANIMATOR = 'ANIMATOR',
    ADMIN = 'ADMIN',
    SUPER_ADMIN = 'SUPER_ADMIN',
    IMPERSONATOR = 'IMPERSONATOR',
}

enum Scope {
    GD_GET = 'GreetDesktopController_GET',
    GD_POST = 'GreetDesktopController_POST',
    GD_PUT = 'GreetDesktopController_PUT',
    GD_DELETE = 'GreetDesktopController_DELETE',
    CO_GET = 'CohortController_GET',
    CO_POST = 'CohortController_POST',
    CO_PUT = 'CohortController_PUT',
    CO_DELETE = 'CohortController_DELETE',
    USER_POST = 'UserController_POST',
    USER_PUT = 'UserController_PUT',
}

const RoleScopes = {
    SIMPLE: [Scope.GD_GET, Scope.GD_PUT],
    ANIMATOR: [Scope.GD_GET, Scope.GD_POST, Scope.GD_PUT],
    ADMIN: [
        Scope.CO_POST,
        Scope.GD_GET,
        Scope.GD_POST,
        Scope.CO_GET,
        Scope.GD_PUT,
        Scope.GD_DELETE,
        Scope.USER_PUT,
        Scope.CO_PUT,
        Scope.CO_DELETE,
    ],
    SUPER_ADMIN: [
        Scope.CO_POST,
        Scope.GD_GET,
        Scope.GD_POST,
        Scope.CO_GET,
        Scope.GD_PUT,
        Scope.GD_DELETE,
        Scope.USER_POST,
        Scope.USER_PUT,
        Scope.CO_PUT,
        Scope.CO_DELETE,
    ],
    IMPERSONATOR: [
        Scope.CO_GET,
        Scope.GD_GET
    ]
}

export default Role

export { Scope, RoleScopes }
