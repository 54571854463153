import { createUseStyles } from 'react-jss'
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { MediaQueryBreakpoints } from '../components/MediaQueryBreakpoints'
import CohortDetails from '../components/admin/CohortDetails'
import CohortList from '../components/admin/CohortList'
import { isAdmin } from '../components/rolesUtils'
import { useUser } from '../contexts'
import { Params } from '../models'

const useStyles = createUseStyles({
    adminHome: {
        maxWidth: '1142px',
        margin: '0 auto',
        paddingBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
    },
    mainTitle: {
        display: 'flex',
        width: '100%',
        padding: '2rem 0 0 0',
        gap: '1rem',
        fontSize: 'var(--gd-size-big-title)',
        fontWeight: 'bold',
        color: 'var(--gd-big-text-dark-color)',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        adminHome: {
            padding: '0',
            paddingBottom: '20px',
        },
    },
    [MediaQueryBreakpoints.TABLETTE]: {
        adminHome: {
            padding: '0',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        adminHome: {
            padding: '0',
        },
    },
})

export default function AdminHome() {
    const { user, lang } = useUser()
    const styles = useStyles()
    const { path } = useRouteMatch()
    const location = useLocation()
    const params = useParams<Params>()

    if (!isAdmin(user)) {
        return <Redirect to={`${params.langParam}/${params.customerParam}/`} />
    }

    return (
        <div className={styles.adminHome}>
            <div className={styles.mainTitle}>{lang.administration.title} </div>
            <Switch>
                <Route exact path={`${path}`} component={CohortList} />
                <Route path={`${path}/cohort`} component={CohortDetails} />
                <Route
                    render={() => {
                        console.error('No admin route !')
                        return <Redirect to={location.pathname} />
                    }}
                />
            </Switch>
        </div>
    )
}
