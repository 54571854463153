import { createUseStyles } from 'react-jss'
import { convertToRem } from '../utils'
import classNames from 'classnames'

interface Props {
    classNames?: {
        container?: string
    }
    title: string
    description: string
}

const useStyles = createUseStyles({
    container: {
        padding: `${convertToRem(10)} ${convertToRem(20)}`,
        display: 'flex',
        flexDirection: 'column',
        gap: convertToRem(5),
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: convertToRem(10),
        fontSize: 'var(--gd-size-small-body-text)',
    },
    title: {
        fontWeight: 'bold',
    },
})

export default function ChartDescription({ title, description, classNames: customClassNames }: Readonly<Props>) {
    const styles = useStyles()
    return (
        <div className={classNames(styles.container, customClassNames?.container)}>
            <div className={styles.title}>{title}</div>
            <p>{description}</p>
        </div>
    )
}
