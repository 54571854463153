import classNames from 'classnames'
import { useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Link, useHistory } from 'react-router-dom'
import { useInstance, useUser } from '../../contexts'
import { doLogout } from '../../contexts/AuthService'
import { Role } from '../../models'
import { useDelete, usePut } from '../../services/api/useFetch'
import settings from '../../settings'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import { isAdmin, isAdminUsingAnimatorMode, isAtLeastAnimator, isSuperAdmin } from '../rolesUtils'
import ConfirmationPopup from '../share/ConfirmationPopup'
import useOnClickOutside from '../useOnClickOutside'
import Avatar from './Avatar'

const useStyles = createUseStyles({
    headerContainer: {
        width: '100%',
        height: 'auto',
        maxWidth: '1142px',
        margin: '15px auto 5px auto',
        padding: '20px 0',
        zIndex: 3,
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    logo: {
        cursor: 'pointer',
        maxWidth: '270px',
        '& *': {
            width: '100%',
            padding: '0 !important',
        },
    },
    langContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    contentLeft: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
    },
    langs: {
        fontWeight: 'bold',
        margin: '0 5px',
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
    },
    activeLang: {
        color: 'var(--gd-primary-color)',
        borderBottom: '3px solid var(--gd-primary-color)',
    },
    inactiveLang: {
        color: '#C8C8C8',
    },
    menuContainer: {
        position: 'absolute',
        maxWidth: '1180px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 15px 0 0 ',
    },
    menu: {
        position: 'relative',
        boxShadow: 'rgb(0 0 0 / 8%) 1px 5px 5px 5px',
        minWidth: '300px',
        backgroundColor: 'var(--gd-primary-color)',
        padding: '0 15px',
        borderRadius: 'var(--gd-border-small-radius)',
    },
    menuItem: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        padding: '15px',
        borderBottom: '2px solid #FFFFFF',
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
        width: '100%',
        fontSize: '15px',
        display: 'flex',
    },
    menuItemLast: {
        borderBottom: 'none',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        headerContainer: {
            padding: '20px',
        },
    },
})

export default function Header() {
    const { user, switchRole, switchLang, lang, refreshUser } = useUser()
    const { customer } = useInstance()
    const [showPopup, setShowPopup] = useState(false)
    const { doDelete, working } = useDelete({
        endpoint: 'delete-my-account',
        params: { userId: user?.userId },
        onSuccess: () => {
            logout()
        },
    })
    const styles = useStyles()
    const history = useHistory()

    const [menuVisibility, setMenuVisibility] = useState<boolean>(false)

    const menuRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({
        targetRef: menuRef,
        callback: () => {
            setMenuVisibility(false)
        },
        trigger: menuVisibility,
    })

    const { doPut: toggleDemoMode } = usePut({
        endpoint: 'toggle-demo-mode',
        onSuccess: () => {
            refreshUser()
        },
    })

    const logout = async () => {
        return doLogout(customer, lang)
    }

    const deleteMyAccount = async () => {
        if (working) {
            return
        }
        await doDelete()
    }

    const switchToRealRole = async () => {
        history.replace(`/${lang.code}/${customer.name}`)
        await switchRole(user?.realRole ?? Role.SIMPLE)
    }

    const getHelpButton = () => {
        return (
            <a href={lang.headerMenu.help.link} target='_blank' rel='noopener noreferrer'>
                <div className={styles.menuItem}>{lang.headerMenu.help.title}</div>
            </a>
        )
    }

    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerContent}>
                <Link className={styles.logo} to={`/${lang.code}/${customer.name}`}>
                    <customer.logo />
                </Link>
                <div className={styles.contentLeft}>
                    <div className={styles.langContainer}>
                        {settings.lang.map((lng) => {
                            return (
                                <button
                                    key={lng.code}
                                    className={classNames([styles.langs, lng.code === lang.code ? styles.activeLang : styles.inactiveLang])}
                                    onClick={async () => await switchLang(lng)}
                                >
                                    <div key={lng.code}>{lng.display.toUpperCase()}</div>
                                </button>
                            )
                        })}
                    </div>
                    <Avatar onClick={() => setMenuVisibility(!menuVisibility)} />
                </div>
            </div>

            {menuVisibility ? (
                <div className={styles.menuContainer}>
                    <div
                        className={styles.menu}
                        ref={menuRef}
                        onClick={() => {
                            setMenuVisibility(false)
                        }}
                    >
                        <Link to={`/${lang.code}/${customer.name}/legal-information`}>
                            <div className={styles.menuItem}>{lang.headerMenu.info}</div>
                        </Link>
                        {getHelpButton()}
                        {isAdmin(user) ? (
                            <Link to={`/${lang.code}/${customer.name}/administration`}>
                                <div className={styles.menuItem}>{lang.headerMenu.admin}</div>
                            </Link>
                        ) : null}
                        {isAtLeastAnimator(user) ? (
                            <Link to={`/${lang.code}/${customer.name}/animation`}>
                                <div className={styles.menuItem}>{lang.headerMenu.animation}</div>
                            </Link>
                        ) : null}
                        {isAdminUsingAnimatorMode(user) ? (
                            <button className={styles.menuItem} onClick={switchToRealRole}>
                                {lang.headerMenu.switchToAdministratorAlert}
                            </button>
                        ) : null}
                        {isSuperAdmin(user) ? (
                            <button className={styles.menuItem} onClick={toggleDemoMode}>
                                {user?.inDemoMode ? lang.headerMenu.switchToStandardMode : lang.headerMenu.switchToDemoMode}
                            </button>
                        ) : null}
                        <button className={styles.menuItem} onClick={logout}>
                            {lang.headerMenu.logout}
                        </button>
                        <button
                            className={classNames(styles.menuItem, styles.menuItemLast)}
                            onClick={() => {
                                setShowPopup(true)
                                setMenuVisibility(false)
                            }}
                        >
                            {lang.headerMenu.deleteAccount}
                        </button>
                    </div>
                </div>
            ) : null}

            <ConfirmationPopup
                showPopup={showPopup}
                title={lang.deleteAccountPopup.title}
                messages={lang.deleteAccountPopup.messages}
                confirmBtnText={lang.deleteAccountPopup.confirmBtnText}
                cancelBtnText={lang.deleteAccountPopup.cancelBtnText}
                onCancel={() => {
                    setShowPopup(false)
                }}
                onConfirm={async () => {
                    await deleteMyAccount()
                    setShowPopup(false)
                }}
            />
        </div>
    )
}
