import { useEffect, useState } from 'react'

interface CarbonFootprintIconProps {
    activated: boolean
    onClick: () => void
}

export default function CarbonFootprintIcon({ activated, onClick }: Readonly<CarbonFootprintIconProps>) {
    const [active, setActive] = useState<boolean>()

    useEffect(() => {
        setActive(activated)
    }, [activated])

    return (
        <svg
            width='38'
            height='38'
            viewBox='0 0 38 38'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onMouseOver={() => setActive(true)}
            onMouseLeave={() => {
                if (!activated) setActive(false)
            }}
            onClick={onClick}
            className={active ? 'activated' : ''}
        >
            <circle cx='19' cy='19' r='19' />
            <path
                d='M22.3631 16.3574C18.8957 14.1658 15.3071 15.7391 11.9915 12.3039C11.2192 11.5023 11.6678 21.2927 15.8181 25.0027C18.8524 27.7127 23.5376 27.4104 25.1173 24.7768C26.6971 22.1431 25.8304 18.5487 22.3631 16.3574V16.3574Z'
                stroke='white'
                strokeWidth='1.4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='M17.0039 19.578C20.14 22.6033 23.3854 24.3961 27.6519 25.0684' stroke='white' strokeWidth='1.4' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    )
}
