import { createUseStyles } from 'react-jss'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import { convertToRem } from '../utils'
import { GreetFeaturingTarget } from '../share/GreetFeaturingComponent'

export const useGoalCardContainerStyle = createUseStyles({
    cardButton: {
        width: '80%',
        padding: '0 20px',
    },
    activeContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 5px',
    },
    activeContent: {
        backgroundColor: 'var(--gd-background-clear-color)',
        width: '100%',
        height: '100%',
        borderRadius: 'var(--gd-border-normal-radius)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        border: '4px solid transparent',
        color: 'var(--gd-text-dark-color)',
        padding: '30px 0',
        paddingBottom: '26px',
    },
    activeDate: {
        width: '80%',
        backgroundColor: '#fff',
        borderRadius: 'var(--gd-border-big-radius)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--gd-secondary-dark-color)',
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-small-body-text)',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    cohortNameRanking: {
        width: '80%',
        height: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 'var(--gd-border-big-radius)',
        color: 'var(--gd-secondary-dark-color)',
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-small-body-text)',
    },
    progressTextRanking: {
        marginTop: convertToRem(20),
        fontSize: 'var(--gd-size-small-body-text)',
        textAlign: 'center',
    },
    individualActiveContent: {
        border: '4px solid var(--gd-primary-color)',
        color: 'var(--gd-primary-color)',
    },
    individualActiveDate: {
        color: 'var(--gd-primary-color)',
    },
    activeProgressTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70%',
        height: '70px',
        textAlign: 'center',
        fontSize: 'var(--gd-size-body-text)',
        fontWeight: 'bold',
        rowGap: '5px',
    },
    activeProgressTitle: {
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'normal',
    },
    activeCohortName: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
    },
    individualActiveProgressTextContainer: {
        rowGap: '10px',
    },
    userIconContainer: {
        width: '15px',
        height: '20px',
    },
    activePrecision: {
        display: 'flex',
        fontWeight: '600',
        fontSize: 'var(--gd-size-small-body-text)',
        justifyContent: 'center',
        textAlign: 'center',
        width: '70%',
    },
    activePrecisionCollective: {
        marginBottom: convertToRem(34),
    },
    pointsContainer: {
        display: 'flex',
        gap: '5px',
        padding: '3px 10px 3px 3px',
        borderRadius: '25px',
        background: 'var(--gd-main-app-background-color)',
        fontWeight: 'bold',
        alignItems: 'center',
    },
    card: {
        backgroundColor: 'var(--gd-background-dark-color)',
        width: '100%',
        borderRadius: 'var(--gd-border-normal-radius)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        color: 'var(--gd-text-dark-color)',
        margin: '0 5px',
        maxWidth: '275px',
    },
    goalIcon: {
        height: '22px',
        width: '22px',
    },
    scorePointsInformationIcon: {
        width: '15px',
        height: '15px',
    },
    individualProgressTimeline: {
        marginTop: convertToRem(15),
        fontSize: 'var(--gd-size-small-body-text)',
        textAlign: 'center',
    },
    individualProgressContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '16px',
        paddingBottom: '30px',
        width: '100%',
        backgroundColor: 'var(--gd-background-clear-color)',
        gap: '0.95rem',
    },
    rankingContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.6rem',
        marginTop: '10px',
        alignItems: 'center',
    },
    avatar: {
        width: '60px !important',
        height: '60px !important',
    },
})

export const useGoalCardStyle = createUseStyles({
    goalCard: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        maxWidth: '285px',
        minWidth: '285px',
        minHeight: '322px',
        gap: '25px',
    },
    goalTitle: {
        display: 'block',
    },
    inactiveContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 5px',
        height: '100%',
    },
    inactiveContent: {
        backgroundColor: 'var(--gd-background-clear-color)',
        width: '100%',
        height: '100%',
        borderRadius: 'var(--gd-border-normal-radius)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: '26px',
        padding: '50px 10px 28px 10px',
        color: 'var(--gd-primary-color)',
    },
    inactiveDescription: {
        fontSize: 'var(--gd-size-body-text)',
        fontWeight: 'bold',
        lineHeight: '20px',
    },
    individualInactiveContent: {
        border: '4px solid var(--gd-primary-color)',
    },
    icon: {
        width: '38px',
        height: '38px',
        marginBottom: '5px',
    },
    inactiveTheme: {
        color: 'var(--gd-primary-color)',
        textAlign: 'center',
        height: 'content',
    },
    inactiveTitle: {
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
        color: 'var(--gd-secondary-dark-color)',
        textAlign: 'center',
    },
    inactiveIllustration: {
        width: '100%',
        height: '150px',
        marginBottom: '40px',
    },

    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        goalCard: {
            maxWidth: 'unset',
        },
        goalTitle: {
            display: 'none',
        },
    },
})

export const useGoalTitleStyle = (textColor: string, lineColor: string, featuring: GreetFeaturingTarget[]) =>
    createUseStyles({
        goalTitle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: featuring.includes(GreetFeaturingTarget.RANKING) ? 'center' : 'flex-end',
            gap: '12px',
            width: '100%',
            height: '80px',
            padding: '17px 0',
        },
        goalTitleBorder: {
            borderBottom: '2px solid',
            borderBottomColor: lineColor,
        },
        goalTitleShortBorder: {
            width: '97.5%',
        },
        goalTitleHeight: {
            height: '80px',
            padding: '17px 0',
        },
        goalTileInfo: {
            display: 'flex',
            flexDirection: 'column',
            color: textColor,
        },
        goalTileType: {
            fontSize: 'var(--gd-size-small-body-text)',
            lineHeight: '17.6px',
        },
        goalTileDescription: {
            fontSize: 'var(--gd-size-highlighted-small-title)',
            lineHeight: '20px',
            fontWeight: 'bold',
        },
        goalIcon: {
            height: '38px',
            width: '38px',
            minWidth: '38px',
        },
        addIcon: {
            height: '23px',
            width: '23px',
            minWidth: '23px',
            cursor: 'pointer',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            goalTitle: {
                maxWidth: 'unset',
            },
        },
    })()
