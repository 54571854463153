const STORAGE_NAME = 'deleteFolderProposalIndex'

interface DeleteFolderProposals {
    updatedDate: string
    index: number
}

const deleteFolderProposal = {
    store: (deleteFolderProposal: DeleteFolderProposals): void => {
        localStorage.setItem(STORAGE_NAME, JSON.stringify(deleteFolderProposal))
    },
    get: (): DeleteFolderProposals | null => {
        const str = localStorage.getItem(STORAGE_NAME)
        return str == null ? null : JSON.parse(str)
    },
    clear: () => {
        localStorage.removeItem(STORAGE_NAME)
    },
}

export default deleteFolderProposal

