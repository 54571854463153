import { Customer } from '../../models'
import digigreen from './digigreen'
import digigreenBeta from './digigreenBeta'
import fft from './fft'
import fftBeta from './fftBeta'
import lecko from './lecko'
import leckoBeta from './leckoBeta'
import leckoGsuite from './leckoGsuite'
import loreal from './loreal'
import lorealBeta from './lorealBeta'
import swisslife from './swisslife'
import swisslifeBeta from './swisslifeBeta'
import ten from './ten'
import tenMs from './tenMs'

const customer: Customer[] = [lecko, leckoBeta, leckoGsuite, loreal, lorealBeta, swisslife, swisslifeBeta, digigreen, digigreenBeta, fft, fftBeta, ten, tenMs]

export default customer
