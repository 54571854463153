import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { convertToRem } from '../../utils'

type Orientation = 'horizental' | 'vertical'

interface ItemI {
    label: string
    icon: JSX.Element
    orientation?: Orientation
    reversed?: boolean
    classNames?: {
        container?: string
        label?: string
    }
}

interface Props {
    items: Array<ItemI>
    classNames?: {
        container?: string
    }
}

const useLegendStyles = createUseStyles({
    legend: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export default function ChartLegend({ items, classNames: customClassNames }: Readonly<Props>) {
    const styles = useLegendStyles()
    return (
        <div className={classNames(styles.legend, customClassNames?.container)}>
            {items.map((item) => (
                <LegendItem key={item.label} classNames={item.classNames} label={item.label} icon={item.icon} orientation={item.orientation} reversed={item.reversed} />
            ))}
        </div>
    )
}

const useLegendItemStyles = createUseStyles({
    item: {
        display: 'flex',
        flexDirection: ({ orientation, reversed }: { orientation: Orientation; reversed: boolean }) =>
            ({ ['horizental']: reversed ? 'row-reverse' : 'row', ['vertical']: reversed ? 'column-reverse' : 'column' }[orientation]),
        justifyContent: 'center',
        alignItems: 'center',
    },
    label: {
        fontSize: 'var(--gd-size-legend-text)',
        fontWeight: 400,
        color: 'var(--gd-text-dark-color)',
        lineHeight: convertToRem(16),
        letterSpacing: convertToRem(0.15),
    },
})

function LegendItem({ label, icon, orientation = 'horizental', reversed = false, classNames: customClassNames }: Readonly<ItemI>) {
    const styles = useLegendItemStyles({ orientation, reversed })

    return (
        <div className={classNames(styles.item, customClassNames?.container)}>
            {icon}
            <div className={classNames(styles.label, customClassNames?.label)}>{label}</div>
        </div>
    )
}
