import { Lang } from '../../../../models'
import { BadgeType } from '../../../../models/Badge'
import defaultLang from '../../../lang/fr'

const LeckoGsuiteFr: Lang = {
    ...defaultLang,
    authentication: {
        ...defaultLang.authentication,
        description: ['Mesurez les émissions CO2 de vos usages sur Google pour développer des pratiques numériques de travail tenables sur la durée'],
        buttonLabel: 'Se connecter avec Google',
    },
    presentation: {
        ...defaultLang.presentation,
        description: ['Mesurez les émissions CO2 de vos usages sur Google pour développer des pratiques numériques de travail tenables sur la durée'],
    },
    deleteAccountPopup: {
        ...defaultLang.deleteAccountPopup,
        messages: [
            'En confirmant, votre compte Greet sera supprimé, vos données personnelles seront effacées.',
            "Pour finaliser l'opération, nous vous demandons dans l'écran suivant de vous déconnecter de votre compte Google.",
        ],
    },
    home: {
        ...defaultLang.home,
        victory: {
            ...defaultLang.home.victory,
            badges: {
                ...defaultLang.home.victory.badges,
                drive: {
                    ...defaultLang.home.victory.badges.drive,
                    comparedToLastYear: (removedDataMB: number) => `${removedDataMB} Mo supprimé${removedDataMB > 1 ? 's' : ''} sur votre Drive`,
                },
                imageHunt: {
                    ...defaultLang.home.victory.badges.imageHunt,
                    comparedToLastYear: (deletedImagesVolume: number) => `${deletedImagesVolume} Mo d'images supprimées sur votre Drive personnel`,
                },
                videoHunt: {
                    ...defaultLang.home.victory.badges.imageHunt,
                    comparedToLastYear: (deletedVideosVolume: number) => `${deletedVideosVolume} Mo de vidéos supprimées sur votre Drive personnel`,
                },
            },
        },
    },
    goal: {
        ...defaultLang.goal,
        weeklyCleaning: {
            ...defaultLang.goal.weeklyCleaning,
            description: 'Nettoyez votre boite email et votre espace Drive',
        },
        carbonFootprint: {
            ...defaultLang.goal.carbonFootprint,
            evolution: {
                ...defaultLang.goal.carbonFootprint.evolution,
                details: 'émis avec l’envoi d’emails, l’usage de Drive et Drive partagé',
            },
        },
        individual: {
            ...defaultLang.goal.individual,
            deleteFolderProposal: {
                ...defaultLang.goal.individual.deleteFolderProposal,
                title: 'Nettoyer Drive',
                goToFolder: 'Ouvrir dans Drive',
            },
        },
        challenge: {
            ...defaultLang.goal.challenge,
            digitalCleanupDay: {
                ...defaultLang.goal.challenge.digitalCleanupDay,
                after: {
                    ...defaultLang.goal.challenge.digitalCleanupDay.after,
                    title: 'Nettoyez votre boite email et votre espace Drive',
                },
            },
            description(challenge) {
                switch (challenge.badgeType) {
                    case BadgeType.MAIL:
                    case BadgeType.MAILSHOT:
                    case BadgeType.INTERN_ATTACHMENT:
                        return defaultLang.goal.challenge.description(challenge)
                    case BadgeType.DRIVE:
                        return `Supprimez ${challenge.driveGoalSize} Mo dans votre Drive personnel`
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return 'Nettoyez votre boite email et votre espace Drive'
                    case BadgeType.IMAGE_HUNT:
                        return `Supprimez cette semaine ${challenge.imageHuntGoalSize} Mo d’images dans votre Drive personnel`
                    case BadgeType.VIDEO_HUNT:
                        return `Supprimez cette semaine ${challenge.videosHuntGoalSize} Mo de vidéos dans votre Drive personnel`
                    default:
                        return ''
                }
            },
            badgeDescription(badgeType) {
                switch (badgeType) {
                    case BadgeType.MAIL:
                    case BadgeType.MAILSHOT:
                    case BadgeType.INTERN_ATTACHMENT:
                    case BadgeType.PLANET_FRIENDLY:
                    case BadgeType.DIGITAL_CLEANUP_DAY:
                        return defaultLang.goal.challenge.badgeDescription(badgeType)
                    case BadgeType.DRIVE:
                        return 'Les badges liés à la suppression de fichier dans votre Drive personnel'
                    case BadgeType.IMAGE_HUNT:
                        return "Les badges liés à la réduction du volume d'image sur votre Drive personnel"
                    case BadgeType.VIDEO_HUNT:
                        return 'Les badges liés à la réduction du volume de vidéo sur votre Drive personnel'
                    default:
                        return ''
                }
            },
        },
    },
    welcomePopupMessage: [
        "L'application Greet collecte les informations de profil de votre compte Google (nom, prénom, photo) ainsi que des données d'usage des applications Gmail, Drive partagé, Drive pour mesurer leur impact sur les émissions de CO2.",
        'Vous pourrez à tout moment supprimer votre compte via le menu disponible en cliquant sur votre photo de profil.',
    ],
    badgeMonitor: {
        ...defaultLang.badgeMonitor,
        popup: {
            ...defaultLang.badgeMonitor.popup,
            drive: (removedDataMB: number) => `${removedDataMB} Mo supprimé${removedDataMB > 1 ? 's' : ''} sur votre Drive`,
            imageHunt: (deletedImagesVolume: number) => `${deletedImagesVolume} Mo d'images supprimées sur votre Drive personnel`,
            videoHunt: (deletedVideosVolume: number) => `${deletedVideosVolume} Mo de vidéos supprimées sur votre Drive personnel`,
        },
    },
}

export default LeckoGsuiteFr
