import { createUseStyles } from 'react-jss'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import Rate from './Rate'
import { convertToRem } from './utils'

interface Props {
    period: string
    periodComputedCount: {
        count?: string
        description: string
    }
    periodComputedEvolution: number
    noEvolutionInfo: string
    comparedToText: string
}

const useStyles = createUseStyles({
    container: {
        padding: `${convertToRem(15)} 0`,
        height: convertToRem(280),
        maxWidth: convertToRem(360),
        minWidth: convertToRem(360),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        backgroundColor: 'var(--gd-secondary-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
    },
    periodContainer: {
        padding: convertToRem(15),
        width: '80%',
        height: convertToRem(25),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: 'var(--gd-border-big-radius)',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 'bold',
        color: 'var(--gd-text-dark-color)',
    },
    carbonfootprintContainer: {
        marginTop: convertToRem(15),
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    detailsText: {
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'bold',
        color: 'var(--gd-text-clear-color)',
        width: '80%',
        marginTop: convertToRem(15),
        textAlign: 'center',
    },
    rateValue: {
        fontSize: 'var(--gd-size-highlighted-number)',
        fontWeight: 'bold',
        color: 'var(--gd-text-clear-color)',
        marginRight: convertToRem(4),
    },
    rateWrapper: {
        marginTop: convertToRem(10),
        width: '50%',
        height: convertToRem(45),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: 'var(--gd-border-normal-radius)',
    },
    comparedToText: {
        marginTop: convertToRem(10),
        fontSize: 'var(--gd-size-small-body-text)',
        color: 'var(--gd-text-clear-color)',
        textAlign: 'center',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        container: {
            width: '100%',
            maxWidth: 'unset',
            minWidth: 'unset',
        },
    },
})

export default function EvolutionWidget({ period, periodComputedCount, noEvolutionInfo, periodComputedEvolution, comparedToText }: Props) {
    const styles = useStyles()
    return (
        <div className={styles.container}>
            <div className={styles.periodContainer}>{period}</div>
            {periodComputedCount.count ? (
                <span className={styles.detailsText}>
                    <span className={styles.rateValue}>{periodComputedCount.count}</span>
                    <span>{periodComputedCount.description}</span>
                </span>
            ) : (
                <div className={styles.carbonfootprintContainer}>
                    <span className={styles.rateValue}>{noEvolutionInfo}</span>
                </div>
            )}
            <div className={styles.rateWrapper}>
                <Rate value={periodComputedEvolution} />
            </div>
            <span className={styles.comparedToText}>{comparedToText}</span>
        </div>
    )
}
