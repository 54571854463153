import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ApiError from '../../components/share/ApiErrors'
import { useUser } from '../../contexts'
import { buildUserAxiosInstance, cancelationTokenSource } from './axiosService'

interface ApiCallParams {
    baseEndpoint?: string
    endpoint: string
    params?: any
    watchedParams?: any
}

interface FetchParams extends ApiCallParams {
    retrieveStoredData?: (params: any) => any
    onSuccess?: (data: any) => void
}

export const useFetch = ({ baseEndpoint = '/greet-desktop/', endpoint, params = {}, watchedParams = {}, retrieveStoredData, onSuccess }: FetchParams) => {
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [errors, setErrors] = useState<any>(null)
    const { user, refreshToken } = useUser()
    const paramsHasNullValues = Object.keys(params)?.some((key) => params[key] == null)
    const watchedParamsHasNullValues = Object.keys(watchedParams)?.some((key) => watchedParams[key] == null)
    const axiosInstance = buildUserAxiosInstance(user, refreshToken)

    useEffect(() => {
        const queryParams = { params: params }
        const fireApiCall = async () => {
            try {
                setLoading(true)
                setErrors(null)
                setData(null)
                let localData = null
                if (retrieveStoredData != null) {
                    localData = retrieveStoredData(params)
                }
                if (retrieveStoredData == null || localData == null || localData?.length === 0) {
                    const response = await axiosInstance.get(`${baseEndpoint}${endpoint}`, { ...queryParams, cancelToken: cancelationTokenSource.token })
                    localData = response.data
                }
                if (onSuccess != null && localData != null) {
                    onSuccess(localData)
                }
                setData(localData)
            } catch (error: any) {
                setErrors(error?.message ?? error)
                fireTaostError(error)
                console.error(error)
            } finally {
                setLoading(false)
            }
        }
        if (paramsHasNullValues || watchedParamsHasNullValues) {
            setLoading(false)
            // avoid firing query whith nulls definied params
            return
        }
        fireApiCall()
    }, [endpoint, ...(Object.keys(params)?.map((key) => params[key]) ?? []), ...(Object.keys(watchedParams)?.map((key) => watchedParams[key]) ?? [])])
    return {
        data,
        loading,
        errors,
    }
}

interface PutParams extends ApiCallParams {
    enableTaostSuccess?: boolean
    onSuccess?: (data: any, putBody: any) => void
    processError?: (error: any) => string
}

export const usePut = ({ baseEndpoint = '/greet-desktop/', endpoint, params = {}, onSuccess: onSucess, processError, enableTaostSuccess = true }: PutParams) => {
    const [data, setData] = useState<any>(null)
    const [working, setWorking] = useState<boolean>(false)
    const [errors, setErrors] = useState<any>(null)
    const { user, refreshToken } = useUser()

    const paramsHasNullValues = Object.keys(params)?.some((key) => params[key] == null)
    const axiosInstance = buildUserAxiosInstance(user, refreshToken)

    const doPut = useCallback(
        async (payload: any) => {
            const queryParams = { params: params }

            const fireApiCall = async () => {
                if (paramsHasNullValues) {
                    // avoid firing query whith nulls definied params
                    return
                }

                setErrors(null)
                setData(null)
                try {
                    setWorking(true)
                    const response = await axiosInstance.put(`${baseEndpoint}${endpoint}`, payload, { ...queryParams, cancelToken: cancelationTokenSource.token })
                    setData(response.data)
                    if (onSucess != null) {
                        onSucess(response.data, payload)
                    }
                    if (enableTaostSuccess) {
                        fireTaostSuccess('Opération réussie')
                    }
                } catch (error: any) {
                    if (processError != null) {
                        setErrors(processError(error))
                    } else {
                        setErrors(error?.message ?? error)
                    }
                    fireTaostError(error)
                    console.error(error)
                } finally {
                    setWorking(false)
                }
            }
            await fireApiCall()
        },
        [endpoint, ...(Object.keys(params)?.map((key) => params[key]) ?? [])],
    )
    return {
        doPut,
        data,
        working,
        errors,
    }
}
interface DeleteParams extends ApiCallParams {
    onSuccess?: (data: any, specificParams?: any) => void
}

export const useDelete = ({ baseEndpoint = '/greet-desktop/', endpoint, params = {}, onSuccess }: DeleteParams) => {
    const [data, setData] = useState<any>(null)
    const [working, setWorking] = useState<boolean>(false)
    const [errors, setErrors] = useState<any>(null)
    const { user, refreshToken } = useUser()

    const paramsHasNullValues = Object.keys(params)?.some((key) => params[key] == null)
    const axiosInstance = buildUserAxiosInstance(user, refreshToken)

    const doDelete = useCallback(
        async (specificParams?: any) => {
            const queryParams = { params: { ...params, ...(specificParams ?? {}) } }
            setErrors(null)
            setData(null)

            const fireApiCall = async () => {
                if (paramsHasNullValues) {
                    // avoid firing query whith nulls definied params
                    return
                }
                try {
                    setWorking(true)
                    const response = await axiosInstance.delete(`${baseEndpoint}${endpoint}`, { ...queryParams, cancelToken: cancelationTokenSource.token })
                    setData(response.data)
                    if (onSuccess != null) {
                        onSuccess(response.data, specificParams)
                    }
                } catch (error: any) {
                    setErrors(error?.message ?? error)
                    fireTaostError(error)
                    console.error(error)
                } finally {
                    setWorking(false)
                }
            }
            await fireApiCall()
        },
        [endpoint, ...(Object.keys(params)?.map((key) => params[key]) ?? [])],
    )
    return {
        doDelete,
        data,
        working,
        errors,
    }
}

export const fireTaostError = (error?: any) => {
    return toast.error(<ApiError error={error} />, {
        pauseOnFocusLoss: false,
        position: toast.POSITION.BOTTOM_RIGHT,
    })
}

export const fireTaostSuccess = (message?: any) => {
    return toast.success(message, {
        pauseOnFocusLoss: false,
        position: toast.POSITION.BOTTOM_RIGHT,
    })
}
