import { Lang } from '../../../../models'
import defaultLang from '../../../lang/fr'
import { tipsFr } from './tipsContents'

const swisslifeFr: Lang = {
    ...defaultLang,
    webinar: {
        ...defaultLang.webinar,
        title: "Le parcours de l'engagement",
        startAt: '',
        contentTitle: '#ActForLife',
        description: `Lutter contre la pollution numérique, c’est aussi diminuer nos usages de dispositifs informatiques et chercher à les faire durer plus longtemps.
        Via les défis de notre plateforme d’engagement #ActForLife, découvrez toutes les clés pour adopter une démarche plus responsable, à toutes les étapes de la vie d’un appareil numérique.
        `,
        registerationUrl: 'https://app.vendredi.cc/collaborateur/swisslife/defis/804-numerique-responsable-conference-sensibilisation',
        register: 'Je me lance !',
    },
    tipsContent: { ...tipsFr },
    authentication: {
        ...defaultLang.authentication,
        title: 'Mesurez les émissions CO2 de vos usages Office 365',
        description: ['Collectivement, nous avons la capacité de transformer notre organisation : développons des pratiques numériques responsables et tenables sur la durée !'],
    },
    presentation: {
        title: 'Mesurez les émissions CO2 de vos usages Office 365',
        description: ['Collectivement, nous avons la capacité de transformer notre organisation : développons des pratiques numériques responsables et tenables sur la durée !'],
    },
    goal: {
        ...defaultLang.goal,
        typeInfo: {
            ...defaultLang.goal.typeInfo,
            carbonFootPrint: {
                ...defaultLang.goal.typeInfo.carbonFootPrint,
                title: 'Réduire mes émissions CO2',
            },
        },
        collective: {
            ...defaultLang.goal.collective,
            presentation: {
                ...defaultLang.goal.collective.presentation,
                noDescription: 'Définissez l’objectif de votre équipe',
            },
        },
        individual: {
            ...defaultLang.goal.individual,
            planetFriendlyBadge: {
                ...defaultLang.goal.individual.planetFriendlyBadge,
                noBadge: `Chaque mois, j’obtiens mon badge Planet friendly
               en réduisant mes émissions de 4% sur le mois comparé au même mois de l’année précédente.`,
            },
        },
        carbonFootprint: {
            ...defaultLang.goal.carbonFootprint,
            evolution: {
                ...defaultLang.goal.carbonFootprint.evolution,
                details: 'émis suite à l’envoi d’emails, l’usage de OneDrive et Teams',
            },
        },
        carbonCumul: {
            ...defaultLang.goal.carbonCumul,
            leftTextIndividuel: 'Réduire de 4% mes émissions CO2 / an pour s’aligner avec les Accords de Paris.',
            leftTextGroup: 'Réduire de 4% nos émissions CO2 / an pour s’aligner avec les Accords de Paris.',
        },
        cohort: {
            ...defaultLang.goal.cohort,
            title: 'Nos collaborateurs se mobilisent',
        },
    },
}

export default swisslifeFr
