import { useEffect, useState } from 'react'
import { Path, SubmitHandler, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { useParams } from 'react-router-dom'
import IconAssets from '../assets/icon/IconAssets'
import SetupChallengeIcon from '../assets/icon/setup-challenge'
import { useUser } from '../contexts'

import { EGoal, Lang } from '../models'
import { BadgeType } from '../models/Badge'
import { Challenge } from '../models/Challenge'
import DateRequestDto from '../models/dto/DateRequestDto'
import { useDelete, usePut } from '../services/api/useFetch'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import { TimeUnit, add, areSame, dateToISOStringWithoutTime, isOnCurrentWeek, toDayLiteralMonthString } from './dateUtils'
import { GreetSubmitButton } from './share/GreetButton'
import { SelectField, TextField } from './share/Inputs'

const NUMBER_OF_WEEK_PERIOD = 10

interface SetupChallengeProps {
    cohortId: string
    challenges: Challenge[]
    addChallenge: (challenge: Challenge) => void
    updateChallenge: (challenge: Challenge) => void
    removeChallenge: (challengeId: string) => void
}
const defaulFormValues = {
    periode: undefined,
    categorie: undefined,
    mailSize: undefined,
    driveSize: undefined,
}

const useStyles = createUseStyles({
    setupChallenge: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'var(--gd-primary-color)',
        width: '100%',
        minWidth: '40vw',
        minHeight: '40vh',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '50px',
    },
    headerTitle: {
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 700,
        color: 'var(--gd-text-clear-color)',
    },
    setupChallengeBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: 'var(--gd-main-app-background-color)',
        height: '100%',
        borderRadius: 'var(--gd-border-normal-radius)',
        overflowY: 'auto',
    },
    bodyTitle: {
        fontSize: 24,
        fontWeight: 700,
        marginLeft: '50px',
    },
    currentChallengeSummary: {
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 50px',
        gap: '20px',
    },
    currentChallengeSummaryTitle: {
        fontSize: 24,
        fontWeight: 700,
        color: 'var(--gd-secondary-dark-color)',
    },
    currentChallengeContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 'var(--gd-border-normal-radius)',
        gap: '10px',
    },
    currentChallenge: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    currentChallengeTitle: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 700,
    },
    currentChallengeInfo: {
        display: 'flex',
        flexDirection: 'row',
        flex: '90',
        columnGap: '25px',
        alignItems: 'center',
    },
    currentChallengeActions: {
        display: 'flex',
        flexDirection: 'row',
        flex: '10',
        columnGap: '15px',
        alignSelf: 'center',
    },
    currentChallengeText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '60%',
    },
    currentChallengeCategory: {
        fontSize: 18,
        fontWeight: 700,
    },
    currentChallengeDescription: {
        fontSize: 18,
        fontWeight: 400,
    },
    currentChallengeIcon: {
        width: '45px',
        height: '45px',
    },
    currentChallengeActionIcon: {
        width: '30px',
        height: '30px',
        cursor: 'pointer',
    },
    setupChallengeForm: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        height: '100%',
        width: '100%',
        borderRadius: 'var(--gd-border-normal-radius)',
        padding: '20px 40px',
        paddingBottom: '50px',
        backgroundColor: 'var(--gd-background-clear-color)',
    },
    setupChallengeFormTitle: {
        fontSize: 24,
        fontWeight: 700,
        color: 'var(--gd-secondary-dark-color)',
    },
    noChallenge: {
        textAlign: 'center',
        fontSize: 18,
        margin: '15px 0px 15px 0px',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        currentChallengeSummary: {
            flexDirection: 'column',
            padding: '30px 20px',
            gap: '20px',
        },
        currentChallengeSummaryTitle: {
            fontSize: 24,
            fontWeight: 700,
            color: 'var(--gd-secondary-dark-color)',
        },
        currentChallengeIcon: {
            width: '35px',
            height: '35px',
        },
        currentChallengeInfo: {
            flex: '80',
            gap: '10px',
        },
        currentChallengeText: {
            width: '100%',
        },
        currentChallengeActions: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            flex: '20',
            gap: '10px',
        },
        setupChallengeForm: {
            gap: '10px',
            padding: '20px 20px',
            '& div': {
                maxHeight: '85px',
            },
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        setupChallengeForm: {
            gap: '10px',
            padding: '30px 20px',
        },
        currentChallengeSummary: {
            padding: '20px 20px',
            gap: '20px',
        },
        currentChallenge: {
            gap: '10px',
        },
        currentChallengeIcon: {
            width: '40px',
            height: '40px',
        },
        currentChallengeInfo: {
            flex: '90',
            gap: '10px',
        },
        currentChallengeText: {
            width: '100%',
        },
    },
})

function getDefaultNoActiveFields(lang: Lang) {
    return {
        mailSize: false,
        mailSizeLabel: lang.goal.setupChallenge.form.sizeMail,
        driveSize: false,
        mailShotSize: false,
        imageHuntSize: false,
        videoHuntSize: false,
    }
}

export default function SetupChallenge(props: SetupChallengeProps) {
    const styles = useStyles()
    const { goalId } = useParams<{ goalId: EGoal }>()
    const { lang } = useUser()
    const [submitStatus, setSubmitStatus] = useState<string | null>(null)
    const [challengeUpdateId, setChallengeUpdateId] = useState<string | null>()
    const [defaultChallengeFormValues, setDefaultChallengeFormValues] = useState<IChallengeForm>(defaulFormValues)
    const [activeFields, setActiveFields] = useState(getDefaultNoActiveFields(lang))
    const { doDelete: doDeleteChallenge, working: deletingChallenge } = useDelete({
        endpoint: 'delete-challenge-by-id',
        onSuccess: (data, specificParams) => {
            props.removeChallenge(specificParams.challengeId)
            setDefaultChallengeFormValues(defaulFormValues)
            setChallengeUpdateId(null)
        },
    })
    const { doPut: doCreateChallenge, working: creatingChallenge } = usePut({
        endpoint: 'create-challenge-by-cohort',
        onSuccess: (data) => {
            props.addChallenge(data)
            setSubmitStatus('OK')
            setDefaultChallengeFormValues(defaulFormValues)
        },
        processError: (error: any) => {
            const message = error.response.status === 409 ? 'Un challenge existe déjà pour les même valeurs' : "Une erreur s'est produite lors de la création du challenge"
            setSubmitStatus(message)
            return message
        },
    })
    const { doPut: doUpdateChallenge, working: updatingChallenge } = usePut({
        endpoint: 'update-challenge',
        onSuccess: (data, putBody) => {
            props.updateChallenge({...data, oldId: putBody.id})
            setSubmitStatus('OK')
            setDefaultChallengeFormValues({})
            setChallengeUpdateId(null)
        },
        processError: (error: any) => {
            const message = error.response.status === 409 ? 'Un challenge existe déjà pour les même valeurs' : "Une erreur s'est produite lors de la création du challenge"
            setSubmitStatus(message)
            return message
        },
    })

    const createChallenge = async ({ categorie, driveSize, mailSize, mailShotSize, imageHuntSize, videoHuntSize, periode }: IChallengeForm): Promise<any> => {
        if (periode == null || categorie == null || creatingChallenge || updatingChallenge || deletingChallenge) {
            return
        }
        setSubmitStatus(null)
        doCreateChallenge({
            startDate: dateToISOStringWithoutTime(periode.dateFrom),
            endDate: dateToISOStringWithoutTime(periode.dateTo),
            goal: goalId,
            cohortId: props.cohortId,
            badgeType: categorie,
            mailGoalSize: mailSize ? Number(mailSize) : undefined,
            driveGoalSize: driveSize ? Number(driveSize) : undefined,
            mailShotGoalSize: mailShotSize ? Number(mailShotSize) : undefined,
            imageHuntGoalSize: imageHuntSize ? Number(imageHuntSize) : undefined,
            videosHuntGoalSize: videoHuntSize ? Number(videoHuntSize) : undefined,
        })
    }

    const updateChallenge = async ({ categorie, driveSize, mailSize, mailShotSize, imageHuntSize, videoHuntSize, periode }: IChallengeForm): Promise<any> => {
        if (periode == null || categorie == null || challengeUpdateId == null || creatingChallenge || updatingChallenge || deletingChallenge) {
            return
        }
        doUpdateChallenge({
            id: challengeUpdateId,
            startDate: dateToISOStringWithoutTime(periode.dateFrom),
            endDate: dateToISOStringWithoutTime(periode.dateTo),
            goal: goalId,
            cohortId: props.cohortId,
            badgeType: categorie,
            mailGoalSize: mailSize ? Number(mailSize) : undefined,
            driveGoalSize: driveSize ? Number(driveSize) : undefined,
            mailShotGoalSize: mailShotSize ? Number(mailShotSize) : undefined,
            imageHuntGoalSize: imageHuntSize ? Number(imageHuntSize) : undefined,
            videosHuntGoalSize: videoHuntSize ? Number(videoHuntSize) : undefined,
        })
    }

    const handleChallengeUpdate = (challenge: Challenge) => {
        setChallengeUpdateId(challenge.id)
        const startDate = challenge.startDate != null ? new Date(challenge.startDate) : null
        const endDate = challenge.endDate != null ? new Date(challenge.endDate) : null
        setDefaultChallengeFormValues({
            periode: { dateFrom: startDate, dateTo: endDate } as DateRequestDto,
            categorie: challenge.badgeType,
            mailSize: challenge.mailGoalSize?.toString(),
            driveSize: challenge.driveGoalSize?.toString(),
            mailShotSize: challenge.mailShotGoalSize?.toString(),
            imageHuntSize: challenge.imageHuntGoalSize?.toString(),
            videoHuntSize: challenge.videosHuntGoalSize?.toString(),
        })
    }

    const handleChallengeDelete = async (challenge: Challenge) => {
        if (challenge!.id == undefined || creatingChallenge || updatingChallenge || deletingChallenge) {
            return
        }
        doDeleteChallenge({ challengeId: challenge.id })
        setActiveFields(getDefaultNoActiveFields(lang))
    }

    const getIcon = (challenge: Challenge) => {
        switch (challenge.badgeType) {
            case BadgeType.MAIL:
                return <IconAssets.Attachement />
            case BadgeType.DRIVE:
                return <IconAssets.Garbage />
            case BadgeType.DIGITAL_CLEANUP_DAY:
                return <IconAssets.Cleanup />
            case BadgeType.INTERN_ATTACHMENT:
                return <IconAssets.InternAttachment />
            case BadgeType.MAILSHOT:
                return <IconAssets.MailShot />
            case BadgeType.IMAGE_HUNT:
                return <IconAssets.ImageHunt />
            case BadgeType.VIDEO_HUNT:
                return <IconAssets.VideoHunt />
            default:
                return null
        }
    }

    const renderCurrentChallenge = (challenge: Challenge): React.ReactNode => {
        if (challenge.startDate == null || challenge.endDate == null) {
            return null
        }

        const period = {
            startDate: toDayLiteralMonthString(new Date(challenge.startDate), lang.code),
            endDate: toDayLiteralMonthString(new Date(challenge.endDate), lang.code),
        }

        return (
            <div className={styles.currentChallengeContainer} key={challenge.id}>
                <div className={styles.currentChallengeTitle}>{lang.date.range(period.startDate, period.endDate)}</div>
                <div className={styles.currentChallenge}>
                    <div className={styles.currentChallengeInfo}>
                        <div className={styles.currentChallengeIcon}>{getIcon(challenge)}</div>
                        <div className={styles.currentChallengeText}>
                            <span className={styles.currentChallengeCategory}>{lang.goal.challenge.label(challenge.badgeType!)}</span>
                            <span className={styles.currentChallengeDescription}>{lang.goal.challenge.description(challenge)}</span>
                        </div>
                    </div>
                    <div className={styles.currentChallengeActions}>
                        <div className={styles.currentChallengeActionIcon} onClick={() => handleChallengeUpdate(challenge)}>
                            <SetupChallengeIcon.Edit />
                        </div>
                        <div className={styles.currentChallengeActionIcon} onClick={() => handleChallengeDelete(challenge)}>
                            <SetupChallengeIcon.Delete />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.setupChallenge}>
            <div className={styles.header}>
                <div className={styles.headerTitle}>
                    <span>{lang.goal.setupChallenge.title}</span>
                </div>
            </div>
            <div className={styles.setupChallengeBody}>
                <div className={styles.currentChallengeSummary}>
                    <div className={styles.currentChallengeSummaryTitle}>{lang.goal.setupChallenge.scheduled(props.challenges.length)}</div>
                    {props.challenges.length > 0 ? (
                        props.challenges.map(renderCurrentChallenge)
                    ) : (
                        <div className={styles.noChallenge}>{lang.goal.challenge.noChallenge.simple}</div>
                    )}
                </div>

                <div className={styles.setupChallengeForm}>
                    <div className={styles.setupChallengeFormTitle}>{lang.goal.setupChallenge.form.title}</div>
                    <ChallengeForm
                        submitStatus={submitStatus}
                        activeFields={activeFields}
                        updateActiveFields={(activeFields) => setActiveFields(activeFields)}
                        defaultValues={defaultChallengeFormValues}
                        fireSubmit={(data) => (challengeUpdateId != null ? updateChallenge(data) : createChallenge(data))}
                        unavailableMondays={props.challenges.map((challenge) => new Date(challenge.startDate as string))}
                    />
                </div>
            </div>
        </div>
    )
}

interface IChallengeForm {
    periode?: DateRequestDto | null
    categorie?: BadgeType | null
    mailSize?: string | null
    driveSize?: string | null
    mailShotSize?: string | null
    imageHuntSize?: string | null
    videoHuntSize?: string | null
}

interface IChallengeActiveFields {
    mailSize: boolean
    mailSizeLabel: string
    driveSize: boolean
    mailShotSize: boolean
    imageHuntSize: boolean
    videoHuntSize: boolean
}

interface IChallengeFormProps {
    defaultValues: IChallengeForm
    activeFields: IChallengeActiveFields
    updateActiveFields: (activeFields: IChallengeActiveFields) => void
    fireSubmit: (data: IChallengeForm) => Promise<any>
    submitStatus: string | null
    unavailableMondays: Date[]
}

const useFormStyles = createUseStyles({
    challengeForm: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
        gap: '20px',
    },
    errorStatus: {
        color: 'red',
    },
    disabledBtn: {
        filter: 'grayscale(100%)',
    },
})

const ChallengeForm = ({ defaultValues, activeFields, updateActiveFields, fireSubmit, submitStatus, unavailableMondays }: IChallengeFormProps) => {
    const { lang } = useUser()
    const styles = useFormStyles()
    const { register, handleSubmit, control, unregister, watch, formState, setValue } = useForm<IChallengeForm>({
        defaultValues: {
            ...defaultValues,
            periode: buildPeriodeOptionValue(lang, defaultValues.periode),
            categorie: buildCategorieOptionValue(lang, defaultValues.categorie),
        },
    })

    const onSubmit: SubmitHandler<IChallengeForm> = async (data: IChallengeForm) => {
        await fireSubmit({
            ...data,
            periode: (data.periode as any).value as DateRequestDto,
            categorie: (data.categorie as any).value as BadgeType,
        })
        updateActiveFields(getDefaultNoActiveFields(lang))
    }
    const watchCategorie = watch('categorie')
    useEffect(() => {
        const selectedCategorie = (watchCategorie as any)?.value
        let unregisterFields: Path<IChallengeForm>[] = []
        switch (selectedCategorie) {
            case BadgeType.DIGITAL_CLEANUP_DAY:
                updateActiveFields({
                    mailSize: false,
                    mailSizeLabel: lang.goal.setupChallenge.form.sizeMailDigitalCleanupDay,
                    driveSize: false,
                    mailShotSize: false,
                    imageHuntSize: false,
                    videoHuntSize: false,
                })
                unregisterFields = []
                break
            case BadgeType.DRIVE:
                updateActiveFields({
                    mailSize: false,
                    mailSizeLabel: lang.goal.setupChallenge.form.sizeMailDigitalCleanupDay,
                    driveSize: true,
                    mailShotSize: false,
                    imageHuntSize: false,
                    videoHuntSize: false,
                })
                unregisterFields = ['mailSize', 'mailShotSize', 'imageHuntSize', 'videoHuntSize']
                break
            case BadgeType.MAIL:
                updateActiveFields({
                    mailSize: true,
                    mailSizeLabel: lang.goal.setupChallenge.form.sizeMail,
                    driveSize: false,
                    mailShotSize: false,
                    imageHuntSize: false,
                    videoHuntSize: false,
                })
                unregisterFields = ['driveSize', 'mailShotSize', 'imageHuntSize', 'videoHuntSize']
                break
            case BadgeType.INTERN_ATTACHMENT:
                updateActiveFields({
                    mailSize: false,
                    mailSizeLabel: lang.goal.setupChallenge.form.sizeMail,
                    driveSize: false,
                    mailShotSize: false,
                    imageHuntSize: false,
                    videoHuntSize: false,
                })
                unregisterFields = ['mailSize', 'driveSize', 'mailShotSize', 'imageHuntSize', 'videoHuntSize']
                break
            case BadgeType.MAILSHOT:
                updateActiveFields({
                    mailSize: false,
                    mailSizeLabel: lang.goal.setupChallenge.form.sizeMail,
                    driveSize: false,
                    mailShotSize: true,
                    imageHuntSize: false,
                    videoHuntSize: false,
                })
                unregisterFields = ['driveSize', 'mailSize', 'imageHuntSize', 'videoHuntSize']
                break
            case BadgeType.IMAGE_HUNT:
                updateActiveFields({
                    mailSize: false,
                    mailSizeLabel: lang.goal.setupChallenge.form.sizeMail,
                    driveSize: false,
                    mailShotSize: false,
                    imageHuntSize: true,
                    videoHuntSize: false,
                })
                unregisterFields = ['driveSize', 'mailSize', 'mailShotSize', 'videoHuntSize']
                break
            case BadgeType.VIDEO_HUNT:
                updateActiveFields({
                    mailSize: false,
                    mailSizeLabel: lang.goal.setupChallenge.form.sizeMail,
                    driveSize: false,
                    mailShotSize: false,
                    imageHuntSize: false,
                    videoHuntSize: true,
                })
                unregisterFields = ['driveSize', 'mailSize', 'mailShotSize', 'imageHuntSize']
                break
        }
        unregisterFields.forEach((field) => unregister(field))
    }, [watchCategorie])

    useEffect(() => {
        if (submitStatus === 'OK') {
            // On set les valeurs, trouver une solution pour le reset
            setValue('periode', null)
            setValue('categorie', null)
            setValue('mailSize', null)
            setValue('driveSize', null)
            setValue('mailShotSize', null)
            setValue('imageHuntSize', null)
            setValue('videoHuntSize', null)
        }
    }, [submitStatus])

    useEffect(() => {
        // On set les valeurs, trouver une solution pour le reset
        setValue('periode', buildPeriodeOptionValue(lang, defaultValues.periode))
        setValue('categorie', buildCategorieOptionValue(lang, defaultValues.categorie))
        setValue('mailSize', defaultValues.mailSize)
        setValue('driveSize', defaultValues.driveSize)
        setValue('mailShotSize', defaultValues.mailShotSize)
        setValue('imageHuntSize', defaultValues.imageHuntSize)
        setValue('videoHuntSize', defaultValues.videoHuntSize)
    }, [defaultValues])

    const periodesOptions = buildPeriodesOptions(lang, unavailableMondays)
    const categorieOptions = buildCategoriesOptions(lang)

    return (
        <form className={styles.challengeForm} onSubmit={handleSubmit(onSubmit)}>
            <SelectField control={control} register={register} label={lang.goal.setupChallenge.form.labels.period} name='periode' options={periodesOptions} required />
            <SelectField control={control} register={register} label={lang.goal.setupChallenge.form.labels.category} name='categorie' options={categorieOptions} required />
            {activeFields.mailSize ? <TextField label={activeFields.mailSizeLabel} name='mailSize' maxLength={10} type='number' register={register} required /> : null}
            {activeFields.driveSize ? (
                <TextField label={lang.goal.setupChallenge.form.sizeDrive} name='driveSize' maxLength={10} type='number' register={register} required />
            ) : null}
            {activeFields.mailShotSize ? (
                <TextField label={lang.goal.setupChallenge.form.sizeMailShot} name='mailShotSize' maxLength={10} type='number' register={register} required />
            ) : null}
            {activeFields.imageHuntSize ? (
                <TextField label={lang.goal.setupChallenge.form.sizeImageHunt} name='imageHuntSize' maxLength={10} type='number' register={register} required />
            ) : null}
            {activeFields.videoHuntSize ? (
                <TextField label={lang.goal.setupChallenge.form.sizeVideoHunt} name='videoHuntSize' maxLength={10} type='number' register={register} required />
            ) : null}
            {submitStatus !== 'OK' ? <div className={styles.errorStatus}>{submitStatus}</div> : null}
            <GreetSubmitButton className={formState.isValid ? undefined : styles.disabledBtn} label={lang.goal.setupChallenge.save} disabled={!formState.isValid} />
        </form>
    )
}

const buildPeriodeOptionValue = (lang: Lang, value?: DateRequestDto | null | undefined): any =>
    value
        ? {
              label: lang.date.range(toDayLiteralMonthString(value.dateFrom, lang.code), toDayLiteralMonthString(value.dateTo, lang.code)),
              value: value,
          }
        : null

const buildPeriodesOptions = (lang: Lang, unavailableMondays: Date[]) => {
    let nextMonday = new Date()
    while (nextMonday.getDay() !== 1 || isOnCurrentWeek(nextMonday)) {
        nextMonday = add(nextMonday, 1, TimeUnit.DAYS)
    }
    const periodesOptions: any[] = []
    for (let i = 0; i < NUMBER_OF_WEEK_PERIOD; i++) {
        const nextMondayIsAvailable = unavailableMondays?.find((item) => areSame(item, nextMonday)) == null
        if (nextMondayIsAvailable) {
            const value = {
                dateFrom: nextMonday,
                dateTo: add(nextMonday, 4, TimeUnit.DAYS),
            } as DateRequestDto
            periodesOptions.push(buildPeriodeOptionValue(lang, value))
        }
        nextMonday = add(nextMonday, 7, TimeUnit.DAYS)
    }
    return periodesOptions
}

const buildCategorieOptionValue = (lang: Lang, badge?: BadgeType | null): any =>
    badge
        ? {
              label: lang.goal.challenge.label(badge!),
              value: badge,
          }
        : null

const buildCategoriesOptions = (lang: Lang) =>
    Object.values(BadgeType)
        .filter((badgeType) => badgeType !== BadgeType.PLANET_FRIENDLY)
        .map((item) => buildCategorieOptionValue(lang, item)) ?? []
