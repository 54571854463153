import { useInstance, useUser } from '../../contexts'
import classnames from 'classnames'
import { requireAnimator } from '../rolesUtils'
import IconAssets from '../../assets/icon/IconAssets'
import { GoalProps } from '../../models'
import { useGoalTitleStyle } from './styles'
import GoalPeriod from './GoalPeriod'
import { substractDays, toDayLiteralMonthString } from '../dateUtils'
import GreetFeaturingComponent from '../share/GreetFeaturingComponent'

interface GoalTitleProps {
    showCardTitle?: boolean
    goal: GoalProps
    shortBorder?: boolean
    showPeriod?: boolean
}
const GoalTitle = ({ showCardTitle = true, goal, shortBorder = false, showPeriod = false }: GoalTitleProps) => {
    const { user, lang } = useUser()
    const { customer } = useInstance()
    const styles = useGoalTitleStyle(goal.textColor, goal.lineColor, customer.featuring ?? [])
    const period = lang.date.range(toDayLiteralMonthString(goal.startDate, lang.locale), toDayLiteralMonthString(substractDays(goal.endDate, 1), lang.code))
    return (
        <div>
            <div className={classnames([styles.goalTitle, styles.goalTitleHeight])}>
                {showCardTitle ? (
                    <>
                        <div className={styles.goalIcon}>{goal.iconActive}</div>
                        <div className={styles.goalTileInfo}>
                            <span className={styles.goalTileType}>{goal.theme}</span>
                            <span className={styles.goalTileDescription}>{goal.title}</span>
                        </div>
                        {requireAnimator(
                            user,
                            <div className={styles.addIcon}>
                                <IconAssets.PlusIcon />
                            </div>,
                        )}
                    </>
                ) : null}
                <GreetFeaturingComponent {...{ period, show: showPeriod }} nextDef={GoalPeriod} legacyDef={() => null} />
            </div>
            <div className={classnames([styles.goalTitleBorder, shortBorder ? styles.goalTitleShortBorder : ''])}></div>
        </div>
    )
}

export default GoalTitle
