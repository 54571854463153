import { Customer } from '../../models'

const STORAGE_NAME = 'customer'

const customer = {
    store: (customer: Customer): void => {
        localStorage.setItem(STORAGE_NAME, JSON.stringify(customer))
    },
    get: (): Customer | null => {
        const customerStringified = localStorage.getItem(STORAGE_NAME)
        if (customerStringified !== null) {
            return JSON.parse(customerStringified)
        } else {
            return null
        }
    },
    clear: () => {
        localStorage.removeItem(STORAGE_NAME)
    },
}

export default customer
