import IconAssets from '../../../assets/icon/IconAssets'

const referentialEmissions = [
    {
        'usageAction': {
            'fr': 'à la consommation de',
            'en': 'to the consumption of',
        },
        'name': {
            'fr': 'Eau en bouteille',
            'en': 'Bottled water',
        },
        'prefix': {
            'fr': "Litre[s] d'",
            'en': 'Liter[s] of ',
        },
        'emoji': <IconAssets.Eau />,
        'total': 0.26707461992,
    },
    {
        'usageAction': {
            'fr': 'au parcourir de',
            'en': 'to travel of',
        },
        'name': {
            'fr': 'TGV',
            'en': 'TGV',
        },
        'prefix': {
            'fr': 'km en ',
            'en': 'km by ',
        },
        'emoji': <IconAssets.Tgv />,
        'total': 0.00236,
    },
    {
        'usageAction': {
            'fr': 'au parcourir de',
            'en': 'to travel of',
        },
        'name': {
            'fr': 'Voiture',
            'en': 'Car',
        },
        'prefix': {
            'fr': 'km en ',
            'en': 'km by ',
        },
        'emoji': <IconAssets.Voiture />,
        'total': 0.2176,
    },
    {
        'usageAction': {
            'fr': 'à la fabrication de',
            'en': 'to the manufacture of ',
        },
        'name': {
            'fr': 'Jeans',
            'en': 'Jeans',
        },
        'emoji': <IconAssets.Jeans />,
        'total': 22.674430228399554,
        'usage': {
            'peryear': 0.250618604,
            'defaultyears': 5,
        },
        'end': 0.63563578618721,
    },
    {
        'usageAction': {
            'fr': 'à la fabrication de',
            'en': 'to the manufacture of ',
        },
        'name': {
            'fr': 'Smartphone[s]',
            'en': 'Smartphone[s]',
        },
        'emoji': <IconAssets.Smartphone />,
        'total': 30.524805201332256,
        'usage': {
            'peryear': 0.133428421,
            'defaultyears': 4,
        },
        'end': -1.8163965605781,
    },
    {
        'usageAction': {
            'fr': 'à la fabrication de',
            'en': 'to the manufacture of ',
        },
        'name': {
            'fr': 'T-shirt[s]',
            'en': 'T-shirt[s]',
        },
        'emoji': <IconAssets.TShirt />,
        'total': 5.149616900739109,
        'usage': {
            'peryear': 0.196677291,
            'defaultyears': 5,
        },
        'end': 0.250404099863735,
    },
    {
        'usageAction': {
            'fr': 'à la consommation de',
            'en': 'to the consumption of',
        },
        'name': {
            'fr': 'Repas avec du boeuf',
            'en': 'Meals with beef',
        },
        'emoji': <IconAssets.Steak />,
        'total': 7.26,
    },
    {
        'usageAction': {
            'fr': 'à la consommation de',
            'en': 'to the consumption of',
        },
        'name': {
            'fr': 'Repas végétarien',
            'en': 'Vegetarian meal',
        },
        'emoji': <IconAssets.Salade />,
        'total': 0.51,
    },
]

export default referentialEmissions
