import classnames from 'classnames'
import { ReactNode, useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { createUseStyles } from 'react-jss'
import { useClientThemeStyles } from '../App'
import IconAssets from '../assets/icon/IconAssets'
import { useInstance } from '../contexts'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'

interface PopupProps {
    closable?: boolean
    closeOnOutside?: boolean
    closeIconColor?: string
    onCloseRequest: () => void
    children: ReactNode
    [x: string]: any
}

const useStyles = () =>
    createUseStyles({
        wrapper: {
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(5px)',
            zIndex: 101,
            animation: '$show 0.2s ease',
        },
        '@keyframes show': {
            '0%': {
                display: 'none',
                opacity: 0,
            },
            '1%': {
                display: 'flex',
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        },
        container: {
            position: 'relative',
            margin: 'auto',
            overflow: 'auto',
            maxWidth: '80vw',
            maxHeight: '100vh',
            borderRadius: 'var(--gd-border-normal-radius)',
            animation: '$scale-in-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) none',
            justifyContent: 'center',
        },
        '@keyframes scale-in-center': {
            '0%': {
                transform: 'scale(0)',
                opacity: '1',
            },
            '100%': {
                transform: 'scale(1)',
                opacity: '1',
            },
        },
        closeButton: {
            position: 'absolute',
            zIndex: 100,
            top: 0,
            right: 0,
            cursor: 'pointer',
            height: '15px',
            width: '15px',
            margin: '15px',
            padding: 0,
            border: 0,
            outline: 0,
        },
        [MediaQueryBreakpoints.MOBILE_AND_TABLETTE]: {
            container: {
                width: '80%',
                maxHeight: '95%',
            },
        },
        [MediaQueryBreakpoints.TABLETTE_XL]: {
            container: {
                width: '80%',
                maxHeight: '80%',
            },
        },
        [MediaQueryBreakpoints.MOBILE]: {
            container: {
                width: '100%',
                height: '100%',
                maxWidth: '100vw',
                maxHeight: '100vh',
                borderRadius: '0px',
                display: 'flex',
            },
        },
    })()

const Popup = ({ closable = true, onCloseRequest, closeIconColor, children, closeOnOutside = true, ...rest }: PopupProps) => {
    const styles = useStyles()
    const { customer } = useInstance()
    const themeStyles = useClientThemeStyles(customer.theme)
    const overlayRef = useRef(null)
    const { className, restProps } = rest
    const handleKeyUp = useCallback(
        (event) => {
            const keys = {
                27: () => {
                    if (closable) {
                        // esc key
                        event.preventDefault()
                        onCloseRequest()
                        window.removeEventListener('keyup', handleKeyUp, false)
                    }
                },
            } as any

            if (keys[event.keyCode]) {
                keys[event.keyCode]()
            }
        },
        [closable, onCloseRequest],
    )

    const handleOutsideClick = useCallback(
        (event) => {
            if (closable && closeOnOutside) {
                if (overlayRef?.current === event.target) {
                    onCloseRequest()
                    document.removeEventListener('click', handleOutsideClick, false)
                }
            }
        },
        [closable, onCloseRequest],
    )

    useEffect(() => {
        function disbaleScroll() {
            document.body.style.overflow = 'hidden'
        }

        function enableScroll() {
            document.body.style.overflow = 'auto'
        }

        disbaleScroll()

        window.addEventListener('keyup', handleKeyUp, false)
        document.addEventListener('click', handleOutsideClick, false)
        return () => {
            window.removeEventListener('keyup', handleKeyUp, false)
            document.removeEventListener('click', handleOutsideClick, false)
            enableScroll()
        }
    }, [handleKeyUp, handleOutsideClick])

    return ReactDOM.createPortal(
        <div className={classnames([styles.wrapper, themeStyles.clientTheme])} ref={overlayRef} {...restProps}>
            <div className={classnames([styles.container, className ?? ''])}>
                {closable ? (
                    <div className={styles.closeButton} onClick={onCloseRequest}>
                        <IconAssets.CloseIcon strokeColor={closeIconColor} />
                    </div>
                ) : null}
                {children}
            </div>
        </div>,
        document.getElementById('root-modal') as Element,
    )
}

export default Popup
