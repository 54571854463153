import { Tips } from '../../models/Tip'

const tipsFr: Tips = {
    title: 'Tips',
    tips: [
        {
            title: 'Limiter l’envoi de documents en pièce jointe.',
            content:
                'En interne tout particulièrement, évitez les pièces jointes par email et utilisez OneDrive pour partager vos documents même les plus volumineux par un simple lien. Cela évite de dupliquer les documents dans les boîtes aux lettres des destinataires. Régulièrement pensez à supprimer les documents du dossier “Pièces jointes” dans OneDrive.',
        },
        {
            title: 'Collaborer dans Teams, pas dans l’email.',
            content:
                'Réduire au possible l’usage des emails pour communiquer et collaborer en interne. Privilégier les conversations au sein des espaces d’équipe. Au-delà de l’absence de duplication du fichier, il est plus facile pour chacun de retrouver l’information, être certain d’accéder à la dernière version, discuter avec les parties prenantes ou commenter des points précis au sein même du document.',
        },
        {
            title: 'Perdre l’habitude de dupliquer les documents présents sur les espaces d’équipe.',
            content:
                'Souvent, on préfère garder une copie des documents des projets sur lesquels on travaille. Il est préférable de faire attention à bien nommer les fichiers et les organiser de manière à ce que chacun s’y retrouve. Les documents sont versionnés et rendent possible le retour à une version antérieure si une modification non souhaitée devait être supprimée.',
        },
        {
            title: 'Maîtriser la taille de son espace OneDrive.',
            content:
                'Concevez votre espace OneDrive comme une zone de transit avec un flux entrant (les nouveaux documents), un flux sortant (les documents supprimés) et une capacité (volume de stockage). Le jeu consiste à maîtriser le volume stocké en supprimant autant de documents que de nouveaux documents sont ajoutés. Les documents de travail sont en transit sur le OneDrive, leur destination est un espace d’équipe, un référentiel documentaire ou la poubelle.',
        },
        {
            title: 'OneDrive est un système de sauvegarde dont l’usage doit être modéré.',
            content:
                'OneDrive assure la sauvegarde de ses documents y compris ceux enregistrés sur le poste de travail. Cette facilité se justifie pour les documents que l’on produit soi-même, pas pour les documents téléchargés et encore moins pour les vidéos ou photos volumineuses. D’autres solutions moins énergivores peuvent être trouvées. Il s’agit donc de configurer la synchronisation de son disque dur de manière ciblée.',
        },
        {
            title: 'Organiser les espaces partagés.',
            content:
                'Adopter des conventions d’organisation de l’information dans les espaces d’équipe permet à chacun de s’y référer sans dupliquer l’information dans son espace OneDrive. Un espace projet mal organisé conduit à la duplication par les participants.',
        },
        {
            title: 'Faire différemment, plutôt que faire moins à l’identique',
            content:
                'Adopter des pratiques numériques responsables consiste à créer des habitudes qui soient durables pour la planète et pour les équipes. Il s’agit de faire différemment pour faire mieux et non pas, de continuer comme avant en faisant moins.',
        },
        {
            title: 'Collaborer dans les documents.',
            content:
                'Converser directement dans les documents et les co-éditer en ligne, limite la duplication de version et fédère en un seul endroit toute l’information. C’est une pratique sobre numériquement et qui fait gagner du temps à chacun.',
        },
    ],
    link: 'https://blog.lecko.fr/quels-sont-les-impacts-de-nos-pratiques-de-travail-dans-microsoft-365-sur-nos-emissions-carbone/',
}

const tipsEn: Tips = {
    title: 'Tips',
    tips: [
        {
            title: 'Limit sending documents as attachments.',
            content:
                'Especially internally, avoid sending attachments by email and use OneDrive to share even the largest documents by a simple link. This avoids duplicating documents in the mailboxes of the recipients. Regularly remember to delete documents from the "Attachments" folder in OneDrive.',
        },
        {
            title: 'Collaborate in Teams, not in email.',
            content:
                'Minimize the use of email for internal communication and collaboration. Favour conversations within team spaces. Beyond the absence of duplication of the file, it is easier for everyone to find the information, be sure to access the latest version, discuss with stakeholders or comment on specific points within the document.',
        },
        {
            title: 'Lose the habit of duplicating documents on team spaces.',
            content:
                'Often, we prefer to keep a copy of the documents of the projects we are working on. It is preferable to take care to name the files and organize them in such a way that everyone can find their way around. The documents are versioned and make it possible to go back to a previous version if an unwanted change has to be deleted.',
        },
        {
            title: 'Control the size of your OneDrive space.',
            content:
                'Think of your OneDrive space as a staging area with an incoming flow (new documents), an outgoing flow (deleted documents) and a capacity (storage volume). The game is to control the volume stored by deleting as many documents as new ones are added. Work documents are in transit on the OneDrive, their destination is a team space, a document repository or the trash can.',
        },
        {
            title: 'OneDrive is a backup system that should be used in moderation.',
            content:
                "OneDrive ensures the backup of its documents including those saved on the workstation. This facility is justified for documents that you produce yourself, not for downloaded documents and even less for videos or large photos. Other less energy-consuming solutions can be found. It is therefore a matter of configuring the synchronization of one's hard disk in a targeted manner.",
        },
        {
            title: 'Organize shared spaces.',
            content:
                'Adopting conventions for organizing information in team spaces allows everyone to refer to it without duplicating information in their OneDrive space. A poorly organized project space leads to duplication by participants.',
        },
        {
            title: 'Doing things differently, rather than doing less of the same.',
            content:
                "Adopting responsible digital practices is about creating habits that are sustainable for the planet and for teams. It's about doing things differently to do things better, not about doing less the same way.",
        },
        {
            title: 'Collaborate in documents.',
            content:
                "Conversing directly in documents and co-editing them online, limits duplication of versions and federates all information in one place. It's a digitally sober practice that saves everyone time.",
        },
    ],
    link: 'https://blog.lecko.fr/quels-sont-les-impacts-de-nos-pratiques-de-travail-dans-microsoft-365-sur-nos-emissions-carbone/',
}

export { tipsEn, tipsFr }
