import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { useHistory, useLocation } from 'react-router-dom'
import IconAssets from '../../assets/icon/IconAssets'
import { useFetch, usePut } from '../../services/api/useFetch'
import Popup from '../Popup'
import Title from '../Title'
import { toNumericString } from '../dateUtils'
import { GreetButton, GreetSubmitButton } from '../share/GreetButton'
import { DebouncedInputFilter, GreetTable, GreetTableFilterProps } from '../share/GreetTable'
import { PasswordField } from '../share/Inputs'
import { CohortDetailsMode } from './CohortDetails'
import { rsaEncrypt } from '../utils'
import { useUser } from '../../contexts'
import { Lang } from '../../models'

const useCohortListStyle = createUseStyles({
    challengeResults: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
    },
})

const CohortList = () => {
    const styles = useCohortListStyle()
    const { lang } = useUser()

    return (
        <div className={styles.challengeResults}>
            <Title title={lang.administration.subTitle} />
            <CohortListFilteredTable lang={lang} />
        </div>
    )
}

const useCohortListFilteredTableStyle = createUseStyles({
    cellEditButton: {
        backgroundColor: 'unset',
        border: 'none',
        cursor: 'pointer',
        '& svg': {
            width: '18px',
            height: '18px',
        },
    },
    secretHashPopup: {
        width: '200px',
    },
})

interface CohortListFilteredTableProps {
    lang: Lang
}

const CohortListFilteredTable = ({ lang }: CohortListFilteredTableProps) => {
    const styles = useCohortListFilteredTableStyle()
    const { loading, data } = useFetch({ baseEndpoint: 'cohort', endpoint: '/list-all' })
    const [showSecretHashPopup, setShowSecretHashPopup] = useState<boolean>(false)
    const [currentCohort, setCurrentCohort] = useState<any>()
    const [cohortDetailMode, setCohortDetailMode] = useState<CohortDetailsMode>()

    const columns = [
        {
            id: 'libelle',
            header: lang.administration.fieldName.label,
            accessorKey: 'displayName',
        },
        {
            id: 'nom',
            header: lang.administration.fieldName.name,
            accessorKey: 'name',
        },
        {
            id: 'nbEmail',
            header: lang.administration.fieldName.size,
            accessorKey: 'nbEmail',
        },
        {
            id: 'update',
            header: lang.administration.fieldName.lastUpdate,
            accessorFn: ({ createdDateTime }: any) => toNumericString(new Date(createdDateTime)),
        },
        {
            id: 'edit',
            header: '',
            cell: (props: any) => (
                <GreetButton
                    className={styles.cellEditButton}
                    onClick={() => {
                        setShowSecretHashPopup(true)
                        setCurrentCohort(props.row.original)
                        setCohortDetailMode(CohortDetailsMode.EDIT)
                    }}
                    label={''}
                    icon={<IconAssets.Pen />}
                />
            ),
        },
    ]

    const onAddCohortClick = () => {
        setShowSecretHashPopup(true)
        setCurrentCohort(null)
        setCohortDetailMode(CohortDetailsMode.CREATION)
    }

    const TableHearder = (props: any) => <CohortListHeader {...props} onAddCohortClick={onAddCohortClick} lang={lang} />

    return (
        <>
            <GreetTable columns={columns} data={data ?? []} loading={loading} headerComponentFn={TableHearder} />
            {showSecretHashPopup ? <SecretHashPopup cohort={currentCohort} cohortDetailMode={cohortDetailMode} close={() => setShowSecretHashPopup(false)} lang={lang} /> : null}
        </>
    )
}

const useCohortListFiltersStyle = createUseStyles({
    cohortListHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '15px',
    },
    cohortListFilter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '15px',
    },
    headerButton: {
        fontSize: 'var(--gd-size-button-text)',
        fontWeight: 'normal',
    },
})

interface CohortListHeaderProps<T> extends GreetTableFilterProps<T> {
    onAddCohortClick: () => void
    lang: Lang
}

const CohortListHeader = ({ setGlobalFilter, onAddCohortClick, lang }: CohortListHeaderProps<any>) => {
    const styles = useCohortListFiltersStyle()
    const [allFieldsFilter, setAllFieldsFilter] = useState<string>('')

    return (
        <div className={styles.cohortListHeader}>
            <div className={styles.cohortListFilter}>
                <DebouncedInputFilter
                    value={allFieldsFilter ?? ''}
                    onChange={(value) => {
                        const stringValue = String(value)
                        setAllFieldsFilter(stringValue)
                        setGlobalFilter(stringValue)
                    }}
                    placeholder={lang.administration.search}
                    icon={<IconAssets.SearchIcon />}
                />
                <GreetButton type='secondary' className={styles.headerButton} label={lang.administration.deleteFilter} onClick={() => setAllFieldsFilter('')} />
            </div>

            <GreetButton type='secondary' className={styles.headerButton} label={lang.administration.addCohort} onClick={onAddCohortClick} icon={<IconAssets.PlusLightIcon />} />
        </div>
    )
}

const useSecretHashPopupStyle = createUseStyles({
    secretHashPopupContent: {
        width: '25vw!important',
    },
    secretHashPopup: {
        width: '100%',
        backgroundColor: 'var(--gd-main-popup-background-color)',
        padding: '30px 25px',
        borderRadius: 'var(--gd-border-small-radius)',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        gap: '10px',
    },
    titles: {
        color: 'var(--gd-secondary-dark-color)',
    },
    mainTitle: {
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
    },
    errorField: {
        height: '10px',
        color: '#ED2C2C',
        fontSize: 'var(--gd-size-small-body-text)',
        fontStyle: 'italic',
        margin: '5px 0px',
    },
    formButtons: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '10px',
    },
})

interface SecretHashForm {
    secret: string
}

interface SecretHashPopupProps {
    close: () => void
    cohort: any
    cohortDetailMode: CohortDetailsMode | undefined
    lang: Lang
}

const SecretHashPopup = ({ close, cohort, cohortDetailMode, lang }: SecretHashPopupProps) => {
    const styles = useSecretHashPopupStyle()
    const { register, handleSubmit } = useForm<SecretHashForm>({
        defaultValues: {
            secret: '',
        },
    })
    const location = useLocation()
    const history = useHistory()
    const [isSecretWrong, setIsSecretWrong] = useState<boolean>(false)
    const { doPut: verifySecret, working } = usePut({
        baseEndpoint: '/user/',
        endpoint: 'check-secret',
        enableTaostSuccess: false,
        onSuccess: (response, request) => {
            if (response === true) {
                let queryParam = null
                if (cohortDetailMode === CohortDetailsMode.CREATION) {
                    queryParam = ''
                } else if (cohortDetailMode === CohortDetailsMode.EDIT) {
                    queryParam = `?name=${encodeURIComponent(cohort.name)}`
                } else {
                    throw new Error('Unkown cohort détail mode')
                }
                history.push(`${location.pathname}/cohort${queryParam}`, {
                    cohort,
                    secret: request?.secret,
                    cohortDetailMode: cohortDetailMode,
                })
            } else {
                setIsSecretWrong(true)
            }
        },
    })

    const onSubmit: SubmitHandler<SecretHashForm> = async (data: SecretHashForm) => {
        data.secret = await rsaEncrypt(data.secret)
        setIsSecretWrong(false)
        verifySecret(data)
    }

    return (
        <Popup onCloseRequest={close} closeOnOutside={false} className={styles.secretHashPopupContent}>
            <form className={styles.secretHashPopup} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.titles}>
                    <div className={styles.mainTitle}>{lang.administration.secret.title}</div>
                    <div>{lang.administration.secret.description}</div>
                </div>
                <div>
                    <PasswordField name='secret' register={register} />
                    <p className={styles.errorField}>{isSecretWrong ? lang.administration.secret.invalid : ''}</p>
                </div>
                <div className={styles.formButtons}>
                    <GreetButton type='secondary-inv' disabled={working} onClick={close} label={lang.administration.secret.cancel} />
                    <GreetSubmitButton type='secondary' disabled={working} label={lang.administration.secret.confirm} />
                </div>
            </form>
        </Popup>
    )
}

export default CohortList
