import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { Customer } from './models'
import settings from './settings'

ReactDOM.render(<App />, document.getElementById('root'))

initPWA()

function initPWA() {
    const customer = getCustomer()

    if (customer.disablePwa) {
        return
    }

    const manifest = document.createElement('link')
    manifest.setAttribute('rel', 'manifest')
    manifest.setAttribute('href', `/manifest/${customer.name}.json`)
    document.head.appendChild(manifest)

    serviceWorkerRegistration.register()
}

function getCustomer(): Customer {
    const MIN_NB_PARAMS = 2
    const params = window.location.pathname.split('/').filter((param) => param)

    if (params.length < MIN_NB_PARAMS) {
        return settings.customer[0]
    }

    return settings.customer.find((c) => c.name === params[MIN_NB_PARAMS - 1]) ?? settings.customer[0]
}
