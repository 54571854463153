import { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { useInstance, useStore, useUser } from '../../contexts'
import { EBreathingTimeMeetingPeriod } from '../../models/BreathingTimeMeeting'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import InfoBulle from '../share/Infobulle'
import { convertToRem, numberFormatter } from '../utils'
import { BreathingTimeMeetingDefaultIndicators } from './MeetingBreathingTime'

const useStyles = () =>
    createUseStyles({
        qVTMonthlyEvolutionCount: {
            width: '100%',
            maxHeight: convertToRem(500),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            gap: '8px',
            backgroundColor: 'var(--gd-main-app-background-color)',
            borderRadius: 'var(--gd-border-normal-radius)',
            padding: '20px',
        },
        graphContainer: {
            position: 'relative',
            height: '28vh',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '15px',
        },
        graphIndicator: {
            position: 'absolute',
            left: '5px',
            right: '0',
            top: '0',
            bottom: '0',
            margin: 'auto',
            width: '35px',
            height: '35px',
            color: 'var(--gd-primary-color)',
            fontSize: 'var(--gd-size-big-number)',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        titleContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            alignContent: 'center',
            gap: '8px'
        },
        title: {
            fontWeight: 'bold',
            fontSize: 'var(--gd-size-highlighted-small-title)',
            lineHeight: '20px',
        },
        legend: {
            borderRadius: 'var(--gd-border-normal-radius)',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '1rem',
            fontSize: 'var(--gd-size-legend-text)',
        },
        lineWithDot: {
            position: 'relative',
            height: '2px',
            backgroundColor: 'black',
            margin: '10px',
        },
        dot: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '10px',
            height: '10px',
            backgroundColor: 'black',
            borderRadius: '50%',
        },
        inMeeting: {
            backgroundColor: 'var(--gd-secondary-color)',
        },
        mobilized: {
            backgroundColor: 'var(--gd-primary-color)',
        },
        descriptionContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            backgroundColor: 'var(--gd-background-clear-color)',
            borderRadius: '10px',
            padding: '10px 20px',
            fontSize: 'var(--gd-size-small-body-text)',
        },
        infobullIcon: {
            width: '25px',
            padding: '4px 0',
        },
        [MediaQueryBreakpoints.DESKTOP]: {
            container: {
                maxWidth: convertToRem(750),
            },
        },
        [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
            container: {
                maxWidth: 'unset',
            },
        },
        [MediaQueryBreakpoints.MOBILE]: {
            graphContainer: {
                width: '95%',
            },

            legend: {
                marginTop: convertToRem(15),
                paddingInline: '1rem',
                paddingBlock: '0.5rem',
                flexDirection: 'row',
                gap: '2rem',
                borderRadius: 'var(--gd-border-small-radius)',
            },
        },
    })()

export default function QVTMonthlyMeetingCounts() {
    const { lang } = useUser()
    const {
        customer: { theme: customerTheme },
    } = useInstance()
    const styles = useStyles()
    const {
        state: { userPayload },
    } = useStore()

    const graphData = useMemo(() => {
        const breathingTimeMeetings = userPayload.breathingTimeMeetings ?? []
        let totalMeetingWithBreathingTime = 0
        let totalMeetingWithoutBreathingTime = 0
        const oneMonthBreathingTimeMeetingsIndictors =
            breathingTimeMeetings.find((item) => item.breathingTimeMeetingPeriod === EBreathingTimeMeetingPeriod.ONE_MONTH)?.breathingTimeMeetingIndicatorsByUnits ??
            BreathingTimeMeetingDefaultIndicators
        for (const [, value] of Object.entries(oneMonthBreathingTimeMeetingsIndictors)) {
            totalMeetingWithBreathingTime += value?.meetingWithBreathingTime ?? 0
            totalMeetingWithoutBreathingTime += value?.meetingWithoutBreathingTime ?? 0
        }
        const totalMeetings = totalMeetingWithBreathingTime + totalMeetingWithoutBreathingTime || 1
        const tauxMeetingWithBreathingTime = (totalMeetingWithBreathingTime * 100) / totalMeetings
        const tauxMeetingWithoutBreathingTime = (totalMeetingWithoutBreathingTime * 100) / totalMeetings

        return {
            indicator: `${numberFormatter(tauxMeetingWithBreathingTime)}%`,
            tooltip: {},
            data: [tauxMeetingWithBreathingTime, tauxMeetingWithoutBreathingTime],
        }
    }, [userPayload])

    return (
        <div className={styles.qVTMonthlyEvolutionCount}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{lang.goal.qvt.meetingEvolutionCount.title}</div>
                <InfoBulle translateX='-50%' component={null}>
                    <div className={styles.infobullIcon}>
                        <IconAssets.Information />
                    </div>
                </InfoBulle>
            </div>

            <div className={styles.graphContainer}>
                <div className={styles.graphIndicator}>{graphData.indicator}</div>
                <Doughnut
                    data={{
                        datasets: [
                            {
                                data: graphData.data,
                                backgroundColor: [customerTheme.primaryColor, customerTheme.backgroundDarkColor],
                                borderWidth: 1,
                                hoverOffset: 1,
                            },
                        ],
                        labels: ['Réunion non jointive', 'Réunion jointive'],
                    }}
                    options={{
                        cutout: '60%',
                        plugins: {
                            tooltip: {
                                enabled: false,
                                displayColors: false,
                                backgroundColor: customerTheme.backgroundDarkColor,
                                bodyColor: customerTheme.textDarkColor,
                                bodyFont: {
                                    size: 14,
                                },
                                bodyAlign: 'center',
                                callbacks: {
                                    title: () => '',
                                    label: (context) => {
                                        return `${context.label} ${context.formattedValue}%`
                                    },
                                },
                            },
                        },
                    }}
                />
            </div>
            <div className={styles.descriptionContainer}>{lang.goal.qvt.meetingEvolutionCount.meetingLegend}</div>
        </div>
    )
}
