import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import IconAssets from '../assets/icon/IconAssets'
import { useStore, useUser } from '../contexts'
import MailSendingByWorkingRange from '../models/MailSendingByWorkingRange'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import Title from './Title'
import { getWeekNumber } from './dateUtils'
import Chart from './qvt/EmailSendingRange/Chart'
import WorkingDaysEditor from './qvt/PersonnalWorkloadEvaluation/WorkingDaysEditor'
import GreetTabs from './share/GreetTabs/GreetTabs'
import InfoBulle from './share/Infobulle'
import ChartLegend from './share/qvt/ChartLegend'
import { convertToRem } from './utils'

const useStyles = createUseStyles({
    emailSendingRange: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    emailSendingRangeMainContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: '3px var(--gd-background-dark-color) solid',
        borderRadius: 'var(--gd-border-normal-radius)',
        marginTop: '20px',
        height: 'auto',
    },
    emailSendingRangeContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    workingDaysEditorContent: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '10px',
        alignItems: 'center',
        padding: '5px 50px',
    },
    emailSendingRangeDescriptions: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
        margin: '0px 40px 10px 40px',
        padding: '10px 20px',
        borderRadius: 'var(--gd-border-small-radius)',
        backgroundColor: 'var(--gd-background-clear-color)',
        fontSize: 'var(--gd-size-small-body-text)',
    },
    workingDaysEditorLegend: {
        width: '20px',
        height: '20px',
        backgroundColor: '#EDF7EB',
    },
    contentDisplay: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    legendContainer: {
        width: '30px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '10px 10px 0 0',
    },
    legend: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        borderRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: 'var(--gd-background-clear-color)',
        padding: '20px',
        gap: '10px',
    },
    legendItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
        fontSize: 'var(--gd-size-legend-text)',
    },
    circle: {
        borderRadius: '50%',
        paddingLeft: '10px',
        height: '10px',
    },
    subContentDisplayTop: {
        marginTop: convertToRem(20),
        display: 'flex',
        flexDirection: 'row',
        gap: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'var(--gd-size-tiny-body-text)',
    },
    contentDisplayPanel: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
    },
    tabsContainer: {
        rowGap: convertToRem(20),
    },
    tabItemLabel: {
        fontSize: 'var(--gd-size-tiny-body-text)',
    },
    monthSticks: {
        gap: '35px',
    },
    yearSticks: {
        gap: '35px',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE]: {
        contentContainer: {
            height: convertToRem(550),
            flexDirection: 'column',
            rowGap: convertToRem(50),
        },
        legendContainer: {
            width: '90%',
            height: 'unset',
            alignSelf: 'center',
        },
        legend: {
            width: 'unset',
            height: '80%',
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        weekSticks: {
            gap: '10px',
        },
        monthSticks: {
            gap: '25px',
        },
        yearSticks: {
            gap: '25px',
        },
    },
    [MediaQueryBreakpoints.TABLETTE]: {
        contentDisplay: {
            width: '100%',
            height: '70%',
        },
        weekSticks: {
            gap: '10px',
        },
        monthSticks: {
            gap: '15px',
        },
        yearSticks: {
            gap: '15px',
        },
    },
    [MediaQueryBreakpoints.MOBILE]: {
        contentDisplay: {
            width: '100%',
            height: '70%',
        },
        weekSticks: {
            gap: '4px',
        },
        monthSticks: {
            gap: '5px',
        },
        yearSticks: {
            gap: '5px',
        },
    },
})

export enum EmailSendingRangeMode {
    THREE_WEEKS,
    THREE_MONTHS,
    ONE_YEAR,
}

const defaultValues: MailSendingByWorkingRange = {
    dailySlots: [],
    weeklySlots: [],
    monthlySlots: [],
}

export default function EmailSendingRange({ includeWorkingDaysEditor = false }: Readonly<{ includeWorkingDaysEditor?: boolean }>) {
    const { lang } = useUser()
    const styles = useStyles()
    const {
        state: { userPayload },
    } = useStore()
    const mailSendingByWorkingRange = useMemo(() => userPayload.mailSendingByWorkingRange ?? defaultValues, [userPayload.mailSendingByWorkingRange])
    const workingRange = useMemo(() => userPayload.workingRange ?? {}, [userPayload.workingRange])

    const buildTabId = (suffix: EmailSendingRangeMode) => 'tab-' + suffix

    return (
        <div className={styles.emailSendingRange}>
            <Title title={lang.goal.qvt.emailSendingRange.title} />
            <div className={styles.emailSendingRangeMainContainer}>
                <div className={styles.emailSendingRangeContentContainer}>
                    <div className={styles.contentDisplay}>
                        <GreetTabs
                            classNames={{ container: styles.tabsContainer, tabs: styles.subContentDisplayTop, panel: styles.contentDisplayPanel }}
                            tabItemCommonClasseNames={{ label: styles.tabItemLabel }}
                            initialTab={buildTabId(EmailSendingRangeMode.THREE_WEEKS)}
                            tabs={[
                                { id: buildTabId(EmailSendingRangeMode.THREE_WEEKS), label: lang.goal.qvt.emailSendingRange.button1, model: EmailSendingRangeMode.THREE_WEEKS },
                                { id: buildTabId(EmailSendingRangeMode.THREE_MONTHS), label: lang.goal.qvt.emailSendingRange.button2, model: EmailSendingRangeMode.THREE_MONTHS },
                                { id: buildTabId(EmailSendingRangeMode.ONE_YEAR), label: lang.goal.qvt.emailSendingRange.button3, model: EmailSendingRangeMode.ONE_YEAR },
                            ]}
                            panels={[
                                {
                                    id: buildTabId(EmailSendingRangeMode.THREE_WEEKS),
                                    component: (
                                        <Chart
                                            slots={mailSendingByWorkingRange.dailySlots}
                                            chartType='daily'
                                            workingRange={workingRange}
                                            tooltipText={lang.goal.qvt.emailSendingRange.tooltip}
                                            slotTooltip={lang.goal.qvt.emailSendingRange.slotsTooltip.oneSlot}
                                            getDayLetter={(date) => date.toLocaleString(lang.locale, { weekday: 'long' })[0]}
                                        />
                                    ),
                                },
                                {
                                    id: buildTabId(EmailSendingRangeMode.THREE_MONTHS),
                                    component: (
                                        <Chart
                                            classNames={{ stick: styles.monthSticks }}
                                            chartType='aggreate'
                                            slots={mailSendingByWorkingRange.weeklySlots}
                                            workingRange={workingRange}
                                            tooltipText={lang.goal.qvt.emailSendingRange.tooltip}
                                            slotTooltip={lang.goal.qvt.emailSendingRange.slotsTooltip.averageSlot}
                                            getDayLetter={(date) => String(getWeekNumber(date))}
                                        />
                                    ),
                                },
                                {
                                    id: buildTabId(EmailSendingRangeMode.ONE_YEAR),
                                    component: (
                                        <Chart
                                            classNames={{ stick: styles.yearSticks }}
                                            slots={mailSendingByWorkingRange.monthlySlots}
                                            chartType='aggreate'
                                            workingRange={workingRange}
                                            tooltipText={lang.goal.qvt.emailSendingRange.tooltip}
                                            slotTooltip={lang.goal.qvt.emailSendingRange.slotsTooltip.averageSlot}
                                            getDayLetter={(date) => date.toLocaleString(lang.locale, { month: 'long' })[0]}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </div>
                    <div className={styles.legendContainer}>
                        <InfoBulle
                            translateX='-50%'
                            component={
                                <ChartLegend
                                    classNames={{ container: styles.legend }}
                                    items={[
                                        {
                                            label: lang.goal.qvt.emailSendingRange.legend.microRange,
                                            icon: (
                                                <div
                                                    className={styles.circle}
                                                    style={{ backgroundColor: 'unset', border: 'solid 0.5px var(--gd-email-sending-range-small-range-color)' }}
                                                ></div>
                                            ),
                                            classNames: {
                                                container: styles.legendItem,
                                            },
                                        },
                                        {
                                            label: lang.goal.qvt.emailSendingRange.legend.smallRange,
                                            icon: <div className={styles.circle} style={{ backgroundColor: 'var(--gd-email-sending-range-small-range-color)' }}></div>,
                                            classNames: {
                                                container: styles.legendItem,
                                            },
                                        },
                                        {
                                            label: lang.goal.qvt.emailSendingRange.legend.mediumRange,
                                            icon: <div className={styles.circle} style={{ backgroundColor: 'var(--gd-email-sending-range-medium-range-color)' }}></div>,
                                            classNames: {
                                                container: styles.legendItem,
                                            },
                                        },
                                        {
                                            label: lang.goal.qvt.emailSendingRange.legend.largeRange,
                                            icon: <div className={styles.circle} style={{ backgroundColor: 'var(--gd-email-sending-range-large-range-color)' }}></div>,
                                            classNames: {
                                                container: styles.legendItem,
                                            },
                                        },
                                        {
                                            label: lang.goal.qvt.emailSendingRange.legend.heightRange,
                                            icon: <div className={styles.circle} style={{ backgroundColor: 'var(--gd-email-sending-range-height-range-color)' }}></div>,
                                            classNames: {
                                                container: styles.legendItem,
                                            },
                                        },
                                    ]}
                                />
                            }
                        >
                            <IconAssets.Information />
                        </InfoBulle>
                    </div>
                </div>

                <div>
                    {includeWorkingDaysEditor ? (
                        <div className={styles.workingDaysEditorContent}>
                            <div className={styles.workingDaysEditorLegend}></div>
                            <WorkingDaysEditor />
                        </div>
                    ) : null}
                    <div className={styles.emailSendingRangeDescriptions}>
                        {lang.goal.qvt.emailSendingRange.description1}
                        <br />
                        <br />
                        {lang.goal.qvt.emailSendingRange.description2}
                    </div>
                </div>
            </div>
        </div>
    )
}
