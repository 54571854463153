import { createUseStyles } from 'react-jss'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import Popup from '../Popup'
import { GreetButton } from './GreetButton'

interface ConfirmationPopupProps {
    title: string
    showPopup: boolean
    messages: string[]
    confirmBtnText: string
    onConfirm: (...args: any[]) => void
    cancelBtnText: string
    onCancel: (...args: any[]) => void
}

const useStylesPopup = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'var(--gd-main-popup-background-color)',
        padding: '30px',
        borderRadius: 'var(--gd-border-small-radius)',
        rowGap: '35px',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
        color: 'var(--gd-secondary-dark-color)',
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        width: '80%',
        textAlign: 'center',
    },
    bodyText: {
        fontSize: 'var(--gd-size-body-text)',
        color: 'var(--gd-text-dark-color)',
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '80%',
        gap: '20px',
    },
    btn: {
        width: '170px',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        container: {
            padding: '20px',
        },
    },
})

const ConfirmationPopup = (props: ConfirmationPopupProps) => {
    const styles = useStylesPopup()
    if (!props.showPopup) {
        return null
    }

    return (
        <Popup onCloseRequest={props.onCancel} closable={false}>
            <div className={styles.container}>
                <div className={styles.titleContainer}>{props.title}</div>
                <div className={styles.bodyContainer}>
                    {props.messages.map((message, index) => (
                        <div key={index} className={styles.bodyText}>
                            {message}
                        </div>
                    ))}
                </div>
                <div className={styles.btnContainer}>
                    <GreetButton type='secondary-inv' className={styles.btn} label={props.cancelBtnText} onClick={() => props.onCancel()} />
                    <GreetButton type='danger' className={styles.btn} label={props.confirmBtnText} onClick={() => props.onConfirm()} />
                </div>
            </div>
        </Popup>
    )
}

export default ConfirmationPopup
