import { Plugin } from 'chart.js'

interface GreetMonthOptions {
    color: string
    lineWidth?: number
}

export default function buildGreetxAxePeriod({ color, lineWidth = 5 }: GreetMonthOptions): Plugin<'line'> {
    return {
        id: 'greetperiod',
        afterDraw(chart) {
            const { ctx, chartArea, scales } = chart
            const currentMonth = new Date().getMonth()
            const currentYear = new Date().getFullYear()

            // Date months are zero indexed
            // index of December
            const indexOfLastMonth = 11
            const sectionLength = scales.x.getPixelForTick(3) - scales.x.getPixelForTick(2)
            const x1 = currentMonth !== 0 ? scales.x.getPixelForTick(currentMonth) : chartArea.left + 15
            const x2 = currentMonth !== indexOfLastMonth ? scales.x.getPixelForTick(currentMonth + 1) : chartArea.right - 1
            const y = chartArea.bottom
            const dateOfDay = new Date().getDate()
            const daysInMonth = new Date(currentYear, currentMonth, 0).getDate()
            const shiftGap = sectionLength - (sectionLength * dateOfDay) / daysInMonth
            ctx.save()
            ctx.beginPath()
            ctx.strokeStyle = color
            ctx.lineWidth = lineWidth
            ctx.moveTo(x1 - shiftGap, y)
            ctx.lineTo(x2 - shiftGap, y)
            ctx.stroke()
            ctx.closePath()
            ctx.restore()
        },
    }
}
