import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { Customer, EGoal } from '../../models'
import { BrickTypes } from '../../models/Brick'
import QVTIndividualGoal from '../../pages/QVTIndividualGoal'
import LeckoGsuiteEn from './cutomerLangs/lecko-gsuite/LeckoGsuiteEn'
import LeckoGsuiteFr from './cutomerLangs/lecko-gsuite/LeckoGsuiteFr'
import lecko from './lecko'

const leckoGsuite: Customer = {
    ...lecko,
    name: 'lecko_gsuite',
    useGoogleAuth: true,
    disablePwa: true,
    QVTIndividualGoal: QVTIndividualGoal,
    bricks: [
        {
            type: BrickTypes.GMAIL,
            name: 'Gmail',
        },
        {
            type: BrickTypes.GDRIVE,
            name: 'Drive',
        },
        {
            type: BrickTypes.SHAREDGDRIVE,
            name: 'Shared Drive',
        },
    ],
    goals: [EGoal.CARBON_FOOTPRINT, EGoal.QVT],
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES, GreetFeaturingTarget.QVT],
    customerLang: {
        fr: LeckoGsuiteFr,
        en: LeckoGsuiteEn,
    },
}

export default leckoGsuite
