import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import IllustrationAssets from '../assets/illustration/IllustrationAssets'
import { useInstance, useUser } from '../contexts'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'

interface Props {
    classNames?: {
        container?: string
        textContent?: string
        title?: string
        description?: string
        illustrationContainer?: string
        illustration?: string
        logo?: string
    }
    hideLogo?: boolean
    location?: 'presentation' | 'authentication' | 'piloteWaiting'
}

const useStyles = createUseStyles({
    presentation: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '20px',
        gap: '1rem',
    },
    textContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '20px 35px 20px 0',
    },
    title: {
        fontSize: 'var(--gd-size-big-title)',
        fontWeight: 'bold',
        color: 'var(--gd-big-text-dark-color)',
    },
    description: {
        fontSize: 'var(--gd-size-body-text)',
        color: 'var(--gd-text-dark-color)',
    },
    illustrationContainer: {
        width: '50%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'end',
    },
    illustration: {
        height: '300px',
        width: '520px',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        presentation: {
            flexDirection: 'column',
            padding: '2rem 2rem 0 2rem',
        },
        title: {
            lineHeight: '25px',
        },
        description: {
            display: 'none',
        },
        illustrationContainer: {
            display: 'none',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_XL]: {
        illustrationContainer: {
            width: '100%',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        presentation: {
            flexDirection: 'column-reverse',
            padding: '20px 20px 0px 20px',
        },
        illustrationContainer: {
            alignItems: 'center',
        },
    },
})

export default function Presentation({
    classNames: customStyles = { container: '', textContent: '', title: '', description: '', illustration: '', illustrationContainer: '', logo: '' },
    location = 'presentation',
    hideLogo = true,
}: Readonly<Props>) {
    const { customer } = useInstance()
    const { lang } = useUser()
    const styles = useStyles()

    return (
        <div className={classNames(styles.presentation, customStyles.container)}>
            <div className={classNames(styles.textContent, customStyles.textContent)}>
                {hideLogo ? null : <customer.logo classNames={[customStyles.logo]} />}
                <div className={classNames(styles.title, customStyles.title)}>{(lang[location] as any).title}</div>
                <div className={classNames(styles.description, customStyles.description)}>
                    {(lang[location] as any)?.description?.map((e: string) => (
                        <span key={e}>
                            {e}
                            <br />
                        </span>
                    ))}
                </div>
            </div>
            <div className={classNames(styles.illustrationContainer, customStyles.illustrationContainer)}>
                <div className={classNames(styles.illustration, customStyles.illustration)}>
                    <IllustrationAssets.GreetMainImage />
                </div>
            </div>
        </div>
    )
}
