import classnames from 'classnames'
import { ReactNode, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { useUser } from '../../contexts'

import ColibrisAssets from '../../assets/icon/colibris/ColibrisAssets'
import { Lang } from '../../models'
import { Badge } from '../../models/Badge'
import { useFetch } from '../../services/api/useFetch'
import { getBadgeDetails } from '../Badge'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import SoftLoader from '../SoftLoader'
import Title from '../Title'
import { GridTemplate, MateVictoryDisposition, fullLineGridTemplate, midiumLineGridTemplate, minimuLineGridTemplate, mobileLineGridTemplate } from './MatesVictoriesGridTemplates'

interface MateVictory {
    lastName: string
    firstName: string
    badgeCount: string
    avatar?: string
    score: number
    position: GridTemplate
    lastBadge: Badge
}

const useMatesVictoriesStyles = createUseStyles({
    matesVictories: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '10px 0',
        gap: '1rem',
        marginTop: '25px',
    },
    victoriesMosaic: {
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gridTemplateRows: 'repeat(3, 1fr)',
        gridColumnGap: '25px',
        gridRowGap: '25px',
    },
    victoriesMobileMosaic: {
        display: 'none',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        matesVictories: {
            padding: '20px',
        },
        victoriesMosaic: {
            display: 'none',
        },
        victoriesMobileMosaic: {
            padding: '5px',
            display: 'grid',
            gridTemplateColumns: 'repeat(14, 1fr)',
            gridTemplateRows: 'repeat(2, 1fr)',
            gridColumnGap: '20px',
            gridRowGap: '20px',
            maxWidth: '90vw',
            flexWrap: 'nowrap',
            overflowY: 'hidden',
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            width: '100%',
            rowGap: 'unset',
        },
    },
})

const MatesVictories = () => {
    const { lang } = useUser()
    const styles = useMatesVictoriesStyles()
    const [vicotries, setVicotries] = useState<MateVictory[]>([])
    const [vicotriesMobile, setVicotriesMobile] = useState<MateVictory[]>([])
    const { loading } = useFetch({
        endpoint: 'users-victory',
        onSuccess(data: any[]) {
            data.length = 14
            let gridTemplate: GridTemplate[]
            const mobileGridTemplate: GridTemplate[] = mobileLineGridTemplate
            if (data.length < 6) {
                gridTemplate = minimuLineGridTemplate
            } else if (data.length < 8) {
                gridTemplate = midiumLineGridTemplate
            } else {
                gridTemplate = fullLineGridTemplate
            }
            const usersVitctories: MateVictory[] = []
            const mobileUsersVitctories: MateVictory[] = []

            data.forEach((item: any, index: number) => {
                const avatar = item.avatar ? `url(data:image/jpeg;base64,${item.avatar})` : undefined
                usersVitctories.push({ ...item, avatar: avatar, position: gridTemplate[index] })
                mobileUsersVitctories.push({ ...item, avatar: avatar, position: mobileGridTemplate[index % mobileGridTemplate.length] })
            })
            setVicotries(usersVitctories)
            setVicotriesMobile(mobileUsersVitctories)
        },
    })

    if (loading) {
        return <SoftLoader />
    }

    return (
        <div className={styles.matesVictories}>
            <Title title={lang.home.victory.mateVictoriesTitle} />
            <div className={styles.victoriesMosaic}>
                {vicotries.map((victory: any, index) => (
                    <MateVictory key={`mate-victory-${index}-${victory.firstName}-${victory.lastName}`} victory={victory} lang={lang} />
                ))}
            </div>
            <div className={styles.victoriesMobileMosaic}>
                {vicotriesMobile.map((victory: any, index) => (
                    <MateVictory key={`mate-victory-${index}-${victory.firstName}-${victory.lastName}`} victory={victory} lang={lang} />
                ))}
            </div>
        </div>
    )
}

const useMateVictory = (gridTempalte: GridTemplate) =>
    createUseStyles({
        mateVictory: {
            display: 'flex',
            gridArea: gridTempalte?.area,
            justifyContent: 'center',
            width: '100%',
        },
        mateVictoryVertical: {
            flexDirection: 'column',
        },
        mateVictoryVerticalReverse: {
            flexDirection: 'column-reverse',
        },
        mateVictoryHorizontal: {
            flexDirection: 'row-reverse',
        },
        mateVictoryVerticalAvatar: {
            minHeight: '180px!important',
        },
        mateVictoryHorizontalAvatar: {
            minWidth: '180px!important',
        },
        mateVictoryOneBadge: {
            position: 'relative',
            display: 'none',
            opacity: 0,
        },
        mateAvatarOneBadge: {
            position: 'absolute',
        },
        mateVictorySimpleBadge: {
            '&:hover $mateVictoryOneBadge': {
                display: 'block',
                '-webkit-animation': '$mateVictoryBadgeAnimation 0.2s ease-out',
                animation: '$mateVictoryBadgeAnimation 0.2s ease',
                opacity: 1,
            },
        },
        '@keyframes mateVictoryBadgeAnimation': {
            '0%': {
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        },
        mateVicotryAvatarRadius: {
            borderRadius: '10px',
        },
        mateVicotryAvatarTopRadius: {
            borderRadius: '10px 10px 0 0',
        },
        mateVicotryAvatarBottomRadius: {
            borderRadius: '0 0 10px 10px',
        },
        mateVicotryAvatarRightRadius: {
            borderRadius: '0 10px 10px 0',
        },
        mateVicotryAvatarLeftRadius: {
            borderRadius: '10px 0 0 10px',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            mateVictory: {
                minWidth: '340px',
            },
        },
    })()

interface MateVictoryProps {
    victory: MateVictory
    lang: Lang
    [x: string]: any
}
interface MateVictoryAvatarProps {
    avatar?: string
    score: number
    [x: string]: any
}

const useMateVictoryAvatarStyle = (avatar?: string) =>
    createUseStyles({
        mateAvatar: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '168px',
            minHeight: '168px',
            backgroundColor: 'var(--gd-background-dark-color)',
        },
        userAvatar: {
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: avatar,
        },
        userColibris: {
            width: '100%',
            height: '100%',
            maxWidth: '120px',
            maxHeight: '120px',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            mateAvatar: {
                minWidth: '150px',
                minHeight: '150px',
            },
        },
    })()

const MateVictoryAvatar = ({ avatar, score, ...rest }: MateVictoryAvatarProps) => {
    const styles = useMateVictoryAvatarStyle(avatar)
    const { className: classNameProps, ...restProps } = rest
    if (avatar != null) {
        return <div className={classnames(classNameProps, styles.mateAvatar, styles.userAvatar)} {...restProps}></div>
    }

    let colibris: ReactNode
    if (score == null || score < 999) {
        colibris = <ColibrisAssets.ColibrisGoutte />
    } else if (score < 99999) {
        colibris = <ColibrisAssets.ColibrisVerre />
    } else if (score < 999999) {
        colibris = <ColibrisAssets.ColibrisSeau />
    } else {
        colibris = <ColibrisAssets.ColibrisLance />
    }

    return (
        <div className={classnames([styles.mateAvatar, classNameProps])} {...restProps}>
            <div className={styles.userColibris}>{colibris}</div>
        </div>
    )
}

const MateVictory = ({ victory, lang }: MateVictoryProps) => {
    const styles = useMateVictory(victory.position)
    const disposition = victory.position.disposition
    if (disposition == null) {
        return (
            <div className={classnames([styles.mateVictory, styles.mateVictorySimpleBadge])}>
                <MateVictoryAvatar avatar={victory.avatar} score={victory.score} className={classnames([styles.mateAvatarOneBadge, styles.mateVicotryAvatarRadius])} />
                <div className={classnames([styles.mateVictoryOneBadge])}>
                    <MateLastBadge victory={victory} lang={lang} className={styles.mateVicotryAvatarRadius} />
                </div>
            </div>
        )
    }

    if (disposition === MateVictoryDisposition.VERTICAL) {
        return (
            <div className={classnames([styles.mateVictory, styles.mateVictoryVerticalReverse])}>
                <MateVictoryAvatar avatar={victory.avatar} score={victory.score} className={classnames([styles.mateVicotryAvatarBottomRadius, styles.mateVictoryVerticalAvatar])} />
                <MateLastBadge victory={victory} lang={lang} className={styles.mateVicotryAvatarTopRadius} />
            </div>
        )
    }

    if (disposition === MateVictoryDisposition.VERTICAL_REVERSE) {
        return (
            <div className={classnames([styles.mateVictory, styles.mateVictoryVertical])}>
                <MateVictoryAvatar avatar={victory.avatar} score={victory.score} className={classnames([styles.mateVicotryAvatarTopRadius, styles.mateVictoryVerticalAvatar])} />
                <MateLastBadge victory={victory} lang={lang} className={styles.mateVicotryAvatarBottomRadius} />
            </div>
        )
    }

    return (
        <div className={classnames([styles.mateVictory, styles.mateVictoryHorizontal])}>
            <MateVictoryAvatar avatar={victory.avatar} score={victory.score} className={classnames([styles.mateVicotryAvatarRightRadius, styles.mateVictoryHorizontalAvatar])} />
            <MateLastBadge victory={victory} lang={lang} className={styles.mateVicotryAvatarLeftRadius} />
        </div>
    )
}

const useMateLastBadgeStyles = createUseStyles({
    mateLastBadge: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        textAlign: 'center',
        gap: '5px',
        fontSize: 'var(--gd-size-small-body-text)',
        backgroundColor: 'var(--gd-background-dark-color)',
        width: '100%',
        height: '100%',
        minWidth: '168px',
        minHeight: '168px',
    },
    mateLastBadgeIcon: {
        display: 'flex',
        height: '60px',
        width: '60px',
    },
    mateName: {
        fontWeight: 'bold',
    },
    mateBadgeName: {
        fontWeight: 'bold',
    },
    mateBadgeFullName: {
        color: 'var(--gd-secondary-color)',
        fontSize: '13px',
    },
})

const MateLastBadge = ({ victory, lang, ...rest }: MateVictoryProps) => {
    const styles = useMateLastBadgeStyles()
    const badgeDetails = getBadgeDetails(victory.lastBadge, lang)
    const { className: classNameProps, ...restProps } = rest
    return (
        <div className={classnames([styles.mateLastBadge, classNameProps])} {...restProps}>
            <div className={styles.mateLastBadgeIcon}>{badgeDetails?.icon}</div>
            <div>
                <div className={styles.mateName}>{victory.firstName}</div>
                <div>{lang.home.victory.mateVictory}</div>
                <div className={styles.mateBadgeFullName}>
                    &#10077;<span className={styles.mateBadgeName}>{badgeDetails?.title}</span>&#10078;
                </div>
            </div>
        </div>
    )
}

export default MatesVictories
