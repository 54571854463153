import { useMemo } from 'react'
import { useStore, useUser } from '../../contexts'
import { EGraph, RelationshipPositioningGraph } from '../RelationshipPositioning'

const QVTWeeklyRelationshipPositioningGraph = () => {
    const {
        state: { userPayload },
    } = useStore()
    const { lang } = useUser()

    const weeklyMeetingTimeSlots = useMemo(() => userPayload?.qvtRelationshipPositioning?.weeklyMeetingTimeSlots ?? [], [userPayload])

    return <RelationshipPositioningGraph slots={weeklyMeetingTimeSlots} lang={lang.goal.qvt.relationshipPositioning.weeklyMeetingTime} graphType={EGraph.MEETING} />
}

export default QVTWeeklyRelationshipPositioningGraph
