import { createUseStyles } from 'react-jss'
import SwissLifeLogoImg from '../../assets/logos/SwissLifeDesktop.svg'

const useSwisslifeLogoStyles = createUseStyles({
    swisslifeLogo: {
        display: 'block',
        minHeight: '47px',
        height: '100%',
        width: '100%',
        maxWidth: '450px',
    },
})

const SwissLifeLogo = () => {
    const styles = useSwisslifeLogoStyles()
    return <img className={styles.swisslifeLogo} src={SwissLifeLogoImg} />
}

export default SwissLifeLogo
