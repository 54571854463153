import { ReactComponent as AccessDeniedIcon } from './AccessDeniedIcon.svg'
import { ReactComponent as AddChallengeIcon } from './AddChallengeIcon.svg'
import { ReactComponent as ArrowButton } from './ArrowButton.svg'
import ArrowRight from './ArrowRight'
import { ReactComponent as Attachement } from './Attachement.svg'
import { ReactComponent as AttachementClip } from './AttachementClip.svg'
import { ReactComponent as AttachementFileVolume } from './AttachementFileVolume.svg'
import AwardedBadge from './AwardedBadge'
import BenchMarkSubTabIcon from './BenchMarkSubTabIcon'
import { ReactComponent as Bulb } from './Bulb.svg'
import { ReactComponent as Calendar } from './Calendar.svg'
import CarbonCumulGraphSubTabIcon from './CarbonCumulGraphSubTabIcon'
import CarbonFootprintIcon from './CarbonFootprintIcon'
import CaretLeft from './CaretLeft'
import CaretRight from './CaretRight'
import ChallengeSubTabIcon from './ChallengeSubTabIcon'
import { ReactComponent as Cleanup } from './Cleanup.svg'
import CloseIcon from './CloseIcon'
import { ReactComponent as Copilote } from './Copilote.svg'
import { ReactComponent as CopyIcon } from './CopyIcon.svg'
import CrossIcon from './CrossIcon'
import { ReactComponent as Dart } from './Dart.svg'
import { ReactComponent as Destinataire } from './Destinataire.svg'
import DisconnectionTimeIcon from './DisconnectionTimeIcon'
import DoughnutSubTabIcon from './DoughnutSubTabIcon'
import { ReactComponent as Down } from './Down.svg'
import { ReactComponent as Eau } from './Eau.svg'
import { ReactComponent as EmailIcon } from './EmailIcon.svg'
import { ReactComponent as EmailIconBg } from './EmailIconBg.svg'
import { ReactComponent as Equal } from './Equal.svg'
import { ReactComponent as Eye } from './Eye.svg'
import { ReactComponent as EyeOff } from './EyeOff.svg'
import { ReactComponent as File } from './File.svg'
import { ReactComponent as Garbage } from './Garbage.svg'
import { ReactComponent as GDrive } from './GDrive.svg'
import { ReactComponent as Gmail } from './Gmail.svg'
import { ReactComponent as GroupIcon } from './GroupIcon.svg'
import { ReactComponent as GroupTabIcon } from './GroupTabIcon.svg'
import { ReactComponent as HeartBeating } from './HeartBeating.svg'
import { ReactComponent as ImageHunt } from './ImageHunt.svg'
import InfobesityIcon from './InfobesityIcon'
import { ReactComponent as Information } from './Information.svg'
import { ReactComponent as InternAttachment } from './InternAttachment.svg'
import { ReactComponent as Jeans } from './Jeans.svg'
import { ReactComponent as MailShot } from './MailShot.svg'
import { ReactComponent as MeetingCalendar } from './MeetingCalendar.svg'
import { ReactComponent as Minus } from './Minus.svg'
import { ReactComponent as Monitor } from './Monitor.svg'
import NewLightBulb from './NewLightBulb'
import { ReactComponent as NoChallengeIcon } from './NoChallengeIcon.svg'
import { ReactComponent as Objective } from './Objective.svg'
import { ReactComponent as OneDriveIcon } from './OneDriveIcon.svg'
import { ReactComponent as OneDriveIconBG } from './OneDriveIconBG.svg'
import Pen from './Pen'
import { ReactComponent as PlanetFriendlyIcon } from './PlanetFriendlyIcon.svg'
import { ReactComponent as PlusDarkIcon } from './PlusDarkIcon.svg'
import { ReactComponent as PlusIcon } from './PlusIcon.svg'
import { ReactComponent as PlusLightIcon } from './PlusLightIcon.svg'
import { ReactComponent as PositionDown } from './PositionDown.svg'
import { ReactComponent as PositionEqual } from './PositionEqual.svg'
import { ReactComponent as PositionUp } from './PositionUp.svg'
import QVTIcon from './QVTIcon'
import { ReactComponent as Refresh } from './Refresh.svg'
import { ReactComponent as Salade } from './Salade.svg'
import { ReactComponent as SearchIcon } from './SearchIcon.svg'
import { ReactComponent as Send } from './Send.svg'
import { ReactComponent as SharedGDrive } from './SharedGDrive.svg'
import { ReactComponent as SharepointIcon } from './SharepointIcon.svg'
import { ReactComponent as Smartphone } from './Smartphone.svg'
import { ReactComponent as SortArrowDown } from './SortArrowDown.svg'
import { ReactComponent as SortArrowUp } from './SortArrowUp.svg'
import { ReactComponent as Steak } from './Steak.svg'
import { ReactComponent as TeamsIcon } from './TeamsIcon.svg'
import { ReactComponent as Tgv } from './Tgv.svg'
import { ReactComponent as ToothWheel } from './ToothWheel.svg'
import { ReactComponent as Transcript } from './Transcript.svg'
import { ReactComponent as TShirt } from './TShirt.svg'
import { ReactComponent as Up } from './Up.svg'
import { ReactComponent as Upload } from './Upload.svg'
import { ReactComponent as User } from './User.svg'
import { ReactComponent as UserIcon } from './UserIcon.svg'
import { ReactComponent as UserTabIcon } from './UserTabIcon.svg'
import { ReactComponent as ValidIcon } from './ValidIcon.svg'
import { ReactComponent as VideoHunt } from './VideoHunt.svg'
import { ReactComponent as VisionIcon } from './VisionIcon.svg'
import { ReactComponent as Voiture } from './Voiture.svg'
import { ReactComponent as ClockIcon } from './Clock.svg'
import { ReactComponent as HourglassIcon } from './Hourglass.svg'

export default {
    AccessDeniedIcon,
    AddChallengeIcon,
    ArrowButton,
    ArrowRight,
    Attachement,
    AttachementClip,
    AttachementFileVolume,
    AwardedBadge,
    BenchMarkSubTabIcon,
    Bulb,
    Calendar,
    CarbonCumulGraphSubTabIcon,
    CarbonFootprintIcon,
    CaretLeft,
    CaretRight,
    ChallengeSubTabIcon,
    Cleanup,
    CloseIcon,
    CrossIcon,
    ClockIcon,
    Dart,
    DisconnectionTimeIcon,
    DoughnutSubTabIcon,
    Down,
    Destinataire,
    Eau,
    EmailIcon,
    EmailIconBg,
    Equal,
    Eye,
    EyeOff,
    File,
    Garbage,
    GroupIcon,
    GroupTabIcon,
    ImageHunt,
    InfobesityIcon,
    Information,
    InternAttachment,
    Jeans,
    MailShot,
    MeetingCalendar,
    Minus,
    Monitor,
    NewLightBulb,
    NoChallengeIcon,
    Objective,
    OneDriveIcon,
    OneDriveIconBG,
    Pen,
    PlanetFriendlyIcon,
    PlusDarkIcon,
    PlusIcon,
    PlusLightIcon,
    PositionDown,
    PositionEqual,
    PositionUp,
    QVTIcon,
    Salade,
    SearchIcon,
    Send,
    SharepointIcon,
    Smartphone,
    SortArrowDown,
    SortArrowUp,
    Steak,
    TeamsIcon,
    Tgv,
    ToothWheel,
    TShirt,
    Up,
    User,
    UserIcon,
    UserTabIcon,
    Upload,
    ValidIcon,
    VideoHunt,
    VisionIcon,
    Voiture,
    Refresh,
    Gmail,
    GDrive,
    SharedGDrive,
    Copilote,
    Transcript,
    CopyIcon,
    HeartBeating,
    HourglassIcon,
}
