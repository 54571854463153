import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { useUser } from '../../contexts'

const useStyles = (avatar: string | null | undefined) =>
    createUseStyles({
        avatar: {
            width: '50px',
            height: '50px',
            backgroundColor: '#C8C8C8',
            borderRadius: '50%',
            cursor: 'pointer',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${avatar})`,
        },
    })()

interface AvatarProps {
    onClick?: () => void
    [x: string]: any
}

export default function Avatar({ onClick, ...rest }: AvatarProps) {
    const { user } = useUser()
    const styles = useStyles(user?.avatar)
    const { className: overridedCLassName, ...restProps } = rest
    return (
        <div className={classnames([styles.avatar, overridedCLassName])} onClick={onClick} {...restProps}>
            {user?.avatar ? null : <IconAssets.User />}
        </div>
    )
}
