import classNames from 'classnames'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { useUser } from '../../contexts'
import { Params } from '../../models'
import leckoPlatformInfo from '../../models/LeckoPlatformInfo'
import { useDelete, useFetch, usePut } from '../../services/api/useFetch'
import SoftLoader from '../SoftLoader'
import Title from '../Title'
import ConfirmationPopup from '../share/ConfirmationPopup'
import { GreetButton, GreetSubmitButton } from '../share/GreetButton'
import GreetCSVUpload from '../share/GreetCSVUpload'
import { TextAreaField, TextField } from '../share/Inputs'
import useQueryParams from '../useQueryParams'
import { isEmailValide } from '../utils'

export enum CohortDetailsMode {
    CREATION,
    EDIT,
}

const useCohortDetailsStyle = createUseStyles({
    cohortDetails: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
    },
    mainDescriptionTitle: {
        display: 'flex',
        width: '50%',
        fontSize: 'var(--gd-size-small-body-text)',
        padding: '0 0 1rem 0',
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

const CohortDetails = () => {
    const { lang } = useUser()
    const styles = useCohortDetailsStyle()
    const queryParams = useQueryParams()
    const params = useParams<Params>()
    const location = useLocation()
    const locationState = location.state as { cohort: any; secret: string; cohortDetailMode: CohortDetailsMode }
    const cohortNameQueryParams = decodeURIComponent(queryParams.get('name') ?? '')
    const [leckoPlatformInfo, setLeckoPlatformInfo] = useState<leckoPlatformInfo>()

    const getDescription = () => {
        switch (locationState?.cohortDetailMode) {
            case CohortDetailsMode.CREATION:
                return (
                    <div className={styles.mainDescriptionTitle}>{`${lang.administration.globalDescriptionSentence1(
                        leckoPlatformInfo?.minimumCohortMembersAuthorized,
                    )} ${lang.administration.globalDescriptionSentence2(leckoPlatformInfo?.domains)} ${lang.administration.globalDescriptionSentence3}`}</div>
                )
            case CohortDetailsMode.EDIT:
                return (
                    <div>
                        <div className={styles.mainDescriptionTitle}>{lang.administration.editDescription}</div>
                        <div className={styles.mainDescriptionTitle}>{`${lang.administration.globalDescriptionSentence1(
                            leckoPlatformInfo?.minimumCohortMembersAuthorized,
                        )} ${lang.administration.globalDescriptionSentence2(leckoPlatformInfo?.domains)} ${lang.administration.globalDescriptionSentence3}`}</div>
                    </div>
                )
            default:
                return null
        }
    }

    const { loading: minimumCohortMembersAuthorizedLoading } = useFetch({
        baseEndpoint: 'cohort',
        endpoint: '/lecko-platform-info',
        onSuccess: (data) => {
            setLeckoPlatformInfo(data)
        },
    })

    if (
        locationState?.secret == null ||
        locationState?.cohortDetailMode == null ||
        (locationState?.cohortDetailMode === CohortDetailsMode.EDIT && (locationState?.cohort == null || locationState?.cohort?.name !== cohortNameQueryParams))
    ) {
        return <Redirect to={`/${params.langParam}/${params.customerParam}/administration`} />
    }
    const cohortName = locationState?.cohortDetailMode === CohortDetailsMode.EDIT ? locationState?.cohort?.displayName : 'Nouvelle cohorte'
    return minimumCohortMembersAuthorizedLoading ? (
        <div className={styles.loader}>
            <SoftLoader />
        </div>
    ) : (
        <div className={styles.cohortDetails}>
            {getDescription()}
            <Title title={`Gestion de cohorte : ${cohortName}`} />
            <CohortEditor cohort={locationState.cohort} secret={locationState.secret} cohortDetailMode={locationState.cohortDetailMode} />
        </div>
    )
}

const useCohortEditorStyle = createUseStyles({
    cohortEditor: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '20px',
    },
    fields: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '30px',
        borderTop: '1px solid var(--gd-secondary-color)',
        padding: '20px 0',
        width: '100%',
        '& input, textarea': {
            backgroundColor: 'var(--gd-background-clear-color)',
            padding: '10px',
        },
    },
    fieldLabel: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        color: 'var(--gd-secondary-color)',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 'bold',
        width: '180px',
    },
    fieldLabelDetail: {
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'normal',
    },
    fieldError: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: '5px',
        '& p': {
            width: '100%',
            maxHeight: '50px',
            overflow: 'auto',
            color: 'var(--gd-negative-color)',
        },
    },
    membersField: {
        alignSelf: 'flex-start',
    },
    membersInputs: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        width: '100%',
    },
    fileUploadField: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '100%',
        height: '80px',
    },
    buttonsGroup: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTop: '1px solid var(--gd-secondary-color)',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '20px',
        padding: '20px 0',
    },
})

interface CohortEditorForm {
    name: string
    displayName: string
    emails: string
}

const CohortEditor = ({ cohort, secret, cohortDetailMode }: { cohort: any; secret: string; cohortDetailMode: CohortDetailsMode }) => {
    const params = useParams<Params>()
    const history = useHistory()
    const styles = useCohortEditorStyle()
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
        resetField,
    } = useForm<CohortEditorForm>({
        mode: 'onChange',
        defaultValues: {
            displayName: cohort?.displayName ?? '',
            name: cohort?.name ?? '',
            emails: '',
        },
    })

    const { doPut: doCreateCohort, working: createWorking } = usePut({
        baseEndpoint: 'cohort',
        endpoint: '/create-cohort',
        enableTaostSuccess: true,
        onSuccess: () => {
            history.replace(`/${params.langParam}/${params.customerParam}/administration`)
        },
    })
    const { doPut: doUpdateCohort, working: updateWorking } = usePut({
        baseEndpoint: 'cohort',
        endpoint: '/update-cohort',
        params: {
            name: cohort?.name,
        },
        enableTaostSuccess: true,
        onSuccess: () => {
            resetField('emails')
            history.replace(`/${params.langParam}/${params.customerParam}/administration`)
        },
    })
    const { doDelete: doDeleteCohort, working: deleteWorking } = useDelete({
        baseEndpoint: 'cohort',
        endpoint: '/delete-cohort-by-name',
        onSuccess: () => {
            setShowConfirmDelete(false)
            history.replace(`/${params.langParam}/${params.customerParam}/administration`)
        },
    })

    const cleanEmailsInputValue = (value: string): string => {
        // ',' text area delimiter
        return value.trim().replace(/,+$/, '')
    }

    const areEmailsValide = (input: string, seperator: string): { valid: boolean; invalidEmails: string[] } => {
        const emails = input.split(seperator).map((email) => email.trim())
        const invalidEmails: string[] = []
        emails.forEach((email) => {
            if (!isEmailValide(email)) {
                invalidEmails.push(email)
            }
        })

        return {
            valid: invalidEmails.length === 0,
            invalidEmails,
        }
    }

    const onSubmit: SubmitHandler<CohortEditorForm> = (data: CohortEditorForm) => {
        const cleanedEmails = cleanEmailsInputValue(data.emails)
        const { valid, invalidEmails } = areEmailsValide(cleanedEmails, ',')
        if (!valid) {
            setError('emails', {
                message: `Addresse email invalide : ${invalidEmails.join(', ')}`,
            })
            return
        }

        if (cohortDetailMode === CohortDetailsMode.CREATION) {
            doCreateCohort({
                name: data.displayName,
                description: data.displayName,
                emails: data.emails.split(',').map((email) => email.trim()),
                saltKey: secret,
            })
        } else if (cohortDetailMode === CohortDetailsMode.EDIT) {
            doUpdateCohort({
                name: data.displayName,
                description: data.displayName,
                emails: data.emails.split(',').map((email) => email.trim()),
                saltKey: secret,
            })
        } else {
            throw new Error('Unkown cohort detail mode')
        }
    }

    const deleteCohort = async () => {
        await doDeleteCohort({ name: cohort.name, secret: secret })
    }

    const onUploadCSV = (data: any) => {
        const emails = parseCSV(data)
        setValue('emails', emails.join(','))
    }

    const parseCSV = (rows: any): string[] => {
        const data: string[] = []
        rows.forEach((row: string[]) => {
            if (row.length > 0) {
                const value = row[0].trim()
                value && data.push(value)
            }
        })
        return data
    }

    return (
        <form className={styles.cohortEditor} onSubmit={handleSubmit(onSubmit)}>
            {cohortDetailMode === CohortDetailsMode.CREATION ? (
                <div className={styles.fields}>
                    <div className={styles.fieldLabel}>
                        <span>Nom :</span>
                        <span className={styles.fieldLabelDetail}>Le nom fait office d’identifiant unique</span>
                    </div>
                    <TextField name='name' register={register} type='text' disabled />
                </div>
            ) : null}
            <div className={styles.fields}>
                <div className={styles.fieldLabel}>
                    <span>Libelle :</span>
                    <span className={styles.fieldLabelDetail}>Le libellé apparaitra dans l’interface de l’utilisateur</span>
                </div>
                <TextField name='displayName' register={register} type='text' required />
            </div>
            <div className={styles.fields}>
                <div className={classNames(styles.fieldLabel, styles.membersField)}>
                    <span>Membres :</span>
                    <span className={styles.fieldLabelDetail}>Ou saisir les emails séparés par des virgules</span>
                </div>
                <div className={styles.membersInputs}>
                    <div className={styles.fileUploadField}>
                        <GreetCSVUpload onUpload={onUploadCSV} />
                    </div>
                    <div className={styles.fieldError}>
                        {/* TODO: add number of members and message to explain that emails could not be displayed as they are hashed */}
                        <TextAreaField name='emails' register={register} nbRows={3} required />
                        {errors.emails?.message && <p>{errors.emails.message}</p>}
                    </div>
                </div>
            </div>
            <div className={styles.buttonsGroup}>
                <div className={styles.buttons}>
                    {cohortDetailMode === CohortDetailsMode.EDIT ? (
                        <GreetButton type='danger' label=' Supprimer la cohorte' disabled={deleteWorking} onClick={() => setShowConfirmDelete(true)} />
                    ) : null}
                </div>
                <div className={styles.buttons}>
                    <GreetButton type='secondary-inv' label='Retour' onClick={() => history.push(`/${params.langParam}/${params.customerParam}/administration`)} />
                    <GreetSubmitButton label='Enregistrer' disabled={createWorking || updateWorking} />
                </div>
            </div>
            <ConfirmationPopup
                showPopup={showConfirmDelete}
                title={'Suppression de cohorte'}
                messages={['Voulez-vous vraiment supprimer cette cohorte ?', 'Toutes les données relatives à cette cohorte seront supprimées']}
                confirmBtnText={'Confirmer'}
                cancelBtnText={'Annuler'}
                onCancel={() => {
                    setShowConfirmDelete(false)
                }}
                onConfirm={async () => {
                    await deleteCohort()
                }}
            />
        </form>
    )
}

export default CohortDetails
