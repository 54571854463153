import classnames from 'classnames'
import { createUseStyles } from 'react-jss'
import { useClientThemeStyles } from '../App'
import { MediaQueryBreakpoints } from '../components/MediaQueryBreakpoints'
import Presentation from '../components/Presentation'
import { useInstance } from '../contexts'

const useStyles = createUseStyles({
    mainContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 25px',
        backgroundColor: 'var(--gd-main-app-background-color)',
    },
    content: {
        width: '100%',
        maxWidth: '1142px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0',
    },
    presentation: {
        padding: 'unset',
    },
    presentationTitle: {
        fontSize: 'var(--gd-size-big-title)',
        lineHeight: '42px',
    },
    presentationDescription: {
        paddingTop: '10px',
        fontSize: 'var(--gd-size-body-text)',
        lineHeight: '20px',
    },
    illustrationContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'end',
    },
    textContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
    },
    buttonContent: {
        width: '100%',
    },
    loginButton: {
        width: '50%',
        maxWidth: '400px',
    },
    illustration: {
        height: '400px',
        width: '550px',
    },
    logo: {
        display: 'flex',
        justifyContent: 'flex-start',
        height: '75px',
        width: '195px',
        marginBottom: '30px',
        '& svg': {
            maxWidth: '450px',
        },
    },
    [MediaQueryBreakpoints.MOBILE]: {
        textContent: {
            padding: '0',
            gap: '5px',
            '& img': {
                height: '75px',
                maxWidth: '290px  !important',
            },
        },
        illustration: {
            height: '230px',
            width: '300px',
        },
        presentationTitle: {
            lineHeight: '25px',
        },
        presentationDescription: {
            display: 'block',
            lineHeight: '17px',
            marginBottom: '20px',
        },
        illustrationContainer: {
            width: '100%',
            alignItems: 'center',
            marginTop: '20px',
        },
        logo: {
            display: 'flex',
            justifyContent: 'flex-start',
            height: '75px',
            width: '150px',
            marginBottom: '10px',
        },
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE]: {
        content: {
            flexDirection: 'column',
            gap: '50px',
        },
        loginButton: {
            fontSize: 'var(--gd-size-button-text)',
            width: '100%',
            whiteSpace: 'nowrap',
        },
    },
})

const WaitingForPilote = () => {
    const { customer } = useInstance()
    const styles = useStyles()
    const themeStyles = useClientThemeStyles(customer.theme)
    return (
        <div className={classnames([styles.mainContainer, themeStyles.clientTheme])}>
            <div className={styles.content}>
                <Presentation
                    classNames={{
                        textContent: styles.textContent,
                        container: styles.presentation,
                        title: styles.presentationTitle,
                        description: styles.presentationDescription,
                        illustrationContainer: styles.illustrationContainer,
                        illustration: styles.illustration,
                        logo: styles.logo,
                    }}
                    hideLogo={false}
                    location='piloteWaiting'
                />
            </div>
        </div>
    )
}

export default WaitingForPilote
