import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import { Customer } from '../../models'
import fft from './fft'

const fftBeta: Customer = {
    ...fft,
    name: 'fft_beta',
    disablePwa: true,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.RANKING_VICTORIES],
}

export default fftBeta
