import { createUseStyles } from 'react-jss'
import UserPayload from '../../models/UserPayload'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import { convertToRem } from '../utils'
import { useInstance, useUser } from '../../contexts'
import { substractDays, toDayLiteralMonthString } from '../dateUtils'
import Title from '../Title'
import CommunicationModesDistribution from '../../widgets/qvt/CommunicationModesDistribution'
import TeamsConfiguration from '../../widgets/qvt/TeamsConfiguration'

interface TeamsInterractionsProps {
    userPayload: UserPayload
}

const useStyles = () =>
    createUseStyles({
        qvtCommunicationModesTeamsConfiguration: {
            marginTop: convertToRem(30),
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            alignItems: 'center',
            gap: convertToRem(26),
        },
        qvtCommunicationModesTitle: {
            order: 'unset',
        },
        teamsConfigurationTitle: {
            marginTop: 'unset',
            order: 'unset',
        },
        qvtCommunicationModes: {
            order: 'unset',
        },
        teamsConfiguration: {
            order: 'unset',
        },
        [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
            qvtCommunicationModesTitle: {
                order: 0,
            },
            qvtCommunicationModes: {
                order: 1,
            },
        },
        [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
            qvtCommunicationModesTeamsConfiguration: {
                gridTemplateColumns: '1fr',
            },
        },
        [MediaQueryBreakpoints.MOBILE]: {
            qvtCommunicationModesTeamsConfiguration: {
                gridTemplateColumns: '100%',
            },
        },
    })()

const TeamsInterractions = ({ userPayload }: Readonly<TeamsInterractionsProps>) => {
    const styles = useStyles()
    const { lang } = useUser()
    const { customer } = useInstance()

    if (customer.useGoogleAuth) {
        return null
    }

    return (
        <div className={styles.qvtCommunicationModesTeamsConfiguration}>
            <Title
                classNames={{ container: styles.qvtCommunicationModesTitle }}
                title={lang.goal.qvt.communicationModesDistribution.title}
                subTitle={lang.goal.qvt.communicationModesDistribution.subtitle(
                    toDayLiteralMonthString(substractDays(userPayload.calculationDate, 30), lang.code),
                    toDayLiteralMonthString(substractDays(userPayload.calculationDate, 1), lang.code),
                )}
            />
            <Title classNames={{ container: styles.teamsConfigurationTitle }} title={lang.goal.qvt.teamsConfiguration.title} />
            <CommunicationModesDistribution className={styles.qvtCommunicationModes} payload={userPayload} />
            <TeamsConfiguration className={styles.teamsConfiguration} payload={userPayload} />
        </div>
    )
}

export default TeamsInterractions
