import { rankItem } from '@tanstack/match-sorter-utils'
import {
    ColumnDef,
    ColumnFiltersState,
    FilterFn,
    SortingState,
    Table,
    TableOptions,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Select from 'react-select'
import IconAssets from '../../assets/icon/IconAssets'

interface GreetTableProps<T> {
    columns: ColumnDef<T>[]
    data: T[]
    loading?: boolean
    headerComponentFn?: (props: GreetTableFilterProps<T>) => React.ReactChild
}

export interface GreetTableFilterProps<T> {
    table: Table<T>
    setGlobalFilter: (...args: any[]) => void
}

const fuzzyFilter: FilterFn<any> = (row: any, columnId: any, value: any, addMeta: any) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({ itemRank })
    return itemRank.passed
}

export const GreetTable = <T,>({ columns, data, headerComponentFn, loading = false }: GreetTableProps<T>) => {
    const styles = useGreetTableStyle()
    const [sorting, setSorting] = useState<SortingState>([])
    const memozedCols = useMemo<ColumnDef<T>[]>(() => columns, [columns])
    const [globalFilter, setGlobalFilter] = useState('')
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

    const table = useReactTable({
        data,
        columns: memozedCols,
        state: {
            sorting,
            globalFilter,
            columnFilters,
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        globalFilterFn: fuzzyFilter,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
    } as TableOptions<T>)

    return (
        <div className={styles.greetFilteredTable}>
            {headerComponentFn != null ?headerComponentFn({ table, setGlobalFilter: (value: string) => setGlobalFilter(value) }) : null}
            <table className={styles.greetTable}>
                <thead className={styles.greetTableHead}>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} className={styles.greetTableLine}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        <div
                                            className={classNames([styles.greetTableCell, header.column.getCanSort() ? styles.greetTableSortingCell : ''])}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {'    '}
                                            {{
                                                asc: <IconAssets.SortArrowDown />,
                                                desc: <IconAssets.SortArrowUp />,
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody className={styles.greetTableBody}>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id} className={styles.greetTableLine}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id} className={styles.greetTableCell}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    {loading ? (
                        <tr className={styles.greetTableLine}>
                            <td className={styles.greetTableCell}>Chargement...</td>
                        </tr>
                    ) : table.getPageCount() === 0 ? (
                        <tr className={styles.greetTableLine}>
                            <td className={styles.greetTableCell}>Aucun résultat.</td>
                        </tr>
                    ) : null}
                </tbody>
            </table>
            <Pagination table={table} />
        </div>
    )
}

const useGreetTableStyle = createUseStyles({
    greetFilteredTable: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    greetTable: {
        width: '100%',
        fontSize: 'var(--gd-size-table-text)',
        borderCollapse: 'collapse',
        borderRadius: 'var(--gd-border-small-radius)',
        overflow: 'hidden',
        boxShadow: '0 0 20px 0 rgba(0,0,0,.15)',
    },
    greetTableHead: {
        borderRadius: 'var(--gd-border-small-radius)',
        backgroundColor: 'var(--gd-primary-color)',
        color: '#fff',
    },
    greetTableCell: {
        padding: '15px',
        maxHeight: '55px',
        minWidth: '90px',
    },
    greetTableSortingCell: {
        cursor: 'pointer',
    },
    greetTableLine: {
        height: '55px',
        textAlign: 'left',
    },
    greetTableBody: {
        '& tr:nth-child(even)': {
            backgroundColor: 'var(--gd-background-clear-color)',
        },
    },
    greetTableNoData: {
        color: 'red',
    },
})

const useDebouncedFilterInputStyle = createUseStyles({
    searchField: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 'var(--gd-border-small-radius)',
        gap: '5px',
        border: '1px solid var(--gd-secondary-color)',
        width: '300px',
        height: '42px',
        margin: '5px 5px 5px 0',
        padding: '1px 10px',
        backgroundColor: '#FFFFFF',
    },
    searchInput: {
        height: '38px',
        width: '100%',
        border: 'none',
        fontSize: 'var(--gd-size-small-body-text)',
        backgroundColor: '#FFFFFF',
    },
    searchInputIcon: {
        margin: '8px 2px 5px 0',
    },
})

export const DebouncedInputFilter = ({
    value: initialValue,
    onChange,
    debounce = 300,
    className = '',
    icon,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    icon?: React.ReactChild
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
    const styles = useDebouncedFilterInputStyle()
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])
    const hasIcon = icon != null
    return (
        <div className={styles.searchField}>
            {hasIcon ? <div className={styles.searchInputIcon}>{icon}</div> : null}
            <input {...props} value={value} onChange={(e) => setValue(e.target.value)} className={classNames([styles.searchInput, className])} />
        </div>
    )
}

export const SelectFilter = ({
    value,
    onChange,
    options = [],
    placeholder = '',
}: {
    value: any
    onChange: (value: any) => void
    icon?: React.ReactChild
    options?: any[]
    placeholder?: string
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
    const styles = useDebouncedFilterSelectStyle()

    return (
        <Select
            value={value}
            placeholder={placeholder}
            isSearchable={false}
            noOptionsMessage={() => 'No options'}
            isMulti={false}
            options={options}
            onChange={onChange}
            isDisabled={false}
            classNames={{
                container: () => classNames(styles.debouncedFilterSelect),
                control: () => classNames(styles.selectControl),
                indicatorSeparator: () => classNames(styles.selectindIcatorSeparator),
                menu: () => classNames(styles.selectMenu),
                dropdownIndicator: () => classNames(styles.selectDropdownIndicator),
                singleValue: () => classNames(styles.selectInput),
            }}
        />
    )
}

const useDebouncedFilterSelectStyle = createUseStyles({
    debouncedFilterSelect: {
        width: '300px',
        border: 'none',
        fontSize: 'var(--gd-size-small-body-text)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 'var(--gd-border-small-radius)',
        gap: '5px',
    },
    selectControl: {
        height: '42px',
        width: '100%',
        border: '1px solid var(--gd-secondary-color) !important',
    },
    selectindIcatorSeparator: {
        display: 'none',
    },
    selectMenu: {
        width: '100%',
        marginTop: '0',
        paddingTop: '0',
    },
    selectDropdownIndicator: {
        color: 'var(--gd-secondary-color) !important',
        marginBottom: '5px',
    },
    selectInput: {
        width: '100%',
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'normal',
    },
})

const usePaginationStyle = createUseStyles({
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: '10px',
    },
    carets: {
        height: '14px',
        width: '14px',
    },
    pageNumber: {
        display: 'flex',
        height: '28px',
        width: '28px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: 'var(--gd-secondary-color)',
        color: 'var(--gd-background-clear-color)',
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 700,
        cursor: 'pointer',
        opacity: 0.33,
    },
    activePageNumber: {
        opacity: 1,
    },
    activeCarets: {
        cursor: 'pointer',
    },
    dot: {
        color: 'var(--gd-secondary-color)',
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 700,
    },
})

const MAX_NB_PAGE_INDEXES = 5
const DOTS = '...'

const Pagination = <T,>({ table }: { table: Table<T> }) => {
    const styles = usePaginationStyle()
    const pagesIndexes = useMemo(() => {
        // table pageIndex is zero indexed

        const numberOfPages = [...Array(table.getPageCount()).keys()].length

        if (numberOfPages < MAX_NB_PAGE_INDEXES || numberOfPages - MAX_NB_PAGE_INDEXES <= 1) {
            return range(0, numberOfPages - 1)
        }

        const current = table.getState().pagination.pageIndex

        // dots in the right
        if (current <= MAX_NB_PAGE_INDEXES - 1) {
            return [...range(0, MAX_NB_PAGE_INDEXES - 1), DOTS, numberOfPages - 1]
        }

        // dots in the left
        if (current === numberOfPages || current >= numberOfPages - MAX_NB_PAGE_INDEXES) {
            return [0, DOTS, ...range(numberOfPages - MAX_NB_PAGE_INDEXES, numberOfPages - 1)]
        }

        // dots in both left and right
        return [0, DOTS, ...range(current - 1, current + 1), DOTS, numberOfPages - 1]
    }, [table.getPageCount(), table.getState().pagination.pageIndex])

    if (!table.getCanPreviousPage() && !table.getCanNextPage()) {
        return null
    }

    return (
        <div className={styles.pagination}>
            {table.getCanPreviousPage() ? (
                <div className={classNames([styles.carets, styles.activeCarets])} onClick={() => table.previousPage()}>
                    <IconAssets.CaretLeft fillColor='var(--gd-secondary-color)' />
                </div>
            ) : (
                <div className={styles.carets}></div>
            )}
            {pagesIndexes.map((index) =>
                typeof index === 'string' ? (
                    <span className={styles.dot} key={(Math.random() * 36).toString(36)}>
                        {index}
                    </span>
                ) : (
                    <div
                        key={index}
                        className={classNames([styles.pageNumber, index === table.getState().pagination.pageIndex ? styles.activePageNumber : ''])}
                        onClick={() => table.setPageIndex(index)}
                    >
                        <span>{index + 1}</span>
                    </div>
                ),
            )}
            {table.getCanNextPage() ? (
                <div className={classNames([styles.carets, styles.activeCarets])} onClick={() => table.nextPage()}>
                    <IconAssets.CaretRight fillColor='var(--gd-secondary-color)' />
                </div>
            ) : (
                <div className={styles.carets}></div>
            )}
        </div>
    )
}

function range(start: number, stop: number, step = 1) {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
}
