import classNames from 'classnames'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { MediaQueryBreakpoints } from '../components/MediaQueryBreakpoints'
import Tabs from '../components/goal/Tabs'
import { GreetFeaturingTarget } from '../components/share/GreetFeaturingComponent'
import { useScrollTo } from '../components/share/useScoll'
import { useInstance, useUser } from '../contexts'
import Params from '../models/Params'
import CollectiveGoal from './CollectiveGoal'
import IndividualGoal from './IndividualGoal'
import QVTManagerBoard from './QVTManagerBoard'

const useStyles = createUseStyles({
    contentRule: {
        maxWidth: '1142px',
        margin: '0 auto',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        boxShadow: 'rgb(255 255 255) 0px -28px 22px 0px',
        borderTopRightRadius: 'unset',
        borderTopLeftRadius: 'unset',
    },
    shadowedContainer: {
        boxShadow: 'var(--gd-main-app-shadowed-content)',
        borderTopRightRadius: '45px',
        borderTopLeftRadius: '45px',
        backgroundColor: 'var(--gd-main-app-background-color)',
        height: '100%',
        paddingBottom: '20px',
    },
    shadowedContainerNextDef: {
        boxShadow: 'unset',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '30px',
    },
    noGoalFoundMsg: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'var(--gd-secondary-dark-color) !important',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        shadowedContainer: {
            borderTopRightRadius: '0',
            borderTopLeftRadius: '0',
        },
    },
})

export default function Goal() {
    const { customer } = useInstance()
    const { user } = useUser()
    const isLegacy = !customer?.featuring?.includes(GreetFeaturingTarget.RANKING)
    const hasGoalManagerAccess = useMemo(() => customer.canViewOnlyQVTGoal && user?.isManager, [user, customer])
    const styles = useStyles()
    const { path } = useRouteMatch()
    useScrollTo(0, 0)

    return (
        <div className={styles.container}>
            <Tabs className={styles.contentRule} />
            <div className={classNames(styles.shadowedContainer, isLegacy ? '' : styles.shadowedContainerNextDef)}>
                <div className={classNames([styles.content, styles.contentRule])}>
                    <Switch>
                        <Route exact path={`${path}/collective`} component={CollectiveGoal} />
                        <Route exact path={`${path}/individual`} component={IndividualGoal} />
                        <Route exact path={`${path}/manager`} component={hasGoalManagerAccess ? QVTManagerBoard : RedirectHome} />
                        <Route
                            render={() => {
                                console.error('No goal route !')
                                return <Redirect to={'/'} />
                            }}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

const RedirectHome = () => {
    const { langParam, customerParam } = useParams<Params>()
    return <Redirect to={`/${langParam}/${customerParam}/`} />
}
