const STORAGE_NAME = 'cohortName'

const cohortName = {
    store: (cohortName: string): void => {
        localStorage.setItem(STORAGE_NAME, cohortName)
    },
    get: (): string | null => {
        return localStorage.getItem(STORAGE_NAME)
    },
    clear: () => {
        localStorage.removeItem(STORAGE_NAME)
    },
}

export default cohortName
