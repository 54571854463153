import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { MailSendingByWorkingRangeSlot } from '../../../models/MailSendingByWorkingRange'
import WorkingRange from '../../../models/WorkingRange'
import { MediaQueryBreakpoints } from '../../MediaQueryBreakpoints'
import { getStringTimeAccordingToSlot } from '../../utils'
import ChartBar from './ChartBar'
import { END_SLOT, SLOT_HEIGHT, START_SLOT } from './constants'

interface Props {
    classNames?: {
        stick?: string
    }
    chartType: 'daily' | 'aggreate'
    workingRange: WorkingRange
    slots: MailSendingByWorkingRangeSlot[]
    tooltipText: string
    getDayLetter: (date: Date) => string
    slotTooltip: (slot: number, from: string, to: string) => string
}

const useStyles = createUseStyles({
    contentDisplayBottom: {
        display: 'flex',
        flexDirection: 'row',
        width: '95%',
    },
    contentLeft: {
        display: 'flex',
    },
    subContentLeft: {
        width: '100%',
        borderRight: '3px var(--gd-background-dark-color) solid',
        fontSize: 'var(--gd-size-tiny-body-text)',
        '& div': {
            display: 'flex',
            position: 'relative',
            justifyContent: 'flex-end',
            marginRight: '10px',
            height: '20px',
        },
    },
    contentRightContainer: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr',
        '& > div': {
            gridRowStart: '1',
            gridColumnStart: '1',
            borderRadius: '3px',
        },
    },
    contentRightDottedLine: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            width: '100%',
            borderRadius: '5px',
            letterSpacing: '10px',
        },
    },
    contentRightSticks: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingLeft: '10px',
    },

    [MediaQueryBreakpoints.TABLETTE]: {
        leftAxe: {
            width: '8%',
        },
    },
    [MediaQueryBreakpoints.MOBILE]: {
        leftAxe: {
            width: '10%',
        },
    },
})

export default function Chart({ workingRange, slots, tooltipText, getDayLetter, slotTooltip, chartType, classNames: customClassNames }: Readonly<Props>) {
    const styles = useStyles()
    return (
        <div className={styles.contentDisplayBottom}>
            <div className={styles.contentLeft}>
                <div className={styles.subContentLeft} style={{ height: `${(END_SLOT - START_SLOT) * 10.5}px` }}>
                    <div style={{ top: '-10px' }}>{getStringTimeAccordingToSlot(START_SLOT)}</div>
                    <div style={{ top: `calc(${(workingRange.startSlot - START_SLOT) * SLOT_HEIGHT}px - 30px)` }}>{getStringTimeAccordingToSlot(workingRange.startSlot)}</div>
                    <div style={{ top: `calc(${(workingRange.endSlot - START_SLOT) * SLOT_HEIGHT}px - 50px)` }}>{getStringTimeAccordingToSlot(workingRange.endSlot)}</div>
                    <div style={{ top: `calc(${(END_SLOT - START_SLOT) * SLOT_HEIGHT}px - 70px)` }}>{getStringTimeAccordingToSlot(END_SLOT)}</div>
                </div>
            </div>
            <div className={styles.contentRightContainer}>
                {chartType == 'aggreate' ? (
                    <div className={styles.contentRightDottedLine} style={{ height: `${(END_SLOT - START_SLOT) * SLOT_HEIGHT}px` }}>
                        <div style={{ height: `${(workingRange.startSlot - START_SLOT) * 10.5}px` }}></div>
                        <div style={{ height: `${(workingRange.endSlot - workingRange.startSlot) * 10.5}px`, backgroundColor: '#EDF7EB' }}></div>
                    </div>
                ) : null}
                <div className={classNames(styles.contentRightSticks, customClassNames?.stick)}>
                    {slots.map((value) => (
                        <ChartBar
                            key={String(value.date)}
                            value={value}
                            chartType={chartType}
                            workingRange={workingRange}
                            tooltipText={tooltipText}
                            getDayLetter={getDayLetter}
                            slotTooltip={slotTooltip}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
