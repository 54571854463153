export default function ArrowRight({ color='var(--gd-primary-dark-color)' }: { color?: string }) {
    return (
        <svg width='100%' height='100%' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='16.5601' cy='16.5234' r='16' fill={color} />
            <path
                d='M10.0268 17.6389L21.4961 17.6389L16.4853 22.7842C16.0849 23.1954 16.0849 23.8702 16.4853 24.2814C16.8858 24.6926 17.5326 24.6926 17.9331 24.2814L24.6997 17.3332C25.1001 16.922 25.1001 16.2577 24.6997 15.8465L17.9331 8.89825C17.5326 8.48704 16.8858 8.48704 16.4853 8.89825C16.0849 9.30945 16.0849 9.9737 16.4853 10.3849L21.4961 15.5302L10.0268 15.5302C9.46206 15.5302 9 16.0047 9 16.5846C9 17.1645 9.46206 17.6389 10.0268 17.6389Z'
                fill='white'
            />
        </svg>
    )
}
