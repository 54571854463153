import { createContext, useContext, useReducer } from 'react'
import { TimeUnit, dateToISOStringWithoutTime, substract } from '../components/dateUtils'
import { EGoalType, GoalDto } from '../models'
import { Cohort, CohortUsers } from '../models/Cohort'
import PayloadStore from '../models/PayloadStore'
import services from '../services'

const initialState = {
    creationDate: new Date().toISOString(),
} as PayloadStore
const payloadStore = createContext<PayloadStoreProvider>({
    state: initialState,
    dispatch: () => {
        // no-op
    },
})

const useStore = () => useContext(payloadStore)

const { Provider } = payloadStore

type StoreProviderProps = {
    children: React.ReactNode
}

type PayloadStoreAction = {
    type: string
    valueId?: any
    value: any
}

interface PayloadStoreProvider {
    state: PayloadStore
    dispatch: (...args: any[]) => void
}

const StoreProvider = ({ children }: StoreProviderProps) => {
    const updateStateKey = (key: string, data: any, state: PayloadStore): PayloadStore => {
        const newState = {
            ...state,
            [key]: data,
        }
        // We need to merge localStorage Object before store it to avoid concurrent operations
        const userId = newState?.userPayload?.userId
        if (userId != null) {
            const localStorageData = services.storage.payload.get(userId)
            services.storage.payload.store(userId, { ...localStorageData, ...newState })
        }
        return newState
    }

    const userCohortsComparator = (a: Cohort, b: Cohort) => a.cohortName?.localeCompare(b.cohortName)

    const [state, dispatch] = useReducer((state: PayloadStore, action: PayloadStoreAction) => {
        switch (action.type) {
            case 'nuke': {
                services.storage.payload.clear(state?.userPayload?.userId)
                services.storage.deleteFolderProposal.clear()
                services.storage.user.clear()
                return initialState
            }
            case 'user-payload-fetched': {
                return updateStateKey('userPayload', action.value, state)
            }
            case 'cohorts-payload-fetched': {
                return updateStateKey('cohortsPayload', action.value, state)
            }
            case 'user-cohorts-fetched': {
                return updateStateKey('userCohorts', action.value.sort(userCohortsComparator), state)
            }
            case 'add-cohort-users': {
                const addedValue = { cohortId: action.valueId, users: action.value } as CohortUsers
                let cohortUsers
                if (state.cohortUsers == null || !state.cohortUsers?.length) {
                    cohortUsers = [addedValue]
                } else {
                    const stateCohortUsers = state.cohortUsers.find((item) => item.cohortId === action.valueId)
                    if (stateCohortUsers == null) {
                        cohortUsers = [...state.cohortUsers, addedValue]
                    } else {
                        cohortUsers = state.cohortUsers.map((item) => (item.cohortId === addedValue.cohortId ? addedValue : item))
                    }
                }

                state.cohortUsers = cohortUsers
                services.storage.payload.store(state?.userPayload?.userId, state)
                return state
            }
            case 'add-goal': {
                const { payload, goalType, goalId } = action.value
                let updatedStore
                if (goalType === EGoalType.INDIVIDUAL) {
                    updatedStore = updateStateKey('userPayload', payload, state)
                } else if (goalType === EGoalType.COLLECTIVE) {
                    const cohortPayload = [...(state.cohortsPayload ?? []), payload]
                    updatedStore = updateStateKey('cohortsPayload', cohortPayload, state)
                    const newCollectiveGoal = {
                        cohortId: payload.cohortId,
                        cohortDisplayName: payload.cohortName,
                        cohortName: payload.cohortId,
                        link: payload.link,
                        carbonFootprint: 0,
                        evolution: payload.evolution,
                    }
                    let goals
                    if (!updatedStore?.userPayload?.goals?.length || updatedStore?.userPayload?.goals.find((item) => item.egoal === goalId) == null) {
                        goals = [
                            ...(updatedStore?.userPayload?.goals ?? []),
                            {
                                egoal: goalId,
                                startDate: dateToISOStringWithoutTime(new Date()),
                                endDate: dateToISOStringWithoutTime(substract(new Date(), 1, TimeUnit.MONTHS)),
                                active: true,
                                mainEvolution: 0,
                                collectiveGoals: [newCollectiveGoal],
                            },
                        ] as GoalDto[]
                    } else {
                        goals = updatedStore?.userPayload?.goals?.map((item) => {
                            if (item.egoal !== goalId) {
                                return item
                            }
                            return {
                                ...item,
                                active: true,
                                collectiveGoals: [...(item.collectiveGoals ?? []), newCollectiveGoal],
                            }
                        })
                    }
                    updatedStore = {
                        ...updatedStore,
                        userPayload: {
                            ...updatedStore.userPayload,
                            goals: goals,
                        },
                    }
                } else {
                    throw new Error('Goal type non définit')
                }
                services.storage.payload.store(state?.userPayload?.userId, updatedStore)
                return updatedStore
            }
            case 'add-user-cohort': {
                const cohort = action.value
                const currentUserCohorts = state.userCohorts ?? ([] as Cohort[])
                const userCohorts = [...currentUserCohorts, cohort].sort(userCohortsComparator)
                const newStore = updateStateKey('userCohorts', userCohorts, state)
                services.storage.payload.store(state?.userPayload?.userId, newStore)
                return newStore
            }
            case 'clearCacheData':
            case 'impersonate': {
                services.storage.payload.clear(state?.userPayload?.userId)
                return initialState
            }
            case 'coaching-events-fetched': {
                return updateStateKey('coachingEvents', action.value, state)
            }
            case 'coaching-meeting-fetched': {
                return updateStateKey('coachingMeeting', action.value, state)
            }
            default:
                throw new Error('Action non définit')
        }
    }, initialState)

    return <Provider value={{ state, dispatch } as any}>{children}</Provider>
}

export { StoreProvider, useStore }
