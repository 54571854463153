import { createUseStyles } from 'react-jss'
import DigigreenLogoImg from '../../assets/logos/DigigreenDesktop.svg'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'

const useDigigreenLogoStyles = createUseStyles({
    digigreenLogo: {
        display: 'block',
        minHeight: '47px',
        height: '100%',
        width: '100%',
        maxWidth: '330px',
        padding: '0 0 20px 0'

    },
    [MediaQueryBreakpoints.MOBILE]: {
        digigreenLogo: {
            maxWidth: '190px',
        },
    },
})

const DigigreenLogo = () => {
    const styles = useDigigreenLogoStyles()
    return <img className={styles.digigreenLogo} src={DigigreenLogoImg} />
}

export default DigigreenLogo
