import { createUseStyles } from 'react-jss'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import Info from './Info'
import IconAssets from '../../assets/icon/IconAssets'

interface Props {
    text: string
    textColor: string
}

const useStyles = (textColor: string) =>
    createUseStyles({
        icon: {
            width: '120px',
            height: '120px',
        },
        text: {
            fontWeight: 700,
            color: textColor,
            textAlign: 'center',
            marginTop: '15px',
        },
        [MediaQueryBreakpoints.MOBILE]: {
            icon: {
                width: '150px',
                height: '150px',
            },
        },
    })()

export default function NoChallenge({ text, textColor }: Props) {
    const styles = useStyles(textColor)

    return (
        <Info>
            <div className={styles.icon}>
                <IconAssets.NoChallengeIcon />
            </div>
            <span className={styles.text}>{text}</span>
        </Info>
    )
}
