import { useState } from 'react'
import { createUseStyles } from 'react-jss'

interface ApiErrorPros {
    error: any
}

const useStyle = createUseStyles({
    message: {
        margin: '10px 0',
        color: 'red',
    },
})

const ApiError = ({ error }: ApiErrorPros) => {
    const styles = useStyle()
    const [showDetail, setShowDetail] = useState<boolean>(false)
    if (error == null) {
        return null
    }
    const errorMessage = typeof error === 'string' ? error : error?.message
    return (
        <div className={styles.message}>
            <p onClick={() => setShowDetail(!showDetail)}>{"Une erreur s'est produite, veuillez réessayer ultérieurement"}</p>
            {showDetail ? <pre>{errorMessage}</pre> : null }
        </div>
    )
}

export default ApiError
