import classnames from 'classnames'
import { Link } from 'react-router-dom'
import IconAssets from '../../assets/icon/IconAssets'
import { useStore } from '../../contexts'
import { Customer, GoalProps, Lang } from '../../models'
import UserRanking from '../../models/UserRanking'
import Rate from '../Rate'
import Avatar from '../headers/Avatar'
import { GreetButton } from '../share/GreetButton'
import GreetFeaturingComponent, { GreetFeaturingTarget } from '../share/GreetFeaturingComponent'
import InfoBulle from '../share/Infobulle'
import { TrucateSpan } from '../share/TrucateSpan'
import { numberFormatter } from '../utils'
import GoalCardContainerLegacy from './legacy/GoalCardContainerLegacy'
import { useGoalCardContainerStyle } from './styles'

export interface GoalCardContainerProps {
    goal: GoalProps
    ranking?: UserRanking
    customer?: Customer
    lang: Lang
}

export default function GoalCardContainer(props: GoalCardContainerProps) {
    return (
        <Link
            to={`/${props.lang.code}/${props?.customer?.name}/goal/${props.goal.id}/${
                props.goal.cohort != null ? 'collective?cohortName=' + encodeURIComponent(props.goal.cohort.cohortName) : 'individual'
            }`}
        >
            <GreetFeaturingComponent {...props} target={GreetFeaturingTarget.RANKING} nextDef={GoalCardContent} legacyDef={GoalCardContainerLegacy} />
        </Link>
    )
}

function GoalCardContent({ goal, lang, ranking }: GoalCardContainerProps) {
    const { state } = useStore()
    const styles = useGoalCardContainerStyle()
    const { score = 0 } = ranking ?? {}
    return goal?.cohort ? (
        <div className={styles.activeContainer}>
            <div className={styles.activeContent}>
                <TrucateSpan text={goal.cohort.cohortDisplayName} maxLength={24} className={styles.cohortNameRanking} />
                <div className={styles.progressTextRanking}>{lang.goal.home.groupProgress.replace(':', '')} :</div>
                <Rate value={goal.evolution} />
                <div className={classnames(styles.activePrecision, styles.activePrecisionCollective)}>{lang.goal.home.precision}</div>
                <GreetButton type='secondary' label={lang.goal.home.see} className={classnames(styles.cardButton)} />
            </div>
        </div>
    ) : (
        <div className={styles.card}>
            <div className={styles.rankingContainer}>
                <Avatar className={styles.avatar} />
                <div className={styles.pointsContainer}>
                    <div className={styles.goalIcon}>{IconAssets.CarbonFootprintIcon('var(--gd-positive-color)')}</div>
                    {numberFormatter(score)} pts
                    <InfoBulle text={lang.personalScoring.tooltip.carbonFootprint}>
                        <div className={styles.scorePointsInformationIcon}>
                            <IconAssets.Information />
                        </div>
                    </InfoBulle>
                </div>
            </div>
            <div className={styles.individualProgressContainer}>
                <span className={styles.individualProgressTimeline}>{lang.goal.home.individualProgress} :</span>
                {state.userPayload != null ? <Rate value={state.userPayload.evolution} /> : <></>}
                <div className={styles.activePrecision}>{lang.goal.home.precision}</div>
                <GreetButton type='secondary' label={lang.goal.home.see} className={classnames(styles.cardButton)} />
            </div>
        </div>
    )
}
