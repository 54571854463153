import { useCallback, useEffect } from 'react'

interface ParamsI {
    targetRef: React.RefObject<HTMLElement>
    callback: () => void
    trigger: boolean
}

export default function useOnClickOutside({ targetRef, callback, trigger }: ParamsI) {
    const clickListener = useCallback(
        (event: MouseEvent) => {
            if (targetRef.current == null || !(event.target instanceof Element) || targetRef.current.contains(event.target) || !trigger) {
                return
            }

            callback()
        },
        [trigger, targetRef, callback],
    )

    useEffect(() => {
        document.addEventListener('click', clickListener, false)
        return function () {
            document.removeEventListener('click', clickListener, false)
        }
    }, [clickListener])
}
