import { useState } from 'react'
import { Control, Controller, FieldValues, Path, UseFormRegister, Validate } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import Select from 'react-select'
import IconAssets from '../../assets/icon/IconAssets'

const useFieldStyle = createUseStyles({
    field: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    label: {
        fontSize: 'var(--gd-size-input-text)',
        fontWeight: 700,
        color: 'var(--gd-secondary-dark-color)',
    },
    input: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '45px',
        backgroundColor: '#fff',
        border: '1px solid var(--gd-secondary-dark-color)',
        borderRadius: 'var(--gd-border-small-radius)',
        marginTop: '10px',
        paddingLeft: '10px',
        fontSize: 'var(--gd-size-input-text)',
        fontWeight: 400,
        color: 'var(--gd-secondary-dark-color)',
    },
    select: {
        marginTop: '15px',
        fontSize: 'var(--gd-size-input-text)',
        fontWeight: 400,
        color: 'var(--gd-secondary-dark-color)',
    },
    radio: {
        display: 'flex',
        marginRight: '10px',
    },
    submit: {
        fontSize: 18,
        fontWeight: 700,
        backgroundColor: 'var(--gd-secondary-dark-color)',
        color: 'var(--gd-text-clear-color)',
        border: 'none',
        borderRadius: 'var(--gd-border-big-radius)',
        transition: 'all 0s ease',
        padding: '20px 30px',
        cursor: 'pointer',
    },
    textArea: {
        width: '100%',
        height: '100%',
        padding: '20px 10px',
        backgroundColor: '#fff',
        border: '1px solid var(--gd-secondary-dark-color)',
        borderRadius: 'var(--gd-border-small-radius)',
        marginTop: '10px',
        paddingLeft: '10px',
        fontSize: 'var(--gd-size-input-text)',
        fontWeight: 400,
        color: 'var(--gd-secondary-dark-color)',
    },
    passwordField: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 'var(--gd-border-small-radius)',
        gap: '5px',
        border: '1px solid var(--gd-secondary-color)',
        width: '100%',
        height: '45px',
        margin: '5px 5px 5px 0',
        backgroundColor: '#FFFFFF',
        '& input': {
            padding: '1px 10px',
            height: '43px',
            borderRadius: 'var(--gd-border-small-radius)',
            width: '100%',
            border: 'none',
            fontSize: 'var(--gd-size-small-body-text)',
        },
        '& .inputIcon': {
            margin: '8px 8px 5px 8px',
            width: '32px',
        },
    },
})

interface FieldProps<T extends FieldValues> {
    label?: string
    name: Path<T>
    required?: boolean
    register: UseFormRegister<T>
    [restProps: string]: any
}

interface FieldThems {
    labelColor?: string
}

interface TextFieldProps<T extends FieldValues> extends FieldProps<T> {
    type: 'text' | 'number'
    maxLength?: number
}

export const TextField = <T extends FieldValues>({ label, type, maxLength, name, required = false, register, ...rest }: TextFieldProps<T>) => {
    const styles = useFieldStyle()
    const patternProps = type === 'number' ? { pattern: '[0-9]*' } : {}
    const { onChange, ...fieldRegisterProps } = register(name, { required: required })

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (type === 'text') {
            onChange(event)
        } else {
            try {
                const value = event.target.value.replace(/\D/g, '')
                if (!value) {
                    event.target.value = ''
                } else if (maxLength != null && value.length > maxLength) {
                    event.target.value = value.substring(0, maxLength)
                } else {
                    event.target.value = value
                }
                onChange(event)
            } catch (error) {
                event.preventDefault()
            }
        }
    }

    return (
        <div className={styles.field}>
            {label != null ? <span className={styles.label}>{label}</span> : null}
            <input className={styles.input} type='text' {...patternProps} onChange={handleChangeValue} {...fieldRegisterProps} {...rest} />
        </div>
    )
}

export const PasswordField = <T extends FieldValues>({ label, name, required = false, register }: FieldProps<T>) => {
    const styles = useFieldStyle()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const { onChange, ...fieldRegisterProps } = register(name, { required: required })

    return (
        <div>
            <span className={styles.label}>{label}</span>
            <div className={styles.passwordField}>
                <input type={showPassword ? 'text' : 'password'} onChange={onChange} autoComplete='on' {...fieldRegisterProps} />
                <div className='inputIcon' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <IconAssets.EyeOff /> : <IconAssets.Eye />}
                </div>
            </div>
        </div>
    )
}

interface SelectFielsStyles {
    width?: string
    menuList: React.CSSProperties
}
interface SelectFieldProps<T extends FieldValues> extends FieldProps<T>, FieldThems {
    control: Control<T>
    disabled?: boolean
    options: any[]
    customStyles?: SelectFielsStyles
    validate?: Validate<T>
}

export const SelectField = <T extends FieldValues>({
    label,
    options,
    name,
    required = false,
    disabled = false,
    control,
    register,
    labelColor,
    customStyles,
    validate,
}: SelectFieldProps<T>) => {
    const styles = useFieldStyle()
    const { ref: fieldRef, ...fieldProps } = register(name, { required: required, validate: validate })
    return (
        <div className={styles.field} style={{ width: customStyles?.width }}>
            <span className={styles.label} style={{ color: labelColor }}>
                {label}
            </span>
            <Controller
                control={control}
                {...fieldProps}
                render={({ field: { onChange, onBlur, value, name }, fieldState, formState }) => (
                    <Select
                        className={styles.select}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: 'var(--gd-secondary-dark-color)',
                                neutral20: 'var(--gd-secondary-dark-color)',
                                neutral30: 'var(--gd-secondary-dark-color)',
                                neutral60: 'var(--gd-secondary-dark-color)',
                                neutral70: 'var(--gd-secondary-dark-color)',
                                neutral80: 'var(--gd-secondary-dark-color)',
                                neutral90: 'var(--gd-secondary-dark-color)',
                            },
                        })}
                        styles={{
                            valueContainer: (provided) => ({
                                ...provided,
                                height: '45px',
                            }),
                            indicatorsContainer: (provided) => ({
                                ...provided,
                                height: '45px',
                            }),
                            menuList: (provided) => ({
                                ...provided,
                                ...customStyles?.menuList,
                            }),
                        }}
                        maxMenuHeight={115}
                        isDisabled={disabled}
                        ref={fieldRef}
                        {...{ options, onChange, onBlur, value, name }}
                        {...fieldState}
                        {...formState}
                    />
                )}
            />
        </div>
    )
}

interface RadioButtonProps<T extends FieldValues> {
    id: string
    name: Path<T>
    value: string | ReadonlyArray<string> | number
    required?: boolean
    disabled?: boolean
    register: UseFormRegister<T>
}

export const RadioButton = <T extends FieldValues>({ id, name, value, required = false, disabled = false, register }: RadioButtonProps<T>) => {
    const styles = useFieldStyle()
    const fieldRegisterProps = register(name, { required: required })

    return (
        <div className={styles.radio}>
            <input className={styles.radio} id={id} type='radio' value={value} disabled={disabled} {...fieldRegisterProps} />
        </div>
    )
}

interface TextAreaProps<T extends FieldValues> extends FieldProps<T> {
    nbRows?: number
    rest?: any
}

export const TextAreaField = <T extends FieldValues>({ label, name, required = false, nbRows = 5, register, ...rest }: TextAreaProps<T>) => {
    const styles = useFieldStyle()

    return (
        <div className={styles.field}>
            <span className={styles.label}>{label}</span>
            <textarea className={styles.textArea} rows={nbRows} {...register(name, { required: required })} {...rest}></textarea>
        </div>
    )
}

interface DateTimePickerFieldProps<T extends FieldValues> extends FieldProps<T> {
    type?: 'datetime-local'
}

export const DateTimePickerField = <T extends FieldValues>({ label, name, required = false, register, ...rest }: DateTimePickerFieldProps<T>) => {
    const styles = useFieldStyle()
    const { onChange, ...fieldRegisterProps } = register(name, { required: required })

    return (
        <div className={styles.field}>
            {label != null ? <span className={styles.label}>{label}</span> : null}
            <input className={styles.input} onChange={onChange} {...fieldRegisterProps} {...rest} type='datetime-local' />
        </div>
    )
}
