import { Customer } from '../../models'
import { MinimalQVCTIndividualGoal } from '../../pages/QVCTIndividualGoal'

import ten from './ten'

const tenMs: Customer = {
    ...ten,
    name: 'technip-energies',
    QVTIndividualGoal: MinimalQVCTIndividualGoal,
}

export default tenMs
