import { useEffect, useState } from 'react'
import { useInstance, useStore, useUser } from '../../contexts'
import SoftLoader from '../SoftLoader'
import EDisplay from '../../models/coaching/EDisplay'
import CoachingItem from './CoachingItem'
import CoachingItemMeeting from './CoachingItemMeeting'
import CoachContent from '../../models/coaching/CoachContent'
import Description from '../../models/coaching/Description'
import { isSuperAdmin } from '../rolesUtils'
import { createUseStyles } from 'react-jss'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { usePut } from '../../services/api/useFetch'
import Popup from '../Popup'
import { TextField } from '../share/Inputs'
import { GreetButton, GreetFileInputToBase64Button, GreetRadioButton, GreetSubmitButton } from '../share/GreetButton'
import { CoachingMeeting } from '../../models'

const CoachingMeetingComponent = () => {
    const { user, lang } = useUser()
    const { state } = useStore()
    const { customer } = useInstance()
    const [shuffledCoachContent, setShuffledCoachContent] = useState<CoachContent[] | null>(null)
    const [description, setDescription] = useState<Description | null>(null)
    const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false)

    useEffect(() => {
        let coachingMeeting: CoachingMeeting
        if (
            state.coachingMeeting == null ||
            state.coachingMeeting.coachContentList.length === 0 ||
            (state.coachingMeeting.coachContentList.length === 1 && state.coachingMeeting.display === EDisplay.TWO_COACH)
        ) {
            coachingMeeting = customer.defaultCoachingMeeting
        } else {
            coachingMeeting = state.coachingMeeting
        }
        setShuffledCoachContent(coachingMeeting.coachContentList.sort(() => 0.5 - Math.random()))
        setDescription(coachingMeeting.description.find((item) => item.language === lang.code) ?? null)
    }, [state.coachingMeeting, customer.defaultCoachingMeeting, lang])

    const openEditPop = () => setEditPopupVisible(true)
    const closeEditPop = () => setEditPopupVisible(false)

    return shuffledCoachContent == null || description == null ? (
        <SoftLoader />
    ) : (
        <>
            {state?.coachingMeeting?.display === EDisplay.ONE_COACH ? (
                <CoachingItem
                    title='Coaching'
                    onEditClick={isSuperAdmin(user) ? openEditPop : null}
                    illustrationSrc={shuffledCoachContent[0].base64Picture}
                    contentTitle={`${description.part1} ${description.part2}`}
                    buttonLabel={`${lang.goal.coach.textBtn} ${shuffledCoachContent[0].firstname}`}
                    buttonLink={shuffledCoachContent[0].calendarURL}
                />
            ) : (
                <CoachingItemMeeting
                    title='Coaching'
                    onEditClick={isSuperAdmin(user) ? openEditPop : null}
                    coaches={[shuffledCoachContent[0], shuffledCoachContent[1]]}
                    appointmentTitle={lang.goal.coach.textBtn}
                    contentTitle1={description.part1}
                    contentTitle2={description.part2}
                />
            )}
            {editPopupVisible ? <EditCoachingMeetingPopup onClose={closeEditPop} /> : null}
        </>
    )
}

const useEditCoachingMeetingPopupStyles = createUseStyles({
    editCoachingMeetingPopup: {
        display: 'flex',
        flexDirection: 'column',
        width: '125vh',
        backgroundColor: 'var(--gd-main-popup-background-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        padding: '30px',
        gap: '20px',
    },
    editCoachingMeetingPopupFormParts: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        justifyContent: 'center',
    },
    editCoachingMeetingPopupFormPart: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '8px',
        justifyContent: 'center',
    },
    editCoachingMeetingPopupFormPartCoachContent: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        flexDirection: 'row',
        gap: '8px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    editCoachingMeetingPopupFormSubPart: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        borderLeft: '1px solid var(--gd-secondary-dark-color)',
        paddingLeft: '20px',
        gap: '10px',
    },
    editCoachingMeetingPopupFormSubPartCoachContent: {
        display: 'flex',
        width: '49%',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '20px',
        gap: '10px',
    },
    langLabel: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 700,
        color: 'var(--gd-secondary-dark-color)',
    },
    recommendedSizeLabel: {
        fontSize: 'var(--gd-size-highlighted-small-title)',
        fontWeight: 500,
        color: 'var(--gd-secondary-dark-color)',
        marginLeft: '10px',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    editCoachingMeetingPopupPicturePreviewContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    editCoachingMeetingPopupPicturePreview: {
        width: '50%',
        height: 'auto',
    },
    editCoachingMeetingPopupRow: {
        display: 'flex',
        flexDirection: 'row',
    },
})

interface EditCoachingMeetingPopup {
    onClose: () => void
}

interface EditCoachingMeetingPopupForm {
    descriptionFr: {
        part1: string
        part2: string
    }
    descriptionEn: {
        part1: string
        part2: string
    }
    coachContentList: CoachContent[]
    display: EDisplay
}

const EditCoachingMeetingPopup = ({ onClose }: EditCoachingMeetingPopup) => {
    const { state, dispatch } = useStore()
    const { customer } = useInstance()
    const { user, lang } = useUser()
    const styles = useEditCoachingMeetingPopupStyles()
    const descriptionFr = state.coachingMeeting.description.find((item) => item.language === 'fr')
    const descriptionEn = state.coachingMeeting.description.find((item) => item.language === 'en')
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const { register, handleSubmit, control } = useForm<EditCoachingMeetingPopupForm>({
        defaultValues: {
            descriptionFr: {
                part1: descriptionFr?.part1,
                part2: descriptionFr?.part2,
            },
            descriptionEn: {
                part1: descriptionEn?.part1,
                part2: descriptionEn?.part2,
            },
            coachContentList: state.coachingMeeting.coachContentList,
            display: state.coachingMeeting.display,
        },
    })

    const {
        fields: coachContentList,
        append: appendCoachContent,
        remove: removeCoachContent,
        update: updateCoachContent,
    } = useFieldArray({
        control,
        name: 'coachContentList',
    })

    const { doPut: doUpdate } = usePut({
        endpoint: 'coaching-meeting',
        params: { clientName: customer.platformName },
        onSuccess: (data) => {
            dispatch({ type: 'coaching-meeting-fetched', value: data })
            onClose()
            setSaveLoading(false)
        },
    })

    const onSubmit: SubmitHandler<EditCoachingMeetingPopupForm> = (data: EditCoachingMeetingPopupForm) => {
        setSaveLoading(true)
        const descriptionFr: Description = {
            language: 'fr',
            part1: data.descriptionFr.part1,
            part2: data.descriptionFr.part2,
        }
        const descriptionEn: Description = {
            language: 'en',
            part1: data.descriptionEn.part1,
            part2: data.descriptionEn.part2,
        }
        doUpdate({
            description: [descriptionFr, descriptionEn],
            coachContentList: data.coachContentList,
            display: data.display,
        })
    }

    return isSuperAdmin(user) ? (
        <Popup onCloseRequest={onClose} closeOnOutside={false}>
            <form className={styles.editCoachingMeetingPopup} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.editCoachingMeetingPopupFormParts}>
                    <span className={styles.langLabel}>{lang.goal.coach.form.display.description}</span>
                    <div className={styles.editCoachingMeetingPopupFormSubPart}>
                        <GreetRadioButton
                            id={EDisplay.ONE_COACH}
                            name='display'
                            value={EDisplay.ONE_COACH}
                            label={lang.goal.coach.form.display.oneCoach}
                            required={true}
                            register={register}
                        />
                        <GreetRadioButton
                            id={EDisplay.TWO_COACH}
                            name='display'
                            value={EDisplay.TWO_COACH}
                            label={lang.goal.coach.form.display.twoCoach}
                            required={true}
                            register={register}
                        />
                    </div>
                </div>
                <div className={styles.editCoachingMeetingPopupFormParts}>
                    <div className={styles.editCoachingMeetingPopupFormPart}>
                        <span className={styles.langLabel}>Français</span>
                        <div className={styles.editCoachingMeetingPopupFormSubPart}>
                            <TextField label={lang.goal.coach.form.description.part1} name='descriptionFr.part1' required={true} register={register} type='text' />
                            <TextField label={lang.goal.coach.form.description.part2} name='descriptionFr.part2' required={true} register={register} type='text' />
                        </div>
                    </div>
                    <div className={styles.editCoachingMeetingPopupFormPart}>
                        <span className={styles.langLabel}>Anglais</span>
                        <div className={styles.editCoachingMeetingPopupFormSubPart}>
                            <TextField label={lang.goal.coach.form.description.part1} name='descriptionEn.part1' required={true} register={register} type='text' />
                            <TextField label={lang.goal.coach.form.description.part2} name='descriptionEn.part2' required={true} register={register} type='text' />
                        </div>
                    </div>
                </div>

                <div className={styles.editCoachingMeetingPopupFormPart}>
                    <div className={styles.editCoachingMeetingPopupRow}>
                        <span className={styles.langLabel}>{lang.goal.coach.form.coachContent.description}</span>
                        <span className={styles.recommendedSizeLabel}>{lang.goal.coach.form.coachContent.recommendedSize}</span>
                    </div>
                    <div className={styles.editCoachingMeetingPopupFormPartCoachContent}>
                        {coachContentList.map((coachContent, index) => {
                            return (
                                <div key={index} className={styles.editCoachingMeetingPopupFormSubPartCoachContent}>
                                    <TextField
                                        label={lang.goal.coach.form.coachContent.firstname}
                                        name={`coachContentList.${index}.firstname`}
                                        required={true}
                                        register={register}
                                        type='text'
                                    />
                                    <TextField
                                        label={lang.goal.coach.form.coachContent.calendarURL}
                                        name={`coachContentList.${index}.calendarURL`}
                                        required={true}
                                        register={register}
                                        type='text'
                                    />
                                    <div className={styles.editCoachingMeetingPopupPicturePreviewContainer}>
                                        <img className={styles.editCoachingMeetingPopupPicturePreview} src={coachContent.base64Picture} />
                                    </div>
                                    <GreetFileInputToBase64Button
                                        id={`input-file-${index}`}
                                        type='secondary-inv'
                                        label={lang.goal.coach.form.coachContent.addPicture}
                                        base64Callback={(base64: string) => {
                                            const coachContent = coachContentList[index]
                                            coachContent.base64Picture = base64
                                            updateCoachContent(index, coachContent)
                                        }}
                                    />
                                    <GreetButton type='danger' onClick={() => removeCoachContent(index)} label={lang.goal.coach.form.coachContent.removeCoach} />
                                </div>
                            )
                        })}
                    </div>

                    <GreetButton
                        type='secondary'
                        onClick={() => {
                            const coachContent: CoachContent = {
                                firstname: '',
                                calendarURL: '',
                                base64Picture: '',
                            }
                            appendCoachContent(coachContent)
                        }}
                        label={lang.goal.coach.form.coachContent.addCoach}
                    />
                </div>
                <div className={styles.footer}>
                    {saveLoading ? <SoftLoader /> : <GreetSubmitButton type='secondary' label={lang.goal.coach.form.save} />}
                    <GreetButton type='secondary-inv' onClick={onClose} label={lang.goal.coach.form.cancel} />
                </div>
            </form>
        </Popup>
    ) : null
}

export default CoachingMeetingComponent
