export enum MateVictoryDisposition {
    VERTICAL,
    VERTICAL_REVERSE,
    HORIZONTAL,
}

export interface GridTemplate {
    area: string
    disposition?: MateVictoryDisposition
}

export const minimuLineGridTemplate: GridTemplate[] = [
    { area: '1 / 1 / 2 / 2' },
    { area: '1 / 2 / 2 / 3' },
    { area: '1 / 3 / 2 / 4' },
    { area: '1 / 4 / 2 / 5' },
    { area: '1 / 5 / 2 / 7', disposition: MateVictoryDisposition.HORIZONTAL },
]

export const midiumLineGridTemplate: GridTemplate[] = [
    { area: '1 / 1 / 3 / 2', disposition: MateVictoryDisposition.VERTICAL },
    { area: '1 / 2 / 2 / 3' },
    { area: '1 / 3 / 2 / 4' },
    { area: '1 / 4 / 2 / 5' },
    { area: '1 / 5 / 2 / 7', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '2 / 2 / 3 / 3' },
    { area: '2 / 3 / 3 / 4' },
]

export const fullLineGridTemplate: GridTemplate[] = [
    { area: '1 / 1 / 3 / 2', disposition: MateVictoryDisposition.VERTICAL },
    { area: '1 / 2 / 2 / 3' },
    { area: '1 / 3 / 2 / 4' },
    { area: '1 / 4 / 3 / 5', disposition: MateVictoryDisposition.VERTICAL_REVERSE },
    { area: '1 / 5 / 2 / 7', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '2 / 2 / 3 / 3' },
    { area: '2 / 3 / 3 / 4' },
    { area: '2 / 5 / 3 / 6' },
    { area: '2 / 6 / 3 / 7' },
    { area: '3 / 1 / 4 / 3', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '3 / 3 / 4 / 4' },
    { area: '3 / 4 / 4 / 5' },
    { area: '3 / 5 / 4 / 6' },
    { area: '3 / 6 / 4 / 7' },
]

export const mobileLineGridTemplate: GridTemplate[] = [
    { area: '1 / 1 / 2 / 3', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '1 / 3 / 2 / 5', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '1 / 5 / 2 / 7', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '1 / 7 / 2 / 9', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '1 / 9 / 2 / 11', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '1 / 11 / 2 / 13', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '1 / 13 / 2 / 15', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '2 / 1 / 3 / 3', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '2 / 3 / 3 / 5', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '2 / 5 / 3 / 7', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '2 / 7 / 3 / 9', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '2 / 9 / 3 / 11', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '2 / 11 / 3 / 13', disposition: MateVictoryDisposition.HORIZONTAL },
    { area: '2 / 13 / 3 / 15', disposition: MateVictoryDisposition.HORIZONTAL },
]
