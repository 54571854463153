import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import PlanetFriendlyMonthlyImageBadge from '../../assets/illustration/PlanetFriendlyMonthlyImageBadge'
import { useUser } from '../../contexts'
import { Badge as BadgeModel, BadgeType } from '../../models/Badge'
import UserPayload from '../../models/UserPayload'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import Popup from '../Popup'
import Title from '../Title'
import { TimeUnit, diff, toLiteralMonthString, toLiteralMonthYearString } from '../dateUtils'
import { capitalizeFirstLetter } from '../utils'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '360px',
        minWidth: '360px',
        height: '280px',
        maxHeight: '280px',
        borderRadius: 'var(--gd-border-normal-radius)',
        paddingTop: '0.50rem',
        gap: '20px',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        fontWeight: 700,
        color: '#FFFFFF',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: 'var(--gd-background-clear-color)',
        rowGap: '15px',
        borderRadius: 'var(--gd-border-normal-radius)',
        gap: '20px',
    },
    details: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        gap: '25px',
    },
    icon: {
        width: '123px',
        height: '100%',
    },
    reduction: {
        width: '99px',
        padding: '6px 20px 11px 20px',
        borderRadius: 'var(--gd-border-small-radius)',
        backgroundColor: '#FFF',
        fontSize: 'var(--gd-size-big-number)',
        fontWeight: 'bold',
        color: 'var(--gd-positive-color)',
        textAlign: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    },
    seeAllBtn: {
        fontSize: 'var(--gd-size-button-text)',
        fontWeight: 'bold',
        padding: '10px 15px',
        borderRadius: 'var(--gd-border-normal-radius)',
        backgroundColor: 'var(--gd-primary-color)',
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    noBadgeMessage: {
        fontSize: 'var(--gd-size-small-body-text)',
        padding: '0 10px 5px 10px',
        color: '#00000',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        container: {
            width: '100%',
        },
    },
    [MediaQueryBreakpoints.TABLETTE_AND_TABLETTE_XL]: {
        container: {
            width: '45%',
            maxWidth: 'unset',
        },
    },
})

interface PlanetFriendlyBadgeProps {
    userPayload: UserPayload
}

/**
 * @deprecated well be removed
 */
export default function PlanetFriendlyBadge({ userPayload }: PlanetFriendlyBadgeProps) {
    const { lang } = useUser()
    const styles = useStyles()
    const [popupIsActive, displayPopup] = useState<boolean>(false)
    const userBadges: BadgeModel[] = filterBadges(userPayload?.userBadges)
    const hasBadges = userBadges?.length > 0
    const lastBadge = hasBadges ? userBadges[0] : null
    const lastMonthlyBadge = lastBadge != null ? toLiteralMonthString(lastBadge.period, lang.locale) : ''
    const lastReductionBadge = lastBadge?.reducedBy != null ? Math.round(lastBadge?.reducedBy) : ''

    return (
        <div className={styles.container}>
            <Title title={lang.goal.individual.planetFriendlyBadge.title} />
            <div className={styles.content}>
                <div className={styles.details}>
                    <div className={styles.icon}>
                        <PlanetFriendlyMonthlyImageBadge month={lastMonthlyBadge} earned={hasBadges} />
                    </div>
                    {hasBadges ? <div className={styles.reduction}>-{lastReductionBadge}%</div> : null}
                </div>
                <div className={styles.actions}>
                    {hasBadges ? (
                        <div className={styles.seeAllBtn} onClick={() => displayPopup(true)}>
                            {lang.goal.individual.planetFriendlyBadge.seeAllBadges}
                        </div>
                    ) : (
                        <div className={styles.noBadgeMessage}>{lang.goal.individual.planetFriendlyBadge.noBadge}</div>
                    )}
                </div>
            </div>
            {popupIsActive ? <PlanetFriendlyBadgesPopup badges={userBadges} close={() => displayPopup(false)} /> : null}
        </div>
    )
}

const usePopupStyles = createUseStyles({
    badgesPopups: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    badgesPopupsHeader: {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        gap: '20px',
        alignItems: 'center',
    },
    badgesPopupsHeaderIcon: {
        width: '115px',
    },
    badgesPopupsHeaderText: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    badgesPopupsHeaderTextTitle: {
        color: 'var(--gd-secondary-dark-color)',
        fontSize: 'var(--gd-size-highlighted-title)',
        fontWeight: 'bold',
    },
    badgesPopupsHeaderTextDescription: {
        fontSize: 'var(--gd-size-body-text)',
    },
    badgesPopupsContent: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        gap: '50px',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        borderRadius: 'var(--gd-border-normal-radius)',
        padding: '20px',
        overflowY: 'scroll',
    },
    badge: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    badgeIcon: {
        width: '87px',
    },
    badgeDate: {
        backgroundColor: 'var(--gd-background-clear-color)',
        color: 'var(--gd-secondary-dark-color)',
        fontWeight: 'bold',
        fontSize: 'var(--gd-size-small-body-text)',
        padding: '5px 25px',
        borderRadius: 'var(--gd-border-normal-radius)',
    },
    badgeReduction: {
        color: 'var(--gd-positive-color)',
        fontWeight: 'bold',
        fontSize: 18,
    },
})

interface PlanetFriendlyBadgesPopupProps {
    badges: BadgeModel[]
    close: () => void
}

const PlanetFriendlyBadgesPopup = ({ badges, close }: PlanetFriendlyBadgesPopupProps) => {
    const { lang } = useUser()
    const styles = usePopupStyles()

    return (
        <Popup onCloseRequest={close}>
            <div className={styles.badgesPopups}>
                <div className={styles.badgesPopupsHeader}>
                    <div className={styles.badgesPopupsHeaderIcon}>
                        <PlanetFriendlyMonthlyImageBadge month='' earned={false} />
                    </div>
                    <div className={styles.badgesPopupsHeaderText}>
                        <div className={styles.badgesPopupsHeaderTextTitle}>{lang.goal.individual.planetFriendlyBadge.title}</div>
                        <div className={styles.badgesPopupsHeaderTextDescription}>{lang.goal.individual.planetFriendlyBadge.description}</div>
                    </div>
                </div>
                <div className={styles.badgesPopupsContent}>
                    {badges?.map((badge, index) => {
                        const month = toLiteralMonthString(badge.period, lang.locale)
                        const monthYear = capitalizeFirstLetter(toLiteralMonthYearString(badge.period, lang.locale))
                        return (
                            <div key={index} className={styles.badge}>
                                <div className={styles.badgeIcon}>
                                    <PlanetFriendlyMonthlyImageBadge month={month} earned />
                                </div>
                                <div className={styles.badgeDate}>{monthYear}</div>
                                <div className={styles.badgeReduction}>{-Math.round(badge.reducedBy)}%</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Popup>
    )
}

function filterBadges(badges?: BadgeModel[] | null): BadgeModel[] {
    const MAX_NB_MONTHS = 12

    return (badges ?? [])
        .filter((badge) => badge.type === BadgeType.PLANET_FRIENDLY)
        .filter((badge) => diff(new Date(), new Date(badge.period), TimeUnit.MONTHS) <= MAX_NB_MONTHS)
        .sort((a, b) => new Date(b.period).getTime() - new Date(a.period).getTime())
}
