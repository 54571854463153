import { createUseStyles } from 'react-jss'
import { useUser } from '../contexts'
import { RelationshipPositioningGraphLang } from '../models/Lang'
import QVTRelationshipPositioning, { QVTRelationshipPositioningSlot } from '../models/RelationshipPositioning'
import UserPayload from '../models/UserPayload'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import QVTBulbInformation from './qvt/QVTBulbInformation'
import RelationshipPositioningFooter from './RelationshipPositioningFooter'
import Title from './Title'
import { convertToRem } from './utils'
import { useMemo } from 'react'

const NB_MAX_INTERLOCUTORS = 20
const NB_MAX_HOURS_IN_MEETING = 30
const SCORE_MAX = 100

const useRelationshipPositioningStyles = createUseStyles({
    container: {},
    subContainer: {
        marginTop: '20px',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        padding: '30px 50px',
    },
    description: {
        fontSize: '14px',
    },
    graphContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        marginTop: '20px',
        marginBottom: '20px',
        width: '100%',
        height: '400px',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        subContainer: {
            padding: '30px 30px',
        },
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        graphContainer: {
            flexDirection: 'column',
            height: '1300px',
        },
    },
})

const defaultValues: QVTRelationshipPositioning = {
    weeklyMeetingTimeSlots: [],
    mobilizedMeetingTimeSlots: [],
    wellBeingScoreSlots: [],
}

interface RelationshipPositioningProps {
    userPayload: UserPayload
}

export default function RelationshipPositioning({ userPayload }: Readonly<RelationshipPositioningProps>) {
    const { lang } = useUser()
    const styles = useRelationshipPositioningStyles()

    const qvtRelationshipPositioning = userPayload.qvtRelationshipPositioning ?? defaultValues

    return (
        <div className={styles.container}>
            <Title title={lang.goal.qvt.relationshipPositioning.title} />
            <div className={styles.subContainer}>
                <div className={styles.description}>{lang.goal.qvt.relationshipPositioning.description}</div>
                <div className={styles.graphContainer}>
                    <RelationshipPositioningGraph
                        slots={qvtRelationshipPositioning.weeklyMeetingTimeSlots}
                        lang={lang.goal.qvt.relationshipPositioning.weeklyMeetingTime}
                        graphType={EGraph.MEETING}
                    />
                    <RelationshipPositioningGraph
                        slots={qvtRelationshipPositioning.mobilizedMeetingTimeSlots}
                        lang={lang.goal.qvt.relationshipPositioning.mobilizedMeetingTime}
                        graphType={EGraph.MEETING}
                    />
                    <RelationshipPositioningGraph
                        slots={qvtRelationshipPositioning.wellBeingScoreSlots}
                        lang={lang.goal.qvt.relationshipPositioning.wellBeingScore}
                        graphType={EGraph.SCORE}
                    />
                </div>
                <RelationshipPositioningFooter />
            </div>
        </div>
    )
}

const useRelationshipPositioningGraphStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
        maxHeight: convertToRem(500),
        backgroundColor: 'var(--gd-main-app-background-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
    },
    title: {
        width: '80%',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '20px 0 0 20px',
    },
    graphContent: {
        height: '100%',
        padding: '20px',
    },
    graphTop: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100% - 20px)',
    },
    graphY: {
        width: '20px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: `calc(100% / (${NB_MAX_INTERLOCUTORS} + 1))`,
        marginRight: '10px',
        '& span': {
            display: 'flex',
            position: 'relative',
            justifyContent: 'flex-end',
            fontSize: '14px',
        },
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        alignItems: 'flex-end',
        padding: '0 5px',
        borderBottom: '1px solid var(--gd-background-dark-color)',
    },
    graphX: {
        height: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '14px',
        justifyContent: 'space-between',
        marginTop: '5px',
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: '10px',
        padding: '10px 20px',
        fontSize: '14px',
    },
    descriptionTitle: {
        fontWeight: 'bold',
    },
    legendContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
        fontSize: '14px',
        paddingLeft: '20px',
        marginBottom: '10px',
    },
    circle: {
        borderRadius: '50%',
        paddingLeft: '10px',
        height: '10px',
        backgroundColor: 'var(--gd-primary-color)',
    },
    slot: {
        width: '100%',
        borderTopLeftRadius: '2px',
        borderTopRightRadius: '2px',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:hover $tooltip': {
            visibility: 'visible',
        },
    },
    tooltip: {
        position: 'absolute',
        visibility: 'hidden',
        transform: 'translateY(-100%)',
        zIndex: 10,
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    tooltipRectangle: {
        padding: `${convertToRem(4)} ${convertToRem(7)}`,
        maxWidth: convertToRem(170),
        maxHeight: convertToRem(58),
        minWidth: convertToRem(124),
        minHeight: convertToRem(46),
        backgroundColor: 'var(--gd-background-dark-color)',
        borderRadius: convertToRem(5),
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: 'normal',
        color: 'var(--gd-text-dark-color)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tooltipTriangle: { position: 'relative', top: convertToRem(-2) },
    bulbInformation: {
        padding: '20px',
        borderTop: '5px solid var(--gd-background-clear-color)',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        backgroundColor: 'var(--gd-main-app-background-color) !important',
    },
    bulbInformationIcon: {
        '& > *:first-child': {
            fill: 'var(--gd-background-clear-color)',
        },
    },
})

export enum EGraph {
    MEETING,
    SCORE,
}

interface RelationshipPositioningGraphProps {
    slots: QVTRelationshipPositioningSlot[]
    lang: RelationshipPositioningGraphLang
    graphType: EGraph
}

export function RelationshipPositioningGraph({ slots, lang, graphType }: Readonly<RelationshipPositioningGraphProps>) {
    const styles = useRelationshipPositioningGraphStyles()
    const nbMaxInterlocutors = useMemo(() => {
        if (graphType === EGraph.MEETING) {
            const maxNbUsers = Math.max(...slots.map((item) => item.nbUsers))
            return maxNbUsers > 0 && maxNbUsers <= NB_MAX_INTERLOCUTORS ? maxNbUsers : NB_MAX_INTERLOCUTORS
        }
        return NB_MAX_INTERLOCUTORS
    }, [slots, lang, graphType])

    const graphYAxesSteps = useMemo(() => {
        if (graphType === EGraph.MEETING) {
            const isDividerOf5 = nbMaxInterlocutors % 5 === 0
            const maxStep = isDividerOf5 ? nbMaxInterlocutors + 5 : nbMaxInterlocutors + (5 - (nbMaxInterlocutors % 5))
            return Array.from({ length: maxStep / 5 + 1 }, (_, i) => i * 5).reverse()
        }
        return []
    }, [nbMaxInterlocutors])

    return (
        <div className={styles.container}>
            <div className={styles.title}>{lang.title}</div>
            <div className={styles.graphContent}>
                <div className={styles.graphTop}>
                    {graphType === EGraph.MEETING ? (
                        <div className={styles.graphY} style={{ paddingTop: nbMaxInterlocutors }}>
                            {graphYAxesSteps.map((step) => (
                                <span key={step}>{step}</span>
                            ))}
                        </div>
                    ) : null}
                    <div className={styles.content}>
                        {slots.map((item) => {
                            return (
                                <div
                                    key={item.from}
                                    className={styles.slot}
                                    style={{
                                        height: `calc(100% / (${nbMaxInterlocutors} + 1) * ${item.nbUsers})`,
                                        backgroundColor: item.userPosition ? 'var(--gd-primary-color)' : 'var(--gd-secondary-color)',
                                    }}
                                >
                                    <div className={styles.tooltip}>
                                        <div className={styles.tooltipRectangle}>{lang.tooltip(item.nbUsers, item.from, item.to)}</div>
                                        <svg className={styles.tooltipTriangle} width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                            <path d='M10 12L0.473721 0.75L19.5263 0.749998L10 12Z' fill='var(--gd-background-dark-color)' />
                                        </svg>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {graphType === EGraph.MEETING ? (
                    <div
                        className={styles.graphX}
                        style={{ paddingLeft: `calc(20px + 100% / ${NB_MAX_HOURS_IN_MEETING} * 5)`, paddingRight: `calc(100% / ${NB_MAX_HOURS_IN_MEETING} * 5)` }}
                    >
                        <span>5h</span>
                        <span>15h</span>
                        <span>25h</span>
                    </div>
                ) : (
                    <div className={styles.graphX} style={{ padding: `0 calc( 100% / ${SCORE_MAX} * 25)` }}>
                        <span>25pts</span>
                        <span>50pts</span>
                        <span>75pts</span>
                    </div>
                )}
            </div>
            <div className={styles.legendContainer}>
                <div className={styles.circle}></div>
                <div>{lang.legend}</div>
            </div>

            <QVTBulbInformation textContent={lang.description.content} classNames={{ container: styles.bulbInformation, icon: styles.bulbInformationIcon }} />
        </div>
    )
}
