import { ReactComponent as Cry } from './Cry.svg'
import { ReactComponent as Disappointed } from './Disappointed.svg'
import { ReactComponent as Happy } from './Happy.svg'
import { ReactComponent as Medium } from './Medium.svg'
import { ReactComponent as Smile } from './Smile.svg'

export default {
    Cry,
    Disappointed,
    Happy,
    Smile,
    Medium,
}
