import { ReactNode, useMemo } from 'react'
import { Redirect, useParams, useRouteMatch } from 'react-router-dom'
import { useInstance } from '../../contexts'
import { EGoal, Params } from '../../models'

export default function withRedirectToQvt<P extends JSX.IntrinsicAttributes & { children?: ReactNode }>(Component: React.ComponentType<P>) {
    const Hoc = (props: P) => {
        const { customer } = useInstance()
        const { langParam, customerParam } = useParams<Params>()
        const { url } = useRouteMatch()
        const authorizedQvtPaths = useMemo(() => {
            return [`/${langParam}/${customerParam}/goal/${EGoal.QVT}/individual`, `/${langParam}/${customerParam}/goal/${EGoal.QVT}/manager`]
        }, [customer])

        if (customer.canViewOnlyQVTGoal && !authorizedQvtPaths.includes(url)) {
            return <Redirect to={`/${langParam}/${customerParam}/goal/${EGoal.QVT}/individual`} />
        }

        return <Component {...props} />
    }

    return Hoc
}
