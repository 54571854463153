import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'
import { useStore, useUser } from '../../contexts'
import { secondsToLiteralTime } from '../dateUtils'
import { convertToRem } from '../utils'

const useQVTFutureMeetingStyle = createUseStyles({
    qvtFutureMeeting: {
        backgroundColor: 'var(--gd-background-clear-color)',
        borderRadius: 'var(--gd-border-normal-radius)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        justifyItems: 'center',
        padding: convertToRem(50),
        fontWeight: 'bold',
        gap: convertToRem(8),
    },
    qvtFutureMeetingDescription: {
        fontSize: 'var(--gd-size-body-text)',
    },
    qvtFutureMeetingEvaluation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: convertToRem(8),
        color: 'var(--gd-primary-color)',
        fontSize: 'var(--gd-size-big-number)',
    },
    qvtFutureMeetingComparaison: {
        fontSize: 'var(--gd-size-small-body-tex)',
    },
})

const QVTFutureMeeting = () => {
    const styles = useQVTFutureMeetingStyle()
    const { lang } = useUser()
    const {
        state: { userPayload },
    } = useStore()
    const userFuturMeeting = useMemo(() => {
        const isNegative = userPayload.qvtFutureMeeting?.averageEvolution < 0
        const averageEvolution = secondsToLiteralTime(Math.abs(userPayload.qvtFutureMeeting?.averageEvolution ?? 0) * 60)
        const averageEvolutionValue = isNegative ? '- ' + averageEvolution : '+ ' + averageEvolution
        const hoursWillBeSpent = secondsToLiteralTime((userPayload.qvtFutureMeeting?.totalMinutes ?? 0) * 60)
        return {
            hoursWillBeSpent: hoursWillBeSpent != null && hoursWillBeSpent != '' ? hoursWillBeSpent : '-',
            averageEvolution: averageEvolution != null && averageEvolution != '' ? averageEvolutionValue : '-',
        }
    }, [userPayload])
    return (
        <div className={styles.qvtFutureMeeting}>
            <div className={styles.qvtFutureMeetingDescription}>{lang.qvtFutureMeeting.description}</div>
            <div className={styles.qvtFutureMeetingEvaluation}>
                <IconAssets.ClockIcon />
                {userFuturMeeting.hoursWillBeSpent}
            </div>
            <div className={styles.qvtFutureMeetingComparaison}>
                {userFuturMeeting.averageEvolution}/{lang.qvtFutureMeeting.comparaison}
            </div>
        </div>
    )
}

export default QVTFutureMeeting
