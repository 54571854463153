import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { MediaQueryBreakpoints } from '../MediaQueryBreakpoints'
import Popup from '../Popup'
import { convertToRem } from '../utils'

interface Props {
    message: string
    confirmLabel: string
    cancelLabel: string
    onConfirm: () => void
    onCancel: () => void
    customStyles?: {
        container?: string
        message?: string
        actions?: string
        confirmAction?: string
        cancelAction?: string
    }
}

const useStyles = createUseStyles({
    confirmPopup: {
        padding: convertToRem(28),
        width: '100%',
        minWidth: convertToRem(400),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'var(--gd-main-popup-background-color)',
    },
    message: {
        fontSize: convertToRem(16),
        color: 'var(--gd-text-dark-color)',
    },
    actions: {
        marginTop: convertToRem(36),
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-end',
        columnGap: convertToRem(17),
    },
    actionLabel: {
        fontSize: convertToRem(16),
        fontWeight: 'bold',
        color: 'var(--gd-secondary-color)',
        cursor: 'pointer',
    },
    [MediaQueryBreakpoints.MOBILE]: {
        confirmPopup: {
            width: '90%',
            minWidth: 'unset',
            alignSelf: 'center',
            borderRadius: 'var(--gd-border-normal-radius)',
        },
    },
})

export default function ConfirmPopup({ customStyles, message, confirmLabel, cancelLabel, onConfirm, onCancel }: Props) {
    const styles = useStyles()

    return (
        <Popup
            onCloseRequest={() => {
                return
            }}
            closable={false}
        >
            <div className={classNames(styles.confirmPopup, customStyles?.container)}>
                <p className={classNames(styles.message, customStyles?.message)}>{message}</p>
                <div className={classNames(styles.actions, customStyles?.actions)}>
                    <span className={classNames(styles.actionLabel, customStyles?.cancelAction)} onClick={onCancel}>
                        {cancelLabel}
                    </span>
                    <span className={classNames(styles.actionLabel, customStyles?.confirmAction)} onClick={onConfirm}>
                        {confirmLabel}
                    </span>
                </div>
            </div>
        </Popup>
    )
}
