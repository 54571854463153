import { GoalCardContainerProps } from '../GoalCardContainer'
import classnames from 'classnames'
import { useGoalCardContainerStyle } from '../styles'
import { substractDays, toDayLiteralMonthString } from '../../dateUtils'
import IconAssets from '../../../assets/icon/IconAssets'
import { TrucateSpan } from '../../share/TrucateSpan'
import Rate from '../../Rate'
import { GreetButton } from '../../share/GreetButton'

export default function GoalCardContainerLegacy({ goal, lang }: GoalCardContainerProps) {
    return goal?.cohort ? <GoalCardCohortContainer goal={goal} lang={lang} /> : <GoalCardIndividualContainer goal={goal} lang={lang} />
}

export function GoalCardCohortContainer({ goal, lang }: GoalCardContainerProps) {
    const styles = useGoalCardContainerStyle()
    return (
        <div className={styles.activeContainer}>
            <div className={styles.activeContent}>
                <div className={styles.activeDate}>
                    {lang.date.range(toDayLiteralMonthString(goal.startDate, lang.locale), toDayLiteralMonthString(substractDays(goal.endDate, 1), lang.code))}
                </div>
                <div className={styles.activeProgressTextContainer}>
                    <IconAssets.GroupIcon />
                    <span className={styles.activeProgressTitle}>{lang.goal.home.groupProgress}</span>
                    <TrucateSpan text={goal?.cohort?.cohortDisplayName} maxLength={24} className={styles.activeCohortName} />
                </div>
                <Rate value={goal.evolution} />
                <div className={styles.activePrecision}>{lang.goal.home.precision}</div>
                <GreetButton type='secondary' label={lang.goal.home.see} className={classnames(styles.cardButton)} />
            </div>
        </div>
    )
}

function GoalCardIndividualContainer({ goal, lang }: GoalCardContainerProps) {
    const styles = useGoalCardContainerStyle()
    return (
        <div className={styles.activeContainer}>
            <div className={classnames(styles.activeContent, styles.individualActiveContent)}>
                <div className={classnames(styles.activeDate, styles.individualActiveDate)}>
                    {lang.date.range(toDayLiteralMonthString(goal.startDate, lang.locale), toDayLiteralMonthString(substractDays(goal.endDate, 1), lang.code))}
                </div>
                <div className={classnames(styles.activeProgressTextContainer, styles.individualActiveProgressTextContainer)}>
                    <div className={styles.userIconContainer}>
                        <IconAssets.UserIcon />
                    </div>
                    <span className={styles.activeCohortName}>{lang.goal.home.individualProgress}</span>
                </div>
                <Rate value={goal.evolution} />
                <div className={styles.activePrecision}>{lang.goal.home.precision}</div>
                <GreetButton type='primary' label={lang.goal.home.see} className={classnames(styles.cardButton)} />
            </div>
        </div>
    )
}
