import { LorealLogo } from '../../components/headers/LorealLogo'
import { Customer, EGoal } from '../../models'

import WebinarImg from '../../assets/illustration/WebinarLoreal.jpg'

import { GreetFeaturingTarget } from '../../components/share/GreetFeaturingComponent'
import ECoachingEvent from '../../models/coaching/ECoachingEvent'
import LorealEn from './cutomerLangs/loreal/LorealEn'
import LorealFr from './cutomerLangs/loreal/LorealFr'
import { defaultBricks, defaultBugReport, defaultCoachingMeeting } from './defaultCustomerValues'
import QVTIndividualGoal from '../../pages/QVTIndividualGoal'

const loreal: Customer = {
    name: 'loreal',
    platformName: 'loreal',
    logo: LorealLogo,
    bricks: defaultBricks,
    QVTIndividualGoal: QVTIndividualGoal,
    strictMode: true,
    theme: {
        primaryColor: '#10304B', //                                          --gd-primary-color
        primaryDarkColor: '#2C506D', //                                      --gd-primary-dark-color
        secondaryColor: '#2C506D', //                                        --gd-secondary-color
        secondaryDarkColor: '#10304B', //                                    --gd-secondary-dark-color
        tertiaryColor: '#8A9AA7', //                                         --gd-tertiary-color

        borderSmallRadius: '10px', //                                        --gd-border-small-radius
        borderNormalRadius: '20px', //                                       --gd-border-normal-radius
        borderBigRadius: '50px', //                                          --gd-border-big-radius

        positiveColor: '#009A54', //                                         --gd-positive-color
        intermediaryColor: '#F4991D', //                                     --gd-intermediary-color
        negativeColor: '#FD470E', //                                         --gd-negative-color

        primaryButtonColor: 'var(--gd-primary-color)', //                    --gd-primary-button-color
        secondaryButtonColor: 'var(--gd-primary-color)', //                  --gd-secondary-button-color
        dangerButtonColor: 'var(--gd-negative-color)', //                    --gd-danger-button-color

        mainAppBackgroundColor: '#FFFFFF', //                                --gd-main-app-background-color
        mainAppShadowedContent: 'rgb(0 0 0 / 8%) 0px -15px 15px 0px', //     --gd-main-app-shadowed-content
        mainActiveTabBackgroundColor: 'var(--gd-background-clear-color)', // --gd-main-active-tab-background-color
        mainActiveTabColor: 'var(--gd-secondary-color)', //                    --gd-main-active-tab-color
        mainPopupBackgroundColor: '#FFFFFF', //                              --gd-main-popup-background-color

        blogsPrimaryColor: 'var(--gd-secondary-color)', //                   --gd-blogs-primary-color
        blogsPrimaryDarkColor: 'var(--gd-secondary-dark-color)', //          --gd-blogs-primary-color
        blogsSecondaryColor: 'var(--gd-primary-color)', //                   --gd-blogs-secondary-color

        backgroundClearColor: '#DFDAD4', //                                  --gd-background-clear-color
        backgroundDarkColor: '#C8BDB2', //                                   --gd-background-dark-color

        textClearColor: '#FFFFFF', //                                        --gd-text-clear-color
        textDarkColor: '#1C1B1F', //                                         --gd-text-dark-color
        textMainSubTitleColor: 'var(--gd-primary-color)', //                 --gd-text-main-subtitle-color
        bigTextDarkColor: '#2C506D', //                                      --gd-big-text-dark-color

        brickEmailColor: '#10304B', //                                       --gd-brick-email-color
        brickEmailActiveColor: '#FFFFFF', //                                 --gd-brick-email-active-color
        brickOneDriveColor: '#586E81', //                                    --gd-brick-onedrive-color
        brickOneDriveActiveColor: '#FFFFFF', //                              --gd-brick-onedrive-active-color
        brickTeamsColor: '#8A9AA7', //                                       --gd-brick-teams-color
        brickTeamsActiveColor: '#FFFFFF', //                                 --gd-brick-teams-active-color
        brickSharepointColor: '#8A9AA7', //                                  --gd-brick-sharepoint-color
        brickSharepointActiveColor: '#FFFFFF', //                            --gd-brick-sharepoint-active-color
        brickVisionColor: '#8A9AA7', //                                      --gd-brick-vision-color
        brickVisionActiveColor: '#FFFFFF', //                                --gd-brick-vision-active-color
        brickChatColor: '#10304B', //                                        --gd-brick-chat-color
        brickGmailColor: '#002F7D', //                                       --gd-brick-gmail-color
        brickGmailActiveColor: '#F0F6FF', //                                 --gd-brick-gmail-active-color
        brickGDriveColor: '#14675A', //                                      --gd-brick-gdrive-color
        brickGDriveActiveColor: '#F0F6FF', //                                --gd-brick-gdrive-active-color
        brickSharedGDriveColor: '#302E9D', //                                --gd-brick-shared-gdrive-color
        brickSharedGDriveActiveColor: '#F0F6FF', //                          --gd-brick-shared-gdrive-active-color
        brickCopiloteColor: '#A86EDD', //                                    --gd-brick-copilote-color
        brickCopiloteActiveColor: '#1C1B1F', //                              --gd-brick-copilote-active-color

        goalTextColor: '#1C1B1F', //                                         --gd-goal-text-color
        goalFillColor: '#FFF', //                                            --gd-goal-fill-color
        goalCarbonFootprintColor: '#8A9AA7', //                              --gd-goal-carbon-footprint-color
        goalInfobisityColor: '#8A9AA7', //                                   --gd-goal-infobesity-color
        goalDeconnectionTimeColor: '#8A9AA7', //                             --gd-goal-deconnection-time-color
        badgeTextColor: '#1C1B1F', //                                        --gd-badge-text-color

        badgeFillColor: '#FFF', //                                           --gd-badge-fill-color
        badgeMailsOnDietColor: '#10304B', //                                 --gd-badge-mails-on-diet-color
        badgeMailsOnDietLineColor: '#10304B', //                             --gd-badge-mails-on-diet-line-color
        badgeMailsOnDietFillColor: '#10304B', //                             --gd-badge-mails-on-diet-fill-color
        badgeCleanUpColor: '#10304B', //                                     --gd-badge-cleanup-color
        badgeCleanUpLineColor: '#10304B', //                                 --gd-badge-cleanup-line-color
        badgeCleanUpFillColor: '#10304B', //                                 --gd-badge-cleanup-fill-color
        badgePlanetFriendlyColor: '#10304B', //                              --gd-badge-planet-friendly
        badgePlanetFriendlyLineColor: '#10304B', //                          --gd-badge-planet-line-friendly
        badgePlanetFriendlyFillColor: '#10304B', //                          --gd-badge-planet-fill-friendly
        badgeInternAttachmentColor: '#10304B', //                            --gd-badge-intern-attachment-color
        badgeInternAttachmentLineColor: '#10304B', //                        --gd-badge-intern-attachment-line-color
        badgeInternAttachmentFillColor: '#10304B', //                        --gd-badge-intern-attachment-fill-color
        badgeMailShotColor: '#10304B', //                                    --gd-badge-mailshot-color
        badgeMailShotLineColor: '#10304B', //                                --gd-badge-mailshot-line-color
        badgeMailShotFillColor: '#10304B', //                                --gd-badge-mailshot-fill-color
        badgeImageHuntColor: '#10304B', //                                   --gd-badge-imagehunt-color
        badgeImageHuntLineColor: '#10304B', //                               --gd-badge-imagehunt-line-color
        badgeImageHuntFillColor: '#10304B', //                               --gd-badge-imagehunt-fill-color
        badgeVideoHuntColor: '#10304B', //                                   --gd-badge-video-hunt-color
        badgeVideoHuntLineColor: '#10304B', //                               --gd-badge-video-hunt-line-color
        badgeVideoHuntFillColor: '#10304B', //                               --gd-badge-video-hunt-fill-color
        loaderColors: ['#2C506D', '#C8BDB2', '#C8BDB2', '#FFFFFF', '#C8BDB2'],

        emailSendingRangeLessThanTen: '#9BCDD0', //                            --gd-email-sending-range-stick-less-than-ten-fill-color
        emailSendingRangeBetweenTenAndThirty: '#59ABB1', //                    --gd-email-sending-range-stick-beetwen-ten-and-thirty-fill-color
        emailSendingRangeMoreThanThirty: '#2D5659', //                          --gd-email-sending-range-stick-more-than-thirty-fill-color

        emailSendingRangeSmallRange: '#9BCDD0', //                             --gd-email-sending-range-small-range-color
        emailSendingRangeMediumRange: '#59ABB1', //                            --gd-email-sending-range-medium-range-color
        emailSendingRangeLargeRange: '#4d7679', //                             --gd-email-sending-range-large-range-color
        emailSendingRangeHeightRange: '#2D5659', //                            --gd-email-sending-range-height-range-color
    },
    goals: [EGoal.CARBON_FOOTPRINT, EGoal.QVT],
    goalPagesLayout: {
        coachingOnIndividual: false,
        coachingOnCollective: {
            tips: true,
            training: true,
            coaching: false,
        },
    },
    contact: {
        support: {
            name: 'Yuki Deguitre',
            mail: 'yuki.deguitre@loreal.com',
        },
        hasLinkSupport: false,
        link: '',
        linkName: ''
    },
    legalInformation: {
        cgu: 'https://leckogreet.blob.core.windows.net/lecko/Greet_CGU_Lecko_Oct_2023.pdf',
        charter: 'https://leckogreet.blob.core.windows.net/lecko/Greet_Charte_de_protection_des_donnees_personnelles_Oct_2023.pdf',
    },
    customerLang: {
        fr: LorealFr,
        en: LorealEn,
    },
    webinarImg: WebinarImg,
    bugReport: defaultBugReport,
    defaultCoachingMeeting: defaultCoachingMeeting,
    coachingEvent: ECoachingEvent.WEBINAR,
    disablePwa: true,
    featuring: [GreetFeaturingTarget.RANKING, GreetFeaturingTarget.DRIVE_FOLDER_DELETION],
    blogs: {
        fr: [
            {
                title: "Chez L'Oréal, nous nous engageons à réduire notre empreinte environnementale dans tous les aspects de notre activité, y compris notre Digital Workplace. Découvrez notre programme SUstainable IT & Tech",
                description: '',
                link: 'https://loreal.sharepoint.com/sites/OneITLibrary/SitePages/Sustainable-IT-&-Tech.aspx',
                illustrationIndex: 3,
            },
            {
                title: 'Astuces quotidiennes pour réduire votre impact environnemental',
                description: '',
                link: 'https://qrco.de/bf4DPa',
                illustrationIndex: 4,
            },
        ],
        en: [
            {
                title: "At L'Oréal, we are committed to reducing our environmental footprint across all aspects of our business, including our digital workplace. Discover our global Sustainable IT & Tech Program",
                description: '',
                link: 'https://loreal.sharepoint.com/sites/OneITLibrary/SitePages/Sustainable-IT-&-Tech.aspx',
                illustrationIndex: 3,
            },
            {
                title: 'Small Changes, Big Impact: digital Tips to lower your environmental footprint',
                description: '',
                link: 'https://qrco.de/bf4DPa',
                illustrationIndex: 4,
            },
        ],
    }
}

export default loreal
