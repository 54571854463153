import { ChartArea, Plugin } from 'chart.js'

interface GreetTooltipOptions {
    datasetIndex: number
    rate: number | undefined
    period: string
    backgroundColor: string
    spotColor: string
    textColor?: string
    fontSize?: number
}

interface Point {
    x: number
    y: number
}

interface Size {
    w: number
    h: number
}

enum Edge {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    TOP_LEFT = 'TOP_LEFT',
    TOP_RIGHT = 'TOP_RIGHT',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
    NONE = 'NONE',
}

// start point => top left
function drawRect(
    ctx: CanvasRenderingContext2D,
    start: Point,
    size: Size,
    borderRadius: number,
): void {
    const { x, y } = start
    const { w, h } = size
    ctx.save()
    ctx.beginPath()
    ctx.moveTo(x, y)
    ctx.arcTo(x + w, y, x + w, y + h, borderRadius)
    ctx.arcTo(x + w, y + h, x, y + h, borderRadius)
    ctx.arcTo(x, y + h, x, y, borderRadius)
    ctx.arcTo(x, y, x + w, y, borderRadius)
    ctx.fill()
    ctx.closePath()
    ctx.restore()
}

function drawTriangle(ctx: CanvasRenderingContext2D, start: Point, p1: Point, p2: Point): void {
    const { x, y } = start
    ctx.save()
    ctx.beginPath()
    ctx.moveTo(x, y)
    ctx.lineTo(p1.x, p1.y)
    ctx.lineTo(p2.x, p2.y)
    ctx.fill()
    ctx.closePath()
    ctx.restore()
}

function drawCircle(
    ctx: CanvasRenderingContext2D,
    target: Point,
    radius: number,
    lineWidth: number,
): void {
    const { x, y } = target
    ctx.save()
    ctx.beginPath()
    ctx.arc(x, y, radius, 0, 2 * Math.PI)
    ctx.fill()
    ctx.save()
    ctx.globalAlpha = 0.6
    ctx.lineWidth = lineWidth
    ctx.strokeStyle = ctx.fillStyle
    ctx.stroke()
    ctx.closePath()
    ctx.restore()
}

const coordinates = new Map<
    Edge,
    (
        origin: Point,
        circleRadius: number,
        triangleSize: Size,
        rect: {
            size: Size
            radius: number
        },
    ) => {
        triangle: { p1: Point; p2: Point; p3: Point }
        rect: { startCorner: Point }
    }
>([
    [
        Edge.NONE,
        function (
            origin: Point,
            circleRadius: number,
            triangleSize: Size,
            rect: {
                size: Size
                radius: number
            },
        ) {
            return {
                triangle: {
                    p1: {
                        x: origin.x,
                        y: origin.y - circleRadius,
                    },
                    p2: {
                        x: origin.x + triangleSize.w / 2,
                        y: origin.y - triangleSize.h - circleRadius - 1, // -1 to remove space between triangle and rectangle
                    },
                    p3: {
                        x: origin.x - triangleSize.w / 2,
                        y: origin.y - triangleSize.h - circleRadius - 1,
                    },
                },
                rect: {
                    startCorner: {
                        x: origin.x - rect.size.w / 2,
                        y: origin.y - triangleSize.h - rect.size.h - circleRadius,
                    },
                },
            }
        },
    ],
    [
        Edge.TOP,
        function (
            origin: Point,
            circleRadius: number,
            triangleSize: Size,
            rect: {
                size: Size
                radius: number
            },
        ) {
            return {
                triangle: {
                    p1: {
                        x: origin.x,
                        y: origin.y + circleRadius,
                    },
                    p2: {
                        x: origin.x - triangleSize.w / 2,
                        y: origin.y + triangleSize.h + circleRadius,
                    },
                    p3: {
                        x: origin.x + triangleSize.w / 2,
                        y: origin.y + triangleSize.h + circleRadius,
                    },
                },
                rect: {
                    startCorner: {
                        x: origin.x - rect.size.w / 2,
                        y: origin.y + triangleSize.h + circleRadius - 1,
                    },
                },
            }
        },
    ],
    [
        Edge.LEFT,
        function (
            origin: Point,
            circleRadius: number,
            triangleSize: Size,
            rect: {
                size: Size
                radius: number
            },
        ) {
            return {
                triangle: {
                    p1: {
                        x: origin.x,
                        y: origin.y - circleRadius,
                    },
                    p2: {
                        x: origin.x,
                        y: origin.y - triangleSize.h - circleRadius - rect.radius,
                    },
                    p3: {
                        x: origin.x + triangleSize.w,
                        y: origin.y - triangleSize.h - circleRadius,
                    },
                },
                rect: {
                    startCorner: {
                        x: origin.x,
                        y: origin.y - rect.size.h - triangleSize.h - circleRadius,
                    },
                },
            }
        },
    ],
    [
        Edge.RIGHT,
        function (
            origin: Point,
            circleRadius: number,
            triangleSize: Size,
            rect: {
                size: Size
                radius: number
            },
        ) {
            return {
                triangle: {
                    p1: {
                        x: origin.x,
                        y: origin.y - circleRadius,
                    },
                    p2: {
                        x: origin.x,
                        y: origin.y - triangleSize.h - rect.radius - circleRadius,
                    },
                    p3: {
                        x: origin.x - triangleSize.w,
                        y: origin.y - triangleSize.h - circleRadius,
                    },
                },
                rect: {
                    startCorner: {
                        x: origin.x - rect.size.w,
                        y: origin.y - triangleSize.h - rect.size.h - circleRadius,
                    },
                },
            }
        },
    ],
    [
        Edge.TOP_LEFT,
        function (
            origin: Point,
            circleRadius: number,
            triangleSize: Size,
            rect: {
                size: Size
                radius: number
            },
        ) {
            return {
                triangle: {
                    p1: {
                        x: origin.x,
                        y: origin.y + circleRadius, // -1 to remove space between triangle and rectangle
                    },
                    p2: {
                        x: origin.x,
                        y: origin.y + triangleSize.h + circleRadius + rect.radius,
                    },
                    p3: {
                        x: origin.x + triangleSize.w,
                        y: origin.y + triangleSize.h + circleRadius,
                    },
                },
                rect: {
                    startCorner: {
                        x: origin.x,
                        y: origin.y + triangleSize.h + circleRadius,
                    },
                },
            }
        },
    ],
    [
        Edge.TOP_RIGHT,
        function (
            origin: Point,
            circleRadius: number,
            triangleSize: Size,
            rect: {
                size: Size
                radius: number
            },
        ) {
            return {
                triangle: {
                    p1: {
                        x: origin.x,
                        y: origin.y + circleRadius,
                    },
                    p2: {
                        x: origin.x,
                        y: origin.y + triangleSize.h + circleRadius + rect.radius,
                    },
                    p3: {
                        x: origin.x - triangleSize.w,
                        y: origin.y + triangleSize.h + circleRadius,
                    },
                },
                rect: {
                    startCorner: {
                        x: origin.x - rect.size.w,
                        y: origin.y + triangleSize.h + circleRadius,
                    },
                },
            }
        },
    ],
])

function findHorizentalEdge(x: number, chartArea: ChartArea, tooltipW: number): Edge | undefined {
    const { left, right } = chartArea
    if (x - tooltipW / 2 <= left) {
        return Edge.LEFT
    }

    if (x + tooltipW / 2 >= right) {
        return Edge.RIGHT
    }

}

function findVerticalEdge(y: number, chartArea: ChartArea, tooltipH: number): Edge | undefined {
    const { top, bottom } = chartArea
    if (y - tooltipH <= top) {
        return Edge.TOP
    }

    if (y + tooltipH >= bottom) {
        return Edge.BOTTOM
    }
}

function getEdge(chartArea: ChartArea, origin: Point, tooltipSize: Size): string {
    const h = findHorizentalEdge(origin.x, chartArea, tooltipSize.w)
    let v = findVerticalEdge(origin.y, chartArea, tooltipSize.h)

    if (v === Edge.BOTTOM) {
        v = undefined
    }

    if (h && v) {
        return `${v}_${h}`
    } else if (h) {
        return h
    } else if (v) {
        return v
    } else {
        return Edge.NONE
    }
}

// inspired by:  https://www.chartjs3.com/docs/chart/chart-types/line-chart/
export const buildGreetTooltip = ({
    datasetIndex,
    rate: inputRate,
    period,
    backgroundColor,
    spotColor,
    textColor = 'white',
    fontSize = 14,
}: GreetTooltipOptions): Plugin<'line'> => ({
    id: 'greetooltip',

    afterDraw(chart) {
        const { ctx } = chart
        const data = chart.getDatasetMeta(datasetIndex).data
        if (data.length && inputRate != null) {
            const { chartArea } = chart
            const {x, y} = data[data.length - 1].tooltipPosition(false)
            const roundedRate = Math.round(inputRate);
            const rate = inputRate > 0 ? `+${roundedRate}%` : `${roundedRate}%`
            const rateW = ctx.measureText(rate).width
            const periodW = ctx.measureText(period).width // as period is the largest text use it to compute rectangle width
            const rectR = 7 // rectangle border radius
            const padding = 10 // rectangle padding
            const rectW = periodW + rectR + padding // add radius and padding to coompute actual rectangle width
            const rectH = 50
            const triangleW = 18
            const triangleH = 18
            const circleR = 10 // circle radius
            const cirlceLW = 8 // circle line width
            const securityMargin = 45;
            const edge = getEdge(
                chartArea,
                { x: x, y: y },
                { w: rectW, h: rectH + triangleH + circleR + cirlceLW + securityMargin },
            )
            const coordinate = coordinates.get(edge as Edge)
            if (coordinate) {
                const values = coordinate(
                    { x: x, y: y },
                    circleR + cirlceLW, // add line width to compute actual circle radius
                    { w: triangleW, h: triangleH },
                    {
                        size: { w: rectW, h: rectH },
                        radius: rectR,
                    },
                )
                ctx.save()
                ctx.fillStyle = spotColor
                drawCircle(ctx, { x: x, y: y }, circleR, cirlceLW)
                ctx.restore()
                ctx.fillStyle = backgroundColor
                drawTriangle(ctx, values.triangle.p1, values.triangle.p2, values.triangle.p3)
                drawRect(ctx, values.rect.startCorner, { w: rectW, h: rectH }, rectR)
                ctx.restore()
                // text
                ctx.save()
                ctx.font = `${fontSize}px`
                ctx.fillStyle = textColor
                const rateX = values.rect.startCorner.x + rectW / 2 - rateW / 2
                const rateY = values.rect.startCorner.y + padding + fontSize / 2
                const periodX = values.rect.startCorner.x + padding
                const periodY = rateY + fontSize / 2 + padding
                ctx.fillText(rate, rateX, rateY)
                ctx.fillText(period, periodX, periodY)
                ctx.restore()
            }
        }
    },
})
