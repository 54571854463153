import { Lang } from '../../../../models'
import defaultLang from '../../../lang/en'

const fftEn: Lang = {
    ...defaultLang,
    webinar: {
        ...defaultLang.webinar,
        startAt: '',
        contentTitle: 'Coming soon',
        description: '',
    },
}

export default fftEn
