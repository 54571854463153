import { createUseStyles } from 'react-jss'
import IconAssets from '../../assets/icon/IconAssets'

export interface EvolutionBoxProps {
    carbonImpact: string
    unit: string
    growthPercentage: number
    noActivityLastYear: boolean
    comparedToText: string
    startDate: string
    textColor: string
    backgroundColor: string
}

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '371px',
        width: '100%',
        height: '118px',
        borderRadius: 'var(--gd-border-small-radius)',
        alignSelf: 'center',
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        gap: '10px',
        borderRadius: 'var(--gd-border-small-radius) var(--gd-border-small-radius) 0px 0px',
        height: '50%',
    },
    compareContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: 'var(--gd-negative-color)',
        borderRadius: '0px 0px var(--gd-border-small-radius) var(--gd-border-small-radius)',
        height: '50%',
    },
    number: {
        fontSize: 'var(--gd-size-highlighted-number)',
        alignSelf: 'center',
        fontWeight: 'bold',
        margin: '10px 0px',
    },
    unit: {
        alignSelf: 'center',
        marginTop: '15px',
        marginLeft: '2px',
        fontWeight: 'bold',
    },
    iconContainer: {
        alignSelf: 'center',
        width: '30px',
        height: '30px',
    },
    comparedToNumber: {
        fontSize: 'var(--gd-size-highlighted-number)',
        alignSelf: 'center',
        fontWeight: 'bold',
        margin: '10px 0px',
        color: 'white',
    },
    comparedToText: {
        fontSize: 'var(--gd-size-small-body-text)',
        fontWeight: '600',
        alignSelf: 'center',
        marginLeft: '1vh',
    },
})

export default function EvolutionBox(props: EvolutionBoxProps) {
    const styles = useStyles()
    return (
        <div className={styles.container}>
            <div className={styles.textContainer} style={{ color: props.textColor, backgroundColor: props.backgroundColor }}>
                <h2 className={styles.number}>
                    {' '}
                    {props.carbonImpact} <span className={styles.unit}>{props.unit}</span>
                </h2>
                <div className={styles.iconContainer}>{props.growthPercentage <= 0 ? <IconAssets.Down /> : <IconAssets.Up />}</div>
            </div>
            <div
                className={styles.compareContainer}
                style={{ color: props.textColor, backgroundColor: props.growthPercentage <= 0 ? 'var(--gd-positive-color)' : 'var(--gd-negative-color)' }}
            >
                <h4 className={styles.comparedToNumber} style={{color: props.textColor}}>
                    {props.noActivityLastYear ? 'n.a %' : `${Math.sign(props.growthPercentage) === 1 ? '+' : ''}${Math.round(props.growthPercentage * 100) / 100}%`}
                    <span className={styles.comparedToText}>{props.comparedToText}</span>
                </h4>
            </div>
        </div>
    )
}
