import { FieldValues, Path, UseFormRegister } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import SurveyAssets from '../../assets/icon/survey/SurveyAssets'
import EQVTSurveyFeel from '../../models/EQVTSurveyFeel'
import InfoBulle from '../share/Infobulle'
import { useUser } from '../../contexts'

const useWorkloadEvaluationFormPartStyles = createUseStyles({
    workloadEvaluationFormPart: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '101%',
        backgroundColor: 'var(--gd-primary-color)',
        color: 'var(--gd-text-clear-color)',
        borderRadius: 'var(--gd-border-small-radius)',
        '&>*': {
            padding: '8px',
        },
    },
    workloadEvaluationFormPartHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center',
        gap: '5px',
    },
    workloadEvaluationFormPartHeaderDescription: {
        height: '100%',
        width: '100%',
        fontSize: 'var(--gd-size-highlighted-small-title)',
        maxInlineSize: '250px',
        whiteSpace: 'pre-line',
    },
    workloadEvaluationFormPartBody: {
        backgroundColor: 'var(--gd-background-clear-color)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        borderRadius: 'var(--gd-border-small-radius)',
        '&>*': {
            padding: '10px',
        },
    },
})

interface WorkloadEvaluationFormPartProps {
    description: string
    name: string
    register: UseFormRegister<any>
}

const WorkloadEvaluationFormPart = ({ name, description = '', register }: WorkloadEvaluationFormPartProps) => {
    const styles = useWorkloadEvaluationFormPartStyles()
    const { lang } = useUser()
    return (
        <div className={styles.workloadEvaluationFormPart}>
            <div className={styles.workloadEvaluationFormPartHeader}>
                <div className={styles.workloadEvaluationFormPartHeaderDescription}>{description}</div>
            </div>
            <div className={styles.workloadEvaluationFormPartBody}>
                <SurveyRadioButton
                    register={register}
                    name={name}
                    value={EQVTSurveyFeel.CRY}
                    valueInfo={lang.worloadEvaluation.answersInformations.cry}
                    icon={<SurveyAssets.Cry />}
                />
                <SurveyRadioButton
                    register={register}
                    name={name}
                    value={EQVTSurveyFeel.DISAPPOINTED}
                    valueInfo={lang.worloadEvaluation.answersInformations.disappointed}
                    icon={<SurveyAssets.Disappointed />}
                />
                <SurveyRadioButton
                    register={register}
                    name={name}
                    value={EQVTSurveyFeel.MEDIUM}
                    valueInfo={lang.worloadEvaluation.answersInformations.medium}
                    icon={<SurveyAssets.Medium />}
                />
                <SurveyRadioButton
                    register={register}
                    name={name}
                    value={EQVTSurveyFeel.SMILE}
                    valueInfo={lang.worloadEvaluation.answersInformations.smile}
                    icon={<SurveyAssets.Smile />}
                />
                <SurveyRadioButton
                    register={register}
                    name={name}
                    value={EQVTSurveyFeel.HAPPY}
                    valueInfo={lang.worloadEvaluation.answersInformations.happy}
                    icon={<SurveyAssets.Happy />}
                />
            </div>
        </div>
    )
}

interface SurveyRadioButton<T extends FieldValues> {
    name: Path<T>
    value: string
    icon: React.ReactElement
    valueInfo: string
    register: UseFormRegister<T>
}

const useSurveyRadioButtonStyle = createUseStyles({
    surveyRadioButton: {
        '&> input[type="radio"]': {
            position: 'absolute',
            opacity: 0,
        },
        '&> *': {
            filter: 'grayscale(100%)',
            cursor: 'pointer',
            width: '35px',
        },
        '&> *:hover': {
            filter: 'grayscale(0%)',
        },
        '&> input[type="radio"]:checked + *': {
            filter: 'grayscale(0%)',
        },
    },
})

const SurveyRadioButton = <T extends FieldValues>({ name, value, icon, valueInfo, register }: Readonly<SurveyRadioButton<T>>) => {
    const style = useSurveyRadioButtonStyle()
    const fieldRegisterProps = register(name, { required: true })
    return (
        <InfoBulle text={valueInfo} translateX='-25%'>
            <label className={style.surveyRadioButton}>
                <input type='radio' id={`${name}-${value}`} value={value} {...fieldRegisterProps} />
                {icon}
            </label>
        </InfoBulle>
    )
}

export default WorkloadEvaluationFormPart
