import { createUseStyles } from 'react-jss'
import { useUser } from '../contexts'
import { useFetch, usePut } from '../services/api/useFetch'
import QVTInterlocutorsStatus from '../models/QVTInterlocutorsStatus'
import { useState } from 'react'
import SoftLoader from './SoftLoader'
import IconAssets from '../assets/icon/IconAssets'
import classNames from 'classnames'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
    },
    inputContainer: {
        display: 'flex',
        width: '65px',
        height: '37px',
        cursor: 'pointer',
    },
    inputActive: {
        backgroundColor: 'var(--gd-primary-color)',
    },
    inputInactive: {
        backgroundColor: 'var(--gd-main-app-background-color)',
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        border: '2px solid var(--gd-primary-color)',
        borderRadius: '30px',
        padding: '0 2px',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
    },
    circle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        width: '29px',
        height: '29px',
        position: 'relative',
    },
    circleActive: {
        backgroundColor: 'var(--gd-main-app-background-color)',
        left: '24px',
    },
    circleInactive: {
        backgroundColor: 'var(--gd-primary-color)',
        left: '0px',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    descriptionContainer: {
        fontSize: '14px',
        width: '80%',
    },
    actionContainer: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'var(--gd-primary-color)',
    },
    interlocutorsContainer: {
        marginTop: '20px',
        fontSize: '14px',
    },
    titleContainer: {
        fontWeight: 'bold',
        color: 'var(--gd-primary-color)',
        marginRight: '5px'
    },
})

const defaultValues: QVTInterlocutorsStatus = {
    interlocutors: [],
    isActive: false,
}

export default function RelationshipPositioningFooter() {
    const { lang } = useUser()
    const styles = useStyles()

    const [qvtInterlocutorsStatus, setqvtInterlocutorsStatus] = useState<QVTInterlocutorsStatus>(defaultValues)

    const { loading } = useFetch({
        endpoint: 'list-qvt-interlocutors',
        onSuccess: (data: QVTInterlocutorsStatus) => setqvtInterlocutorsStatus(data),
    })

    const { doPut: toggleInterlocutorsAgreement } = usePut({
        endpoint: 'toggle-qvt-interlocutors-agreement',
        onSuccess: (data: QVTInterlocutorsStatus) => setqvtInterlocutorsStatus(data),
    })

    return (
        <>
            {loading ? (
                <div>
                    <SoftLoader />
                </div>
            ) : (
                <div className={styles.container}>
                    <div className={styles.inputContainer}>
                        <button
                            className={classNames(styles.input, qvtInterlocutorsStatus.isActive ? styles.inputActive : styles.inputInactive)}
                            onClick={() => toggleInterlocutorsAgreement({})}
                        >
                            <div className={classNames(styles.circle, qvtInterlocutorsStatus.isActive ? styles.circleActive : styles.circleInactive)}>
                                {qvtInterlocutorsStatus.isActive ? <IconAssets.ValidIcon /> : <IconAssets.CrossIcon />}
                            </div>
                        </button>
                    </div>
                    {qvtInterlocutorsStatus.isActive ? (
                        <div className={styles.textContainer}>
                            <div className={styles.descriptionContainer}>{lang.goal.qvt.relationshipPositioning.footer.active.description}</div>
                            <div className={styles.actionContainer}>{lang.goal.qvt.relationshipPositioning.footer.active.action}</div>
                            <div className={styles.interlocutorsContainer}>
                                <span className={styles.titleContainer}>{lang.goal.qvt.relationshipPositioning.footer.active.title}</span>
                                {qvtInterlocutorsStatus.interlocutors.length > 0
                                    ? qvtInterlocutorsStatus.interlocutors.join(', ')
                                    : lang.goal.qvt.relationshipPositioning.footer.active.noColleague}
                                .
                            </div>
                        </div>
                    ) : (
                        <div className={styles.textContainer}>
                            <div className={styles.descriptionContainer}>{lang.goal.qvt.relationshipPositioning.footer.inactive.description}</div>
                            <div className={styles.actionContainer}>{lang.goal.qvt.relationshipPositioning.footer.inactive.action}</div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
