import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useStore, useUser } from '../../../contexts'
import { Lang } from '../../../models'
import { usePut } from '../../../services/api/useFetch'
import { DayOfWeek } from '../../dateUtils'

import classNames from 'classnames'
import Select, { components, DropdownIndicatorProps, OptionProps } from 'react-select'
import IconAssets from '../../../assets/icon/IconAssets'
import SoftLoader from '../../SoftLoader'
import { convertToRem, getStringTimeAccordingToSlot } from '../../utils'

const useWorkingDaysEditorStyles = createUseStyles({
    workingDaysEditor: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        background: 'unset',
        border: 'unset',
        color: 'var(--gd-text-color)',
        alignItems: 'center',
    },
    workingDaysEditorLoader: {
        height: '25px',
        width: '25px',
    },
    workingDaysEditorLoaderSpinner: {
        borderColor: '#EDF7EB',
    },
})

const WorkingDaysEditor = () => {
    const { lang, user, updateWorkingDays } = useUser()
    const { state, dispatch } = useStore()
    const css = useWorkingDaysEditorStyles()
    const [userValue, setUserValue] = useState<any>()
    const [selectOptions, setSelectOptions] = useState<any[]>([])

    useEffect(() => {
        setUserValue(
            buildSelectValue(
                lang,
                user?.workingDays?.from ?? DayOfWeek.MONDAY,
                user?.workingDays?.to ?? DayOfWeek.FRIDAY,
                user?.workingDays?.workingRange?.startSlot ?? 18,
                user?.workingDays?.workingRange?.endSlot ?? 36,
            ),
        )
        setSelectOptions([buildSelectValue(lang, DayOfWeek.MONDAY, DayOfWeek.FRIDAY, 18, 36), buildSelectValue(lang, DayOfWeek.SATURDAY, DayOfWeek.THURSDAY, 18, 38)])
    }, [lang, user?.workingDays])

    const { working, doPut } = usePut({
        endpoint: 'working-days',
        onSuccess: (data) => {
            const { workingDays, payloadUserPart } = data
            updateWorkingDays(workingDays)
            setUserValue(
                buildSelectValue(
                    lang,
                    workingDays?.from ?? DayOfWeek.MONDAY,
                    workingDays?.to ?? DayOfWeek.FRIDAY,
                    workingDays?.workingRange?.startSlot ?? 18,
                    workingDays?.workingRange?.endSlot ?? 36,
                ),
            )
            const mailSendingByWorkingRange = payloadUserPart.mailSendingByWorkingRange
            const workingRange = payloadUserPart.workingRange
            const qvtTimeSpentInUserMeetings = payloadUserPart.qvtTimeSpentInUserMeetings
            const newPayload = {
                ...state.userPayload,
                mailSendingByWorkingRange: mailSendingByWorkingRange,
                workingRange: workingRange,
                qvtTimeSpentInUserMeetings: qvtTimeSpentInUserMeetings,
            }
            dispatch({ type: 'user-payload-fetched', value: newPayload })
        },
    })

    return (
        <div className={css.workingDaysEditor}>
            <div>{lang.worloadEvaluationPersonalResult.weekFormat}</div>
            <WorkingDaysSelectEditor options={selectOptions} onChange={(changes) => doPut(buildPutValue(changes))} userValue={userValue} />
            <div className={css.workingDaysEditorLoader}>
                {working ? <SoftLoader customStyles={{ container: css.workingDaysEditorLoader, spinner: css.workingDaysEditorLoaderSpinner }} /> : null}
            </div>
        </div>
    )
}

const useWorkingDaysSelectEditorStyle = createUseStyles({
    container: {
        padding: 'unset !important',
        cursor: 'pointer !important',
    },
    control: {
        backgroundColor: 'transparent !important',
        borderWidth: '0rem !important',
        boxShadow: 'none !important',
    },
    dropDonwnIcon: {
        height: '35px',
        width: '35px',
        cursor: 'pointer',
        '& circle': {
            fill: 'unset',
        },
        '& path': {
            stroke: 'var(--gd-text-dark-color)',
        },
    },
    valueContainer: {
        padding: 'unset !important',
    },
    singleValue: {
        marginLeft: `${convertToRem(0)} !important`,
        color: 'currentcolor !important',
    },
    dropdownIndicator: {
        padding: 'unset !important',
    },
    option: {
        backgroundColor: 'unset !important',
        color: 'var(--gd-text-dark-color) !important',
        fontWeight: 'normal !important',
    },
    optionFocused: {
        backgroundColor: 'var(--gd-background-dark-color) !important',
        color: 'var(--gd-text-dark-color) !important',
        fontWeight: 'normal !important',
    },
    menu: {
        width: `${convertToRem(350)} !important`,
    },
})

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    const css = useWorkingDaysSelectEditorStyle()
    return (
        <components.DropdownIndicator {...props}>
            <div className={css.dropDonwnIcon}>
                <IconAssets.ToothWheel />
            </div>
        </components.DropdownIndicator>
    )
}

const MenuOptions: React.FC<OptionProps> = (props: OptionProps) => {
    const { data, isSelected }: any = props
    return (
        <components.Option {...props}>
            {isSelected && '➧'} {data.label}
        </components.Option>
    )
}

const WorkingDaysSelectEditor = ({ userValue, onChange, options }: { userValue: any; onChange: (change: any) => void; options: any[] }) => {
    const css = useWorkingDaysSelectEditorStyle()
    return (
        <Select
            components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: MenuOptions }}
            value={userValue}
            placeholder=''
            isSearchable={false}
            isMulti={false}
            options={options}
            controlShouldRenderValue={false}
            onChange={onChange}
            classNames={{
                container: () => classNames(css.container),
                control: () => classNames(css.control),
                valueContainer: () => classNames(css.valueContainer),
                singleValue: () => classNames(css.singleValue),
                dropdownIndicator: () => classNames(css.dropdownIndicator),
                menu: () => classNames(css.menu),
                option: ({ isFocused }: any) => classNames(isFocused ? css.optionFocused : css.option),
            }}
        />
    )
}

const buildPutValue = ({ value }: { label: string; value: string }) => {
    const values = value.split('_')
    return {
        from: values[0],
        to: values[1],
        workingRange: {
            startSlot: values[2],
            // endSlot: 36
            endSlot: values[3],
        },
    }
}

const buildSelectValue = (lang: Lang, from: DayOfWeek, to: DayOfWeek, firstSlot: number, lastSlot: number) => {
    const literalFrom = lang.daysOfWeek.find((day) => day.value === from) ?? lang.daysOfWeek[0]
    const literalTo = lang.daysOfWeek.find((day) => day.value === to) ?? lang.daysOfWeek[4]
    const dayFromNumber = lang.daysOfWeek.indexOf(literalFrom)
    const dayToNumber = lang.daysOfWeek.indexOf(literalTo)
    const dayCount = (dayToNumber - dayFromNumber + 1 + 7) % 7
    return {
        label: lang.worloadEvaluationPersonalResult.weekFormatRange(
            literalFrom?.label?.toLocaleLowerCase(),
            literalTo?.label?.toLocaleLowerCase(),
            dayCount,
            getStringTimeAccordingToSlot(firstSlot),
            getStringTimeAccordingToSlot(lastSlot),
        ),
        value: `${literalFrom?.value}_${literalTo?.value}_${firstSlot}_${lastSlot}`,
    }
}

export default WorkingDaysEditor
