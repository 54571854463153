import { EGoal } from '../../models'
import UserPayload from '../../models/UserPayload'
import GreetFeaturingComponent from '../share/GreetFeaturingComponent'
import PersonalScoring from './PersonalScoring'
import PlanetFriendlyBadge from './PlanetFriendlyBadge'

interface IndividualUserWidgetProps {
    userPayload: UserPayload
    eGoal: EGoal
}

const IndividualUserWidget = (props: IndividualUserWidgetProps) => {
    return <GreetFeaturingComponent {...props} nextDef={PersonalScoring} legacyDef={PlanetFriendlyBadge} />
}

export default IndividualUserWidget
