import { useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import IconAssets from '../assets/icon/IconAssets'
import illustrationAssets from '../assets/illustration/IllustrationAssets'
import { useInstance, useStore, useUser } from '../contexts'
import { EGoal, GoalDto, GoalProps } from '../models'
import { Cohort } from '../models/Cohort'
import CohortPayload from '../models/CohortPayload'
import UserPayload from '../models/UserPayload'
import GoalCard from './goalCard/GoalCard'
import GoalTitle from './goalCard/GoalTitle'
import { MediaQueryBreakpoints } from './MediaQueryBreakpoints'
import { isAdminUsingAnimatorMode } from './rolesUtils'
import { GreetButton } from './share/GreetButton'
import { filterCohortsOnIds, filterCollectiveGoalsOnUserRole, matchingCohort } from './utils'

const NB_GOALS_IN_LINE = 4

const useStyles = createUseStyles({
    goalContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    goalsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        width: '100%',
    },
    goalsMobileWrapper: {
        display: 'none',
    },
    goals: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        rowGap: '40px',
    },
    showAllGoalsArea: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0 6px',
    },
    goalTitle: {
        display: 'none',
    },
    titledGoals: {
        display: 'none',
    },
    [MediaQueryBreakpoints.MOBILE_AND_TABLETTE_AND_TABLETTE_XL]: {
        goalContainer: {
            padding: '0 20px',
        },
        showAllGoals: {
            margin: 'auto',
        },
        goals: {
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '90vw',
            flexWrap: 'nowrap',
            overflowY: 'hidden',
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            width: '100%',
            rowGap: 'unset',
        },
        goalsMobileWrapper: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            width: '100%',
        },
        goalsWrapper: {
            display: 'none',
        },

        goalTitle: {
            display: 'block',
        },
        showAllGoalsArea: {
            display: 'none',
        },
        titledGoals: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
        },
    },
})

export default function Goals() {
    const { customer } = useInstance()
    const [allGoalsDisplayed, setAllGoalDisplayed] = useState<boolean>(false)
    const styles = useStyles()
    const { user, lang } = useUser()
    const { state } = useStore()
    const { userPayload, cohortsPayload } = state
    const [goals, setGoals] = useState<GoalProps[]>([])
    const [groupedGoals, setGroupedGoals] = useState<GoalProps[][]>([])

    function isGoalActiveForUser(goal: GoalDto): boolean {
        return goal.active && (goal.individualGoal !== null || filterCollectiveGoalsOnUserRole(goal, user)?.length > 0)
    }

    function getGoalPayloads(goal: GoalDto, userCohorts: Cohort[]): Array<{ cohort?: Cohort; value: UserPayload | CohortPayload }> {
        const payloads: Array<{ cohort?: Cohort; value: UserPayload | CohortPayload }> = []
        if (goal?.individualGoal) {
            payloads.push({ value: { ...goal?.individualGoal } as any })
        } else {
            payloads.push({ value: { active: false } as any })
        }

        if (goal?.collectiveGoals?.length > 0) {
            let filteredCollectiveGoals: Cohort[] = [...goal.collectiveGoals]

            if (isAdminUsingAnimatorMode(user)) {
                filteredCollectiveGoals = filterCohortsOnIds(goal.collectiveGoals, user?.cohortIds)
            }
            const sortedCohorts = filteredCollectiveGoals.sort((a, b) => a.cohortName.localeCompare(b.cohortName))
            for (let i = 0; i < sortedCohorts.length; i++) {
                const payload = cohortsPayload?.find((item) => matchingCohort(item, sortedCohorts[i]))
                const displayedCohort = userCohorts?.find((item) => matchingCohort(item, sortedCohorts[i])) ?? sortedCohorts[i]
                if (payload != null) {
                    payloads.push({
                        cohort: { cohortId: displayedCohort.cohortId, cohortName: displayedCohort.cohortName, cohortDisplayName: displayedCohort.cohortDisplayName },
                        value: { ...sortedCohorts[i] } as any,
                    })
                } else continue
            }
        }

        return payloads
    }

    const getGoalProps = useCallback(
        (goalDto: GoalDto): Array<GoalProps> => {
            switch (goalDto.egoal) {
                case EGoal.CARBON_FOOTPRINT: {
                    const goalIsActive = isGoalActiveForUser(goalDto)
                    const payloads = goalIsActive ? getGoalPayloads(goalDto, state.userCohorts) : []

                    const result: GoalProps = {
                        id: EGoal.CARBON_FOOTPRINT,
                        title: lang.goal.typeInfo.carbonFootPrint.title,
                        theme: lang.goal.typeInfo.carbonFootPrint.theme,
                        lineColor: 'var(--gd-goal-carbon-footprint-color)',
                        textColor: 'var(--gd-goal-text-color)',
                        illustration: illustrationAssets.CarbonFootprintImage,
                        iconActive: IconAssets.CarbonFootprintIcon('var(--gd-goal-carbon-footprint-color)'),
                        iconInactive: IconAssets.CarbonFootprintIcon('var(--gd-goal-carbon-footprint-color)'),
                        startDate: goalDto.startDate,
                        endDate: goalDto.endDate,
                        evolution: undefined,
                        active: goalIsActive,
                    }

                    if (goalIsActive && payloads.length > 0) {
                        return payloads.map((payload) => {
                            return {
                                ...result,
                                evolution: (payload.value as any)?.evolution ?? 0,
                                cohort: payload.cohort,
                                active: (payload.value as any)?.active ?? goalIsActive,
                            }
                        })
                    }
                    return [result]
                }
                case EGoal.QVT: {
                    return []
                }
            }
        },
        [lang.goal, userPayload, cohortsPayload],
    )

    const sortGoalsByCohort = (a: GoalProps, b: GoalProps) => {
        if ((a?.cohort?.cohortDisplayName ?? '') < (b?.cohort?.cohortDisplayName ?? '')) {
            return -1
        }
        if ((a?.cohort?.cohortDisplayName ?? '') > (b.cohort?.cohortDisplayName ?? '')) {
            return 1
        }
        return 0
    }

    useEffect(() => {
        const goalDtos: GoalDto[] = userPayload?.goals ?? []
        const filteredGoals: GoalDto[] = goalDtos.filter((goalDto) => goalDto.egoal === EGoal.CARBON_FOOTPRINT && customer.goals.includes(goalDto.egoal))
        setGroupedGoals(filteredGoals.map(getGoalProps).map((element) => element.sort(sortGoalsByCohort)))
        setGoals(filteredGoals.flatMap(getGoalProps).sort(sortGoalsByCohort))
    }, [customer.goals, user, userPayload, cohortsPayload, lang.code, allGoalsDisplayed])

    return (
        <div className={styles.goalContainer}>
            <div className={styles.goalsWrapper}>
                <div className={styles.goals}>
                    {(allGoalsDisplayed ? goals : goals.slice(0, NB_GOALS_IN_LINE)).map((goal, index) => {
                        return (
                            <GoalCard
                                key={index}
                                goal={goal}
                                showCardTitle={index % NB_GOALS_IN_LINE === 0 || (index > 0 && goals[index - 1].id !== goal.id)}
                                showPeriod={index % NB_GOALS_IN_LINE === 1}
                                separateCard={index < goals.length - 1 && goals[index + 1].id !== goal.id}
                                ranking={userPayload?.ranking ?? {}}
                            />
                        )
                    })}
                </div>

                {goals?.length > NB_GOALS_IN_LINE ? (
                    <div className={styles.showAllGoalsArea}>
                        <GreetButton
                            type='secondary-inv'
                            label={allGoalsDisplayed ? lang.home.goals.reduceDisplayedGoals : lang.home.goals.displayAllGoals}
                            icon={allGoalsDisplayed ? <IconAssets.Minus /> : <IconAssets.PlusLightIcon />}
                            onClick={() => setAllGoalDisplayed((prev) => !prev)}
                        />
                    </div>
                ) : null}
            </div>
            <div className={styles.goalsMobileWrapper}>
                {groupedGoals.map((items: GoalProps[], index) => {
                    return (
                        <div key={index} className={styles.titledGoals}>
                            <div className={styles.goalTitle}>
                                <GoalTitle
                                    goal={items[0]}
                                    showPeriod={true}
                                />
                            </div>
                            <div className={styles.goals}>
                                {items.map((goal, index) => (
                                    <GoalCard key={index} goal={goal} showCardTitle={false} />
                                ))}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
